import React, { Component } from 'react'
import { withI18next } from '../../lib/withI18next'
import { withRouter } from 'react-router'
import { goToAnchor } from 'react-scrollable-anchor'
import { Grid, Image, Responsive } from 'semantic-ui-react'

import CGHeadline from '../../components/common/CGHeadline'

import { styles } from './styles'

function getStepContent(step) {
  switch (step) {
    case 0:
      return 'firstLevel'
    case 1:
      return 'secondLevel'
    case 2:
      return 'thirdLevel'
    case 3:
      return 'fourthLevel'
    case 4:
      return 'codeGenius'
    default:
      return 'Unknown step'
  }
}
class Roadmap extends Component {
  handleStep = step => () => {
    if (this.props.location.pathname === '/corecourses') {
      goToAnchor(getStepContent(step))
    } else {
      this.props.history.push('/corecourses#' + getStepContent(step))
    }
  }

  renderIconStep(levelName, index) {
    if (levelName === 'Code Genius') {
      return (
        <Grid.Column>
          <Image
            src={`${process.env.PUBLIC_URL}/images/CGFace.png`}
            style={styles.logo}
          />
        </Grid.Column>
      )
    } else {
      return (
        <Grid.Column>
          <div style={styles.iconStepContainer}>
            <div style={styles.iconStepText}>{levelName}</div>
            <div style={styles.stepConnector} />
          </div>
        </Grid.Column>
      )
    }
  }

  renderIconStepMobile(levelName, index) {
    if (levelName === 'Code Genius') {
      return (
        <Grid.Row centered>
          <Image
            src={`${process.env.PUBLIC_URL}/images/CGFace.png`}
            style={{ ...styles.logo, ...styles.iconMobile }}
          />
        </Grid.Row>
      )
    } else {
      return (
        <Grid.Row centered>
          <div style={{ ...styles.iconStepContainer, ...styles.iconMobile }}>
            <Grid.Column centered style={styles.iconMobileText}>
              {levelName}
            </Grid.Column>
            <div style={styles.mobileConnector} />
          </div>
        </Grid.Row>
      )
    }
  }

  render() {
    const { t } = this.props
    const steps = [
      'Little Genius',
      'Genius Explorer',
      'Genius Innovator',
      'Code Expert',
      'Code Genius'
    ]
    return (
      <div style={{ padding: '2em 0' }}>
        <CGHeadline
          header={t('roadmap.header')}
          subHeader={t('roadmap.subheader')}
        />
        <Responsive minWidth={1024}>
          <Grid>
            <Grid.Row columns={5} style={{ padding: '50px' }} centered>
              {steps.map(step => {
                return this.renderIconStep(step)
              })}
            </Grid.Row>
          </Grid>
        </Responsive>
        <Responsive maxWidth={1023}>
          <Grid style={{ paddingTop: '3em' }}>
            {steps.map(step => {
              return this.renderIconStepMobile(step)
            })}
          </Grid>
        </Responsive>
      </div>
    )
  }
}

export default withRouter(withI18next(['corecourses'])(Roadmap))
