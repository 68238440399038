import React, {Component} from 'react'
import {Grid, Label, Pagination, Popup, Table} from 'semantic-ui-react'
import _ from 'lodash'
import {withRouter} from 'react-router'
import {formatDateDisplay, isUndefined} from '../../../common/util'
import {STUDENT_DEMO_STATUS, STUDENT_DEMO_STATUS_TEXT} from '../../../common/const'

class DataList extends Component {

    handlePaginationChange = (e, data) => {
        this.props.onPaginationChange(data.activePage)
    }

    handleSort = clickedColumn => {
        let tableDirection = 'asc'
        if (this.props.sorting.column === clickedColumn) {
            tableDirection = this.props.sorting.direction === 'asc' ? 'desc' : 'asc'
        }
        this.props.onSortingChange(clickedColumn, tableDirection)
    }

    reformatName(firstName, lastName, nickName) {
        let result = firstName + ' ' + lastName + ' (' + nickName + ')'
        if (isUndefined(firstName) && isUndefined(lastName)) {
            result = nickName
        }
        return result
    }

    reformatSchool(school, grade) {
        let result = school + ' (' + grade + ')'
        if (isUndefined(grade)) {
            result = school
        }
        return result
    }

    render() {
        const {sorting, tableData, activePage = 1, totalPages = 0, totalData = 0, pageSize = 10} = this.props
        const {column, direction} = sorting

        return (
            <Grid style={{margin: '0'}}>
                <Table celled fluid sortable compact style={{padding: '0'}} size='small'>
                    <Table.Header>
                        <Table.Row>
                        <Table.HeaderCell textAlign='center' width='1'
                                              sorted={column === 'studentDemoId' ? convertSorting(direction) : null}
                                              onClick={() => this.handleSort('studentDemoId')}>ID</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'
                                              sorted={column === 'nickName' ? convertSorting(direction) : null}
                                              onClick={() => this.handleSort('nickName')}>Name</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'
                                              sorted={column === 'age' ? convertSorting(direction) : null}
                                              onClick={() => this.handleSort('age')}>Age</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'
                                              sorted={column === 'mobileNumber' ? convertSorting(direction) : null}
                                              onClick={() => this.handleSort('mobileNumber')}>Tel</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='1'
                                              sorted={column === 'branchName' ? convertSorting(direction) : null}
                                              onClick={() => this.handleSort('branchName')}>Branch</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='2'
                                              sorted={column === 'createdDate' ? convertSorting(direction) : null}
                                              onClick={() => this.handleSort('createdDate')}>Registered</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='2'
                                              sorted={column === 'availableDate' ? convertSorting(direction) : null}
                                              onClick={() => this.handleSort('availableDate')}>Available</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='2'
                                              sorted={column === 'couponExpirationDate' ? convertSorting(direction) : null}
                                              onClick={() => this.handleSort('couponExpirationDate')}>Coupon
                                Exp.</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='1'
                                              sorted={column === 'status' ? convertSorting(direction) : null}
                                              onClick={() => this.handleSort('status')}>Status</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='3'
                                              sorted={column === 'remark' ? convertSorting(direction) : null}
                                              onClick={() => this.handleSort('remark')}>Remark</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='1'></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {_.isEmpty(tableData) ?
                            <Table.Row>
                                <Table.Cell verticalAlign='middle' warning textAlign='center' colSpan='11'>
                                    No Data Found
                                </Table.Cell>
                            </Table.Row> :
                            _.map(tableData, (data, index) => (
                                <Table.Row key={index}>
                                    <Table.Cell textAlign='center'
                                                verticalAlign='middle'>{data.studentDemoId}</Table.Cell>
                                    <Table.Cell verticalAlign='middle'>{data.nickName}</Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign='center'>{data.age}</Table.Cell>
                                    <Table.Cell verticalAlign='middle'>{data.mobileNumber}</Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign='center'>{data.branchShortName}</Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign='center'>
                                        {formatDateDisplay(data.createdDate)}
                                    </Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign='center'>
                                        <Popup
                                            trigger={<span>{formatDateDisplay(data.availableDate)}</span>}
                                            content={data.availableTime}
                                        />
                                    </Table.Cell>
                                    <Table.Cell verticalAlign='middle'
                                                textAlign='center'>{formatDateDisplay(data.couponExpirationDate)}</Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign='center'>
                                        <Label size='small' color={statusColor[data.status]} 
                                            content={STUDENT_DEMO_STATUS_TEXT[data.status]} />
                                    </Table.Cell>
                                    <Table.Cell verticalAlign='middle'>{data.remark}</Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign='center'>
                                        <button className='ui icon button'
                                                onClick={() => this.props.onEditItemClick(data)}
                                                style={{backgroundColor: 'white', padding: '0px'}}>
                                            <i className='pencil icon' style={{fontSize: '14px'}}></i>
                                        </button>
                                    </Table.Cell>
                                </Table.Row>
                            ))
                        }
                    </Table.Body>
                </Table>
                <Grid.Row columns={2}>
                    <Grid.Column textAlign={window.innerWidth > 760 ? 'left':'center'} verticalAlign='middle' computer='8' tablet='4' mobile='16'>
                        {totalData < 1 || !totalData
                            ? null
                            : `${((activePage - 1) * pageSize) + 1}-${((activePage - 1) * pageSize) + tableData.length} of ${totalData}`
                        }
                    </Grid.Column>
                    <Grid.Column textAlign={window.innerWidth > 760 ? 'right':'center'} verticalAlign='middle' computer='8' tablet='12' mobile='16'>
                        {totalPages < 1 || !totalPages
                            ? null
                            : <Pagination
                                defaultActivePage={activePage}
                                pointing
                                secondary
                                boundaryRange={window.innerWidth > 760 ? 1:0}
                                siblingRange={window.innerWidth > 760 ? 1:0}
                                ellipsisItem={window.innerWidth > 760 ? undefined : null}
                                totalPages={totalPages}
                                onPageChange={this.handlePaginationChange}
                            />
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

export default withRouter(DataList)

const convertSorting = type => {
    return type === 'asc' ? 'ascending' : 'descending'
}

const statusColor = {
    [STUDENT_DEMO_STATUS.LEAD_IN]: undefined,
    [STUDENT_DEMO_STATUS.CONFIRM]: 'blue',
    [STUDENT_DEMO_STATUS.REJECT]: 'black',
    [STUDENT_DEMO_STATUS.ALREADY_DEMO]: 'yellow',
    [STUDENT_DEMO_STATUS.WIN]: 'green',
    [STUDENT_DEMO_STATUS.LOSE]: 'red',
}