export default {
  container: {
    padding: '50px 30px',
    fontSize: '14px'
  },
  dateInput: {
    width: '50%'
  },
  resetBorder: {
    borderRadius: '0'
  }
}