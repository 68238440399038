export default {
  "company": {
    "name": "Code Genius",
    "address": {
      "line1": "ศูนย์การค้าสยามพารากอน ชั้น 4",
      "line2": "โซนเอ็ดดูเทนเมนท์",
      "line3": "991 ถนนพระราม 1 แขวงปทุมวัน",
      "line4": "เขตปทุมวัน กรุงเทพมหานคร 10330"
    },
    "openHour": {
      "weekDay": "วันจันทร์ - วันศุกร์\nเวลา 10.00 - 19.00 น.",
      "weekEnd": "วันเสาร์ - วันอาทิตย์\nเวลา 09.00 - 20.00 น."
    }
  }
}
