import React from 'react'
import CardMedia from '@material-ui/core/CardMedia'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'
import {withStyles} from '@material-ui/core/styles'
import {CGButton} from '../../common'
import {withTranslation} from 'react-i18next'
import styles from './styles'
import {ROLE, MAIN_BRANCH_ID} from '../../../common/const'
import {removeToken, validateBranchId} from "../../../common/util";
import {withRouter} from "react-router";

class NavBar extends React.Component {

    signout = async () => {
        await removeToken()
        this.props.history.push('/home')
    }

    render() {
        const {classes, t, currentUser, changeLanguage, showLang} = this.props
        const isMainBranch = validateBranchId([MAIN_BRANCH_ID])
        return (
            <div className={classes.container}>
                <Toolbar position='static'>
                    <Grid container>
                        <Grid item md={3} xs={5}>
                            <div className={classes.logoContainer}>
                                <div>
                                    <CardMedia
                                        component='img'
                                        image={`${process.env.PUBLIC_URL}/images/logo_300.png`}
                                        title={t('common:company.name')}
                                        onClick={() => this.props.history.push('/home')}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={9}
                            xs={7}
                            className={classes.controllerContainer}>
                            <Toolbar className={classes.toolBar}>
                                {showLang ? (
                                    <div className={classes.languageSelector}>
                                        <CGButton
                                            className={classes.languageButton}
                                            onClick={() => {
                                                changeLanguage('en')
                                            }}>
                                            {t('en')}
                                        </CGButton>
                                        <div className={classes.seperator}/>
                                        <CGButton
                                            className={classes.languageButton}
                                            onClick={() => {
                                                changeLanguage('th')
                                            }}>
                                            {t('th')}
                                        </CGButton>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {currentUser ? (
                                    <CGButton
                                        className={classes.menuButton}
                                        variant='contained'
                                        onClick={this.signout}>
                                        Sign Out
                                    </CGButton>
                                ) : (
                                    <CGButton
                                        className={classes.menuButton}
                                        variant='contained'
                                        href={currentUser ? '/schedule' : '/signin'}>
                                        {currentUser
                                            ? `${t('hello')}${currentUser.name}`
                                            : t('signIn')}
                                    </CGButton>
                                )}
                            </Toolbar>
                            <div>
                                <Toolbar className={classes.menuBar}>
                                    {currentUser && (currentUser.role === ROLE.ADMIN || currentUser.role === ROLE.GOD ) ? (
                                        <React.Fragment>
                                            <CGButton href='/studentclass/add' className={classes.menuLink}>
                                                {t('link.pos')}
                                            </CGButton>
                                            <CGButton href='/receipt/search' className={classes.menuLink}>
                                                {t('link.receiptSearch')}
                                            </CGButton>
                                            <CGButton href='/student/search' className={classes.menuLink}>
                                                {t('link.studentSearch')}
                                            </CGButton>
                                            <CGButton href='/studentdemo/search' className={classes.menuLink}>
                                                {t('link.studentDemoSearch')}
                                            </CGButton>
                                            <CGButton href='/teacher/search' className={classes.menuLink}>
                                                {t('link.manageTeacher')}
                                            </CGButton>
                                            {/* <CGButton href='/classschedule' className={classes.menuLink}>
                                                {t('link.classSchedule')}
                                            </CGButton> */}
                                            <CGButton href='/studentchecklist' className={classes.menuLink}>
                                                {t('link.studentCheckList')}
                                            </CGButton>

                                            {isMainBranch && (
                                                <CGButton href='/code/search' className={classes.menuLink}>
                                                    {t('link.manageCodes')}
                                                </CGButton>
                                            )}
                                        </React.Fragment>
                                    ) : currentUser && currentUser.role === ROLE.ACCOUNT ? (
                                        <React.Fragment>
                                            <CGButton href='/receipt/search' className={classes.menuLink}>
                                                {t('link.receiptSearch')}
                                            </CGButton>
                                            <CGButton href='/teacher/report' className={classes.menuLink}>
                                                {t('link.teacherReport')}
                                            </CGButton>
                                            <CGButton href='/accountingSummary' className={classes.menuLink}>
                                                {t('link.accountingSummary')}
                                            </CGButton>
                                            <CGButton href='/studentchecklist' className={classes.menuLink}>
                                                {t('link.studentCheckList')}
                                            </CGButton>
                                        </React.Fragment>
                                    ) : currentUser && currentUser.role === ROLE.CUSTOMER ? (
                                        <React.Fragment>
                                            <CGButton href='/StudentDemo/Add' className={classes.menuLink}>
                                                {t('link.studentDemoAdd')}
                                            </CGButton>
                                            <CGButton href='/StudentDemo/Search' className={classes.menuLink}>
                                                {t('link.studentDemoSearch')}
                                            </CGButton>
											<CGButton href='/classschedule' className={classes.menuLink}>
												{t('link.classSchedule')}
											</CGButton>
                                            <CGButton href='/AddStudent' className={classes.menuLink}>
                                                {t('link.addStudent')}
                                            </CGButton>
                                            <CGButton href='/AttendanceCheck' className={classes.menuLink}>
                                                {t('link.attendanceCheck')}
                                            </CGButton>
                                        </React.Fragment>
                                    ) : currentUser && currentUser.role === ROLE.TEACHER ? (
                                        <React.Fragment>
                                            <CGButton href='/assessment' className={classes.menuLink}>
                                                {t('link.assessment')}
                                            </CGButton>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <CGButton href='/home' className={classes.menuLink}>
                                                {t('link.home')}
                                            </CGButton>
                                            <CGButton href='/corecourses' className={classes.menuLink}>
                                                {t('link.courses')}
                                            </CGButton>
                                            <CGButton href='/cggallery' className={classes.menuLink}>
                                                {t('link.cggallery')}
                                            </CGButton>
                                            <CGButton href='/Codeo' className={classes.menuLink}>
                                                {t('link.videos')}
                                            </CGButton>
                                        </React.Fragment>
                                    )}
                                </Toolbar>
                            </div>
                        </Grid>
                    </Grid>
                </Toolbar>
            </div>
        )
    }
}

export default withRouter(withTranslation('navbar')(withStyles(styles)(NavBar)))
