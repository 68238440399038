import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router'
import { Accordion, Button, Card, Form, Grid, Icon, Input, Label, Dropdown, SearchResult } from 'semantic-ui-react'
import { withI18next } from '../../../lib/withI18next'
import apiClient from '../../../apis'
import styles from './styles'
import MainLayout from '../../../layouts/MainLayout'
import { ROLE } from '../../../common/const'
import ReceiptList from './ReceiptList'
import ReceiptPrintFormV2 from '../../../components/ReceiptPrintFormV2'
import { validateRole, getUserRole, getCurrentUser } from '../../../common/util'
import moment from 'moment'

class ReceiptSearch extends React.Component {
	constructor(props) {
		super(props)
		let startOfMonth = moment().startOf('month').format('YYYY-MM-DD')
		let endOfMonth = moment().endOf('month').format('YYYY-MM-DD')
		this.state = {
			searchData: {
				branchId: '',
				receiptId: '',
				receiptNo: '',
				studentId: '',
				name: '',
				fromDate: getUserRole() === ROLE.ACCOUNT?startOfMonth:'',
				toDate: getUserRole() === ROLE.ACCOUNT?endOfMonth:'',
				orderBy: 'receiptId',
				orderType: 'desc',
				page: 1,
				pageSize: 20,
				status: ''
			},
			statusOptions:[
				{key: 'All', text: 'All', value: ''},
				{key: 'Active', text: 'Active', value: 'active'},
				{key: 'Canceled', text: 'Canceled', value: 'canceled'}
			],
			branchList: [],
			isAdmin: validateRole([ROLE.ADMIN]),
			adminBranch: null,
			total: undefined,
			branch: undefined,
			tableData: undefined,
			summary: undefined,
			isLoading: false,
			roleOptions: undefined,
			isShowTool: true
		}
		this.handleSortingChange = this.handleSortingChange.bind(this)
		this.handlePaginationChange = this.handlePaginationChange.bind(this)
		this.handleInputChange = this.handleInputChange.bind(this)
		this.printReceipt = this.printReceipt.bind(this)
	}

	componentDidMount() {
		const branchId = getCurrentUser().branchId

		if (branchId === 0){
			this.setState({
				isLoading: true,
				adminBranch: branchId,
			},this.getBranchList
			)
		}else{
			this.setState({
				isLoading: true,
				adminBranch: branchId,
				searchData: {
					...this.state.searchData,
					branchId: branchId
				}
			},this.searchReceipt
			)
		}
	}

	getBranchList = async() => {
		const result = await apiClient.branch.getAllBranches()

		if (result !== null){
			let arr = result.dataList.map((p, index) => {
                return {
                    key: p.branchId,
                    text: p.branchName,
                    value: p.branchId,
                }
			})

			if (getUserRole() === ROLE.ADMIN || getUserRole() === ROLE.GOD ){
				arr.unshift({key: 0, text: 'All', value: ''})
			}

			if (getUserRole() === ROLE.ACCOUNT){
				this.setState({
					searchData: {
						...this.state.searchData,
						branchId: 1
					}
				})
			}

		  	this.setState({ 
				branchList: arr,
				isLoading: false,
			})
		}

		this.searchReceipt()
	}


	searchReceipt = async() => {
		this.setState({
			isLoading: true
		})
		const data = this.state.searchData
		
		let searchData = {}
		for (const key in data) {
			if (data[key] !== '' && data[key] !== null && data[key] !== undefined && data[key] !== 'undefined') {
				searchData[key] = data[key]
			}
		}

		const result = await apiClient.course.searchReceipt(searchData)
		if (result) {
			this.setState({
				tableData: result.dataList,
				total: result.total,
				summary: result.summary,
				fromDateSelected: data.fromDate,
				toDateSelected: data.toDate,
				isLoading: false
			})
		}
	}

	handlePaginationChange = (activePage) => {
		this.setState(
			{
				searchData: {
					...this.state.searchData,
					page: activePage
				}
			},
			this.searchReceipt
		)
	}

	searchByInitialSetting = () => {
		this.setState({
				isLoading: true,
				searchData: {
					...this.state.searchData,
					orderBy: 'receiptNo',
					orderType: 'desc',
					page: 1
				}
			},
			this.searchReceipt
		)
	}
	
	handleDropdownChange = name => (event, { value }) => {
        this.setState({
            searchData: {
                ...this.state.searchData,
                [name]: value,
            }
        })
	}
	
	handleInputChange = (e, value) => {
		this.setState({
			searchData: {
				...this.state.searchData,
				[value.id]: value.value
			}
		})
	}

	handleSortingChange = (orderBy, orderType) => {
		this.setState(
			{
				searchData: {
					...this.state.searchData,
					orderBy: orderBy,
					orderType: orderType
				}
			},
			this.searchReceipt
		)
	}

	printReceipt = (branchId, receiptId, extraData) => {
		console.log(branchId)
		console.log(receiptId)
		console.log(extraData)
		if (receiptId !== null) {
			if (extraData) {
				const newTableData = this.state.tableData.map(data => {
					if (data.receiptId === receiptId) {
						return {
							...data,
							...extraData
						}
					} else {
						return data
					}
				})
				this.setState({ tableData: newTableData })
			}
			this.printForm.print(branchId, receiptId, null)
		} else {
			const data = this.state.searchData
			let searchData = {}
			for (const key in data) {
				if (data[key] !== '' && data[key] !== null && data[key] !== undefined && data[key] !== 'undefined') {
					searchData[key] = data[key]
				}
			}
			this.printForm.print(null, null, searchData)
		}
	}

	renderSearchCard = () => {
		const { branchId, receiptNo, receiptId, studentId, name, fromDate, toDate, status } = this.state.searchData

		return (
			<Card fluid>
                <Card.Content>
                    <Form fluid="true">
                        <Form.Group widths='equal'>
                            {this.state.adminBranch === 0 ? (
							<Form.Field>
                                <label>Branch</label>
								<Dropdown 
									fluid selection placeholder='All'
									onChange={this.handleDropdownChange('branchId')}
									options={this.state.branchList} 
									value={branchId}/>
                            </Form.Field>): null}
							<Form.Field>
                                <label>Status</label>
								<Dropdown 
									fluid selection placeholder='All'
									onChange={this.handleDropdownChange('status')}
									options={this.state.statusOptions} 
									value={status}/>
                            </Form.Field>
                            <Form.Field>
                                <label>Receipt NO</label>
								<Input id='receiptNo' fluid placeholder='' type='text'
									value={receiptNo} onChange={this.handleInputChange}
								/>
                            </Form.Field>
							<Form.Field>
								<label>Student ID</label>
								<Input id='studentId' fluid placeholder='' type='text'
									   value={studentId} onChange={this.handleInputChange} />
							</Form.Field>
							<Form.Field>
                                <label>Name</label>
								<Input id='name' fluid placeholder='' type='text'
									value={name} onChange={this.handleInputChange}
								/>
                            </Form.Field>
						</Form.Group>
						<Form.Group widths='equal'>
							<Form.Field >
							<label>Receipt Date</label>
                            <Grid.Column computer={8}>
                                <Input
                                    labelPosition='left'
                                    id='fromDate'
                                    type='date'
                                    value={fromDate}
                                    onChange={this.handleInputChange}
                                    size='small'
                                    style={styles.fromToDateInput}
                                >
                                    <Label>From</Label>
                                    <input style={styles.fromDateInput} />
                                </Input>
                                <Input
                                    labelPosition='left'
                                    id='toDate'
                                    type='date'
                                    value={toDate}
                                    onChange={this.handleInputChange}
                                    size='small'
                                    style={styles.fromToDateInput}
                                >
                                    <Label style={styles.ToDateLabel}>To</Label>
                                    <input style={styles.ToDateInput} />
                                </Input>
                            </Grid.Column>
							</Form.Field>
							<Form.Field/>
						</Form.Group>
                    </Form>
                </Card.Content>
                <Card.Content extra textAlign='right'>
                    <Button primary loading={this.state.isLoading} onClick={() => this.searchByInitialSetting()}>
                        Search
                    </Button>
                </Card.Content>
            </Card>
		)
	}
	

	render() {
		return (
			<div>
				<MainLayout id='react-no-print'>
					<div style={styles.container}>
						<Grid centered stackable>
							<Grid.Row>
								<Grid.Column>
									<Accordion fluid>
										<Accordion.Title 
											active={this.state.isShowTool}                
											onClick={() => this.setState({isShowTool: !this.state.isShowTool})}
										>
											<Icon name='dropdown'/>
											Search
										</Accordion.Title>
										<Accordion.Content active={this.state.isShowTool}>
											{this.renderSearchCard()}
										</Accordion.Content>
									</Accordion>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column>
									<ReceiptList
										onPrintReceipt={this.printReceipt}
										refresh={this.searchReceipt}
										tableData={this.state.tableData}
										onPaginationChange={this.handlePaginationChange}
										onSortingChange={this.handleSortingChange}
										totalPages={Math.ceil(this.state.total / this.state.searchData.pageSize)}
										totalData={this.state.total}
										summary={this.state.summary}
										pageSize={this.state.searchData.pageSize}
										activePage={this.state.searchData.page}
										fromDate={this.state.fromDateSelected}
										toDate={this.state.toDateSelected}
										sorting={{
											column: this.state.searchData.orderBy,
											direction: this.state.searchData.orderType
										}}
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</div>
				</MainLayout>
				<ReceiptPrintFormV2 ref={(el) => (this.printForm = el)} />
			</div>
		)
	}
}

export default withRouter(withI18next()(withStyles(styles)(ReceiptSearch)))
