export default {
    container: {
        padding: '50px 30px',
        fontFamily: "\"Varela Round\", \"Pridi\", \"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
        display: 'flex'
    },
    nationalityItem: {
        marginRight: '25px'
    },
    availableDateLabel: {
        fontWeight: '500',
        alignSelf: 'center',
        lineHeight: '1.5em'
    },
    nationalityButton: {
        margin: '10px'
    },
    primaryColor: {
        color: '#00B3C5'
    },
    successContainer: {
        padding: '2em',
        margin: 'auto',
        lineHeight: '2em',
        fontSize: '1.2em',
        width: 'auto'
    },
    qrContainer: {
        width: '300px'
    },
    successButton: {
        marginTop: '1em'
    },
    dimmer: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: '0',
        left: '0'
    }
}