import React, { Component } from 'react'
import { Button, Grid, Input, Table } from 'semantic-ui-react'
import { withRouter } from 'react-router'
import apiClient from '../../apis'
import { getCurrentUser, getUserRole } from '../../common/util'
import { ROLE } from '../../common/const'

class TeacherCheckList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tableData: props.tableData,
            editIndex: -1,
            isAdmin: getUserRole() === ROLE.ADMIN || getUserRole() === ROLE.GOD 
        }

        this.handleInputChange = this.handleInputChange.bind(this)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.tableData !== state.tableData) {
            return {
                tableData: props.tableData
            }
        }
    }

    handleInputChange = (e, value) => {
        this.state.tableData[this.state.editIndex][value.id] = value.value
        this.forceUpdate()
    }

    getTeacherCheckList() {
        this.setState({editIndex: -1})
        this.props.getTeacherCheckList()
    }

    onSave(idx) {
        let userDetail = getCurrentUser()
        this.setState({editIndex: -1})

        let data = this.state.tableData[idx]
        let form = {
            teacherId: data.teacherId,
            checkInTime: data.checkInTime,
            checkOutTime: data.checkOutTime,
            userId: userDetail.userId
        }

        apiClient.teacher.setTeacherByAdmin(form)
            .then(res => {
                this.getTeacherCheckList()
            })
    }

    render() {
        const {tableData, editIndex} = this.state

        return (
            <Grid style={{margin: '0'}}>
                <Table celled sortable style={{padding: '0'}}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell textAlign='center' width='1'></Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='3'>Teacher</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='2'>Check In</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='2'>Check Out</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='1' hidden={!this.state.isAdmin}>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {!tableData || tableData.length === 0 ?
                            <Table.Row><Table.Cell verticalAlign='middle' warning textAlign='center' colSpan='5'>
                                No Data Found</Table.Cell></Table.Row> :
                            tableData.map((item, index) => (
                                <Table.Row key={index}>
                                    <Table.Cell verticalAlign='middle' textAlign='center'>{index + 1}</Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign='center'>
                                        {item.firstName} {item.lastName} ({item.nickName})
                                    </Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign='center'>
                                        {index === editIndex ?
                                            <Input id='checkInTime' placeholder='HH:mm' type='time'
                                                   value={item.checkInTime} onChange={this.handleInputChange}/>
                                            : <label>{item.checkInTime}</label>
                                        }
                                    </Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign='center'>
                                        {index === editIndex ?
                                            <Input id='checkOutTime' placeholder='HH:mm' type='time'
                                                   value={item.checkOutTime} onChange={this.handleInputChange}/>
                                            : <label>{item.checkOutTime}</label>
                                        }
                                    </Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign='center' hidden={!this.state.isAdmin}>
                                        {index === editIndex ?
                                            <div style={{whiteSpace: 'nowrap'}}>
                                                <Button positive size='small'
                                                        onClick={() => this.onSave(index)}>
                                                    Save
                                                </Button>
                                                <Button negative size='small'
                                                        onClick={() => this.getTeacherCheckList()}>
                                                    Cancel
                                                </Button>
                                            </div> :
                                            <Button primary size='small' disabled={editIndex !== -1}
                                                    onClick={() => this.setState({editIndex: index})}>
                                                Edit
                                            </Button>
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            ))
                        }
                    </Table.Body>
                </Table>
            </Grid>
        )
    }
}

export default withRouter(TeacherCheckList)
