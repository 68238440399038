import React from 'react'
import { withRouter } from 'react-router'
import qs from 'query-string'
import {
  Grid,
  Segment,
  Form,
  Input,
  Message,
  Divider
} from 'semantic-ui-react'

import MainLayout from '@layouts/MainLayout'
import CGButton from '../../../components/common/CGButton/index'

import { styles } from './styles'

class signin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      code: '',
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  signIn = () => {
    const hash = btoa(this.state.code.trim())
    this.props.history.push(`/Codeo/${hash}`)
  }

  showError = () => {
    const q = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    if (q.e) {
      return (
        <Message
          color='red'
          icon='exclamation circle'
          content={"This passcode is invalid or expired."}
          size='small'
          style={{textAlign:'left'}}
        />
      )
    }
  }

  renderContent() {
    return (
      <Form>
        <Form.Field>{this.showError()}</Form.Field>
        <Form.Field>
          <Input
            placeholder="Passcode"
            value={this.state.code}
            onChange={this.handleChange('code')}
          />
        </Form.Field>
        <Form.Field>
          <CGButton style={styles.signinButton} onClick={this.signIn}>
            Sign In
          </CGButton>
        </Form.Field>
        <Form.Field>
          <Divider style={styles.divider} />
        </Form.Field>
        <Form.Field>
          ** The passcode will be expired in 15 days after the first login **
        </Form.Field>
      </Form>
    )
  }

  render() {
    return (
      <MainLayout showLang={false}>
        <div>
          <Grid>
            <Grid.Row centered>
              <Segment style={styles.card}>{this.renderContent()}</Segment>
            </Grid.Row>
          </Grid>
        </div>
      </MainLayout>
    )
  }
}

export default withRouter(signin)
