export default (theme) => ({
    container: {
        backgroundColor: theme.palette.grey[200],
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    },
    logoSection: {
        backgroundColor: theme.palette.primary.darkest,
        height: '100%',
        width: '6em',
        padding: '1em 0.8em',
        marginLeft: '16px'
    },
    logoContainer: {
        height: '100%',
        width: '100%'
    },
    signInContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        flex: '1'

    },
    controllerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    toolBar: {
        justifyContent: 'flex-end',
        padding: '0'
    },
    menuBar: {
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        padding: '0',
        margin: '0 0 0 auto'
    },
    languageContainer: {
        backgroundColor: 'white',
        marginTop: '0.4em',
        // float: 'right',
        display: 'flex',
        justifyContent : 'flex-end',
        zIndex: '-1'
    },
    languageSelector: {
        margin: '0 16px',
        display: 'flex',

    },
    languageButton: {
        padding: '0.1em 0.3em',
        margin: '0',
        minHeight: '0',
        minWidth: '0'
    },
    seperator: {
        display: 'inline-block',
        width: '2px',
        backgroundColor: theme.palette.grey[500],
        margin: '0.25em 0.5em'
    }
});