export default {
    "title": "คอร์สเรียนหลัก",
    "description": "",
    "roadmap": {
        "header": "คอร์สเรียนหลัก",
        "subheader": "ค้นหาสิ่งที่เหมาะสมกับลูกของคุณ"
    },
    "detail" : {
        "header" : "Course Detail",
        "structure" : "Structure"
    },
    "schedule" : {
        "header" : "ตารางคอร์สเรียน"
    },
    "overview": {
        "firstLevel": {
            "name": "Little Genius",
            "description": "ปลูกฝังทักษะ Code Genius ขั้นพื้นฐาน ให้กับเด็กน้อยช่างจินตนาการ ผ่านกิจกรรมต่าง ๆ",
            "section": {
                "first": "Jujubes sweet roll sweet topping gingerbread gingerbread cheesecake. Chocolate bear claw jujubes sugar plum liquorice soufflé sweet.",
                "second": "Jelly dragée candy bonbon danish jelly beans tootsie roll. Jujubes sweet roll sweet topping gingerbread gingerbread cheesecake. Chocolate bear claw jujubes sugar plum liquorice soufflé sweet.",
                "third": "Jujubes sweet roll sweet topping gingerbread gingerbread cheesecake. Chocolate bear claw jujubes sugar plum liquorice soufflé sweet."
            },
            "structure": "Dog||ant||elephant"
        },
        "secondLevel": {
            "name": "Genius Explorer",
            "description": "สร้างแรงบันดาลใจให้เด็ก ๆ ออกค้นหา และสำรวจ ผ่านกิจกรรมสร้างสรรค์ที่น่าตื่นเต้น เพื่อพัฒนาทักษะ Code Genius ให้เก่งยิ่งขึ้น",
            "section": {
                "first": "Jujubes sweet roll sweet topping gingerbread gingerbread cheesecake. Chocolate bear claw jujubes sugar plum liquorice soufflé sweet.",
                "second": "Jelly dragée candy bonbon danish jelly beans tootsie roll. Jujubes sweet roll sweet topping gingerbread gingerbread cheesecake. Chocolate bear claw jujubes sugar plum liquorice soufflé sweet.",
                "third": "Jujubes sweet roll sweet topping gingerbread gingerbread cheesecake. Chocolate bear claw jujubes sugar plum liquorice soufflé sweet."
            },
            "structure": "Dog||ant||elephant"
        },
        "thirdLevel": {
            "name": "Genius Innovator",
            "description": "ปลดล็อคความคิดสร้างสรรค์ของเด็ก ๆ ให้สามารถนำทักษะ Code Genius มาปรับใช้ได้อย่างมั่นใจ ผ่านกิจกรรมที่ท้าทาย",
            "section": {
                "first": "Jujubes sweet roll sweet topping gingerbread gingerbread cheesecake. Chocolate bear claw jujubes sugar plum liquorice soufflé sweet.",
                "second": "Jelly dragée candy bonbon danish jelly beans tootsie roll. Jujubes sweet roll sweet topping gingerbread gingerbread cheesecake. Chocolate bear claw jujubes sugar plum liquorice soufflé sweet.",
                "third": "Jujubes sweet roll sweet topping gingerbread gingerbread cheesecake. Chocolate bear claw jujubes sugar plum liquorice soufflé sweet."
            },
            "structure": "Dog||ant||elephant"
        },
        "fourthLevel": {
            "name": "Code Expert",
            "description": "ค้นพบโลกของวิทยาการคอมพิวเตอร์ เรียนรู้การเขียนโปรแกรมจากขั้นพื้นฐาน และแก้ไขปัญหาในชีวิตจริง ด้วยเครื่องมือด้านการเขียนโปรแกรมที่เหมาะสม",
            "section": {
                "first": "Jujubes sweet roll sweet topping gingerbread gingerbread cheesecake. Chocolate bear claw jujubes sugar plum liquorice soufflé sweet.",
                "second": "Jelly dragée candy bonbon danish jelly beans tootsie roll. Jujubes sweet roll sweet topping gingerbread gingerbread cheesecake. Chocolate bear claw jujubes sugar plum liquorice soufflé sweet.",
                "third": "Jujubes sweet roll sweet topping gingerbread gingerbread cheesecake. Chocolate bear claw jujubes sugar plum liquorice soufflé sweet."
            },
            "structure": "Dog||ant||elephant"
        }
    },
    "button": {
        "schedule": "ตารางเรียน",
        "viewCourseDetail" : "View Course Detail",
        "hideCourseDetail" : "Hide Course Detail"
    },
    "en": "EN",
    "th": "TH",
    "signIn": "เข้าสู่ระบบ"
}