import React from 'react'
import { CircularProgress, Typography, Avatar } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'

const Spinner = ({ classes }) => {
    return (
        <div className={ classes.spinnerContainer }> 
            <div className={ classes.spinnerItem }>
                <CircularProgress size={46} thickness={1} color='secondary' style={{ position: 'absolute'}} />
                <Avatar alt='Code Genius' src={`${process.env.PUBLIC_URL}/images/CGFace.png`} style={{ position: 'absolute'}} />
            </div>
            <Typography className={ classes.loadingText } variant='caption' color='primary'>
                Loading
            </Typography>
        </div>
    )
}

export default withStyles(styles)(Spinner)