export const styles = {
  sliderControl: {
    padding: '1em 0.5em',
    margin: '0 0.5em',
    fontSize: '2.2em',
    color: 'rgba(255,255,255,0.5)',
    transform: 'scale(1, 1.7)',
    display: 'inline-block',
    cursor: 'pointer'
  },
  whatWeDoSection: {
    margin: '48px 0'
  },
  whatWeDoIcon: {
    width: '80px',
    margin: '0.5em auto'
  },
  textPrimary: {
    textAlign: 'center',
    color: '#00b3c5',
    fontSize: '1.4em',
    fontWeight: 500,
    fontFamily: '"Pridi", "Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: '1.16em'
  },
  textSecondary: {
    textAlign: 'center',
    color: '#f37e71',
    fontSize: '1.05em',
    fontWeight: 300,
    fontFamily:
      '"Varela Round", "K2D", "Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: '1.65em',
    whiteSpace: 'pre-wrap',
    marginTop: '1em'
  },
  quoteSection: {
    backgroundColor: '#FCD634',
    backgroundImage:
      'url("' + process.env.PUBLIC_URL + '/images/Home/quotebg.png")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right center',
    backgroundSize: '275px 275px',
    height: '170px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 2em',
    marginTop: '100px',
    marginLeft: 0,
    marginRight: 0
  },
  quoteContainer: {
    height: '200px',
    backgroundColor: '#00B3C5',
    color: 'white',
    borderRadius: '15px',
    padding: '2em',
    fontSize: '1.25em',
    alignItems: 'center',
    display: 'flex',
    lineHeight: '1.65em',
    marginTop: '-1rem'
  },
  roadmapSection: {
    margin: '1.5em 0'
  },
  featureContentBlock: {
    padding: 0
  },
  featureContentContainer: {
    margin: '0.5em 1.25em'
  },
  featureContent: {
    margin: '1em 0',
    color: '#757575',
    fontWeight: 'normal',
    fontFamily:
      '"Varela Round", "K2D", "Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: '1.65em',
    fontSize: '1.05em'
  },
  featureFooter: {
    margin: '1em 0.5em',
    color: '#00B3C5',
    textAlign: 'right',
    fontWeight: 'light',
    fontFamily:
      '"Varela Round", "K2D", "Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: '1.65em',
    fontSize: '1.05em'
  },
  videoSection: {
    padding: '0 3em'
  },
  video: {
    maxWidth: '427px',
    maxHeight: '240px'
  }
}
