import React from 'react'
import {Button, Form, Grid, Input} from 'semantic-ui-react'
import MainLayout from '@layouts/MainLayout'
import {withRouter} from 'react-router';
import {withI18next} from '../../lib/withI18next';
import styles from './styles'
import {withStyles} from '@material-ui/core/styles';
import apiClient from '../../apis';
import {CSVLink} from 'react-csv';


class AccountingSummary extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            searchData: {
                fromDate: '',
                toDate: '',
            },
            searchData2: {
                receiptFrom: '',
                amorFrom: '',
                amorTo: '',
            },
            csvData: [],
            fileName: '',
            isLoading: false
        }

        this.csvLink = React.createRef()
    }

    componentDidMount() {
        this.setDefaultCriteria()
    }

    exportAmortizationSpecificPeriodReport = async (searchData2) => {
        let data = await apiClient.receipt.getAmorInPeriodSummary(searchData2)
        let csvData

        
        if (data.status === 'success'){            
            csvData = data.data
        } else {
            csvData = ['error in getting the information']
        } 

        this.setState({
            fileName: 'AmorInPeriodSummary.csv',
            csvData: csvData
        })

        this.csvLink.current.link.click()
    }

    exportAmortizationReport = async (searchData) => {

        
        let data = await apiClient.receipt.getAccountingSummary(searchData)
        let csvData

        if (data.status === 'success'){            
            csvData = data.data
        } else {
            csvData = ['error in getting the information']
        } 

        this.setState({
            fileName: 'Detailed_Summary.csv',
            csvData: csvData
        })

        this.csvLink.current.link.click()
    }

    exportReceiptReport = async (searchData) => {

        
        let data = await apiClient.receipt.getAllReceipts(searchData)
        let csvData
        if (data.status === 'success'){            
            csvData = data.data
        } else {
            csvData = ['error in getting the information']
        } 

        this.setState({
            fileName: 'ReceiptsAndPayment.csv',
            csvData: csvData
        })

        this.csvLink.current.link.click()
    }

    handleInputChange = (e, value) => {
        this.setState({
            searchData: {
                ...this.state.searchData,
                [value.id]: value.value
            }
        })
    }

    handleInputChange2 = (e, value) => {
        this.setState({
            searchData2: {
                ...this.state.searchData,
                [value.id]: value.value
            }
        })
    }

    setDefaultCriteria() {
        let today = new Date()
        let toDate
        let mmTo = today.getMonth() + 1
        let yyyy = today.getFullYear()

        if (mmTo >= 10) {
            toDate = new Date(yyyy , 9 , 0 )
        } else if (mmTo >= 7) {
            toDate = new Date(yyyy , 6 , 0 )
        } else if (mmTo >= 4) {
            toDate = new Date(yyyy , 3 , 0 )
        } else {
            toDate = new Date(yyyy , 0 , 0 )
        }
        
        mmTo = toDate.getMonth() + 1

        let toDateString = toDate.getFullYear() + '-' + (mmTo < 10 ? '0' + mmTo : mmTo) + '-' + toDate.getDate()

        let mmTo2 = today.getMonth()
       
        let amorToDate = new Date(yyyy, mmTo2, 0)

        if (mmTo2 == 0) {
            mmTo2 = 12
        }


        let amorFromString = toDate.getFullYear() + '-' + (mmTo2 < 10 ? '0' + mmTo2 : mmTo2) + '-01'
        let amorToString = toDate.getFullYear() + '-' + (mmTo2 < 10 ? '0' + mmTo2 : mmTo2) + '-' + amorToDate.getDate()

        this.setState({
            searchData: {
                fromDate: '2021-01-01',
                toDate: toDateString
            },
            searchData2: {
                receiptFrom: '2021-01-01',
                amorFrom: amorFromString,
                amorTo: amorToString
            }
        })
    }

    render() {
        const {fromDate, toDate} = this.state.searchData
        const {receiptFrom, amorFrom, amorTo} = this.state.searchData2
        const {classes} = this.props


        return (
            <div>
                <MainLayout id='react-no-print'>
                    <div className={classes.container}>
                        <Grid centered stackable>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form>
                                        <p>This accounting summary works with receipt issued after Jan 1st, 2021.</p>
                                        <p>The report contains every receipt between "From Date" and "To Date."</p>
                                        <div className='three fields'>
                                            <div className='field'>
                                                <label>From Date</label>
                                                <Input id='fromDate' fluid placeholder='mm/dd/yyyy' type='date'
                                                       value={fromDate} onChange={this.handleInputChange}/>
                                            </div>
                                            <div className='field'>
                                                <label>To Date</label>
                                                <Input id='toDate' fluid placeholder='mm/dd/yyyy' type='date'
                                                       value={toDate} onChange={this.handleInputChange}/>
                                            </div>
                                        </div>
                                        <div className='two fields'>
                                            <div className='field'></div>
                                            <div className='right field' style={{ textAlign: 'right' }}>
                                                <Button className='ui primary button'
                                                    onClick={() => this.exportAmortizationReport(this.state.searchData)}>
                                                    Export Detailed Report for Amortization
                                                </Button>
                                            </div>
                                            <div className='right field' style={{ textAlign: 'right' }}>
                                                <Button className='ui primary button'
                                                    onClick={() => this.exportReceiptReport(this.state.searchData)}>
                                                    Export Receipts For Bank Consolidation
                                                </Button>
                                            </div>

                                        </div>

                                        <p>For amortization information within a specific period.</p>
                                        <div className='three fields'>
                                            <div className='field'>
                                                <label>Receipt From </label>
                                                <Input id='receiptFrom' fluid placeholder='mm/dd/yyyy' type='date'
                                                       value={receiptFrom} onChange={this.handleInputChange2}/>
                                            </div>
                                            <div className='field'>
                                                <label>Amortize From </label>
                                                <Input id='amorFrom' fluid placeholder='mm/dd/yyyy' type='date'
                                                       value={amorFrom} onChange={this.handleInputChange2}/>
                                            </div>
                                            <div className='field'>
                                                <label>Amortize To</label>
                                                <Input id='amorTo' fluid placeholder='mm/dd/yyyy' type='date'
                                                       value={amorTo} onChange={this.handleInputChange2}/>
                                            </div>
                                        </div>

                                        <div className='two fields'>
                                            <div className='field'></div>
                                            <div className='right field' style={{ textAlign: 'right' }}>
                                                <Button className='ui primary button'
                                                    onClick={() => this.exportAmortizationSpecificPeriodReport(this.state.searchData2)}>
                                                    Export Detailed Report for Amortization Within the specified period
                                                </Button>
                                            </div>
                                            

                                            <CSVLink
                                                data={this.state.csvData}
                                                filename={this.state.fileName}
                                                className="hidden"
                                                ref={this.csvLink}
                                                target="_blank"
                                            />
                                                
                                            
                                        </div>
                                    </Form>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </MainLayout>
            </div>
        )
    }
}

export default withRouter(withI18next()(withStyles(styles)(AccountingSummary)))

