import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { GridList, GridListTile, Typography, } from '@material-ui/core'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'

import styles from './styles'
import { isUndefined, isEmpty } from '../../common/util'

class PhotoGrid extends Component {

	render() {
		const { classes, data, courseName } = this.props
		const isDesktop = isWidthUp('md', this.props.width) ? true : false
		const isSmallMb = isWidthUp('sm', this.props.width) ? true : false

		let cols = 2
		let rows = isSmallMb ? 2 : 0

		if (data.length > 1) {
			cols = isDesktop ? 1 : 2
			rows = isDesktop ? 1 : isSmallMb ? 2 : 0
		}

		return (
			<Fragment>
				<div className={classes.root}>
					<GridList spacing={4} cellHeight={250} gutter="2em">
						{
							(isUndefined(data) || isEmpty(data))
							&& <Typography align='center' color='error'>Photos are coming soon.</Typography>
						}
						{data.map((d, index) => {
							return (
								<GridListTile
									className={classes.photoTileContainer}
									cols={cols}
									rows={rows}
									key={index}>
									<img src={d} alt={`${courseName}-${index}`} />
								</GridListTile>
							)
						})}
					</GridList>
				</div>
			</Fragment>
		)
	}
}

export default withWidth()(withStyles(styles)(PhotoGrid))
