export default {
    container: {
        padding: '30px 30px',
        fontSize: '14px'
    },
    fromToDateInput: {
        width: '50%'
    },
    fromDateInput: {
        width: '50%',
        borderRadius: '0'
    },
    ToDateLabel: {
        borderRadius: '0'
    },
    ToDateInput: {
        width: '50%'
    }
}