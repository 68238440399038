export default (theme) => ({
    subContainer: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center'
    },
    cameraContainer: {
        borderWidth: '3px',
        borderStyle: 'solid',
        borderRadius: '50%',
        borderColor: theme.palette.primary.main,
        overflow: 'hidden',
        width: '240px',
        height: '240px',
        transform: 'rotateY(180deg)',
        // margin: '40px 0px 0px 0px',
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    editProfileCameraContainer : {
        borderWidth: '2px',
        borderStyle: 'solid',
        borderRadius: '50%',
        borderColor: theme.palette.primary.main,
        overflow: 'hidden',
        width: '195px',
        height: '195px',
        transform: 'rotateY(180deg)',
        marginTop : '40px',
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        
        [theme.breakpoints.down('sm')]: {
            width: '177px',
            height: '177px',
            margin: '30px 15px 15px 15px',
        },
    },
    hideCamera : {
        display : 'none'
    },
    cameraIcon: {
        position: 'absolute'
    },
    camera: {
        maxWidth: '320px',
        maxHeight: '320px'
    },
    imgCamera: {
        width: '240px',
        height: '240px'
    },
    row: {
        display: 'flex',
        justifyContent: 'center',
        padding: '15px',
        marginTop: '15px',
        flex: '1'
    },
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        border: '1px solid rgba(0, 0, 0, 0.08)',
        boxShadow: '2px 2px 9px 0px rgba(0, 0, 0, 0.23)',
        margin: 'auto',
        width: '100%',
        paddingTop: '100%',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
        }
    },
    avatarImage: {
        position: 'absolute',
        top: '0',
        left: '0',
        bottom: '0',
        right: '0'
    }
})
