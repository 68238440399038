import React, {Component} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Card, CardContent, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@material-ui/core'
import {convertNumber, formatThaiDate, isUndefined} from '../../../common/util'
import {COMPANY_INFO} from "../../../common/const"

import styles from './styles'

class ReceiptGenV2 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: props.name,
            address: props.address,
            receiptNo: props.receiptNo,
            receiptId: props.receiptId,
            date: props.date,
            mobileNumber: props.mobileNumber,
            receiptItems: props.receiptItems,
            discount: props.discount,
            branchId: props.branchId,
            headline: props.headline,
            subHeadline1: props.subHeadline1,
            subHeadline2: props.subHeadline2,
            body1: props.body1,
            body2: props.body2,
            body3: props.body3
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            name: newProps.name,
            address: newProps.address,
            receiptId: newProps.receiptId,
            receiptNo: newProps.receiptNo,
            date: newProps.date,
            mobileNumber: newProps.mobileNumber,
            receiptItems: newProps.receiptItems,
            discount: newProps.discount,
            branchId: newProps.branchId,
            headline: newProps.headline,
            subHeadline1: newProps.subHeadline1,
            subHeadline2: newProps.subHeadline2,
            body1: newProps.body1,
            body2: newProps.body2,
            body3: newProps.body3
        })
    }

    render() {
        if (!this.state.receiptItems) return <diV></diV>
        const {classes} = this.props
        let tableIndex = 0
        let totalPrice = 0

        return (
            <Card className={classes.card} elevation={0}>
                <table className={classes.heading}>
                    <tbody>
                    <tr>
                        <td style={{verticalAlign: 'top'}}>
                            <img
                                src={`${process.env.PUBLIC_URL}/images/logo_300.png`}
                                className={classes.logo}
                                alt='Code Genius'
                            />
                        </td>
                        <td style={{textAlign: 'right'}}>
                            <Typography variant='headline' align='right' component='h2'>
                                {this.state.headline}
                            </Typography>

                            <Typography color='textSecondary' align='right'>
                                
                                {this.state.subHeadline1?  this.state.subHeadline1:''}
                                {this.state.subHeadline1?  <br/>:''}

                                {this.state.subHeadline2? this.state.subHeadline2: ''}
                                {this.state.subHeadline2?  <br/>:''}

                                {this.state.body1}
                                <br/>
                                {this.state.body2}
                                <br/>
                                {this.state.body3}
                            </Typography>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <Typography
                    variant='title'
                    align='center'>
                    ใบเสร็จรับเงิน
                </Typography>

                <CardContent>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant='body2'>
                                {'ชื่อ-สกุล: ' + this.state.name}
                                <br/>
                                {'ที่อยู่: ' + this.state.address || '-'}
                                <br/>
                                {'เบอร์โทรศัพท์: ' + this.state.mobileNumber || '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant='body2'>
                                {'วันที่: ' + formatThaiDate(this.state.date)}
                                <br/>
                                {'เลขที่: ' +
                                'CG' +
                                (this.state.branchId < 10
                                    ? '0' + this.state.branchId
                                    : this.state.branchId) +
                                this.state.receiptNo}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>

                <div>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.head}>
                                    ลำดับ
                                </TableCell>
                                <TableCell
                                    padding='none'
                                    className={classes.head}>
                                    รายการ
                                </TableCell>
                                <TableCell className={classes.head}>
                                    จำนวนเงิน (บาท)
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.receiptItems.map(receiptItem => {
                                if (
                                    !isUndefined(receiptItem)
                                ) {
                                    tableIndex = tableIndex + 1
                                    totalPrice += receiptItem.unitPrice * receiptItem.amount
                                    return (
                                        <TableRow key={receiptItem.receiptItemId}>
                                            <TableCell numeric>
                                                {tableIndex}
                                            </TableCell>

                                            <TableCell
                                                padding='none'
                                                className={classes.courseContainer}>
                                                <Typography align='left'>
                                                    {receiptItem.displayName}
                                                </Typography>
                                            </TableCell>
                                            <TableCell numeric>
                                                {' '}
                                                {convertNumber(
                                                    receiptItem.unitPrice * receiptItem.amount,
                                                    2
                                                )}{' '}
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            })}

                            <TableRow>
                                <TableCell className={classes.noBorder}/>
                                <TableCell className={classes.noBorder} numeric>
                                    ส่วนลด
                                </TableCell>
                                <TableCell numeric>
                                    {' '}
                                    {convertNumber(this.state.discount, 2)}{' '}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell className={classes.noBorder}/>
                                <TableCell className={classes.noBorder} numeric>
                                    รวมทั้งสิ้น
                                </TableCell>
                                <TableCell numeric>
                                    {' '}
                                    {convertNumber(
                                        totalPrice - this.state.discount,
                                        2
                                    )}{' '}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>

                <CardContent>
                    <Typography
                        variant='headline'
                        component='h3'
                        align='center'>
                        {this.props.footer}
                    </Typography>
                </CardContent>
            </Card>
        )
    }
}

export default withStyles(styles)(ReceiptGenV2)
