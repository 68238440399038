export default {
    "title": "หน้าแรก",
    "lang":"th",
    "advertisement":{
        "1" : "https://premierxoffice.thepremierprep.com/code-genius/home_slider/th_how_to_code.jpg",
        "2" : "https://premierxoffice.thepremierprep.com/code-genius/home_slider/th_soft_opening_soon.jpg"
    },
    "button" : {
        "seemore": "ดูเพิ่มเติม"
    },
    "whatWeDo": {
        "header": "เราทำอะไร",
        "subHeading": "เราสอนทักษะ Code Genius",
        "think": {
            "header": "THINK",
            "content": "พัฒนาความคิดเชิงตรรกะ\n(Logical Thinking)\nเรียนรู้การคิดอย่างเป็นระบบ"
        },
        "solve": {
            "header": "SOLVE",
            "content": "สร้างทักษะการแก้ไขปัญหา\nเรียนรู้การแบ่งปัญหาออกเป็น\nปัญหาย่อยที่จัดการได้ง่าย\nเพื่อให้สามารถคิดวิเคราะห์วิธีแก้ปัญหาได้"
        },
        "innovate": {
            "header": "INNOVATE",
            "content": "เสริมสร้างความคิดสร้างสรรค์\nเรียนรู้ และสร้างความใกล้ชิดคุ้นเคย\nกับเทคโนโลยี ส่งเสริมให้เป็น\nผู้สร้างนวัตกรรมในอนาคต"
        },
        "concept":"Code Genius สร้างหลักสูตรการเรียนรู้ผ่านการเขียนโค้ดที่มีคุณภาพอย่างต่อเนื่อง\nกระตุ้นให้เด็กคิดอย่างเป็นระบบ รู้จักแก้ไขปัญหา และสร้างสรรค์เทคโนโลยีด้วยตนเอง\nเพื่อเติบโตเป็นผู้นำด้านนวัตกรรม เราเรียกสิ่งนี้ว่า ทักษะ Code Genius"
    },
    "feature": {
        "header": "ทำไมเด็กจึงควรเรียนรู้การเขียนโค้ด",
        "subHeading" : "คำกล่าวจากผู้นำระดับโลก",
        "content1": "\"การเรียนรู้การเขียนโปรแกรมจะบริหารความคิดของคุณ ช่วยให้คุณคิดได้ดีขึ้น และสร้างวิธีการคิดเกี่ยวกับสิ่งต่าง ๆ ซึ่งผมคิดว่ามันเป็นประโยชน์ในทุกแขนง\"",
        "footer1": "บิลล์ เกตส์ ผู้ก่อตั้ง Microsoft",
        "content2": "\"ทุกคนในประเทศนี้ (สหรัฐอเมริกา) ควรเรียนรู้การเขียนโปรแกรม เพราะมันช่วยสอนวิธีคิดให้กับคุณ\"",
        "footer2": "สตีฟ จ็อบส์ ผู้ร่วมก่อตั้ง Apple Inc.",
        "content3": "\"ในอีก 15 ปีข้างหน้า เราจะมีการสอนเขียนโปรแกรม เหมือนกับการอ่าน การเขียน และเป็นที่น่าสงสัยว่าทำไมเราจึงไม่ลงมือทำมันก่อน\"",
        "footer3": "มาร์ก ซักเคอร์เบิร์ก ผู้ร่วมก่อตั้ง Facebook"
    },
    "quote": "เมื่อโลกเข้าสู่ยุคดิจิทัล การเขียนโค้ดมีความสำคัญเหมือนกับเป็นภาษาที่สอง\nดังนั้น การสนับสนุนให้เด็กมีทักษะแห่งศตวรรษที่ 21 จึงเป็นจุดตัดสินใจที่สำคัญ"
}