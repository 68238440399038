import { postRequest, getRequest } from '../lib/request'

export function getAllStudents() {
    
    return getRequest(`/student/getAllStudents`)
}

export function getStudentProfile(studentId){
    return getRequest(`/student/profile/${studentId}`)
}

export function updateStudentProfile(data) {
    return postRequest(`/student/updateProfile`, data)
}

export function checkAttendance(data) {
    return postRequest(`/student/attend`, data)
}
export function searchStudent(criteria) {
    return getRequest(`/student/search`, criteria)
}

export function searchStudentByBranchId(criteria, branchId) {
    return getRequest(`/student/search`, { ...criteria, branchId })
}

export function getStudentPhoto(studentId) {
    return getRequest(`/student/getStudentPhoto/${studentId}`)
}

export function getSchoolList() {
    return getRequest(`/student/getSchoolList`)
}

export function getStudentActiveBranch(studentId) {
    return getRequest(`/student/profile/${studentId}/activeBranch`)
}
