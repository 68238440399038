import cggallery from './cggallery'
import common from './common'
import corecourses from './corecourses'
import footer from './footer'
import home from './home'
import navbar from './navbar'

export default {
    cggallery,
    common,
    corecourses,
    footer,
    home,
    navbar
}