export default theme => ({
    container: {
        margin: '0em',
    },
    paginationContainer: {
        textAlign: 'right'
    },
    dateCriteriaContainer: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    dateContainer: {
        display: 'inline-block',
        marginBottom: '0.7em'
    },
    btnCurrentClass: {
        color: '#ffffff !important',
        backgroundColor: '#00B3C5 !important'
    },
    customRow:{
        paddingTop: '0rem !important'
    },
    courseDetailItem: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left'
        }
    },
    courseDetailItemLabel: {
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    courseDetailContainer: {
        marginBottom: '0px !important',
        paddingBottom: '0px !important'
    }
})