export const styles = {
    container: {
        margin: '2em',
        fontSize: '1em'
    },
    paginationContainer: {
        textAlign: 'right',
    },
    dateCriteriaContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dateContainer: {
        display: 'inline-block',
        marginBottom: '0.7em',
    },
}
