import React from 'react'
import {Button, Dimmer, Dropdown, Form, Grid, Icon, Input, Loader, Message, Modal} from 'semantic-ui-react'
import apiClient from '../../../apis'
import styles from './styles'
import {withRouter} from 'react-router'
import {formatDate, formatDateDisplay, validateRole} from '../../../common/util'
import * as CONST from '../../../common/const';
import {DEFAULT_BRANCH_ID, STUDENT_CLASS_STATUS, ROLE} from '../../../common/const'

class ChangeStatusModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            data: {},
            classMember: {},
            isOtherMember: false,
            oldData: {},
            date: '',
            courseList: [],
            classTimeList: [],
            classDateList: [],
            warning: false,
            errorMsg: undefined
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.data.classId !== state.data.classId) {
            return {
                data: {...props.data},
                errorMsg: undefined,
                isLoading: false
            }
        }
        return {}
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.data.classId && prevState.data.classId !== this.state.data.classId) {
            this.getCourseDOWList()
            this.initData()
        }
    }

    getCourseHistory() {
        this.setState({
            isLoading: true
        })
        apiClient.course.getClassHistory(DEFAULT_BRANCH_ID, this.state.data.courseId, this.state.data.studentId)
            .then(res => {
                this.setState({
                    classHistory: res,
                    isLoading: false
                })
            })
    }

    getCourseDOWList() {
        this.setState({
            isLoading: true
        })
        dayOptions = []
        apiClient.course.getCourseDOWList(DEFAULT_BRANCH_ID, this.state.data.courseId)
            .then(res => {
                for (let i = 0; i < res.length; i++) {
                    switch (res[i].dow) {
                        case 1:
                            dayOptions.push({key: 1, text: 'Sunday', value: 1})
                            break;
                        case 2:
                            dayOptions.push({key: 2, text: 'Monday', value: 2})
                            break;
                        case 3:
                            dayOptions.push({key: 3, text: 'Tuesday', value: 3})
                            break;
                        case 4:
                            dayOptions.push({key: 4, text: 'Wednesday', value: 4})
                            break;
                        case 5:
                            dayOptions.push({key: 5, text: 'Thursday', value: 5})
                            break;
                        case 6:
                            dayOptions.push({key: 6, text: 'Friday', value: 6})
                            break;
                        case 7:
                            dayOptions.unshift({key: 7, text: 'Saturday', value: 7})
                            break;
                        default:
                            break;
                    }
                }

                this.setState({
                    isLoading: false
                })
            })
    }

    initData() {
        const { data } = this.state

        // convert day to day of week (+1 mySQL)
        let d = new Date(data.classDate)
        // if cancel mode default new class from getNextClass
        let newClassId = data.isEdit ? data.classId : data.classDetail.classId
        
        this.setState({
            data: {
                ...this.state.data,
                day: d.getDay() + 1,
                newClassId: newClassId,
                classDate: formatDate(data.classDate, 'DD-MM-YYYY')
            },
            oldData: {
                date: formatDate(data.date, 'YYYY-MM-DD'),
                day: d.getDay() + 1,
                time: data.time,
                classDate: formatDate(data.classDate, 'DD-MM-YYYY')
            }
        }, this.getCourseHistory)
        if (data.courseId && data.day) {
            this.getClassTimeList(d.getDay() + 1, false)
        }
    }

    getCourseHistory() {
        this.setState({
            isLoading: true
        })
        apiClient.course.getClassHistory(DEFAULT_BRANCH_ID, this.state.data.courseId, this.state.data.studentId)
            .then(res => {
                this.setState({
                    classHistory: res,
                    isLoading: false
                })
            })
    }

    getNextClass() {
        this.setState({
            isLoading: true
        })

        apiClient.course.getNextClassByCancel(this.state.data.studentId, this.state.data.courseId, this.state.data.classId, this.state.data.branchId)
            .then(res => {
                let d = new Date(res.classDetail.classDate)
                this.setState({
                    data: {
                        ...this.state.data,
                        day: d.getDay() + 1,
                        time: res.classDetail.time,
                        newClassId: res.classDetail.classId,
                        classDate: formatDate(res.classDetail.classDate, 'DD-MM-YYYY')
                    }
                }, this.getCourseHistory)
                if (this.state.data.courseId && d.getDay() + 1) {
                    this.getClassTimeList(d.getDay() + 1, false)
                }
            })
    }

    getClassTimeList(dow, changeDropdown) {
        this.setState({
            classTimeList: [],
            classDateList: [],
            isLoading: true
        })
        apiClient.course.getClassTimeList(this.state.data.courseId, dow)
            .then(res => {
                if (res !== null) {
                    let arr = res.map((p, index) => {
                        return {
                            key: index,
                            text: p.time,
                            value: p.time
                        }
                    })
                    this.setState({
                        classTimeList: arr,
                        isLoading: false
                    })

                    if (res.length > 0) {
                        // changeDropdown is default first value to dropdown
                        if (changeDropdown) {
                            this.setState({
                                data: {
                                    ...this.state.data,
                                    time: arr[0].value
                                }
                            })
                        }

                        // send value to get next dropdown value list
                        this.getClassDateList(changeDropdown ? arr[0].value : this.state.data.time)
                    }
                }
            })
    }

    getClassDateList(startTime) {
        this.setState({
            classDateList: [],
            isLoading: true
        })
        // cancel mode get next of last class (not use day)
        let dow = this.state.data.isEdit ? this.state.data.day : null
        apiClient.course.getClassEditList(this.state.data.branchId, this.state.data.courseId, dow, startTime, this.state.data.studentId)
            .then(res => {
                if (res !== null) {
                    let arr = res.map((p, index) => {
                        return {
                            key: index,
                            text: formatDateDisplay(p.classDate) + " (" + p.stuCount + "/" + p.classSize + ")",
                            value: formatDate(p.classDate, 'DD-MM-YYYY'),
                            lessonid: p.lessonId,
                            classID: p.classId,
                            classsize: p.classSize,
                            stucount: p.stuCount,
                            disabled: p.isDup === 1 ? true : false
                        }
                    })
                    this.setState({
                        classDateList: arr,
                        isLoading: false
                    })
                }
            })
    }

    close = () => {this.props.onClose()}

    doSave = async () => {
        const { data } = this.state
        this.setState({warning: false, isLoading: true})
        let result = {}
        let status = ''

        if (data.isEdit) {
            let form = {
                studentId: data.studentId,
                classId: data.classId,
                newClassId: data.newClassId,
                status: data.status,
                remark: data.remark
            }
            result = await apiClient.course.setClassFromEdit(form)
        } else {
            status = CONST.STUDENT_CLASS_STATUS.CANCEL
            let form = {
                studentId: data.studentId,
                classId: data.newClassId,
                receiptId: data.receiptId,
                receiptPrice: data.classDetail.receiptPrice,
                receiptCourseName: data.classDetail.receiptCourseName,
                status: STUDENT_CLASS_STATUS.ACTIVE,
                remark: data.remark,
                previousClassId: data.classId
            }
            result = await apiClient.course.setClassFromCancel(form)
        }

        if (result.status === 'SUCCESS') {
            this.props.onClose({classId: data.classId, status: status})
        } else {
            this.setState({isLoading: false, errorMsg: result.message})
        }
    }

    validateData = async () => {
        const { data } = this.state

        if (!data.day) {
            this.setState({errorMsg: 'Please select day.'})
        } else if (!data.newClassId) {
            this.setState({errorMsg: 'Please select class date.'})
            // } else if (!data.isEdit && data.courseDetail.total - data.courseDetail.postpone < 1) {
            //     this.setState({errorMsg: 'Cancel class is limited.'})
            // } else if (data.status === STUDENT_CLASS_STATUS.ABSENCE) {
            //     this.setState({warning: true, errorMsg: 'Confirm to set ABSENCE status?'})
        } else {
            this.doSave()
        }
    }

    validLessonDuplicate(lessonId) {
        let lessonDup = ''
        if (this.state.classHistory && this.state.classHistory.length > 0) {
            let classHistory = this.state.classHistory
            for (let i = 0; i < classHistory.length; i++) {
                if (classHistory[i].lessonId === lessonId) {
                    lessonDup += formatDateDisplay(classHistory[i].classDate) + ', '
                }
            }
            if (lessonDup.length > 0) {
                lessonDup = lessonDup.substring(0, lessonDup.length - 2)
            }
            return lessonDup
        }
    }

    handleDropDownChange = name => (event, {value}) => {
        this.setState({
            errorMsg: '',
            data: {
                ...this.state.data,
                newClassId: '',
                classDate: '',
                [name]: value
            }
        })

        if (name === 'day') {
            this.getClassTimeList(value, true)
        } else if (name === 'time') {
            this.getClassDateList(value)
        } else if (name === 'classDate') {
            let classData = this.state.classDateList.find(e => e.value === value)
            setTimeout(function () {
                this.setState({
                    data: {
                        ...this.state.data,
                        newClassId: classData.classID,
                        studentInClass: classData.stucount,
                        classDetail: {classSize: classData.classsize}
                    }
                })
            }.bind(this), 1000)

            let validLesson = this.validLessonDuplicate(classData.lessonid)
            if (value !== this.state.oldData.classDate && validLesson !== '') {
                this.setState({errorMsg: 'Lesson is duplicate in previous class. (' + validLesson + ')'})
            } else if (classData.stucount >= classData.classsize) {
                this.setState({errorMsg: 'Student greater than the room size.'})
            }
        }
    }


    handleChange = name => event => {
        let value = event.target.value

        this.setState({
            errorMsg: '',
            data: {
                ...this.state.data,
                [name]: value
            }
        })
    }

    handleInputChange = (e, value) => {
        this.setState({
            errorMsg: '',
            data: {
                ...this.state.data,
                [value.id]: value.value
            }
        })

        if (value.value === STUDENT_CLASS_STATUS.CANCEL) {
            this.setState({ isLoading: true})
            setTimeout(function () {
                this.resetClassDate()
            }.bind(this))
        }
    }

    resetClassDate() {
        const {oldData, data} = this.state
        this.setState({
            isLoading: true,
            data: {
                ...this.state.data,
                day: oldData.day,
                time: oldData.time,
                classDate: oldData.classDate,
                newClassId: data.classId,
            }
        })
        this.getClassTimeList(oldData.day, false)
    }

    closeWarning = () => this.setState({warning: false})

    renderModalAlert() {
        const { errorMsg, warning} = this.state
        return (
            <Modal size={'mini'} open={warning} onClose={this.closeWarning}>
                <Modal.Header>Warning</Modal.Header>
                <Modal.Content>
                    <p>{errorMsg}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={this.closeWarning}>Cancel</Button>
                    <Button
                        onClick={this.doSave}
                        positive
                        icon='checkmark'
                        labelPosition='right'
                        content='Save'
                    />
                </Modal.Actions>
            </Modal>
        )
    }

    render() {
        const { data, oldData, courseList, errorMsg, isLoading, classTimeList, classDateList } = this.state

        return (
            <Modal open={this.props.open} onClose={this.closeWarning}>
                <Modal.Header>{data.isEdit ? 'Edit' : 'Cancel'}</Modal.Header>
                <Modal.Content>
                    {this.renderModalAlert()}
                    <Form>
                        <Grid>
                            <Grid.Column mobile={16} computer={16}>
                                <Message>
                                    <Message.Header>Old Class Date</Message.Header>
                                    <p>{oldData.date} ({oldData.time})</p>
                                </Message>
                            </Grid.Column>
                            <Grid.Column mobile={16} computer={12}>
                                <label style={styles.formLabelDisable}>Course Name</label>
                                <Input id='courseName' fluid type='text' disabled value={this.state.data.courseName}/>
                            </Grid.Column>
                            <Grid.Column mobile={16} computer={4}>
                                <Form.Input fluid disabled={true} name='receiptId' label='Receipt ID'
                                            placeholder='Receipt ID' value={data.receiptId}
                                            onChange={this.handleChange('receiptId')}/>
                            </Grid.Column>
                        </Grid>
                        <Grid>
                            <Grid.Column mobile={16} computer={4}>
                                <label style={styles.formLabel}>Day</label>
                                <Dropdown fluid selection name='day' placeholder='Select Day'
                                          disabled={data.status === STUDENT_CLASS_STATUS.CANCEL}
                                          onChange={this.handleDropDownChange('day')}
                                          value={data.day} options={dayOptions}/>
                            </Grid.Column>
                            {classTimeList && classTimeList.length > 0 ?
                                <Grid.Column mobile={16} computer={4}>
                                    <label style={styles.formLabel}>Time</label>
                                    <Dropdown fluid selection name='time' placeholder='Select Time'
                                              disabled={data.status === STUDENT_CLASS_STATUS.CANCEL}
                                              onChange={this.handleDropDownChange('time')}
                                              value={data.time} options={classTimeList}/>
                                </Grid.Column> : null
                            }
                            {classDateList && classDateList.length > 0 ?
                                <Grid.Column mobile={16} computer={4}>
                                    <label style={styles.formLabel}>Date</label>
                                    <Dropdown fluid selection name='classDate'
                                              placeholder='Select Date'
                                              disabled={data.status === STUDENT_CLASS_STATUS.CANCEL}
                                              onChange={this.handleDropDownChange('classDate')}
                                              value={data.classDate} options={classDateList}/>
                                </Grid.Column> : null
                            }
                            {data.isEdit ?
                                <Grid.Column mobile={1} computer={1}>
                                    <label style={styles.formLabel}>Skip</label>
                                    <Button icon
                                            disabled={this.state.data.status === STUDENT_CLASS_STATUS.CANCEL || this.state.data.status === STUDENT_CLASS_STATUS.CONFIRMED}
                                            onClick={() => this.getNextClass()}>
                                        <Icon name='share'/>
                                    </Button>
                                </Grid.Column> : null
                            }
                            {data.isEdit ?
                                <Grid.Column mobile={4} computer={3}>
                                    <label style={styles.formLabel}>Status</label>
                                    <Dropdown id='status' fluid placeholder='select' selection options={statusOptions}
                                              value={data.status} onChange={this.handleInputChange}/>
                                </Grid.Column> : null
                            }
                        </Grid>
                        <Grid>
                            <Grid.Column width={16}>
                                <Form.TextArea name='remark' label='Remark' placeholder='Remark'
                                               value={data.remark || ''}
                                               onChange={this.handleChange('remark')}/>
                            </Grid.Column>
                        </Grid>
                    </Form>
                </Modal.Content>
                <Modal.Actions style={{textAlign: 'left'}}>
                    <div style={{width: '70%', display: 'inline-block'}}>
                        {errorMsg &&
                            <Message compact warning size='tiny' style={styles.errorMessageContainer}>
                                <Message.Header>Warning</Message.Header>
                                <p>{errorMsg}</p>
                            </Message>
                        }
                    </div>
                    <div style={{width: '30%', display: 'inline-block', textAlign: 'right'}}>
                        <Button negative onClick={this.close}>Cancel </Button>
                        <Button positive icon='checkmark' labelPosition='right'
                                content='Save' onClick={this.validateData}/>
                    </div>
                </Modal.Actions>
                <Dimmer inverted active={isLoading}><Loader/></Dimmer>
            </Modal>
        )
    }
}

export default withRouter(ChangeStatusModal)

let dayOptions = []

let statusOptions = [
    {
        key: CONST.STUDENT_CLASS_STATUS.ACTIVE,
        text: CONST.STUDENT_CLASS_STATUS_TEXT.ACTIVE,
        value: CONST.STUDENT_CLASS_STATUS.ACTIVE
    },
    {
        key: CONST.STUDENT_CLASS_STATUS.CONFIRMED,
        text: CONST.STUDENT_CLASS_STATUS.CONFIRMED,
        value: CONST.STUDENT_CLASS_STATUS.CONFIRMED
    },
    {
        key: CONST.STUDENT_CLASS_STATUS.ATTENDED,
        text: CONST.STUDENT_CLASS_STATUS.ATTENDED,
        value: CONST.STUDENT_CLASS_STATUS.ATTENDED
    },
    {
        key: CONST.STUDENT_CLASS_STATUS.CANCEL,
        text: CONST.STUDENT_CLASS_STATUS.CANCEL,
        value: CONST.STUDENT_CLASS_STATUS.CANCEL
    },
    {
        key: CONST.STUDENT_CLASS_STATUS.PENDING,
        text: CONST.STUDENT_CLASS_STATUS_TEXT.PENDING,
        value: CONST.STUDENT_CLASS_STATUS.PENDING
    }
]

if (validateRole([ROLE.GOD]) ){
    statusOptions.push({
        key: CONST.STUDENT_CLASS_STATUS.REFUND,
        text: CONST.STUDENT_CLASS_STATUS_TEXT.REFUND,
        value: CONST.STUDENT_CLASS_STATUS.REFUND
    })
}
