export default (theme) => ({
    container: {
        display: 'flex',
        flex: '1',
        backgroundColor: theme.palette.grey[200],
        color: `${theme.palette.grey[600]} !important`,
        alignItems: 'center',
        padding: '0em 2em 1.5em',
        justifyContent: 'space-between',
        marginTop: '1.5em'
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '1.8em'
    },
    logo: {
        width: '200px',
        filter: 'opacity(0.6)'
    },
    contentContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        alignSelf: 'flex-start',
        marginTop: '1.8em'
    },
    headerContainer: {
        fontSize: '1.2em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '0.25em',
        fontWeight: 'bold'
    },
    webVersionContainer:{
        color: theme.palette.grey[400],
        fontSize: '0.4em',
    },
    icon: {
        fontSize: '1em',
        marginRight: '0.3em'
    },
    brandIcon: {
        fontSize: '1.6em',
        marginTop: '0.5em'
    },
    lineBrandIcon: {
        fontSize: '1.43em',
        marginBottom: '0.05em'
    },
    content: {
        whiteSpace: 'pre',
        textAlign: 'center',
        marginBottom: '0.5em'
    },
    link: {
        color: 'inherit',
        textDecoration: 'none'
    }
})