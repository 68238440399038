import React, {Component} from 'react'
import {withStyles} from '@material-ui/core/styles'
import styles from './styles'
import {getCurrentUser, isUndefined, getUserBranchId} from '../../common/util'
import apiClient from '../../apis';
import {Dropdown, Form, Grid, Icon, Label, Message} from 'semantic-ui-react'
import * as CONST from '../../common/const';
import {DEFAULT_PAGE_SIZE, ROLE} from '../../common/const';
import ScheduleList from './ScheduleList'
import ScheduleListMobile from './ScheduleListMobile'
import {withRouter} from 'react-router';
import {withTranslation} from 'react-i18next';
import CGButton from '../common/CGButton/index'
import ChangeClassModal from './ChangeClassModal'
import ChangePrivateClassModal from './ChangePrivateClassModal'
import { ContactsRounded } from '@material-ui/icons';

class ScheduleForm extends Component {

    constructor(props) {
        super(props)
        let currentUser = getCurrentUser()
        this.branchId = getUserBranchId()
        this.state = {
            isAdmin: (currentUser.role === ROLE.ADMIN || currentUser.role === ROLE.GOD),
            currentUser: currentUser,
            userId: currentUser.userId,
            searchData: {
                studentId: '',
                course: '',
                status: '',
                isFirst: true,
                isAdmin: (currentUser.role === ROLE.ADMIN || currentUser.role === ROLE.GOD),
                orderBy: 'classDate',
                orderType: 'asc',
                page: 1
            },
            courseDetail: {
                attend: '',
                attendTimes: '',
                register: '',
                registerTimes: '',
                postpone: ''
            },
            changeStatus: {
                studentId: '',
                courseId: '',
                status: '',
                userId: ''
            },
            courseType: '',
            isPrivateClass: false,
            showPrivate: false,
            showAddPrivateModal: false,
            courseList: [],
            showCourseDetail: false,
            isLoading: false,
            visibleMessage: false,
            statusMessage: '',
            msgMessage: '',
            editData: {},
            studentInfo: {}
        }
        this.handleSortingChange = this.handleSortingChange.bind(this)
        this.handlePaginationChange = this.handlePaginationChange.bind(this)
        this.handleChangeStatus = this.handleChangeStatus.bind(this)
        this.handleAddPrivateClass = this.handleAddPrivateClass.bind(this)
    }

    componentDidMount() {
        this.initSchedulePlan()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.studentId !== this.state.studentId) {
            this.initSchedulePlan()
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.studentId !== state.studentId) {
            return {studentId: props.studentId}
        }
        return {}
    }

    initSchedulePlan = () => {
        const {studentId} = this.props

        if (!isUndefined(studentId)) {
            this.setState({
                showCourseDetail: false,
                searchData: {
                    ...this.state.searchData,
                    studentId: studentId,
                    course: '',
                    status: '',
                    isFirst: true,
                    orderBy: 'classDate',
                    orderType: 'asc',
                    page: 1
                }
            }, this.getSchedulePlan)
            this.getCourseList(studentId)
            this.getStudentProfile(studentId)
        }
    }

    getSchedulePlan = () => {
        this.setState({
            isLoading: true
        })
        apiClient.course.getSchedulePlan({...this.state.searchData, branchId: this.branchId})
            .then(res => {
                if (res !== null) {
                    this.setState({
                        tableData: res.dataList,
                        total: res.total,
                        isLoading: false,
                        searchData: {
                            ...this.state.searchData,
                            isFirst: false,
                            page: res.page
                        }
                    }, this.reloadCourseDetail)
                }
            })
    }

    reloadCourseDetail = () => {
        if (this.state.searchData.course !== '') {
            this.getCourseDetail(this.state.searchData.course)
        }
    }

    getStudentProfile = (studentId) => {
        this.setState({
            isLoading: true
        })

        apiClient.student.getStudentProfile(studentId)
            .then(res => {
                this.setState({
                    studentInfo: res,
                    isLoading: false
                })
            })
    }

    getCourseList = (studentId) => {
        this.setState({
            isLoading: true
        })

        apiClient.course.getCourseList(studentId)
            .then(res => {
                if (res !== null) {
                    let arr = res.map((p, index) => {
                        return {
                            key: index,
                            text: p.courseName,
                            value: p.courseId,
                            type: p.courseType,
                            detailtype: p.courseDetailType,
                            privateclass: p.privateClass,
                            projectclass: p.projectClass,
                        }
                    })
                    arr.unshift({key: -1, text: 'All', value: '', type: ''})
                    this.setState({
                        courseList: arr,
                        isLoading: false
                    })
                    if (res.length === 1) {
                        this.getCourseDetail(res[0].courseId)
                    }
                }
            })
    }

    getCourseDetail = (courseId) => {
        const selectCourse = this.state.courseList.find(courseList => courseList.value === courseId)

        this.setState({
            isLoading: true,
            courseType: selectCourse.type,
            isPrivateClass: selectCourse.detailtype === 'Private' || selectCourse.privateclass === 1 || selectCourse.projectclass === 1,
        })

        apiClient.course.getCourseDetail(this.state.searchData.studentId, courseId)
            .then(res => {
                this.setState({
                    showCourseDetail: true,
                    isLoading: false,
                    searchData: {
                        ...this.state.searchData,
                        course: courseId,
                    },
                    courseDetail: {
                        attend: res.attend,
                        attendTimes: res.minsAttend / 3600,
                        register: res.register,
                        registerTimes: res.minsRegister / 3600,
                        postpone: res.postpone
                    }
                })
            })
    }

    setClassStatus = (courseName) => {
        this.setState({
            isLoading: true
        })

        apiClient.course.setStatus(this.state.changeStatus)
            .then(res => {
                this.setState({
                    visibleMessage: true,
                    statusMessage: res.status,
                    msgMessage: res.message,
                    isLoading: false
                }, this.getSchedulePlan)

                setTimeout(() => {
                    this.setState({visibleMessage: false})
                }, 5000)
            })
    }

    handlePaginationChange = activePage => {
        this.setState({
            searchData: {
                ...this.state.searchData,
                page: activePage,
            }
        }, this.getSchedulePlan)
    }

    handleSortingChange = (orderBy, orderType) => {
        this.setState({
            searchData: {
                ...this.state.searchData,
                orderBy: orderBy,
                orderType: orderType
            }
        }, this.getSchedulePlan)
    }

    handleChangeStatus = async (data, status) => {
        const { showAddPrivateModal } = this.state
        if (showAddPrivateModal === false) {
            data['isEdit'] = status !== CONST.STUDENT_CLASS_STATUS.CANCEL ? true : false
            
            //ยกเลิก การสร้างคลาสใหม่เมื่อกด cancel
            // if (status === CONST.STUDENT_CLASS_STATUS.CANCEL) {
            //     apiClient.course.getNextClassByCancel(data.studentId, data.courseId, data.classId)
            //         .then(res => {
            //             try {
            //                 data['classDetail'] = res.classDetail
            //                 data['courseDetail'] = res.courseDetail
            //                 data['studentInClass'] = res.studentInClass

            //                 data['day'] = res.classDetail.day
            //                 data['time'] = res.classDetail.time
            //                 data['classDate'] = res.classDetail.classDate

            //                 this.setState({
            //                     isLoading: false,
            //                     showEditModal: true,
            //                     editData: data
            //                 })
            //             } catch (e) {
            //                 this.setState({
            //                     visibleMessage: true,
            //                     statusMessage: 'Error',
            //                     msgMessage: 'Something went wrong. Please try again.',
            //                     isLoading: false
            //                 })

            //                 setTimeout(() => {
            //                     this.setState({visibleMessage: false})
            //                 }, 5000)
            //             }
            //         })
            // } else 
            if (status === 'EDIT') {
                this.setState({
                    isLoading: false,
                    showEditModal: true,
                    editData: data,
                    showPrivate: data.privateClass === 1 || data.projectClass === 1 || data.courseDetailType === 'Private',
                })
            } else if (status !== CONST.STUDENT_CLASS_STATUS.SKIP){
                this.setState({
                    changeStatus: {
                        studentId: this.state.searchData.studentId,
                        classId: data.classId,
                        status: status,
                        userId: this.state.userId
                    }
                }, this.setClassStatus)
            } else {

                // case skip update class
                    const nextClassResult = await apiClient.course.getNextClassByCancel(data.studentId, data.courseId, data.classId, data.branchId)
                    
                    const form = {
                        studentId: data.studentId,
                        classId: data.classId,
                        newClassId: nextClassResult.classDetail.classId,
                        status: data.status,
                        remark: '[NO_SAVE]'
                    }
                    await apiClient.course.setClassFromEdit(form)

                    this.getSchedulePlan()
            }
        }
    }

    handleInputChange = (e, value) => {
        this.setState({
            searchData: {
                ...this.state.searchData,
                page: 1,
                [value.id]: value.value
            }
        }, this.getSchedulePlan)

        if (value.id === 'course') {
            if (value.value === '') this.setState({showCourseDetail: false, isPrivateClass: false})
            else this.getCourseDetail(value.value)
        }
    }

    handleDismiss = () => {
        this.setState({visibleMessage: false})
    }

    handleEditItemClose = (data) => {
        if (data && data.status === CONST.STUDENT_CLASS_STATUS.CANCEL) {
            this.setState({
                showEditModal: false,
                editData: {},
                changeStatus: {
                    studentId: this.state.searchData.studentId,
                    classId: data.classId,
                    status: data.status,
                    userId: this.state.userId
                }
            }, this.setClassStatus)
        } else {
            this.setState({
                showEditModal: false,
                editData: {}
            }, this.getSchedulePlan)
        }
    }

    handleAddPrivateClass = () => {
        const { searchData } = this.state
        this.setState({
            showAddPrivateModal: true,
            addData: {
                studentId: searchData.studentId,
                courseId: searchData.course
            }
        })
    }

    handleAddPrivateClose = () => {
        this.setState({
            showAddPrivateModal: false,
            addData: {}
        }, this.getSchedulePlan )
    }

    renderCriteria = () => {
        const {course, status} = this.state.searchData

        return (
            <Form>
                {this.state.isAdmin ?
                    <div className='field'>
                        <Label size='large'><Icon name='user'/>
                            {this.state.studentInfo.studentId} : {this.state.studentInfo.firstName} {this.state.studentInfo.lastName} ({this.state.studentInfo.nickName})
                        </Label>
                    </div> : null}
                <div className='two fields'>
                    <div className='field'>
                        <label>Course</label>
                        <Dropdown id='course' fluid placeholder='All' selection options={this.state.courseList}
                                  value={course} onChange={this.handleInputChange}/>
                    </div>
                    <div className='field'>
                        <label>Status</label>
                        <Dropdown id='status' fluid placeholder='All' selection options={statusOptions}
                                  value={status} onChange={this.handleInputChange}/>
                    </div>
                </div>
            </Form>
        )
    }

    renderCourseDetail = () => {
        return (
            <Form>
                <div className={`two fields ${this.props.classes.courseDetailContainer}`}>
                    <div className={`field ${this.props.classes.courseDetailItem}`}>
                        <CGButton onClick={() => this.setState({
                            searchData: {
                                ...this.state.searchData,
                                status: '',
                                isFirst: true
                            }
                        }, this.getSchedulePlan)}>
                            Go to Current Class
                        </CGButton>
                    </div>
                    {this.state.showCourseDetail && this.state.courseDetail ?
                        <div className={`field ${this.props.classes.courseDetailItem}`}>
                            <div style={{width: '100%'}} className={this.props.classes.courseDetailItemLabel}>
                                {this.state.isAdmin && this.state.courseType === 'Code Expert' && this.state.isPrivateClass === true ?
                                    <Label color='brown'>
                                        Hours
                                        : {this.state.courseDetail.attendTimes}/{this.state.courseDetail.registerTimes}
                                    </Label>
                                    : null}
                                <Label color='green' style={{marginLeft: '10px'}}>
                                    Attended : {this.state.courseDetail.attend}/{this.state.courseDetail.register}
                                </Label>
                                <Label color='grey' style={{marginLeft: '10px'}}>
                                    Absence : {this.state.courseDetail.postpone}/{this.state.courseDetail.register}
                                </Label>
                            </div>
                        </div> : null
                    }
                </div>
            </Form>
        )
    }

    renderMessage() {
        if (this.state.visibleMessage) {
            return (
                <Message icon='info circle'
                         style={{marginTop: '15px'}}
                         success={this.state.statusMessage === 'success'}
                         error={this.state.statusMessage === 'error'}
                         onDismiss={this.handleDismiss}
                         header={this.state.statusMessage}
                         content={this.state.msgMessage}
                />
            )
        }
        return (null)
    }

    render() {
        const {classes} = this.props
        const {isAdmin, isPrivateClass, showPrivate, tableData, total, searchData, showEditModal, editData, addData, showAddPrivateModal, currentUser} = this.state

        const today = new Date()
        const currY = today.getFullYear()
        const currM = today.getMonth()
        let deadline = new Date(currY, currM - 1, 1) //last day of (current Month - 2)
        if (today.getDate() > CONST.EDIT_DEADLINE_DATE){
            deadline = new Date(currY, currM, 1) //last dat of last month
        }

        return (
            <div className={classes.container}>
                <Grid item xs={12}>
                    <Grid.Row className={classes.customRow}>
                        <Grid.Column>
                            {this.renderMessage()}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className={classes.customRow}>
                        <Grid.Column>
                            {this.renderCriteria()}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className={`${classes.customRow} ${classes.courseDetailContainer}`}>
                        <Grid.Column>
                            {this.renderCourseDetail()}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className={classes.customRow} style={{marginTop: '10px'}}>
                        <Grid.Column>
                            {window.innerWidth > 760 ?
                                (
                                    <ScheduleList
                                        isAdmin={isAdmin}
                                        deadline={deadline}
                                        isPrivateClass = {isPrivateClass}
                                        tableData={tableData}
                                        onPaginationChange={this.handlePaginationChange}
                                        onSortingChange={this.handleSortingChange}
                                        onChangeStatus={this.handleChangeStatus}
                                        onAddPrivateClass={this.handleAddPrivateClass}
                                        totalPages={Math.ceil(total / DEFAULT_PAGE_SIZE)}
                                        totalData={total}
                                        pageSize={DEFAULT_PAGE_SIZE}
                                        activePage={searchData.page}
                                        sorting={{
                                            column: searchData.orderBy,
                                            direction: searchData.orderType
                                        }}
                                    />
                                ) : (
                                    <ScheduleListMobile
                                        isAdmin={isAdmin}
                                        deadline={deadline}
                                        isPrivateClass = {isPrivateClass}
                                        tableData={tableData}
                                        onPaginationChange={this.handlePaginationChange}
                                        onSortingChange={this.handleSortingChange}
                                        onChangeStatus={this.handleChangeStatus}
                                        onAddPrivateClass={this.handleAddPrivateClass}
                                        totalPages={Math.ceil(total / DEFAULT_PAGE_SIZE)}
                                        totalData={total}
                                        pageSize={DEFAULT_PAGE_SIZE}
                                        activePage={searchData.page}
                                        sorting={{
                                            column: searchData.orderBy,
                                            direction: searchData.orderType
                                        }}
                                    />
                                )
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                { (isPrivateClass || showPrivate) && !showAddPrivateModal ? 
                    <ChangePrivateClassModal 
                        open={showEditModal}
                        onClose={this.handleEditItemClose}
                        data={editData}
                        showAddPrivateModal={showAddPrivateModal}
                        showEditModal = {showEditModal}/>
                    : 
                    <ChangeClassModal 
                        open={showEditModal}
                        onClose={this.handleEditItemClose}
                        data={editData}/>
                }
                <ChangePrivateClassModal
                    open={showAddPrivateModal}
                    onClose={this.handleAddPrivateClose}
                    data={addData}
                    showAddPrivateModal={showAddPrivateModal}
                    showEditModal = {showEditModal} />
            </div>
        )
    }
}

export default withRouter(withTranslation()(withStyles(styles)(ScheduleForm)))

let statusOptions = [
    {
        key: CONST.STUDENT_CLASS_STATUS.ACTIVE,
        text: CONST.STUDENT_CLASS_STATUS_TEXT.ACTIVE,
        value: CONST.STUDENT_CLASS_STATUS.ACTIVE
    },
    {
        key: CONST.STUDENT_CLASS_STATUS.ATTENDED,
        text: CONST.STUDENT_CLASS_STATUS.ATTENDED,
        value: CONST.STUDENT_CLASS_STATUS.ATTENDED
    },
    {
        key: CONST.STUDENT_CLASS_STATUS.PENDING,
        text: CONST.STUDENT_CLASS_STATUS_TEXT.PENDING,
        value: CONST.STUDENT_CLASS_STATUS.PENDING
    },
    {
        key: CONST.STUDENT_CLASS_STATUS.CANCEL,
        text: CONST.STUDENT_CLASS_STATUS_TEXT.CANCEL,
        value: CONST.STUDENT_CLASS_STATUS.CANCEL
    },
    {
        key: CONST.STUDENT_CLASS_STATUS.UNSCHEDULE,
        text: CONST.STUDENT_CLASS_STATUS_TEXT.UNSCHEDULE,
        value: CONST.STUDENT_CLASS_STATUS.UNSCHEDULE
    },
    {
        key: CONST.STUDENT_CLASS_STATUS.REFUND,
        text: CONST.STUDENT_CLASS_STATUS_TEXT.REFUND,
        value: CONST.STUDENT_CLASS_STATUS.REFUND
    }
]
