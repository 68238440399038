import React, { Component, Fragment } from 'react'
import { Grid, Card, Pagination, Message } from 'semantic-ui-react'
import { isEmpty, isUndefined } from '../../common/util'
import { Spinner } from '../common'
import apiClient from '../../apis'
import Album from '../../components/Album'
import PhotoGrid from '../../components/PhotoGrid'
import { withI18next } from '../../lib/withI18next'
import styles from './styles'

class GroupAlbum extends Component {

    constructor(props) {
        super(props)
        this.state = {
            totalPages: 0,
            totalData: 0,
            activePage: 1,
            pageSize: 10,
            classList: [],
            isLoading: false,
            selectedClass: null,
            courseName: null,
            courseId: null,
            photoList: null,
            studentId: props.studentId
        }
    }

    componentDidMount() {
        this.props.modifyBreadcrumb(1, 'Gallery', () => this.setState({ classList: [], selectedClass: null }))
    }


    getCoursePhotoList = (courseId, courseName) => {
        this.setState({ isLoading: true })
        const { studentId, pageSize, activePage } = this.state
        apiClient.photo.getPhotoUrlByCourseIdAndStudentId(courseId, studentId, pageSize, activePage).then(result => {
            this.setState({
                isLoading: false,
                courseName: courseName,
                courseId: courseId,
                photoList: result.photoList,
                totalData: result.total,
                totalPages: Math.ceil(result.total / pageSize)
            })
            this.props.modifyBreadcrumb(2, courseName, () => this.setState({ photoList: null }))
        })
    }

    handlePaginationChange = (e, { activePage }) => {
        const { courseId, courseName } = this.state
        this.setState({
            activePage: activePage
        }, () => {
            this.getCoursePhotoList(courseId, courseName)
        })
    }

    renderPagination() {
        const { totalPages, totalData, activePage, pageSize, photoList } = this.state
        return (
            <Grid.Row columns={2}>
                <Grid.Column textAlign='left' verticalAlign='middle'>
                    {totalData < 1 || !totalData
                        ? null
                        : `${((activePage - 1) * pageSize) + 1}-${((activePage - 1) * pageSize) + photoList.length} of ${totalData}`}
                </Grid.Column>
                <Grid.Column textAlign='right' verticalAlign='middle'>
                    {totalPages < 1 || !totalPages
                        ? null
                        : <Pagination
                            activePage={activePage}
                            boundaryRange={'0'}
                            onPageChange={this.handlePaginationChange}
                            size='mini'
                            siblingRange={'1'}
                            totalPages={totalPages}
                            ellipsisItem={null}
                            firstItem={'<<'}
                            lastItem={'>>'}
                            prevItem={'<'}
                            nextItem={'>'}
                        />
                    }
                </Grid.Column>
            </Grid.Row>
        )
    }

    renderStudentGallery() {
        const { photoList, courseName } = this.state
        return (
            <Grid textAlign='center' style={{ marginTop: 'auto' }}>
                <PhotoGrid data={photoList} courseName={courseName} />
                {this.renderPagination()}
            </Grid>
        )
    }

    renderNoPhotoText() {
        return (
            <Fragment>
                <Message align='center' color='red'>Photos are coming soon.</Message>
            </Fragment>
        )
    }

    render() {
        const { albumList } = this.props
        const { isLoading, photoList } = this.state
        if (isLoading) {
            return (
                <Fragment>
                    <Spinner />
                </Fragment>
            )
        }

        if (!isUndefined(photoList)) {
            if (isEmpty(photoList)) {
                return this.renderNoPhotoText()
            }
            return this.renderStudentGallery()
        }

        return (
            <Card.Group stackable itemsPerRow={2} style={styles.cardsContainer}>
                {
                    albumList.map((album, idx) => {
                        return (
                            <Album key={`album-${idx}`} albumData={album} clickAction={this.getCoursePhotoList} />
                        )
                    })
                }
            </Card.Group>
        )
    }

}

export default withI18next(["gallery"])(GroupAlbum)