export default {
	"link": {
		"courses": "คอร์สเรียนหลัก",
		"events": "กิจกรรม",
		"cggallery": "อัลบั้มภาพกิจกรรม",
		"aboutUs": "เกี่ยวกับเรา",
		"blog": "Blog",
		"home": "หน้าแรก",
		"receiptReprint": "พิมพ์ใบเสร็จรับเงิน",
		"pos": "ลงทะเบียนคอร์สเรียน",
		"videos": "ดูวีดิโอ"
	},
	"hello": "สวัสดี คุณ",
	"en": "EN",
	"th": "TH",
	"signIn": "เข้าสู่ระบบ"
}
