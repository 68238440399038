import React, { Component } from 'react'
import { withRouter } from 'react-router'
import moment from 'moment'
import { Segment, Icon, Input, Grid, Flag, Header, Modal, Button, Dimmer, Radio, Message, Dropdown } from 'semantic-ui-react'
import styles from './styles'
import { CGButton } from '../../components/common'
import { validateOnlyNumber, formatDate, getUserRole } from '../../common/util'
import apiClient from '../../apis'
import { Spinner } from '../../components/common'
import { ROLE } from '../../common/const'

class StudentDemoForm extends Component {
    constructor(props) {
        super(props)
        const studentData = props.isEdit ? props.studentData : {
            nickName: '',
            mobileNumber: '',
            age: '',
            school: '',
            channel: '',
            branchId: '',
            availableDate: '',
            nationality: 'thai'
        }
        this.state = {
            studentData: studentData,
            branchList: [],
            error: {},
            errorMsg: '',
            isLoading: true,
            showCompletedCard: false,
            messageHidden: false,
            showNationalityPopup: !props.isEdit
        }
    }

    componentDidMount() {
        this.getBranchList()
    }

    getBranchList = async () => {
        const result = await apiClient.branch.getAllBranches()

        if (result.dataList !== null) {
            let branchList = result.dataList.map((p, index) => {
                return {
                    key: index,
                    text: p.branchName,
                    value: p.branchId,
                }
            })

            this.setState({
                branchList: branchList,
                isLoading: false
            })

        }

    }

    handleDropdownChange = (e, value) => {
        this.setState({
            studentData: {
                ...this.state.studentData,
                [value.id]: value.value
            }
        })
    }  

    handleChange = name => event => {
        this.setState({
            error: {},
            errorMsg: '',
            studentData: {
                ...this.state.studentData,
                [name]: event.target.value
            }
        })
    }

    handleNumberChange = name => event => {
        if (/^\d*$/.test(event.target.value)) {
            this.handleChange(name)(event)
        }
    }

    handleRadioChange = name => (event, { value }) => {
        this.setState({
            error: {},
            errorMsg: '',
            studentData: {
                ...this.state.studentData,
                [name]: value
            }
        })
    }

    checkboxHandleChange = () => event => {
        const changeValue = !this.state.studentData.ads
        this.setState({
            error: {},
            errorMsg: '',
            studentData: {
                ...this.state.studentData,
                ads: changeValue,
            }
        })
    }

    onChangeMobileNumber = name => (event) => {
        const newMobileNumber = validateOnlyNumber(event.target.value)
        this.setState({
            error: {},
            errorMsg: '',
            studentData: {
                ...this.state.studentData,
                [name]: newMobileNumber
            }
        })
    }

    onChooseNationality = (nationality) => {
        this.setState({
            studentData: {
                ...this.state.studentData,
                nationality
            },
            showNationalityPopup: false
        })
    }

    validateData = async () => {
        if (this.state.studentData.nickName === null || this.state.studentData.nickName === '') {
            this.setState({ errorMsg: this.getErrorText('nickName'), error: { nickName: true } })
            this.nickName.focus()
        }
        else if (this.state.studentData.age === null || this.state.studentData.age === '') {
            this.setState({ errorMsg: this.getErrorText('age'), error: { age: true } })
            this.age.focus()
        }
        else if (this.state.studentData.age > 35) {
            this.setState({ errorMsg: this.getErrorText('ageRange'), error: { age: true } })
            this.age.focus()
        }
        else if (this.state.studentData.mobileNumber.length === 0) {
            this.setState({ errorMsg: this.getErrorText('mobileNumber'), error: { mobileNumber: true } })
            this.mobileNumber.focus()
        }
        else if (this.state.studentData.mobileNumber.length !== 10) {
            this.setState({ errorMsg: this.getErrorText('mobileNumberFormat'), error: { mobileNumber: true } })
            this.mobileNumber.focus()
        }
        else if (this.state.studentData.school === null || this.state.studentData.school === '') {
            this.setState({ errorMsg: this.getErrorText('school'), error: { school: true } })
            this.school.focus()
        }
        else if (this.state.studentData.branchId === null || this.state.studentData.branchId === '') {
            this.setState({ errorMsg: this.getErrorText('branch'), error: { branchId: true } })
            this.availableDate.focus()
        }
        else if (this.state.studentData.availableDate === null || this.state.studentData.availableDate === '') {
            this.setState({ errorMsg: this.getErrorText('availableDate'), error: { availableDate: true } })
            this.availableDate.focus()
        }
        else if (moment(this.state.studentData.availableDate).isBefore(moment().format('YYYY MM DD'))) {
            this.setState({ errorMsg: this.getErrorText('availableDateRange'), error: { availableDate: true } })
            this.availableDate.focus()
        }
        else if (this.state.studentData.channel === null || this.state.studentData.channel.length === 0) {
            this.setState({ errorMsg: this.getErrorText('channel'), error: { channel: true } })
        }
        else {
            this.doSave()
        }
    }

    doSave = async () => {
        const saveFn = this.props.isEdit ? apiClient.studentDemo.update : apiClient.studentDemo.add
        this.setState({ errorMsg: '', error: {}, isLoading: true })
        const result = await saveFn(this.state.studentData)
        this.setState({ isLoading: false, isSaved: result.status === 'success', errorMsg: result.message || '' })
    }

    showError = () => {
        if (this.state.errorMsg !== '') {
            return <Segment color='red'>{this.state.errorMsg}</Segment>
        }
    }

    getFormText(name) {
        return FORM_TEXT[name][this.state.studentData.nationality]
    }

    getErrorText(name) {
        return ERROR_TEXT[name][this.state.studentData.nationality]
    }

    renderFillInForm = () => {
        return (
            <Grid stackable columns={2}>
                <Grid.Column verticalAlign='middle'>
                    <Header style={styles.primaryColor} size='medium'>{this.getFormText('studentInformation')}</Header>
                </Grid.Column>
                {this.props.isEdit && <Grid.Column verticalAlign='middle'>
                    <Grid textAlign='right' verticalAlign='middle'>
                        <Grid.Column textAlign='right' verticalAlign='middle'>
                            <span style={styles.nationalityItem}>Nationality</span>
                            <Radio
                                style={styles.nationalityItem}
                                label='Thai'
                                name='nationality'
                                value='thai'
                                checked={this.state.studentData.nationality === 'thai'}
                                onChange={this.handleRadioChange('nationality')}
                                disabled
                            />
                            <Radio
                                label='Foreigner'
                                name='nationality'
                                value='others'
                                checked={this.state.studentData.nationality === 'others'}
                                onChange={this.handleRadioChange('nationality')}
                                disabled
                            />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>}
                <Grid.Column textAlign='right'>
                    <Header size='tiny'>{this.getFormText('instruction')}</Header>
                </Grid.Column>
                <Grid.Column>
                    <Input
                        placeholder={this.getFormText('nickName')}
                        value={this.state.studentData.nickName}
                        onChange={this.handleChange('nickName')}
                        ref={field => this.nickName = field}
                        fluid
                        error={this.state.error.nickName}
                    />
                </Grid.Column>
                <Grid.Column>
                    <Input
                        placeholder={this.getFormText('age')}
                        value={this.state.studentData.age}
                        onChange={this.handleNumberChange('age')}
                        ref={field => this.age = field}
                        fluid
                        maxLength={2}
                        error={this.state.error.age}
                    />
                </Grid.Column>
                <Grid.Column>
                    <Input
                        placeholder={this.getFormText('mobileNumber')}
                        value={this.state.studentData.mobileNumber}
                        onChange={this.handleNumberChange('mobileNumber')}
                        ref={field => this.mobileNumber = field}
                        fluid
                        maxLength={10}
                        error={this.state.error.mobileNumber}
                    />
                </Grid.Column>
                <Grid.Column>
                    <Input
                        placeholder={this.getFormText('school')}
                        value={this.state.studentData.school}
                        onChange={this.handleChange('school')}
                        ref={field => this.school = field}
                        fluid
                        error={this.state.error.school}
                    />
                </Grid.Column>
                <Grid.Column>
                    <Grid.Row style={{marginBottom : '2rem'}}>
                        <Dropdown
                            id='branchId'
                            placeholder={this.getFormText('branch')}
                            fluid
                            selection
                            value={this.state.studentData.branchId}
                            options={this.state.branchList}
                            onChange={this.handleDropdownChange}
                            error={this.state.error.branchId}
                        />
                    </Grid.Row>
                    <Grid.Row>
                        <Input
                            label={{ style: styles.availableDateLabel, basic: true, content: this.getFormText('availableDate') }}
                            labelPosition='left'
                            type='date'
                            value={this.state.studentData.availableDate}
                            onChange={this.handleChange('availableDate')}
                            ref={field => this.availableDate = field}
                            fluid
                            size='small'
                            error={this.state.error.availableDate}
                            min={formatDate(moment(), 'YYYY-MM-DD')}
                        />
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column>
                    <Segment>
                        {this.getFormText('channel')}
                        {this.getFormText('channelData').map((element, idx) =>
                            <Grid.Column key={idx}>
                                <Radio
                                    label={element}
                                    name='channel'
                                    value={element}
                                    onChange={this.handleRadioChange('channel')}
                                    checked={this.state.studentData.channel === element.toString()}
                                />
                            </Grid.Column>
                        )}
                    </Segment>
                </Grid.Column>
                <Grid.Column width={16} textAlign='center'>
                    {this.showError()}
                    <CGButton variant='contained' onClick={() => this.validateData()}>{this.getFormText('save')}</CGButton>
                </Grid.Column>
            </Grid>
        )
    }

    renderNationalityPopup() {
        return (
            <Modal
                open={this.state.showNationalityPopup}
                dimmer='blurring'
                closeOnDimmerClick={false}
                closeOnEscape={false}
                blurring
                size='tiny'
            >
                <Modal.Header align='center'>Choose your nationality</Modal.Header>
                <Modal.Content align='center'>
                    <Button size='huge' basic style={styles.nationalityButton} onClick={() => this.onChooseNationality('thai')}>
                        <Flag name='th' />
                        Thai
                    </Button>
                    <Button size='huge' basic style={styles.nationalityButton} onClick={() => this.onChooseNationality('others')}>
                        <Icon name='world' color='blue' />
                        Non-Thai
                    </Button>
                </Modal.Content>
            </Modal>
        )
    }

    handleOkClick() {
        if (getUserRole() === ROLE.ADMIN || getUserRole() === ROLE.CUSTOMER || getUserRole() === ROLE.GOD) {
            window.location.reload()
        } else {
            this.props.history.push('/')
        }
    }

    renderSaveSuccess() {
        return (
            <Message icon compact style={styles.successContainer}>
                <Icon name='check circle outline' color='green' />
                <Message.Content>
                    <Message.Header>Thank you for your registration.</Message.Header>
                    Our team will contact you back to confirm date and time.
                    <CGButton
                        style={styles.goToHomeButton}
                        fullWidth
                        variant='contained'
                        onClick={() => this.handleOkClick()}
                    >
                        OK
                    </CGButton>
                </Message.Content>
            </Message>
        )
    }

    render() {
        return (
            <Segment basic style={styles.container}>
                <Dimmer style={styles.dimmer} inverted active={this.state.isLoading}>
                    <Spinner />
                </Dimmer>
                {this.state.isSaved ? this.renderSaveSuccess() : this.renderFillInForm()}
                {this.renderNationalityPopup()}
            </Segment>
        )
    }
}

export default withRouter(StudentDemoForm)

const FORM_TEXT = {
    studentInformation: {
        thai: 'ข้อมูลนักเรียน',
        others: 'Student Information'
    },
    nickName: {
        thai: 'ชื่อเล่น',
        others: 'Nick Name'
    },
    age: {
        thai: 'อายุ',
        others: 'Age'
    },
    mobileNumber: {
        thai: 'เบอร์โทรศัพท์มือถือ',
        others: 'Mobile Number'
    },
    school: {
        thai: 'โรงเรียน',
        others: 'School'
    },
    branch: {
        thai: 'สาขาที่สะดวกเข้ามาทดลองเรียน',
        others: 'Available Branch'
    },
    availableDate: {
        thai: 'วันที่สะดวกเข้ามาทดลองเรียน',
        others: 'Available Date'
    },
    instruction: {
        thai: 'กรุณากรอกข้อมูลทั้งหมดเป็นภาษาไทย',
        others: 'Please fill data in English only.'
    },
    channel: {
        thai: 'รู้จักเราจากช่องทางใด',
        others: 'How did you hear about us?'
    },
    channelData: {
        thai: [
            'เว็บไซต์',
            'Facebook',
            'Line@',
            'Line Square',
            'กลุ่มผู้ปกครอง เพื่อน คนรู้จัก บอกต่อ',
            'เดินผ่านโรงเรียน Code Genius',
            'สื่อภายในสยามพารากอน',
            'บูธตามงานต่าง ๆ',
            'โบรชัวร์',
            'อื่น ๆ'
        ],
        others: [
            'Website',
            'Facebook',
            'Line@',
            'Line Square',
            'Recommended by friend',
            'Walk through Code Genius',
            'Paragon Media',
            'Event',
            'Brochure',
            'Other'
        ]
    },
    save: {
        thai: 'ลงทะเบียน',
        others: 'Register'
    }
}

const ERROR_TEXT = {
    nickName: {
        thai: 'กรุณากรอกชื่อเล่น',
        others: 'Nick name is required.'
    },
    age: {
        thai: 'กรุณากรอกอายุ',
        others: 'Age is required.'
    },
    ageRange: {
        thai: 'กรุณากรอกอายุให้ถูกต้อง',
        others: 'Invalid age.'
    },
    mobileNumber: {
        thai: 'กรุณากรอกเบอร์โทรศัพท์มือถือ',
        others: 'Mobile number is required.'
    },
    mobileNumberFormat: {
        thai: 'กรุณากรอกเบอร์โทรศัพท์มือถือให้ถูกต้อง',
        others: 'Invalid mobile number.'
    },
    branch: {
        thai: 'กรุณาเลือกสาขาที่สะดวกเข้ามาทดลองเรียน',
        others: 'Available branch is required.'
    },
    school: {
        thai: 'กรุณากรอกโรงเรียน',
        others: 'School is required.'
    },
    channel: {
        thai: 'กรุณาระบุว่ารู้จักเราจากช่องทางไหน',
        others: 'How did you hear about us? is required.'
    },
    availableDate: {
        thai: 'กรุณาระบุวันที่สะดวกเข้ามาทดลองเรียน',
        others: 'Available date is required.'
    },
    availableDateRange: {
        thai: 'กรุณากรอกวันที่สะดวกเข้ามาทดลองเรียนให้ถูกต้อง',
        others: 'Invalid available date.'
    }
}