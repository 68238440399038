import React, {Component} from 'react'
import {Button, Checkbox, Grid, Icon, Image, Label, Loader, Popup, Table} from 'semantic-ui-react'
import {withRouter} from 'react-router'
import {formatDateDisplay, formatTimeDisplay} from '../../common/util'
import * as CONST from '../../common/const';
import './custom-style.css';
import moment from 'moment'

class DataList extends Component {

    handleChangeStatus = (data, status) => {
        this.props.onChangeStatus(data, status)
    }

    handleCheckboxChange = (name, data) => event => {
        if (name === 'isFeedback') {
            this.props.onChangeIsFeedback(data, data.isFeedback === 1 ? 0 : 1)
        }
    }

    render() {
        const {tableData} = this.props
        const status = CONST.STUDENT_CLASS_STATUS
        const statusText = CONST.STUDENT_CLASS_STATUS_TEXT
        const icon = CONST.BUTTON_ICON

        const today = new Date()
        const currY = today.getFullYear()
        const currM = today.getMonth()
        let deadline = new Date(currY, currM - 1, 1) //last day of (current Month - 2)
        if (today.getDate() > CONST>CONST.EDIT_DEADLINE_DATE){
            deadline = new Date(currY, currM, 1) //last dat of last month
        }

        let result = [], rowResult = []
        let classDate = '', firstRow = true
        for (let i = 0; tableData && i < tableData.length; i++) {
            let data = tableData[i]
            if (classDate !== data.classDate) { // row header
                classDate = data.classDate
                firstRow = true
                if (i != 0) {
                    result.push(
                        <Table.Body key={'B' + i}>
                            {rowResult}
                        </Table.Body>
                    )
                    rowResult = []
                }

                result.push(
                    <Table.Header key={'H' + i}>
                        <Table.Row>
                            <Table.HeaderCell textAlign='center' width='2'>
                                {/* {formatDateDisplay(data.classDate)} */}
                                {moment(data.classDate).format('ddd DD MMM YYYY')}
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='2'>Course</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='2'>Student</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='1'>Tel</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='1'>Teacher</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='1'>Room</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='1'>Current</Table.HeaderCell>
                            {/* <Table.HeaderCell textAlign='center' width='1'>Next</Table.HeaderCell> */}
                            <Table.HeaderCell textAlign='center' width='1'>Remain</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='1'>Status</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='1'>Action</Table.HeaderCell>
                            {/* <Table.HeaderCell textAlign='center' width='1'>Feedback</Table.HeaderCell> */}
                            <Table.HeaderCell textAlign='center' width='3'>Remark</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                )
            }

            // check colspan
            let showTime = true, rowSpanTime = 1
            if (firstRow || (i > 0 && (data.startTime !== tableData[i - 1].startTime || data.endTime !== tableData[i - 1].endTime))) {
                for (let j = i + 1; j < tableData.length; j++) {
                    if (data.classDate === tableData[i].classDate && data.startTime === tableData[j].startTime && data.endTime === tableData[j].endTime) {
                        rowSpanTime++
                    } else {
                        break
                    }
                }
            } else {
                showTime = false
            }

            let showCourse = true, rowSpanCourse = 1
            // change course to class for same course, datetime diff room (python online)
            if (firstRow || (i > 0 && data.classId !== tableData[i - 1].classId)) {
                for (let j = i + 1; j < tableData.length; j++) {
                    if (data.classDate === tableData[i].classDate && data.classId === tableData[j].classId) {
                        rowSpanCourse++
                    } else {
                        break
                    }
                }
            } else {
                showCourse = false
            }

            // row body
            rowResult.push(
                <Table.Row key={'R' + i} style={{height: '30px'}} verticalAlign='middle'>
                    {showTime ?
                        <Table.Cell textAlign='center' verticalAlign='top' rowSpan={rowSpanTime}
                                    className='zui-table-base'>
                            {formatTimeDisplay(data.startTime) + ' - ' + formatTimeDisplay(data.endTime)}
                        </Table.Cell> : null
                    }
                    {showCourse ?
                        <Table.Cell verticalAlign='top' rowSpan={rowSpanCourse} className='zui-table-base'>
                            <Popup size='tiny'
                                   content={<div>
                                       <strong>Room: </strong>{data.room}<br/>
                                       <strong>Teacher: </strong>{data.teacherMain} {data.teacherAssist ? data.teacherAssist.replace(',', '-TA ') + '-TA' : ''}
                                   </div>}
                                   trigger={<span>{data.courseName}</span>}/>
                        </Table.Cell> : null
                    }
                    <Table.Cell verticalAlign='middle'>
                        {data.studentId} {data.studentFirstName} {data.studentNickName ? '(' + data.studentNickName + ') ' : ' '}
                        {data.ads === 0 ?
                            <Image style={{height: '1rem'}} src={`${process.env.PUBLIC_URL}/icons/no_camera.ico`}
                                   inline/>
                            : null}
                    </Table.Cell>
                    <Table.Cell textAlign='center' verticalAlign='middle'>
                        {data.studentId ? data.studentEmerMobile : null}
                    </Table.Cell>
                    <Table.Cell textAlign='center' verticalAlign='middle'>
                        {data.teacherMain || '-'}{data.teacherAssist && ` / ${data.teacherAssist}` }
                    </Table.Cell>
                    <Table.Cell textAlign='center' verticalAlign='middle'>
                        {data.room}
                    </Table.Cell>
                    <Table.Cell textAlign='center' verticalAlign='middle'>
                        {data.studentId ? data.currentAttend + ' / ' + data.currentTotal : null}
                    </Table.Cell>
                    {/* Next class */}
                    {/* <Table.Cell textAlign='center' verticalAlign='middle'>
                        {data.nextClass > 0 ?
                            <Popup size='mini' content={data.nextCourseName}
                                   trigger={<label>{data.nextClass}</label>}/> : 0}
                    </Table.Cell> */}
                    <Table.Cell textAlign='center' verticalAlign='middle'
                                style={{
                                    backgroundColor: data.studentId && data.currentTotal - data.currentAttend <= 2 ? 'red' : null,
                                    color: data.studentId && data.currentTotal - data.currentAttend <= 2 ? 'white' : null
                                }}>
                        {data.studentId && data.currentTotal && data.currentAttend ?
                            <div>{data.currentTotal - data.currentAttend}</div> : null}
                    </Table.Cell>
                    <Table.Cell textAlign='center' verticalAlign='middle'>
                        {data.status === status.ATTENDED ?
                            <Label size={'small'} color='green'>{data.status}</Label> : null}
                        {data.status === status.ACTIVE ?
                            <Label size={'small'} color='blue'>{data.status}</Label> : null}
                        {data.status === status.CONFIRMED ?
                            <Label size={'small'} color='yellow'>{data.status}</Label> : null}
                        {data.status === status.PENDING ?
                            <Label size={'small'}>{data.status}</Label> : null}
                        {data.status === status.CANCEL ?
                            <Label size={'small'} color='grey'>{data.status}</Label> : null}
                        {data.status === status.REFUND ?
                            <Label size={'small'} color='black'>{data.status}</Label> : null}
                    </Table.Cell>
                    {data.isActionLoading ?
                        <Table.Cell textAlign='center' verticalAlign='middle'>
                            <Loader active={data.isActionLoading} inline='centered' size='tiny'/>
                        </Table.Cell> :
                        <div>
                            {                           
                             (moment(deadline).isBefore(data.classDate))?
                                <Table.Cell textAlign='center' verticalAlign='middle' style={{whiteSpace: 'nowrap'}}>
                                    {console.log(data.classDate)}
                                    {console.log( deadline )}

                                    {data.studentId && data.status === status.ACTIVE ?
                                        <div>
                                            <Popup content={statusText.CONFIRM} size='mini' trigger={
                                                <Button icon compact color='yellow' size='mini'
                                                        onClick={() => this.handleChangeStatus(data, status.CONFIRMED)}>
                                                    <Icon name={icon.CONFIRM}/>
                                                </Button>
                                            }/>
                                            <Popup content={statusText.CANCEL} size='mini' trigger={
                                                <Button icon compact color='red' size='mini'
                                                        onClick={() => this.handleChangeStatus(data, status.CANCEL)}>
                                                    <Icon name={icon.CANCEL}/>
                                                </Button>
                                            }/>
                                            {data.courseType === 'Core Course' ?
                                                <Popup content={statusText.SKIP} size='mini' trigger={
                                                    <Button icon compact size='mini'
                                                            onClick={() => this.handleChangeStatus(data, status.SKIP)}>
                                                        <Icon name={icon.SKIP}/>
                                                    </Button>
                                                }/> : null
                                            }
                                        </div>
                                        : null}
                                    {data.studentId && data.status === status.CONFIRMED ?
                                        <div>
                                            <Popup content={statusText.ATTEND} size='mini' trigger={
                                                <Button icon compact color='green' size='mini'
                                                        onClick={() => this.handleChangeStatus(data, status.ATTENDED)}>
                                                    <Icon name={icon.ATTEND}/>
                                                </Button>
                                            }/>
                                            <Popup content={statusText.CANCEL} size='mini' trigger={
                                                <Button icon compact color='red' size='mini'
                                                        onClick={() => this.handleChangeStatus(data, status.CANCEL)}>
                                                    <Icon name={icon.CANCEL}/>
                                                </Button>
                                            }/>
                                            <Popup content={statusText.ACTIVE} size='mini' trigger={
                                                <Button icon compact color='blue' size='mini'
                                                        onClick={() => this.handleChangeStatus(data, status.ACTIVE)}>
                                                    <Icon name={icon.ACTIVE}/>
                                                </Button>
                                            }/>
                                        </div>
                                        : null}
                                    {data.studentId && data.status === status.ATTENDED ?
                                        <div>
                                            <Popup content={statusText.CANCEL} size='mini' trigger={
                                                <Button icon compact color='red' size='mini'
                                                        onClick={() => this.handleChangeStatus(data, status.CANCEL)}>
                                                    <Icon name={icon.CANCEL}/>
                                                </Button>
                                            }/>
                                            <Popup content={statusText.ACTIVE} size='mini' trigger={
                                                <Button icon compact color='blue' size='mini'
                                                        onClick={() => this.handleChangeStatus(data, status.ACTIVE)}>
                                                    <Icon name={icon.ACTIVE}/>
                                                </Button>
                                            }/>
                                        </div>
                                        : null}
                                    {data.studentId && data.status === status.CANCEL ?
                                        <div>
                                            <Popup content={statusText.ATTEND} size='mini' trigger={
                                                <Button icon compact color='green' size='mini'
                                                        onClick={() => this.handleChangeStatus(data, status.ATTENDED)}>
                                                    <Icon name={icon.ATTEND}/>
                                                </Button>
                                            }/>
                                            <Popup content={statusText.ACTIVE} size='mini' trigger={
                                                <Button icon compact color='blue' size='mini'
                                                        onClick={() => this.handleChangeStatus(data, status.ACTIVE)}>
                                                    <Icon name={icon.ACTIVE}/>
                                                </Button>
                                            }/>
                                        </div>
                                        : null}
                                    {data.studentId && data.status === status.PENDING ?
                                        <div>
                                            <Popup content={statusText.ACTIVE} size='mini' trigger={
                                                <Button icon compact color='blue' size='mini'
                                                        onClick={() => this.handleChangeStatus(data, status.ACTIVE)}>
                                                    <Icon name={icon.ACTIVE}/>
                                                </Button>
                                            }/>
                                        </div>
                                        : null}
                                </Table.Cell>:null}
                        </div>
                        
                        
                            
                    }
                    {/* Feedback */}
                    {/* <Table.Cell textAlign='center' verticalAlign='middle'>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            {data.isFeedbackLoading ?
                                <Loader active={data.isFeedbackLoading} inline='centered' size='tiny'/> :
                                <Checkbox
                                    checked={data.isFeedback === 1}
                                    onChange={this.handleCheckboxChange('isFeedback', data)}/>
                            }
                        </div>
                    </Table.Cell> */}
                    <Table.Cell verticalAlign='top'>{data.remark}</Table.Cell>
                </Table.Row>
            )

            firstRow = false

            // push last body
            if (i === tableData.length - 1) {
                result.push(
                    <Table.Body key={'B' + i}>
                        {rowResult}
                    </Table.Body>
                )
            }
        }

        return (
            <Grid style={{margin: '0'}}>
                <Table celled structured compact style={{padding: '0'}} size='small'
                       className='zui-table-highlight'>
                    {result && result.length > 0 ? result :
                        <Table.Body key={'B'}>
                            <Table.Row>
                                <Table.Cell verticalAlign='middle' warning textAlign='center' colSpan='9'>
                                    No Data Found
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    }
                </Table>
            </Grid>
        )
    }
}

export default withRouter(DataList)
