import { getRequest, postRequest } from '../lib/request'

export function authenticate(code) {
    return postRequest(`/video/authenticate`, { code })
}

export function extendCode(code) {
    return postRequest(`/video/extend`, { code })
}

export function saveCodeNote(code, note) {
    return postRequest(`/video/note`, { code, note })
}

export function getCodes() {
    return getRequest(`/video/codes`)
}

export function getProducts() {
    return getRequest(`/video/products`)
}

export function getBatchIds() {
    return getRequest(`/video/batchIds`)
}

export function searchCodes(searchData) {
    const data = {
        q: searchData.q,
        activated: searchData.activated,
        productId: searchData.productId,
        orderBy: searchData.orderBy,
        orderType: searchData.orderType,
        page: searchData.page,
        batchId: searchData.batchId
    }
    return getRequest(`/video/codes`, data)
}

export function generateCodes(productId, key, count) {
    const data = { productId, key, count }
    return postRequest(`/video/generateCodes`, data)

}

export function activateCode(code, name, age, number, school) {
    return postRequest('/video/activate', {
        code, name, age, number, school
    })
}