import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import { getCurrentUser, isUndefined } from '../../common/util'
import MainLayout from '../../layouts/MainLayout'
import { withI18next } from '../../lib/withI18next'
import { Spinner } from '../../components/common'
import ProfileBar from '../../components/ProfileBar'
import styles from './styles'
import ScheduleForm from '../../components/ScheduleForm'
import { Icon } from 'semantic-ui-react'

class schedule extends React.Component {

    constructor(props) {
        super(props)
        const userInfo = getCurrentUser()
        this.state = {
            isLoading: false,
            studentId: userInfo && userInfo.selectedStudent && userInfo.selectedStudent.studentId
        }
    }
    
    getSelectedStudent = () => {
        const userInfo = getCurrentUser()
        this.setState({
            isLoading: false,
            studentId: userInfo && userInfo.selectedStudent && userInfo.selectedStudent.studentId
        })
    }

    render() {
        const {classes} = this.props
        const {isLoading, studentId} = this.state

        if (isLoading) {
            return (
                <MainLayout>
                    <ProfileBar>
                        <Grid item xs={12}>
                            <Spinner/>
                        </Grid>
                    </ProfileBar>
                </MainLayout>
            )
        }

        if (isUndefined(studentId)) {
            return (
                <MainLayout>
                    <ProfileBar>
                        <Grid item xs={12}>
                            <Typography align='center' color='error'>
                                Please contact admin for update account with your student.
                            </Typography>
                        </Grid>
                    </ProfileBar>
                </MainLayout>
            )
        }

        return (
            <MainLayout>
                <div>
                    <ProfileBar getSelectedStudent={this.getSelectedStudent}>
                        <Grid container className={classes.headerContainer}>
                            <Icon size='big' name='calendar alternate outline' color='yellow' />
                            <Typography color='error' variant='title'>Schedule</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.formContainer}>
                                <ScheduleForm
                                    studentId={studentId}
                                />
                            </div>
                        </Grid>
                    </ProfileBar>
                </div>
            </MainLayout>
        )
    }
}

export default withI18next(['schedule'])(withStyles(styles)(schedule))