export default theme => ({
	gridReverse: {
		display: 'flex',
		flexDirection: 'column-reverse',
		padding: '0px 11px 25px 24px',
		alignSelf: 'flex-start',
		[theme.breakpoints.down('sm')]: {
			alignSelf: 'center',
			padding: 0,
			marginLeft: '3%',
			marginBottom: '15px',
			width: 'auto'
		},
		[theme.breakpoints.down('xs')]: {
			padding: '2px',
			marginLeft: 0,
			marginBottom: '10px',
			width: '50%'
		}
	},
	menuContainer: {
		borderRadius: '0 0 15px 15px',
		display: 'flex',
		flexDirection: 'column',
		height: 'auto',
		justifyContent: 'center',
		marginTop: '-15px',
		maxWidth: '236.2px',
		padding: '30px 20px 0 20px',
		textAlign: 'center',
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			boxShadow:
				'0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
			borderRadius: '4px',
			margin: '5px',
			padding: '0px',
			width: '90%'
		}
	},
	contentContainer: {
		padding: '20px 15px 10px 0px',
		[theme.breakpoints.down('sm')]: {
			padding: '20px 10px 10px 10px'
		}
	},
	menuBlue: {
		backgroundColor: theme.palette.primary.main
	},
	menuRed: {
		backgroundColor: theme.palette.error.main
	},
	menuYellow: {
		backgroundColor: theme.palette.secondary.main
	},
	menuNavy: {
		backgroundColor: theme.palette.primary.darkest,
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		},
		[theme.breakpoints.down('xs')]: {
			display: 'none'
		}
	},
	menuGrey: {
		backgroundColor: theme.palette.grey[200],
		color: `#A9A9A9 !important`
	},
	menuLinkContainer: {
		margin: '0 0 20px',
		[theme.breakpoints.down('sm')]: {
			margin: '0',
			padding: '20px 50px'
		},
		[theme.breakpoints.down('xs')]: {
			margin: '0',
			padding: '10px'
		}
	},
	menuLink: {
		color: '#ffffff',
		fontWeight: 'bold',
		textDecoration: 'none',
		'&:hover': {
			color: '#ffffff'
		}
	},
	mobileMenu: {
		[theme.breakpoints.down('sm')]: {
			display: 'flex'
		}
	},
	textLeft: {
		textAlign: 'left'
	},
	textCenter: {
		textAlign: 'center'
	},
	profilePhotoContainer: {
		textAlign: 'center',
		width: '100%'
	},
	avatarArea: {
		marginLeft: '24px',
		marginRight: '11px',
		maxWidth: '236.2px',
		[theme.breakpoints.down('sm')]: {
			alignSelf: 'center',
			display: 'flex',
			backgroundColor: theme.palette.primary.darkest,
			borderRadius: '15px',
			boxShadow: 'none',
			marginLeft: '20%',
			marginRight: '0',
			marginBottom: '15px',
			maxWidth: '30%',
			minWidth: '210px'
		},
		[theme.breakpoints.down('xs')]: {
			alignSelf: 'center',
			display: 'flex',
			backgroundColor: theme.palette.primary.darkest,
			borderRadius: '15px',
			boxShadow: 'none',
			// width: '60%',
			marginLeft: '10px',
			marginRight: '0',
			marginBottom: '10px',
			maxWidth: '100%'
		}
	},
	root: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	formControl: {
		minWidth: 120
	},
	selectedStudentSection: {
		[theme.breakpoints.down('sm')]: {
			marginBottom: '15px'
		}
	}
})
