import React from 'react'
import {createMuiTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles'
import {withTranslation} from 'react-i18next'
import styles from './styles'
import ProfilePhoto from '../../components/ProfilePhoto'
import {Spinner} from '../../components/common'
import {getCurrentUser, isEmpty, isUndefined, removeToken, isReadonly} from '../../common/util'
import {FormControl, Grid, InputLabel, MenuItem, Select} from '@material-ui/core/'
import {withRouter} from 'react-router'
import _ from 'lodash'
import {ROLE} from "../../common/const";

const selectTheme = createMuiTheme({
    overrides: {
        MuiSelect: {
            icon: {
                color: 'white',
            }
        },
        MuiInputBase: {
            root: {
                color: 'white',
                fontFamily: '\'Varela Round\', \'K2D\', \'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
                fontSize: '1.25em',
            }
        },
        MuiFormLabel: {
            root: {
                color: 'white',
                fontFamily: 'Varela Round',
                fontSize: '1.25em',
            },
            focused: {
                '&$focused': {
                    color: 'white',
                }
            }
        },
        MuiMenuItem: {
            root: {
                fontFamily: '\'Varela Round\', \'K2D\', \'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
                fontSize: '1.15em',
                padding: '0 15px',
            }
        }
    },
});

class ProfileBar extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showUpload: false,
            isLoading: true,
            avatarUrl: `${process.env.PUBLIC_URL}/images/CGFace.png`,
            studentList: '',
            currentStudent: null,
        }
        this.toggleMenu = this.toggleMenu.bind(this)
    }

    componentDidMount() {
        this.getStudentAvatarAndStudentList()
    }

    getStudentAvatarAndStudentList = async () => {
        const userInfo = getCurrentUser()

        if (isUndefined(userInfo)) {
            this.props.history.push('/home')
        }
        if (isUndefined(userInfo.selectedStudent)) {
            this.setState({isLoading: false})
        } else {
            this.setState({
                currentStudent: userInfo.selectedStudent.studentId,
                avatarUrl: userInfo.selectedStudent.photoUrl,
                studentList: userInfo.student,
                isLoading: false
            })
        }
    }

    setSelectStudentToCurrentStudent = async (studentId) => {
        const userInfo = getCurrentUser()
        const {studentList} = this.state
        const indexStudent = _.findKey(studentList, {'studentId': studentId})

        await localStorage.setItem('user', JSON.stringify({...userInfo, selectedStudent: studentList[indexStudent]}))
        await this.getStudentAvatarAndStudentList()

        if (!isUndefined(this.props.getSelectedStudent)) {
            this.props.getSelectedStudent()
        }
    }

    handleSelectStudent = async event => {

        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            this.setSelectStudentToCurrentStudent(event.target.value)
        })
    }


    toggleMenu() {
        this.setState({
            showUpload: !this.state.showUpload
        })
    }

    signout = async () => {
        await removeToken()
        this.props.history.push('/home')
    }

    renderSelectStudentDropdown() {
        const {classes} = this.props
        const {studentList, currentStudent} = this.state

        if (!isEmpty(studentList)) {
            return (
                <FormControl className={classes.formControl}>
                    <MuiThemeProvider theme={selectTheme}>
                        <InputLabel htmlFor="student-list">Student</InputLabel>
                        <Select
                            disableUnderline={true}
                            value={currentStudent}
                            onChange={this.handleSelectStudent}
                            inputProps={{
                                name: 'currentStudent',
                                id: 'student-list',
                            }}
                        >
                            {studentList.map((student) => {
                                return (
                                    <MenuItem key={student.studentId}
                                              value={student.studentId}>{student.nickName}</MenuItem>
                                )
                            })}
                        </Select>
                    </MuiThemeProvider>
                </FormControl>
            )
        }
    }

    render() {
        const {classes, t, children, dataUri, showCompletedCard, setDataUri} = this.props
        const {isLoading, avatarUrl} = this.state
        const userInfo = getCurrentUser()
        const readonly = isReadonly()

        if (isLoading) {
            return <Spinner/>
        }

        const editProfileFlag = (this.props.location.pathname === '/editprofile' || this.props.location.pathname === '/addstudent' || this.props.location.pathname === '/student/manage' ? true : false)

        return (
            <Grid container>
                {userInfo && (userInfo.role === ROLE.ADMIN || userInfo.role === ROLE.GOD) ? (
                    <Grid item md={3} sm={12} className={classes.mobileMenu}>
                        <Grid className={`${classes.menuNavy} ${classes.avatarArea}`}>
                            <div className={classes.profilePhotoContainer}>
                                <ProfilePhoto setDataUri={setDataUri} showCompletedCard={showCompletedCard}
                                              imageSrc={dataUri ? dataUri : avatarUrl}
                                              editProfileFlag={editProfileFlag} readonly={readonly}/>
                                <div className={classes.selectedStudentSection}>
                                    {readonly || editProfileFlag ? null : this.renderSelectStudentDropdown()}
                                </div>
                            </div>
                        </Grid>
                        <Grid className={classes.gridReverse}>
                            <Grid className={`${classes.menuContainer} ${classes.menuNavy}`}>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item md={3} sm={12} className={classes.mobileMenu}>
                        <Grid className={`${classes.menuNavy} ${classes.avatarArea}`}>
                            <div className={classes.profilePhotoContainer}>
                                <ProfilePhoto setDataUri={setDataUri} showCompletedCard={showCompletedCard}
                                              imageSrc={dataUri ? dataUri : avatarUrl}
                                              editProfileFlag={editProfileFlag} readonly={readonly} />
                                <div className={classes.selectedStudentSection}>
                                    {readonly || editProfileFlag ? null : this.renderSelectStudentDropdown()}
                                </div>
                            </div>
                        </Grid>
                        <Grid className={classes.gridReverse}>
                            <Grid className={`${classes.menuContainer} ${classes.menuGrey}`}>
                                <div className={classes.textCenter}>
                                    <div className={classes.menuLinkContainer}>
                                        <a className={`${classes.menuLink} ${classes.menuGrey}`} href='#'
                                           onClick={() => this.signout()}>Sign Out</a>
                                    </div>
                                </div>
                            </Grid>
                            <Grid className={`${classes.menuContainer} ${classes.menuRed}`}>
                                <div className={classes.textCenter}>
                                    <div className={classes.menuLinkContainer}>
                                        <a className={classes.menuLink} href='/gallery'>Gallery</a>
                                    </div>
                                </div>
                            </Grid>
                            {/* <Grid className={[classes.menuContainer, classes.menuBlue]}>
                            <div className={classes.textCenter}>
                                <div className={classes.menuLinkContainer}>
                                    <a className={classes.menuLink} href='#'>Road Map</a>
                                </div>
                            </div>
                        </Grid> */}
                            <Grid className={`${classes.menuContainer} ${classes.menuBlue}`}>
                                <div className={classes.textCenter}>
                                    <div className={`${classes.menuLinkContainer}`}>
                                        <a className={classes.menuLink} href='/schedule'>Schedule</a>
                                    </div>
                                </div>
                            </Grid>
                            <Grid className={`${classes.menuContainer} ${classes.menuYellow}`}>
                                <div className={classes.textCenter}>
                                    <div className={`${classes.menuLinkContainer}`}>
                                        <a className={classes.menuLink} href='/editprofile'>{readonly?'View Profile':'Edit Profile'}</a>
                                    </div>
                                </div>
                            </Grid>
                            <Grid className={`${classes.menuContainer} ${classes.menuNavy}`}>
                            </Grid>
                        </Grid>
                    </Grid>
                )
                }

                <Grid item lg={9} md={9} xs={12}>
                    <Grid container className={classes.contentContainer}>
                        {children}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withRouter(withTranslation('Home')(withStyles(styles)(ProfileBar)))
