export default {
    "company": {
        "name": "Code Genius",
        "address": {
            "line1": "4th Floor, Siam Paragon,",
            "line2": "Edutainment Zone,",
            "line3": "991 Rama 1 Road, ",
            "line4": "Pathumwan, Bangkok 10330"
        },
        "openHour": {
            "weekDay": "Monday - Friday\n10.00 AM - 7.00 PM",
            "weekEnd": "Saturday - Sunday\n09.00 AM - 8.00 PM"
        }
    }
}