import React from 'react'
import CardMedia from '@material-ui/core/CardMedia'
import Toolbar from '@material-ui/core/Toolbar'
import {withStyles} from '@material-ui/core/styles'
import {CGButton} from '../../common'
import {withTranslation} from 'react-i18next'
import styles from './styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ListItemText from '@material-ui/core/ListItemText'
import {withRouter} from 'react-router'
import {ROLE, MAIN_BRANCH_ID} from '../../../common/const'
import {removeToken, validateBranchId} from "../../../common/util";

class NavBar extends React.Component {

    signout = async () => {
        await removeToken()
        this.props.history.push('/home')
    }

    state = {}

    toggleDrawer(open) {
        return () =>
            this.setState({
                isDrawerOpen: open
            })
    }

    render() {
        const {classes, t, currentUser, changeLanguage, showLang} = this.props
        const isMainBranch = validateBranchId([MAIN_BRANCH_ID])
        return (
            <div className={classes.container}>
                <AppBar position='static' color='default'>
                    <Toolbar>
                        <IconButton
                            onClick={this.toggleDrawer(true)}
                            color='inherit'
                            aria-label='Menu'>
                            <MenuIcon/>
                        </IconButton>
                        <div className={classes.logoSection}>
                            <div className={classes.logoContainer}>
                                <CardMedia
                                    component='img'
                                    image={`${process.env.PUBLIC_URL}/images/logo_300.png`}
                                    title={t('common:company.name')}
                                    onClick={() => this.props.history.push('/home')}
                                />
                            </div>
                        </div>
                        <div className={classes.signInContainer}>
                            {currentUser ? (
                                <CGButton
                                    variant='contained'
                                    onClick={this.signout}>
                                    Sign Out
                                </CGButton>
                            ) : (
                                <CGButton
                                    variant='contained'
                                    href={currentUser ? '/schedule' : '/signin'}>
                                    {currentUser
                                        ? `${t('hello')}${currentUser.name}`
                                        : t('signIn')}
                                </CGButton>
                            )
                            }
                        </div>
                    </Toolbar>
                </AppBar>
                <div className={classes.languageContainer}>
                    {showLang ? (
                        <div className={classes.languageSelector}>
                            <CGButton
                                className={classes.languageButton}
                                onClick={() => {
                                    changeLanguage('en')
                                }}>
                                {t('en')}
                            </CGButton>
                            <div className={classes.seperator}/>
                            <CGButton
                                className={classes.languageButton}
                                onClick={() => {
                                    changeLanguage('th')
                                }}>
                                {t('th')}
                            </CGButton>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                <Drawer
                    open={this.state.isDrawerOpen}
                    onClose={this.toggleDrawer(false)}>
                    <List>
                        {currentUser && (currentUser.role === ROLE.ADMIN || currentUser.role === ROLE.GOD ) ? (
                            <React.Fragment>
                                <ListItem
                                    divider
                                    button
                                    onClick={() => this.props.history.push('/studentclass/add')}>
                                    <ListItemText
                                        color='error'
                                        primary={t('link.pos')}
                                    />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() =>
                                        this.props.history.push('/receipt/search')
                                    }>
                                    <ListItemText
                                        primary={t('link.receiptSearch')}
                                    />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() =>
                                        this.props.history.push('/student/search')
                                    }>
                                    <ListItemText
                                        primary={t('link.studentSearch')}
                                    />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() =>
                                        this.props.history.push('/classschedule')
                                    }>
                                    <ListItemText
                                        primary={t('link.classSchedule')}
                                    />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() =>
                                        this.props.history.push('/studentdemo/search')
                                    }>
                                    <ListItemText
                                        primary={t('link.studentDemoSearch')}
                                    />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() =>
                                        this.props.history.push('/teacher/search')
                                    }>
                                    <ListItemText
                                        primary={t('link.manageTeacher')}
                                    />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() =>
                                        this.props.history.push('/studentchecklist')
                                    }>
                                    <ListItemText
                                        primary={t('link.studentCheckList')}
                                    />
                                </ListItem>
                                {isMainBranch && (
                                    <ListItem
                                        button
                                        onClick={() =>
                                            this.props.history.push('/code/search')
                                        }>
                                        <ListItemText
                                            primary={t('link.manageCodes')}
                                        />
                                    </ListItem>
                                )}
                            </React.Fragment>
                        ) : currentUser && currentUser.role === ROLE.ACCOUNT ? (
                            <React.Fragment>
                                <ListItem
                                    button
                                    onClick={() =>
                                        this.props.history.push('/receipt/search')
                                    }>
                                    <ListItemText
                                        primary={t('link.receiptSearch')}
                                    />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() =>
                                        this.props.history.push('/teacher/report')
                                    }>
                                    <ListItemText
                                        primary={t('link.teacherReport')}
                                    />
                                </ListItem>
                            </React.Fragment>
                        ) : currentUser && currentUser.role === ROLE.CUSTOMER ? (
                            <React.Fragment>
                                <ListItem
                                    button
                                    onClick={() =>
                                        this.props.history.push('/StudentDemo/Add')
                                    }>
                                    <ListItemText
                                        primary={t('link.studentDemoAdd')}
                                    />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() =>
                                        this.props.history.push('/StudentDemo/Search')
                                    }>
                                    <ListItemText
                                        primary={t('link.studentDemoSearch')}
                                    />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() =>
                                        this.props.history.push('/AddStudent')
                                    }>
                                    <ListItemText
                                        primary={t('link.addStudent')}
                                    />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() =>
                                        this.props.history.push('/AttendanceCheck')
                                    }>
                                    <ListItemText
                                        primary={t('link.attendanceCheck')}
                                    />
                                </ListItem>
                            </React.Fragment>
                        ) : currentUser && currentUser.role === ROLE.TEACHER ? (
                            <React.Fragment>
                                <ListItem
                                    button
                                    onClick={() =>
                                        this.props.history.push('/assessment')
                                    }>
                                    <ListItemText
                                        primary={t('link.assessment')}
                                    />
                                </ListItem>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <ListItem
                                    button
                                    onClick={() => this.props.history.push('/home')}>
                                    <ListItemText primary={t('link.home')}/>
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => this.props.history.push('/corecourses')}>
                                    <ListItemText primary={t('link.courses')}/>
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => this.props.history.push('/cggallery')}>
                                    <ListItemText
                                        primary={t('link.cggallery')}
                                    />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => this.props.history.push('/Codeo')}>
                                    <ListItemText
                                        primary={t('link.videos')}
                                    />
                                </ListItem>
                            </React.Fragment>
                        )}
                    </List>
                </Drawer>
            </div>
        )
    }
}

export default withRouter(withTranslation('navbar')(withStyles(styles)(NavBar)))
