import React from 'react'
import {PrintPage} from '../common'
import apiClient from '../../apis';
import {Table} from 'semantic-ui-react';

let PrintTemplate;

class TeacherReportForm extends React.Component {
    reportList = []
    PAGE_SIZE = 17

    constructor(props) {
        super(props)

        this.state = {
            isMounted: false
        }
    }

    componentDidMount() {
        PrintTemplate = require('react-print')
    }

    async print(criteria) {
        await this.loadData(criteria);
        this.setState({isMounted: true})
        window.print()
    }

    loadData = async (criteria) => {
        this.reportList = []
        if (!criteria) return
        let data = await apiClient.teacher.getTeacherReport(criteria)
        if (!data || data.length === 0) return

        const totalPage = this.getTotalPage(data)

        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        let s = new Date(criteria.fromDate);
        let e = new Date(criteria.toDate);
        let period = s.getDate() + ' ' + monthNames[s.getMonth()] + ' - ' + e.getDate() + ' ' + monthNames[e.getMonth()] + ' ' + s.getFullYear()

        let previousTeacher = data[0].teacherId
        let totalHours = 0.0
        let totalBaseTime = 0.0
        let totalDemo = 0
        let row = []
        let pageNo = 1
        let rSpan = 1
        let minCourseTime = '23:00', maxCourseTime = '01:00', checkIn = '00:00'
        let i = 0
        for (i = 0; i < data.length; i++) {
            if (data[i].teacherId !== previousTeacher) {
                let page = Math.ceil(row.length / this.PAGE_SIZE)
                let a = 0, b = 0
                for (let j = 0; j < page; j++) {
                    a = b
                    b = (j + 1) * this.PAGE_SIZE

                    if (b >= row.length) b = row.length
                    else if (row[b].course) {
                        for (let k = b; k > 0; k--) {
                            if (!row[k].course) {
                                b = k
                                break
                            }
                        }
                    }
                    this.reportList.push(this.renderPage(pageNo++, period, data[i - 1].teacherName, data[i - 1].teacherEmail, row.slice(a, b), totalBaseTime, totalDemo, totalHours, totalPage, j === page - 1))
                }

                totalDemo = 0.0
                totalBaseTime = 0.0
                totalHours = 0.0
                row = []
            }

            let baseTime = 0
            let isShow = true

            rSpan--
            if (rSpan > 0) {
                isShow = false
            } else {
                minCourseTime = '23:00'
                maxCourseTime = '01:00'
                for (let j = i; j < data.length; j++) {
                    if (data[i].date === data[j].date) {
                        rSpan++

                        if (data[j].type === 'CLASS') {
                            // find first class time - last class time
                            if (data[j].startTime < minCourseTime) minCourseTime = data[j].startTime
                            if (data[j].endTime > maxCourseTime) maxCourseTime = data[j].endTime

                            // find gap between class
                            if ((j + 1) < data.length && data[j].date === data[j + 1].date)
                                baseTime += this.calcDiffTime(data[j].endTime, data[j + 1].startTime)
                        }
                    } else {
                        break
                    }
                }
            }

            if (data[i].type === 'CHECK') {
                checkIn = data[i].startTime ? data[i].startTime : minCourseTime
                // find base time before first class
                if (this.calcDiffTime(data[i].startTime, minCourseTime) >= 1) baseTime += 1
                // find break time
                if (minCourseTime < '12:00' && maxCourseTime > '13:00') baseTime -= 1
            }

            // check part-time teacher demo
            let demoPaid = false
            if (data[i].type === 'DEMO' && data[i].isFullTime === 0) {
                if (data[i].startTime <= checkIn || data[i].startTime >= maxCourseTime) demoPaid = true
            }

            // sum total hours
            if (data[i].type === 'CLASS') totalHours += this.calcDiffTime(data[i].startTime, data[i].endTime)
            else if (data[i].type === 'CHECK') totalHours += baseTime

            // sum base time
            totalBaseTime += baseTime

            // sum demo
            if (data[i].type === 'DEMO' && data[i].startTime < minCourseTime && this.calcDiffTime(checkIn, minCourseTime) > 1) {
                totalDemo += 1
            }

            row.push({
                type: data[i].type,
                date: data[i].date,
                startTime: data[i].startTime,
                endTime: data[i].endTime,
                course: data[i].courseName,
                baseTime: baseTime,
                demoPaid: demoPaid,
                rSpan: isShow ? rSpan : 0
            })

            previousTeacher = data[i].teacherId
        }
        let page = Math.ceil(row.length / this.PAGE_SIZE)
        let a = 0, b = 0
        for (let j = 0; j < page; j++) {
            let a = b
            let b = (j + 1) * this.PAGE_SIZE
            if (b >= row.length) b = row.length
            else if (row[b].course) {
                for (let k = b; k > 0; k--) {
                    if (!row[k].course) {
                        b = k
                        break
                    }
                }
            }
            this.reportList.push(this.renderPage(pageNo++, period, data[i - 1].teacherName, data[i - 1].teacherEmail, row.slice(a, b), totalBaseTime, totalDemo, totalHours, totalPage, j === page - 1))
        }
    }

    getTotalPage(data) {
        let page = 0
        let row = 1
        for (let i = 1; i < data.length; i++) {
            if (data[i - 1].teacherId === data[i].teacherId) {
                row++
            } else {
                page += Math.ceil(row / this.PAGE_SIZE)
                row = 0
            }
        }
        page += Math.ceil(row / this.PAGE_SIZE)

        return page
    }

    renderPage(pageNo, period, teacherName, teacherEmail, row, totalBaseTime, totalDemo, totalHours, totalPage, isLast) {
        return (
            <div style={{height: '1020px'}}>
                <div style={{width: '90%', margin: '36.5px'}}>
                    <table style={{width: '100%', height: '946px'}}>
                        <tbody>
                        <tr>
                            <td>
                                <table style={{width: '100%'}}>
                                    <tbody>
                                    <tr>
                                        <td style={{
                                            verticalAlign: 'bottom',
                                            textAlign: 'center',
                                            fontSize: '35px',
                                            width: '85%'
                                        }}>
                                            Teaching Hours
                                        </td>
                                        <td rowSpan={2} style={{textAlign: 'right'}}>
                                            <img src={`${process.env.PUBLIC_URL}/images/logo_300.png`} alt='Code Genius'
                                                 style={{width: '200px'}}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            verticalAlign: 'bottom',
                                            textAlign: 'center',
                                            fontSize: '20px',
                                            width: '85%'
                                        }}>
                                            {period}
                                        </td>
                                    </tr>
                                    <tr>
                                    </tr>
                                    </tbody>
                                </table>
                                <Table celled structured>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='3' textAlign='center'>
                                                <span>คุณครู {teacherName}</span>
                                                <span style={{marginLeft: '50px'}}>Email {teacherEmail}</span>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {this.renderRow(row)}
                                    </Table.Body>

                                    {isLast ?
                                        <Table.Footer>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='3' textAlign='right'>
                                                    Total Base Time {totalBaseTime} Hours
                                                </Table.HeaderCell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='3' textAlign='right'>
                                                    Total Demo {totalDemo}
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Footer> : null}
                                </Table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign: 'bottom', height: '100%', textAlign: 'right'}}>
                                Page {pageNo} of {totalPage}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    renderRow(row) {
        let arr = []
        for (let i = 0; i < row.length; i++) {
            arr.push(
                <Table.Row key={i}>
                    {/* column date */}
                    {row[i].rSpan > 0 ?
                        <Table.Cell rowSpan={row[i].rSpan} textAlign='center' verticalAlign='top'>
                            {row[i].date}</Table.Cell> : null}

                    {/* column check-in, demo, class time */}
                    {row[i].type === 'CHECK' ?
                        <Table.Cell textAlign={'left'}>Check
                            In: {row[i].startTime ? row[i].startTime : '-'}</Table.Cell> : null}
                    {row[i].type === 'CLASS' ?
                        <Table.Cell textAlign={'center'}>{row[i].startTime} - {row[i].endTime}</Table.Cell> : null}
                    {row[i].type === 'DEMO' ?
                        <Table.Cell textAlign={'center'}>{row[i].startTime}</Table.Cell> : null}

                    {/* column base time, course name, demo hours */}
                    {row[i].type === 'CLASS' ? <Table.Cell>{row[i].course}</Table.Cell> : null}
                    {row[i].type === 'CHECK' ?
                        <Table.Cell textAlign={'left'}>Check
                            Out: {row[i].endTime ? row[i].endTime : '-'}</Table.Cell> : null}
                    {row[i].type === 'DEMO' ?
                        <Table.Cell textAlign='center'>{row[i].demoPaid ? '(Pay)' : ''}</Table.Cell> : null}
                </Table.Row>
            )
        }
        return arr
    }

    calcDiffTime(start, end) {
        let startDate, endDate
        try {
            start = start.split(':');
            end = end.split(':');
            startDate = new Date(0, 0, 0, start[0], start[1], 0);
            endDate = new Date(0, 0, 0, end[0], end[1], 0);
        } catch (e) {
            return 0
        }

        let diff = endDate.getTime() - startDate.getTime();
        let hours = Math.floor(diff / 1000 / 60 / 60);
        diff -= hours * 1000 * 60 * 60;
        let minutes = Math.floor(diff / 1000 / 60);

        // If using time pickers with 24 hours format, add the below line get exact hours
        if (hours < 0) hours = hours + 24;

        // return (hours <= 9 ? '0' : '') + hours + ':' + (minutes <= 9 ? '0' : '') + minutes;
        return hours + minutes / 60
    }

    render() {
        if (!this.state.isMounted) return <div/>

        return (
            <PrintTemplate>
                <PrintPage>
                    {this.reportList}
                </PrintPage>
            </PrintTemplate>
        )
    }
}

export default TeacherReportForm
