import React, {Component} from 'react'
import {withRouter} from 'react-router'
import {withI18next} from '../../lib/withI18next';
import styles from './styles'
import MainLayout from '../../layouts/MainLayout';
import {Calendar, momentLocalizer,} from 'react-big-calendar'
import moment from 'moment'
import './custom-big-calendar.css';
import apiClient from '../../apis';
import {Button, Checkbox, Confirm, Dimmer, Dropdown, Form, Grid, Icon, Loader} from 'semantic-ui-react';
import {STUDENT_CLASS_STATUS} from '../../common/const'
import ClassDetailModal from './ClassDetailModal'
import {getCurrentUser} from "../../common/util";

class ClassSchedule extends Component {
    current = null

    constructor(props) {
        super(props)
        let currentUser = getCurrentUser()
        this.current = new Date();
        this.state = {
            userBranchId: currentUser.branchId,
            searchData: {
                color: 'teacher',
                view: 'month',
                course: '',
                branch: currentUser.branchId && currentUser.branchId != 0 ? currentUser.branchId : 1,
                teacher: '',
                day: '',
                month: this.current.getMonth() + 1,
                year: this.current.getFullYear(),
            },
            filterData: {
                showStudent: false
            },
            classDetail: {},
            classCalendar: [],
            courseList: [],
            teacherList: [],
            branchList: [],
            showClassDetailModal: false,
            showConfirmModal: false,
            typeConfirmModal: '',
            messageConfirmModal: '',
            isLoading: false
        }

        this.handleInputChange = this.handleInputChange.bind(this)
        this.eventStyleGetter = this.eventStyleGetter.bind(this)
        this.handleNavigateChange = this.handleNavigateChange.bind(this)
        this.handleViewChange = this.handleViewChange.bind(this)
        this.handleSelectEvent = this.handleSelectEvent.bind(this)
    }

    openConfirmModal = (type) => {
        let message = ''
        if (type === 'SYNC_CALENDAR') {
            message = 'Confirm to sync teacher calendar?'
        } else if (type === 'UNSYNC_CALENDAR') {
            message = 'Confirm to delete sync teacher calendar?'
        }

        this.setState({
            showConfirmModal: true,
            typeConfirmModal: type,
            messageConfirmModal: message
        })
    }

    onConfirmModal = () => {
        if (this.state.typeConfirmModal === 'SYNC_CALENDAR') {
            this.syncCalendar()
        } else if (this.state.typeConfirmModal === 'UNSYNC_CALENDAR') {
            this.deleteSyncCalendar()
        }
        this.closeConfirmModal()
    }

    closeConfirmModal = () => this.setState({showConfirmModal: false, typeConfirmModal: '', messageConfirmModal: ''})

    componentDidMount() {
        this.getClassSchedule()
        this.getBranchList()
        this.getCourseList()
        this.getTeacherList()
    }

    getBranchList = async () => {
        this.setState({
            isLoading: true
        })

        const res = await apiClient.branch.getBranchList()
        if (res !== null) {
            let arr = res.map((p, index) => {
                return {
                    key: index,
                    text: p.branchName,
                    value: p.branchId
                }
            })
            this.setState({
                branchList: arr,
                isLoading: false
            })
        }
    }

    getCourseList = async () => {
        this.setState({
            isLoading: true
        })

        const res = await apiClient.course.getCourseList()
        if (res !== null) {
            let arr = res.map((p, index) => {
                return {
                    key: index,
                    text: p.courseName,
                    value: p.courseId
                }
            })
            this.setState({
                courseList: arr,
                isLoading: false
            })
        }
    }

    getTeacherList = async () => {
        this.setState({
            isLoading: true
        })

        const res = await apiClient.teacher.getTeacherList()
        if (res !== null) {
            let arr = res.map((p, index) => {
                return {
                    key: index,
                    text: p.name,
                    value: p.id
                }
            })
            this.setState({
                teacherList: arr,
                isLoading: false
            })
        }
    }

    getClassSchedule = async () => {
        this.setState({
            isLoading: true
        })
        const data = this.state.searchData
        let searchData = {}
        for (const key in data) {
            if (data[key] !== '' && data[key] !== null && data[key] !== undefined && data[key] !== 'undefined') {
                searchData[key] = data[key]
            }
        }

        const res = await apiClient.course.getClassSchedule(searchData)
        if (res !== null) {
            let classSchedule = res.classSchedule
            classSchedule = this.setEmptyRoom(classSchedule, res.room)
            classSchedule = classSchedule.map((p, index) => {
                p.start = new Date(p.start)
                p.end = new Date(p.end)

                let hr = p.start.getHours();
                let ampm = 'am';
                if (hr > 12) {
                    hr -= 12;
                    ampm = 'pm';
                }
                p.title = hr + ampm + ' ' + p.courseName

                return p
            })

            this.setState({
                classCalendar: classSchedule,
                isLoading: false
            })
        }
    }

    setEmptyRoom(data, room) {
        if (this.state.searchData.view !== 'day' || room === null || data.length < 2) return data
        let newData = []
        let subClassSchedule = [data[0]] // init period
        for (let i = 1; i < data.length; i++) {
            if (data[i].start === subClassSchedule[subClassSchedule.length - 1].start) {
                subClassSchedule.push(data[i]) // add class same period
            } else {
                let noRoom = JSON.parse(JSON.stringify(room))
                for (let j = 0; j < subClassSchedule.length && noRoom.length > 0; j++) {
                    let idx = noRoom.findIndex(x => x.roomId === subClassSchedule[j].roomId)
                    if (idx !== -1) {
                        noRoom.splice(idx, 1) // remove room already exist
                    }
                }
                if (noRoom.length > 0) {
                    for (let j = 0; j < noRoom.length > 0; j++) {
                        // add room no exist in period
                        subClassSchedule.push({
                            seq: noRoom[j].seq,
                            roomId: noRoom[j].roomId,
                            classId: 0,
                            courseName: '-',
                            courseShortName: '-',
                            teacherId: null,
                            taIds: null,
                            start: subClassSchedule[0].start,
                            end: subClassSchedule[0].end,
                            color: noRoom[j].color,
                            room: noRoom[j].name,
                            teacher: '-',
                            classSize: 0,
                            studentCount: 0,
                            student: ''
                        })
                    }
                    newData = newData.concat(subClassSchedule)
                }
                subClassSchedule = [data[i]] // init next period
            }
        }
        newData.sort((a, b) => (a.seq > b.seq) ? 1 : -1) // resort all class by room seq
        return newData
    }

    syncCalendar = async () => {
        this.setState({isLoading: true})

        let date = {
            month: this.state.searchData.month,
            year: this.state.searchData.year
        }
        const res = await apiClient.course.syncCalendar(date)
        this.setState({
            isLoading: false
        })
    }

    deleteSyncCalendar = async () => {
        this.setState({isLoading: true})

        let date = {
            month: this.state.searchData.month,
            year: this.state.searchData.year
        }
        const res = await apiClient.course.deleteCalendarEvent(date)
        this.setState({isLoading: false})
    }

    handleInputChange = (e, value) => {
        if (value.toggle) { // Filter Student
            this.setState({
                filterData: {
                    ...this.state.filterData,
                    showStudent: value.checked
                }
            })
        } else if (value.id === 'color') { // Teacher/Room Color
            this.setState({
                searchData: {
                    ...this.state.searchData,
                    color: value.value
                }
            }, this.getClassSchedule)
        } else { // Search Criteria
            this.setState({
                searchData: {
                    ...this.state.searchData,
                    [value.id]: value.value.join()
                }
            }, this.getClassSchedule)
        }
    }

    eventStyleGetter(event) {
        let bgColor = event.color ? '#' + event.color : '#ffffff'
        let border = this.state.searchData.view === 'day' ? '1px solid grey' : '0px'
        let style = {
            backgroundColor: bgColor,
            borderRadius: '1px',
            color: 'black',
            border: border,
            display: 'block'
        };
        return {
            style: style
        };
    }

    handleSelectEvent(event) {
        if (event.classId != 0) {
            this.setState({
                classDetail: event,
                showClassDetailModal: true
            })
        }
    }

    handleNavigateChange(date, view, action) {
        this.current = date
        this.setState({
            searchData: {
                ...this.state.searchData,
                day: date.getDate(),
                month: date.getMonth() + 1,
                year: date.getFullYear(),
                view: action === 'DATE' ? 'day' : this.state.searchData.view
            }
        }, this.getClassSchedule)
    }

    handleViewChange(view) {
        this.setState({
            searchData: {
                ...this.state.searchData,
                color: 'teacher',
                view: view
            },
            filterData: {
                ...this.state.filterData,
                showStudent: false
            }
        }, this.getClassSchedule)
    }

    handleEditItemClose = (data) => {
        this.setState({
            classDetail: {},
            showClassDetailModal: false
        }, this.getClassSchedule)
    }

    renderCriteria = () => {
        const colorOptions = [{key: '1', text: 'Teacher', value: 'teacher'}, {
            key: '2',
            text: 'Room',
            value: 'room'
        }]

        return (
            <Form size='tiny'>
                <Form.Group widths='equal'>
                    {this.state.userBranchId == 0 ?
                        <div className='field'>
                            <label>Branch</label>
                            <Dropdown id='branch' placeholder='All' fluid multiple search selection
                                      value={this.state.searchData.branch.toString().split(',').map(Number)}
                                      options={this.state.branchList} onChange={this.handleInputChange}/>
                        </div> : null
                    }
                    <div className='field'>
                        <label>Course</label>
                        <Dropdown id='course' placeholder='All' fluid multiple search selection
                                  options={this.state.courseList} onChange={this.handleInputChange}/>
                    </div>
                    <div className='field'>
                        <label>Teacher</label>
                        <Dropdown id='teacher' placeholder='All' fluid multiple search selection
                                  options={this.state.teacherList} onChange={this.handleInputChange}/>
                    </div>
                    {this.state.searchData.view === 'month' ?
                        <div className='field'>
                            <div style={{textAlign: 'right', marginTop: '20px'}}>
                                <Button size='small' color='green'
                                        onClick={() => this.openConfirmModal('SYNC_CALENDAR')}>
                                    Sync Teacher Calendar
                                </Button>
                            </div>
                        </div>
                        : null
                    }
                    {this.state.searchData.view === 'week' ?
                        <div className='field'>
                            <label>Color</label>
                            <Dropdown id='color' placeholder='Teacher' fluid selection
                                      options={colorOptions} onChange={this.handleInputChange}/>
                        </div>
                        : null
                    }
                    {this.state.searchData.view === 'day' ?
                        <div className='field'>
                            <div style={{textAlign: 'right'}}>
                                <Form.Field>Show Student</Form.Field>
                                <Form.Field>
                                    <Checkbox
                                        toggle
                                        onChange={this.handleInputChange}
                                    />
                                </Form.Field>
                            </div>
                        </div>
                        : null
                    }
                </Form.Group>
            </Form>
        )
    }

    renderCalendar() {
        const EventComponent = (event) => {
            if (this.state.searchData.view === 'day') {
                let students = []
                let studentList = event.event.student ? event.event.student.split(',') : []
                studentList.map((item, index) => {
                    if (item.includes(STUDENT_CLASS_STATUS.ATTENDED))
                        students.push(<span key={index} style={{fontSize: '12px'}}>
                                <Icon color='green' name='user'/>{item.replace(STUDENT_CLASS_STATUS.ATTENDED, '')}
                            <br/></span>)
                    else if (item.includes(STUDENT_CLASS_STATUS.ACTIVE))
                        students.push(<span key={index} style={{fontSize: '12px'}}>
                                <Icon color='blue' name='user'/>{item.replace(STUDENT_CLASS_STATUS.ACTIVE, '')}
                            <br/></span>)
                    else if (item.includes(STUDENT_CLASS_STATUS.CANCEL))
                        students.push(<span key={index} style={{fontSize: '12px'}}>
                                <Icon color='grey' name='user'/>{item.replace(STUDENT_CLASS_STATUS.CANCEL, '')}
                            <br/></span>)
                    else if (item.includes(STUDENT_CLASS_STATUS.CONFIRMED))
                        students.push(<span key={index} style={{fontSize: '12px'}}>
                                <Icon color='orange' name='user'/>{item.replace(STUDENT_CLASS_STATUS.CONFIRMED, '')}
                            <br/></span>)
                })
                let studentCount = (<span style={{fontSize: '12px'}}>
                                <Icon color='blue' name='users'/>{event.event.studentCount} students
                    </span>)
                return (<span>
                    <span style={{fontSize: '13px', fontWeight: 'bold'}}>{event.event.courseShortName}</span><br/>
                    <span style={{fontSize: '12px'}}>R.{event.event.room}</span><br/>
                    <span style={{fontSize: '12px'}}>By {event.event.teacher}</span><br/>
                    {this.state.filterData.showStudent ? students : studentCount}
                </span>)
            } else if (this.state.searchData.view === 'week') {
                return (<span>
                    <span style={{fontSize: '13px', fontWeight: 'bold'}}>{event.event.courseShortName}</span><br/>
                    <span style={{fontSize: '12px'}}>R.{event.event.room}</span><br/>
                </span>)
            } else {
                let hr = event.event.start.getHours();
                let ampm = 'am';
                if (hr > 12) {
                    hr -= 12;
                    ampm = 'pm';
                }
                return (<span>
                    <span style={{fontSize: '11px'}}>{hr + ampm} </span>
                    <span style={{fontSize: '13px'}}>{event.event.courseShortName}</span>
                </span>)
            }
        }

        let formats = {
            timeGutterFormat: 'HH:mm',
            eventTimeRangeFormat: ({start, end}, culture, local) =>
                local.format(start, 'HH:mm', culture) + '-' + local.format(end, 'HH:mm', culture),
        }

        return (
            <div>
                <Calendar
                    popup
                    localizer={momentLocalizer(moment)}
                    defaultDate={this.current}
                    defaultView='month'
                    min={new Date(2000, 0, 0, 9, 0, 0)}
                    max={new Date(2099, 0, 0, 20, 0, 0)}
                    views={['month', 'week', 'day']}
                    events={this.state.classCalendar}
                    onSelectEvent={(event) => this.handleSelectEvent(event)}
                    onNavigate={(date, view, action) => this.handleNavigateChange(date, view, action)}
                    onView={(view) => this.handleViewChange(view)}
                    eventPropGetter={(event) => this.eventStyleGetter(event)}
                    formats={formats}
                    style={{height: '200vh'}}
                    components={{
                        event: EventComponent
                    }}
                />
                <div style={{height: '20px'}}></div>
            </div>
        )
    }

    render() {
        return (
            <div>
                <MainLayout id='react-no-print'>
                    <div style={styles.container}>
                        <Grid centered stackable>
                            <Grid.Row>
                                <Confirm
                                    open={this.state.showConfirmModal}
                                    header='Warning'
                                    content={this.state.messageConfirmModal}
                                    onCancel={this.closeConfirmModal}
                                    onConfirm={this.onConfirmModal}
                                />
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    {this.renderCriteria()}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    {this.renderCalendar()}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Button basic color='orange' size='small' content='Delete sync calendar'
                                        onClick={() => this.openConfirmModal('UNSYNC_CALENDAR')}/>
                            </Grid.Row>
                            <Grid.Row>
                                <ClassDetailModal
                                    open={this.state.showClassDetailModal}
                                    onClose={this.handleEditItemClose}
                                    data={this.state.classDetail}/>
                            </Grid.Row>
                            <Dimmer inverted active={this.state.isLoading}><Loader/></Dimmer>
                        </Grid>
                    </div>
                </MainLayout>
            </div>
        )
    }
}

export default withRouter(withI18next()(ClassSchedule))
