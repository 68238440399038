import React, {Component} from 'react'
import moment from 'moment'
import QrReader from 'react-qr-reader'
import {CGButton, Spinner} from '../../components/common'
import apiClient from "../../apis"
import styles from './styles'
import {formatDate, isUndefined, validateRole} from '../../common/util'
import {ROLE} from '../../common/const'
import {
    Button,
    Checkbox,
    Dimmer,
    Dropdown,
    Flag,
    Form,
    Grid,
    Header,
    Icon,
    Input,
    Message,
    Modal,
    Radio,
    Segment,
    TextArea
} from 'semantic-ui-react'

class StudentForm extends Component {
    constructor(props) {
        super(props)
        this.cameraPhoto = null
        this.videoRef = React.createRef()
        this.state = {
            studentData: {
                title: '',
                firstName: '',
                lastName: '',
                nickName: '',
                gender: 'boy',
                mobileNumber: '',
                address: '',
                remark: '',
                dob: '',
                school: '',
                photo: '',
                emerMobileNumber: '',
                emerContact: '',
                parentName: '',
                parentEmail: '',
                nationality: 'thai',
                acceptTerm: false,
                authorize: false,
                ads: null,
                picsRights: null,
                ...props.studentData
            },
            errorMsg: '',
            error: {},
            isLoading: isUndefined(props.studentDemoId) ? false : true,
            showNationalityPopup: isUndefined(props.studentData) && isUndefined(props.studentDemoId)
        }

        this.maxDob = formatDate(moment().subtract(3, 'year'), 'YYYY-MM-DD')
        this.minDob = formatDate(moment().subtract(35, 'year'), 'YYYY-MM-DD')
    }

    async componentDidMount() {
        if (this.props.studentDemoId) {
            const studentDemo = await apiClient.studentDemo.get(this.props.studentDemoId);
            if (studentDemo.data) {
                this.setState({
                    isLoading: false,
                    studentData: {...this.state.studentData, ...studentDemo.data}
                });
            } else {
                this.setState({isLoading: false});
            }
        }
    }

    handleNumberChange = name => event => {
        if (/^\d*$/.test(event.target.value)) {
            this.handleChange(name)(event)
        }
    }

    handleChange = name => event => {
        this.setState({
            error: {},
            errorMsg: '',
            studentData: {
                ...this.state.studentData,
                [name]: event.target.value
            }
        })
    }

    handleRadioChange = name => (event, {value}) => {
        this.setState({
            error: {},
            errorMsg: '',
            studentData: {
                ...this.state.studentData,
                [name]: value
            }
        })
    }

    handleCheckboxChange = (name) => event => {
        console.log(name)
        const changeValue = !this.state.studentData[name]
        console.log(changeValue)
        this.setState({
            error: {},
            errorMsg: '',
            studentData: {
                ...this.state.studentData,
                [name]: changeValue,
            }
        })
    }

    onChooseNationality = (nationality) => {
        this.setState({
            studentData: {
                ...this.state.studentData,
                nationality
            },
            showNationalityPopup: false
        })
    }

    validateData = async () => {
        if (this.state.studentData.title === null || this.state.studentData.title === '') {
            this.setState({errorMsg: this.getErrorText('title'), error: {title: true}})
            this.title.open()
        } else if (this.state.studentData.firstName === null || this.state.studentData.firstName === '') {
            this.setState({errorMsg: this.getErrorText('firstName'), error: {firstName: true}})
            this.firstName.focus()
        } else if (this.state.studentData.lastName === null || this.state.studentData.lastName === '') {
            this.setState({errorMsg: this.getErrorText('lastName'), error: {lastName: true}})
            this.lastName.focus()
        } else if (this.state.studentData.nickName === null || this.state.studentData.nickName === '') {
            this.setState({errorMsg: this.getErrorText('nickName'), error: {nickName: true}})
            this.nickName.focus()
        } else if (this.state.studentData.mobileNumber.length === 0) {
            this.setState({errorMsg: this.getErrorText('mobileNumber'), error: {mobileNumber: true}})
            this.mobileNumber.focus()
        } else if (this.state.studentData.mobileNumber.length !== 10) {
            this.setState({errorMsg: this.getErrorText('mobileNumberFormat'), error: {mobileNumber: true}})
            this.mobileNumber.focus()
        } else if (this.state.studentData.dob === null || this.state.studentData.dob === '') {
            this.setState({errorMsg: this.getErrorText('dob'), error: {dob: true}})
            this.dob.focus()
        } else if (moment(this.state.studentData.dob).isBefore(this.minDob) || moment(this.state.studentData.dob).isAfter(this.maxDob)) {
            this.setState({errorMsg: this.getErrorText('dobRange'), error: {dob: true}})
            this.dob.focus()
        } else if (this.state.studentData.school === null || this.state.studentData.school === '') {
            this.setState({errorMsg: this.getErrorText('school'), error: {school: true}})
            this.school.focus()
        } else if (this.state.studentData.address === null || this.state.studentData.address === '') {
            this.setState({errorMsg: this.getErrorText('address'), error: {address: true}})
            this.address.focus()
        } else if (this.state.studentData.emerContact === null || this.state.studentData.emerContact === '') {
            this.setState({errorMsg: this.getErrorText('emerContact'), error: {emerContact: true}})
            this.emerContact.focus()
        } else if (this.state.studentData.emerMobileNumber === null || this.state.studentData.emerMobileNumber === '') {
            this.setState({errorMsg: this.getErrorText('emerMobileNumber'), error: {emerMobileNumber: true}})
            this.emerMobileNumber.focus()
        } else if (this.state.studentData.emerMobileNumber.length !== 10) {
            this.setState({errorMsg: this.getErrorText('emerMobileNumberFormat'), error: {emerMobileNumber: true}})
            this.emerMobileNumber.focus()
        } else if (this.state.studentData.parentName === null || this.state.studentData.parentName === '') {
            this.setState({errorMsg: this.getErrorText('parentName'), error: {parentName: true}})
            this.parentName.focus()
        } else if (this.state.studentData.parentEmail === null || this.state.studentData.parentEmail === '') {
            this.setState({errorMsg: this.getErrorText('parentEmail'), error: {parentEmail: true}})
            this.parentEmail.focus()
        } else if (!this.state.studentData.authorize && !this.props.editProfileFlag) {
            this.setState({errorMsg: this.getErrorText('authorize'), error: {authorize: true}})
        } else if (!this.state.studentData.acceptTerm && !this.props.editProfileFlag) {
            this.setState({errorMsg: this.getErrorText('acceptTerm'), error: {acceptTerm: true}})
        } else if (this.state.studentData.ads === null) {
            this.setState({errorMsg: this.getErrorText('ads'), error: {ads: true}})
        } else if (this.state.studentData.picsRights === null) {
            this.setState({errorMsg: this.getErrorText('picsRights'), error: {picsRights: true}})
        } else {
            this.doSave()
        }
    }

    doSave = async () => {
        const saveFn = this.props.editProfileFlag ? this.props.updateStudentProfile : this.props.addStudent
        this.setState({errorMsg: '', error: {}})
        await this.props.setStudentData(this.state.studentData)
        const result = await saveFn(this.state.studentData)
        if (result) {
            this.setState({ 
                isSaved: result.status === 'success', 
                errorMsg: result.message || '', 
                studentData: {
                    ...this.state.studentData,
                    studentId: result.studentId,
                    studentUserId: result.userId
                } 
            })
        }
    }

    showError = () => {
        const errMsg = this.props.errorMsg || this.state.errorMsg
        if (errMsg !== '') {
            return <Segment color='red'>{errMsg}</Segment>
        }
    }

    getFormText(name) {
        return FORM_TEXT[name][this.state.studentData.nationality]
    }

    getErrorText(name) {
        return ERROR_TEXT[name][this.state.studentData.nationality]
    }

    renderFillInForm = () => {
        const { editable } = this.props
        if (this.state.showQrReader) {
            return (
                <Grid>
                    <Grid.Column width={16} textAlign='center'>
                        <Message icon style={styles.successContainer}>
                            <Message.Content>
                                <div>
                                    {this.state.showQrReader &&
                                    <div style={styles.qrContainer}>
                                        <QrReader
                                            delay={300}
                                            onError={(err) => {
                                                this.setState({showQrReader: false})
                                            }}
                                            onScan={this.onScanQrSuccess}
                                            style={{width: '100%'}}
                                            facingMode='user'
                                        />
                                        {this.state.updatingQrCode && <Spinner/>}
                                    </div>
                                    }
                                    <CGButton
                                        style={styles.successButton}
                                        fullWidth
                                        variant='contained'
                                        onClick={() => this.setState({
                                            showQrReader: !this.state.showQrReader,
                                            updatingQrCode: false,
                                            qrMessage: undefined
                                        })}
                                    >
                                        {this.state.showQrReader ? 'Close QR Scanner' : 'Scan QR Code'}
                                    </CGButton>
                                </div>
                            </Message.Content>
                        </Message>
                    </Grid.Column>
                </Grid>
            )
        } else {
            return (
                <Grid stackable columns={2}>
                    <Grid.Column verticalAlign='middle'>
                        <Header style={styles.primaryColor} size='medium'>{this.getFormText('studentInformation')}</Header>
                    </Grid.Column>
                    {this.props.isEdit && <Grid.Column verticalAlign='middle'>
                        <Grid textAlign='right' verticalAlign='middle'>
                            <Grid.Column textAlign='right' verticalAlign='middle'>
                                <span style={styles.nationalityItem}>Nationality</span>
                                <Radio
                                    style={styles.nationalityItem}
                                    label='Thai'
                                    name='nationality'
                                    value='thai'
                                    checked={this.state.studentData.nationality === 'thai'}
                                    onChange={this.handleRadioChange('nationality')}
                                    disabled
                                />
                                <Radio
                                    label='Foreigner'
                                    name='nationality'
                                    value='others'
                                    checked={this.state.studentData.nationality === 'others'}
                                    onChange={this.handleRadioChange('nationality')}
                                    disabled
                                />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>}
                    <Grid.Column textAlign='right'>
                        <Header size='tiny'>{this.getFormText('instruction')}</Header>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid>
                            <Grid.Column width={6}>
                                <Dropdown
                                    placeholder={this.getFormText('title')}
                                    onChange={this.handleRadioChange('title')}
                                    value={this.state.studentData.title}
                                    options= { this.getFormText('titleOptions').map((item)=>({ key:item, text:item, value:item})) }
                                    selection
                                    fluid
                                    error={this.state.error.title}
                                    ref={field => this.title = field}
                                    disabled={!editable}
                                />
                            </Grid.Column>
                            <Grid.Column width={10}>
                                <Input
                                    placeholder={this.getFormText('firstName')}
                                    value={this.state.studentData.firstName}
                                    onChange={this.handleChange('firstName')}
                                    ref={field => this.firstName = field}
                                    fluid
                                    error={this.state.error.firstName}
                                    disabled={!editable}
                                />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Input
                            placeholder={this.getFormText('lastName')}
                            value={this.state.studentData.lastName}
                            onChange={this.handleChange('lastName')}
                            ref={field => this.lastName = field}
                            fluid
                            error={this.state.error.lastName}
                            disabled={!editable}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Input
                            placeholder={this.getFormText('nickName')}
                            value={this.state.studentData.nickName}
                            onChange={this.handleChange('nickName')}
                            ref={field => this.nickName = field}
                            fluid
                            error={this.state.error.nickName}
                            disabled={!editable}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Input
                            placeholder={this.getFormText('mobileNumber')}
                            value={this.state.studentData.mobileNumber}
                            onChange={this.handleNumberChange('mobileNumber')}
                            ref={field => this.mobileNumber = field}
                            fluid
                            error={this.state.error.mobileNumber}
                            maxLength={10}
                            disabled={!editable}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Input
                            iconPosition='left'
                            type='date'
                            value={formatDate(this.state.studentData.dob, 'YYYY-MM-DD')}
                            onChange={this.handleChange('dob')}
                            ref={field => this.dob = field}
                            fluid
                            size='small'
                            error={this.state.error.dob}
                            max={this.maxDob}
                            min={this.minDob}
                            disabled={!editable}
                        >
                            <Icon name='birthday cake'/>
                            <input/>
                        </Input>
                    </Grid.Column>
                    <Grid.Column>
                        <Input
                            placeholder={this.getFormText('school')}
                            value={this.state.studentData.school}
                            onChange={this.handleChange('school')}
                            ref={field => this.school = field}
                            fluid
                            error={this.state.error.school}
                            disabled={!editable}
                        />
                    </Grid.Column>
                    <Grid.Column width='16'>
                        <Form>
                            <TextArea
                                placeholder={this.getFormText('address')}
                                value={this.state.studentData.address}
                                onChange={this.handleChange('address')}
                                ref={field => this.address = field}
                                error={this.state.error.address}
                                disabled={!editable}
                            />
                        </Form>
                    </Grid.Column>
                    {!this.props.editProfileFlag || validateRole([ROLE.ADMIN]) ?
                        <Grid.Column width='16'>
                            <Form>
                                <TextArea
                                    placeholder={this.getFormText('remark')}
                                    value={this.state.studentData.remark}
                                    onChange={this.handleChange('remark')}
                                    ref={field => this.remark = field}
                                    error={this.state.error.remark}
                                    disabled={!editable}
                                />
                            </Form>
                        </Grid.Column> : null
                    }
                    <Grid.Column width='16'>
                        <Header style={styles.primaryColor} size='medium'>{this.getFormText('emerHeader')}</Header>
                    </Grid.Column>
                    <Grid.Column>
                        <Input
                            placeholder={this.getFormText('emerContact')}
                            value={this.state.studentData.emerContact}
                            onChange={this.handleChange('emerContact')}
                            ref={field => this.emerContact = field}
                            fluid
                            error={this.state.error.emerContact}
                            disabled={!editable}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Input
                            placeholder={this.getFormText('emerMobileNumber')}
                            value={this.state.studentData.emerMobileNumber}
                            onChange={this.handleNumberChange('emerMobileNumber')}
                            ref={field => this.emerMobileNumber = field}
                            fluid
                            error={this.state.error.emerMobileNumber}
                            maxLength={10}
                            disabled={!editable}
                        />
                    </Grid.Column>
                    <Grid.Column width='16'>
                        <Header style={styles.primaryColor} size='medium'>{this.getFormText('parentInfoHeader')}</Header>
                    </Grid.Column>
                    <Grid.Column>
                        <Input
                            placeholder={this.getFormText('parentName')}
                            value={this.state.studentData.parentName}
                            onChange={this.handleChange('parentName')}
                            ref={field => this.parentName = field}
                            fluid
                            error={this.state.error.parentName}
                            disabled={!editable}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Input
                            placeholder={this.getFormText('parentEmail')}
                            value={this.state.studentData.parentEmail}
                            onChange={this.handleChange('parentEmail')}
                            ref={field => this.parentEmail = field}
                            fluid
                            error={this.state.error.parentEmail}
                            disabled={!editable}
                        />
                    </Grid.Column>
                    <Grid.Column width='16'>
                        <Checkbox
                            checked={this.state.studentData.authorize || (!!this.props.editProfileFlag)}
                            onChange={this.handleCheckboxChange('authorize')}
                            label={this.getFormText('authorize')}
                            disabled={this.props.editProfileFlag || !editable}
                        />
                    </Grid.Column>
                    <Grid.Column width='16'>
                        <Checkbox
                            checked={this.state.studentData.acceptTerm || (!!this.props.editProfileFlag)}
                            onChange={this.handleCheckboxChange('acceptTerm')}
                            label={this.getFormText('acceptTerm')}
                            disabled={this.props.editProfileFlag || !editable}
                        />
                    </Grid.Column>
                    <Grid.Column width='16'>
                        <p>{this.getFormText('allowAds')}</p>
                        <Radio
                            style={{marginRight: '12px'}}
                            label={this.getFormText('accept')}
                            checked={this.state.studentData.ads === true || this.state.studentData.ads === 1}
                            name="allowAdvertise"
                            value={true}
                            onChange={this.handleRadioChange('ads')}
                            disabled={!editable}
                        />
                        <Radio
                            label={this.getFormText('reject')}
                            checked={this.state.studentData.ads === false || this.state.studentData.ads === 0}
                            name="allowAdvertise"
                            value={false}
                            onChange={this.handleRadioChange('ads')}
                            disabled={!editable}
                        />
                    </Grid.Column>
                    <Grid.Column width='16'>
                        <p>{this.getFormText('picsRights')}</p>
                        <Radio
                            style={{marginRight: '12px'}}
                            label={this.getFormText('accept')}
                            checked={this.state.studentData.picsRights === true || this.state.studentData.picsRights === 1}
                            name="acceptPicsRights"
                            value={true}
                            onChange={this.handleRadioChange('picsRights')}
                            disabled={!editable}
                        />
                        <Radio
                            label={this.getFormText('reject')}
                            checked={this.state.studentData.picsRights === false || this.state.studentData.picsRights === 0}
                            name="acceptPicsRights"
                            value={false}
                            onChange={this.handleRadioChange('picsRights')}
                            disabled={!editable}
                        />
                        <p style={{marginTop: '24px'}}>{this.getFormText('picsRightsNote')}</p>
                    </Grid.Column>
                    <Grid.Column width='16'>
                        <p>ท่านมีสิทธิเลือกที่จะยินยอม หรือไม่ยินยอมโดยไม่มีผลต่อการสมัครเรียนในครั้งนี้ทั้งสิ้น และหากท่านไม่ประสงค์ให้ประมวลผลข้อมูลส่วนบุคคลตามวัตถุประสงค์ข้างต้น หรือไม่ต้องการรับการสื่อสารใด ๆ ท่านสามารถขอบอกเลิกการรับข่าวสาร (Unsubscribe) จากข้อความการตลาด หรืออาจจะเพิกถอนความยินยอมเมื่อใดก็ได้ โดยไม่เสียค่าใช้จ่าย ผ่านการตั้งค่าที่ระบบโดยเลือก การจัดการข้อมูลส่วนบุคคล และถอนความยินยอม  หรือ  ผ่านการ<a rel="noopener noreferrer" target="_blank" href="https://codegeniusacademy.com/privacy-notice/">กรอกแบบฟอร์มคำขอถอนความยินยอม</a> ในเว็บไซต์ของเรา</p>
                        <p>คำชี้แจงสิทธิ์ส่วนบุคคล  เรามีความมุ่งมั่นในการรักษาความปลอดภัยของข้อมูลส่วนบุคคลที่เราได้รับ โดยเราจะเก็บข้อมูลของท่านและบุตรของท่านไว้ตราบเท่าที่ท่านยังคงเป็นสมาชิก และจะลบข้อมูลของท่านหลังจากพ้นการเป็นสมาชิกแล้ว 2 ปี นับถัดจากปีที่สิ้นสุดสถานะการเป็นสมาชิก หรือในกรณีที่เราได้ขอความยินยอมจากท่านเราจะหยุดใช้ข้อมูลเมื่อท่านแจ้งขอยกเลิกความยินยอม ทั้งนี้ ท่านสามารถศึกษามาตรการการรักษาความปลอดภัย และรายละเอียดเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลของเราได้ที่ <a rel="noopener noreferrer" target="_blank" href="https://codegeniusacademy.com/privacy-notice/">ประกาศความเป็นส่วนตัว (Privacy Notice) สำหรับลูกค้า</a></p>
                        <p>หากท่านเชื่อว่า เราไม่ได้ปฏิบัติตามคำชี้แจงนี้ กรุณาติดต่อกลับมาที่เรา โดยส่งอีเมลมาที่ <b>hello@codegeniusacademy.com</b> หรือส่งจดหมายมายัง สถาบัน code genius บริษัท พรีเมียร์ เอ็ดดูเคชั่น จำกัด 991 อาคารศูนย์การค้าสยามพารากอน ชั้น 4 ห้องเลขที่ 453 ถนนพระราม 1 แขวงปทุมวัน เขตปทุมวัน กรุงเทพมหานคร 10330 และหากท่านมีข้อสงสัย สามารถสอบถามรายละเอียดหรือร้องเรียนได้ที่ DPO@learn.co.th</p>
                    </Grid.Column>
                    <Grid.Column width={16} textAlign='center'>
                        {this.showError()}
                        {!this.props.readonly && validateRole([ROLE.ADMIN, ROLE.ADMIN_BRANCH]) && this.props.editProfileFlag &&
                        <CGButton variant='contained' style={{marginRight: '5px'}}
                                  onClick={() => this.updateQrCode()}>Update QR Code</CGButton>}
                        {!this.props.readonly && <CGButton disabled={!editable} variant='contained' onClick={() => this.validateData()}>
                            {this.getFormText(this.props.editProfileFlag ? 'update' : 'register')}</CGButton>}
                        {this.state.qrMessage &&
                        <Segment color={this.state.qrSuccess ? 'green' : 'red'}>{this.state.qrMessage}</Segment>}
                    </Grid.Column>
                </Grid>
            )
        }
    }

    updateQrCode = async () => {
        const userId = await apiClient.user.getStudentUserId(this.state.studentData.studentId);
        await this.setState({
            isSaved: false,
            studentData: {
                ...this.state.studentData,
                studentUserId: userId
            },
            showQrReader: true,
            updatingQrCode: false
        })
    }

    renderNationalityPopup() {
        return (
            <Modal
                open={this.state.showNationalityPopup}
                dimmer={true}
                closeOnDimmerClick={false}
                closeOnEscape={false}
                size='tiny'
            >
                <Modal.Header align="center">Choose your nationality</Modal.Header>
                <Modal.Content align="center">
                    <Button size='huge' basic style={styles.nationalityButton}
                            onClick={() => this.onChooseNationality('thai')}>
                        <Flag name='th'/>
                        Thai
                    </Button>
                    <Button size='huge' basic style={styles.nationalityButton}
                            onClick={() => this.onChooseNationality('others')}>
                        <Icon name='world' color='blue'/>
                        Non-Thai
                    </Button>
                </Modal.Content>
            </Modal>
        )
    }

    onScanQrSuccess = async (data) => {
        if (data) {
            this.setState({showQrReader: false, updatingQrCode: true})
            const result = await apiClient.user.updateUserUuid(this.state.studentData.studentUserId, data);
            this.setState({updatingQrCode: false, qrCode: data, qrSuccess: result.success, qrMessage: result.message})
            setTimeout(() => {
                this.setState({qrMessage: undefined})
            },2000)
        }
    }

    renderQrScanner = () => {
        if (!validateRole([ROLE.ADMIN, ROLE.ADMIN_BRANCH, ROLE.CUSTOMER])) return
        return (
            <div>
                {this.state.showQrReader &&
                <div style={styles.qrContainer}>
                    <QrReader
                        delay={300}
                        onError={(err) => {
                            console.error(err)
                            this.setState({showQrReader: false})
                        }}
                        onScan={this.onScanQrSuccess}
                        style={{width: '100%'}}
                        facingMode='user'
                    />
                    {this.state.updatingQrCode && <Spinner/>}
                </div>
                }
                <CGButton
                    style={styles.successButton}
                    fullWidth
                    variant='contained'
                    onClick={() => this.setState({
                        showQrReader: !this.state.showQrReader,
                        updatingQrCode: false,
                        qrMessage: undefined
                    })}
                >
                    {this.state.showQrReader ? 'Close QR Scanner' : 'Scan QR Code'}
                </CGButton>
                <p>{!this.state.updatingQrCode && this.state.qrCode && this.state.qrMessage}</p>
            </div>
        )
    }

    renderCompletedCard = () => {
        const {editProfileFlag} = this.props
        let successHeader
        let successContent
        let redirectPath
        if (editProfileFlag) {
            redirectPath = '/editprofile'
            successContent = this.getFormText('updateSuccessContent')
        } else {
            redirectPath = '/addstudent'
            successHeader = `${this.getFormText('addSuccessHeader')} [${this.state.studentData.studentUserId}] ${this.state.studentData.firstName} ${this.state.studentData.lastName} (${this.state.studentData.nickName})`
            successContent = this.getFormText('addSuccessContent')
        }
        return (
            <Message icon style={styles.successContainer}>
                <Icon name='check circle outline' color='green'/>
                <Message.Content>
                    <Message.Header>{successHeader}</Message.Header>
                    {successContent}
                    {!this.state.showQrReader && <CGButton style={styles.successButton} fullWidth variant='contained'
                                                           onClick={() => window.location.href = redirectPath}>OK</CGButton>}
                    {this.renderQrScanner()}
                </Message.Content>
            </Message>
        )
    }

    render() {
        return (
            <Segment basic style={styles.container}>
                <Dimmer inverted active={this.state.isLoading}>
                    <Spinner/>
                </Dimmer>
                {this.state.isSaved ? this.renderCompletedCard() : this.renderFillInForm()}
                {this.renderNationalityPopup()}
            </Segment>
        )
    }
}

export default StudentForm

const FORM_TEXT = {
    studentInformation: {
        thai: 'ข้อมูลนักเรียน',
        others: 'Student Information'
    },
    title: {
        thai: 'คำนำหน้า',
        others: 'Title'
    },
    firstName: {
        thai: 'ชื่อ',
        others: 'First name'
    },
    lastName: {
        thai: 'นามสกุล',
        others: 'Last name'
    },
    nickName: {
        thai: 'ชื่อเล่น',
        others: 'Nick name'
    },
    mobileNumber: {
        thai: 'เบอร์โทรศัพท์มือถือ',
        others: 'Mobile number'
    },
    // gender: {
    //     thai: 'เพศ',
    //     others: 'Gender'
    // },
    // male: {
    //     thai: 'ด.ช.',
    //     others: 'Mr.'
    // },
    // female: {
    //     thai: 'ด.ญ.',
    //     others: 'Miss'
    // },
    address: {
        thai: 'ที่อยู่',
        others: 'Address'
    },
    remark: {
        thai: 'หมายเหตุ',
        others: 'Remark'
    },
    school: {
        thai: 'โรงเรียน',
        others: 'School'
    },
    emerHeader: {
        thai: 'ข้อมูลผู้ติดต่อฉุกเฉิน',
        others: 'Emergency contact'
    },
    emerContact: {
        thai: 'ชื่อ-นามสกุล',
        others: 'Contact name'
    },
    emerMobileNumber: {
        thai: 'เบอร์โทรศัพท์มือถือ',
        others: 'Mobile number'
    },
    instruction: {
        thai: 'กรุณากรอกข้อมูลทั้งหมดเป็นภาษาไทย',
        others: 'Please fill data in English only.'
    },
    allowAdvertise: {
        thai: `อนุญาตให้ใช้รูปถ่ายนักเรียนประกอบการทำสื่อประชาสัมพันธ์`,
        others: `Allowing to use student's pictures in marketing media.`
    },
    register: {
        thai: 'ลงทะเบียน',
        others: 'Register'
    },
    update: {
        thai: 'บันทึก',
        others: 'Save'
    },
    addSuccessHeader: {
        thai: 'Hi,',
        others: 'Hi,'
    },
    addSuccessContent: {
        thai: 'Welcome to Code Genius.',
        others: 'Welcome to Code Genius.'
    },
    updateSuccessContent: {
        thai: 'Update student data completed.',
        others: 'Update student data completed.'
    },
    titleOptions:{
        thai: ['ด.ช.', 'ด.ญ.', 'นาย', 'นาง', 'น.ส.'],
        others: ['Master', 'Mr.', 'Miss', 'Mrs.']
    },
    parentInfoHeader: {
        thai: 'ข้อมูลผู้ปกครอง',
        others: 'Parent Information'
    },
    parentName: {
        thai: 'ชื่อ-นามสกุล',
        others: 'Parent name'
    },
    parentEmail: {
        thai: 'อีเมล',
        others: 'Email'
    },
    acceptTerm: {
        thai: <label>ยอมรับ <a target="_blank" href=" https://codegeniusacademy.com/term-and-conditions/">ข้อตกลงและเงื่อนไขในการใช้บริการ</a></label>,
        others: <label>ยอมรับ <a target="_blank" href=" https://codegeniusacademy.com/term-and-conditions/">ข้อตกลงและเงื่อนไขในการใช้บริการ</a></label>,
    },
    authorize: {
        thai: 'ผู้ปกครองรับรองว่าผู้ปกครองเป็นผู้มีอำนาจโดยในการสมัครเรียนและได้เป็นผู้สมัครเรียนให้แก่บุตรของท่านด้วยตนเอง',
        others: 'ผู้ปกครองรับรองว่าผู้ปกครองเป็นผู้มีอำนาจโดยในการสมัครเรียนและได้เป็นผู้สมัครเรียนให้แก่บุตรของท่านด้วยตนเอง'
    },
    allowAds: {
        thai: 'เพื่อให้ท่านและบุตรของท่านไม่พลาดข้อมูลข่าวสาร และโอกาสรับข้อเสนอที่สำคัญ ท่านยินยอมรับข้อมูลส่วนลด โปรโมชันคอร์สเรียน ข่าวสาร กิจกรรม จาก Code Genius ผ่านช่องทางที่ท่านให้ไว้ หรือไม่',
        others: 'เพื่อให้ท่านและบุตรของท่านไม่พลาดข้อมูลข่าวสาร และโอกาสรับข้อเสนอที่สำคัญ ท่านยินยอมรับข้อมูลส่วนลด โปรโมชันคอร์สเรียน ข่าวสาร กิจกรรม จาก Code Genius ผ่านช่องทางที่ท่านให้ไว้ หรือไม่'
    },
    picsRights: {
        thai: 'เพื่อให้ท่านและบุคคลทั่วไปที่เยี่ยมชมเว็บไซต์ของเราได้รับชมกิจกรรมที่เราจัดขึ้นในระหว่างจัดการเรียนการสอน  ท่านยินยอมให้ถ่ายภาพนิ่ง หรือภาพเคลื่อนไหวเฉพาะบุคคล ของผู้เยาว์ และนำไปใช้เปิดเผยบนเว็บไซต์ในเชิงธุรกิจตามความเหมาะสมและตามวัตถุประสงค์ของบริษัท  การโฆษณา การประชาสัมพันธ์ การส่งเสริมการขาย และเผยแพร่ผ่านช่องทางต่างๆ เช่น เว็บไซต์ เฟสบุค อินสตราแกรม ไลน์ และช่องทางออนไลน์อื่นๆ หรือไม่',
        others: 'เพื่อให้ท่านและบุคคลทั่วไปที่เยี่ยมชมเว็บไซต์ของเราได้รับชมกิจกรรมที่เราจัดขึ้นในระหว่างจัดการเรียนการสอน  ท่านยินยอมให้ถ่ายภาพนิ่ง หรือภาพเคลื่อนไหวเฉพาะบุคคล ของผู้เยาว์ และนำไปใช้เปิดเผยบนเว็บไซต์ในเชิงธุรกิจตามความเหมาะสมและตามวัตถุประสงค์ของบริษัท  การโฆษณา การประชาสัมพันธ์ การส่งเสริมการขาย และเผยแพร่ผ่านช่องทางต่างๆ เช่น เว็บไซต์ เฟสบุค อินสตราแกรม ไลน์ และช่องทางออนไลน์อื่นๆ หรือไม่',
    },
    picsRightsNote: {
        thai: 'หมายเหตุ: ในกรณีที่ท่านเลือกไม่ยินยอมเราจะถ่ายเพียงภาพรวมบรรยากาศการจัดกิจกรรมที่เราจัดขึ้นในระหว่างจัดการเรียนการสอน โดยไม่ดำเนินการถ่ายภาพในลักษณะเฉพาะเจาะจงกับบุตรของท่าน ทั้งนี้หากไม่ประสงค์ถูกถ่ายภาพสามารถแจ้งขอรับสัญลักษณ์ห้ามถ่ายภาพ หรือแจ้งเจ้าหน้าที่ได้ก่อนเข้าห้องเรียน',
        others: 'หมายเหตุ: ในกรณีที่ท่านเลือกไม่ยินยอมเราจะถ่ายเพียงภาพรวมบรรยากาศการจัดกิจกรรมที่เราจัดขึ้นในระหว่างจัดการเรียนการสอน โดยไม่ดำเนินการถ่ายภาพในลักษณะเฉพาะเจาะจงกับบุตรของท่าน ทั้งนี้หากไม่ประสงค์ถูกถ่ายภาพสามารถแจ้งขอรับสัญลักษณ์ห้ามถ่ายภาพ หรือแจ้งเจ้าหน้าที่ได้ก่อนเข้าห้องเรียน',
    },
    accept: {
        thai: 'ยินยอม',
        others: 'ยินยอม'
    },
    reject: {
        thai: 'ไม่ยินยอม',
        others: 'ไม่ยินยอม'
    }
}

const ERROR_TEXT = {
    title: {
        thai: 'กรุณาระบุคำนำหน้าชื่อ',
        others: 'Title is required.'
    },
    firstName: {
        thai: 'กรุณากรอกชื่อ',
        others: 'First name is required.'
    },
    lastName: {
        thai: 'กรุณากรอกนามสกุล',
        others: 'Last name is required.'
    },
    nickName: {
        thai: 'กรุณากรอกชื่อเล่น',
        others: 'Nick name is required.'
    },
    mobileNumber: {
        thai: 'กรุณากรอกเบอร์โทรศัพท์มือถือ',
        others: 'Mobile number is required.'
    },
    mobileNumberFormat: {
        thai: 'กรุณากรอกเบอร์โทรศัพท์มือถือให้ถูกต้อง',
        others: 'Invalid mobile number.'
    },
    // gender: {
    //     thai: 'กรุณาระบุเพศ',
    //     others: 'Gender is required.'
    // },
    dob: {
        thai: 'กรุณาระบุวันเกิด',
        others: 'Date of birth is required.'
    },
    dobRange: {
        thai: 'กรุณากรอกวันเกิดให้ถูกต้อง',
        others: 'Invalid date of birth.'
    },
    address: {
        thai: 'กรุณากรอกที่อยู่',
        others: 'Address is required.'
    },
    school: {
        thai: 'กรุณากรอกโรงเรียน',
        others: 'School is required.'
    },
    emerContact: {
        thai: 'กรุณากรอกชื่อ-นามสกุล ผู้ติดต่อกรณีฉุกเฉิน',
        others: 'Emergency contact name is required.'
    },
    emerMobileNumber: {
        thai: 'กรุณากรอกเบอร์โทรศัพท์มือถือ ผู้ติดต่อกรณีฉุกเฉิน',
        others: 'Emergency mobile number is required.'
    },
    emerMobileNumberFormat: {
        thai: 'กรุณากรอกเบอร์โทรศัพท์มือถือ ผู้ติดต่อกรณีฉุกเฉิน ให้ถูกต้อง',
        others: 'Invalid emergency mobile number.'
    },
    parentName: {
        thai: 'กรุณากรอกชื่อ-นามสกุล ผู้ปกครอง',
        others: 'Parent name is required.'
    },
    parentEmail: {
        thai: 'กรุณากรอกอีเมล ผู้ปกครอง',
        others: 'Parent email is required.'
    },
    authorize: {
        thai: 'กรุณารับรองว่าผู้ปกครองเป็นผู้มีอำนาจในการสมัครเรียน',
        others: 'กรุณารับรองว่าผู้ปกครองเป็นผู้มีอำนาจในการสมัครเรียน'
    },
    acceptTerm: {
        thai: 'กรุณายอมรับข้อตกลงและเงื่อนไขในการใช้บริการ',
        others: 'กรุณายอมรับข้อตกลงและเงื่อนไขในการใช้บริการ'
    },
    ads: {
        thai: 'กรุณาตอบคำถามเกี่ยวกับการโฆษณา',
        others: 'กรุณาตอบคำถามเกี่ยวกับการโฆษณา'
    },
    picsRights: {
        thai: 'กรุณาตอบคำถามเกี่ยวกับการนำภาพไปใช้',
        others: 'กรุณาตอบคำถามเกี่ยวกับการนำภาพไปใช้'
    }
}
