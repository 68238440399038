import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { withTranslation } from 'react-i18next'
import { Grid, Image } from 'semantic-ui-react'

import { CGButton } from '../../components/common'

import { styles } from './styles'

class AdvancedPhotoGrid extends Component {
  renderPreviewButton(t) {
    return (
      <Grid.Row style={{ padding: 0 }}>
        <Grid.Column width={8} floated="right" style={{ textAlign: 'right' }}>
          <CGButton onClick={() => this.props.history.push('/cggallery')}>
            {t('button.seemore')}
          </CGButton>
        </Grid.Column>
      </Grid.Row>
    )
  }
  render() {
    const { t, tileData, previewFlag, height } = this.props
    return (
      <Grid style={{ margin: 0 }}>
        <Grid.Row>
          {tileData.map(tile => {
            return (
              <Grid.Column
                key={tile.img}
                mobile={16}
                tablet={tile.cols * 4}
                largeScreen={tile.cols * 4}
                style={{
                  ...styles.column,
                  height: height ? height : '350px'
                }}
              >
                <Image src={tile.img} style={styles.image} />
              </Grid.Column>
            )
          })}
        </Grid.Row>
        {previewFlag ? this.renderPreviewButton(t) : ''}
      </Grid>
    )
  }
}

export default withRouter(withTranslation('home')(AdvancedPhotoGrid))
