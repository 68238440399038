export default theme => ({
    container: {
        fontFamily : "\"Varela Round\", \"Pridi\", \"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        fontSize: '1.2em',
        color: 'white'
    },
    qrContainer: {
        width: '100%',
        display: 'flex',
        maxWidth: '300px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
    },
    qrScanner: {
        width: '100%'
    },
    qrHeader: {
        padding: '10px 0',
        fontSize: '1.2em'
    },
    closeIcon: {
        position: 'absolute',
        top: '3em',
        right: '0.2em',
        minHeight: '0',
        height: '2em',
        width: '2em',
        zIndex: 9999
    },
    rightContainer: {
        maxWidth: '600px',       
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px'
    },
    leftContainer: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
        maxWidth: '400px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        height: '80%'
    },
    robotImage: {
        maxWidth: '300px',
        maxHeight: '300px',
        width: '40vw',
        height: '40vw',
        zIndex: '99'
    },
    topImage: {
        position: 'absolute',
        width: '100%',
        left: '0',
        top: '0'
    },
    logoImage: {
        position: 'absolute',
        width: '200px',
        right: '20px',
        top: '20px'
    },
    bottomImage: {
        position: 'absolute',
        width: '100%',
        left: '0',
        bottom: '0'
    },
    resultText: {
        fontSize: '1.8em',
        fontWeight: '600',
        lineHeight: '2em',
        textAlign: 'center'
    },
    hilightText: {
        fontSize: '1.2em',
        fontWeight: '900',
        color: theme.palette.secondary.main
    }, 
    noLineHeight: {
        lineHeight: '1.5em'
    },
    grouping: {
        margin: '1.2em 0'
    },
    classTable: {
        marginTop: '10px',
        fontSize: '0.5em',
        fontWeight: '300',
        border: '1px solid',
        borderSpacing: '0',
        width: '100%',
        borderRadius: '15px',
        overflow: 'hidden'
    },
    classTableHeader: {
        fontWeight: '600',
        backgroundColor: 'rgba(255, 255, 255, 0.3)'
    },
    classTableCell: {
        border: '1px solid',
        borderColor: 'rgba(255, 255, 255, 0.4)',
        padding: '10px',
        lineHeight: '2em'
    },
    okButton: {
        marginTop: '20px'
    },
    noteMessage: {
        fontSize: '0.65em',
        lineHeight: '0em',
        color: 'red'
    }
})