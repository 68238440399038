import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Grid } from '@material-ui/core'
import { getCurrentUser, isReadonly, isUndefined, formatDate } from '../../common/util'
import MainLayout from '../../layouts/MainLayout'
import { withI18next } from '../../lib/withI18next'
import { Spinner } from '../../components/common'
import StudentForm from '../../components/StudentForm'
import apiClient from '../../apis'
import ProfileBar from '../../components/ProfileBar'
import styles from './styles'
import { withRouter } from 'react-router'

class editprofile extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            studentId: null,
            studentData: null,
            showCompletedCard: false,
            errorMsg: '',
            dataUri: ''
        }

    }


    async getStudentProfile(studentId) {
        let studentProfile = await apiClient.student.getStudentProfile(studentId)
        studentProfile.dob = formatDate(studentProfile.dob, 'YYYY-MM-DD')

        const photoUrl = await apiClient.student.getStudentPhoto(studentId)
        this.setDataUri(photoUrl)

        this.setState({
            isLoading: false,
            studentId: studentId,
            studentData: studentProfile
        })
    }

    componentDidMount() {
        const userInfo = getCurrentUser()

        if (isUndefined(userInfo)) {
            this.props.history.push('/home')
        }

        if (isUndefined(userInfo.selectedStudent)) {
            this.setState({ isLoading: false })
        } else {
            this.getStudentProfile(userInfo.selectedStudent.studentId)
        }
    }

    setDataUri = (dataUri) => { // Set PhotoUri to state
        this.setState({ dataUri: dataUri })
    }

    setStudentData = (studentData) => {
        this.setState({ studentData: studentData })
    }

    clearSaveErrorMsg = () => {
        this.setState({ errorMsg: '' })
    }

    updateStudentProfile = async () => {
        const { studentData, dataUri, studentId } = this.state
        this.setState({
            isLoading: true
        })
        const result = await apiClient.student.updateStudentProfile({ ...studentData, photo: dataUri, studentId: studentId })

        if (result.success) {
            this.setState({
                showCompletedCard: true,
                //studentData: null,
                errorMsg: '',
                isLoading: false
            })
        } else {
            this.setState({
                errorMsg: result.message,
                isLoading: false
            })
        }

        return result
    }

    render() {
        const { classes } = this.props
        const { isLoading, studentData, studentId, showCompletedCard, errorMsg, dataUri } = this.state

        if (isLoading) {
            return (
                <MainLayout>
                    {/* <ProfileBar > */}
                        <Grid item xs={12}>
                            <Spinner />
                        </Grid>
                    {/* </ProfileBar> */}
                </MainLayout >
            )
        }

        if (isUndefined(studentId)) {
            return (
                <MainLayout>
                    <ProfileBar >
                        <Grid item xs={12}>
                            <Typography align='center' color='error'>Please contact admin for update account with your student.</Typography>
                        </Grid>
                    </ProfileBar>
                </MainLayout >
            )
        }

        return (
            <MainLayout>
                <div>
                    <ProfileBar setDataUri={this.setDataUri} dataUri={dataUri} showCompletedCard={showCompletedCard}>
                        <Grid item xs={12}>
                            <div className={classes.formContainer}>
                                <StudentForm
                                    updateStudentProfile={this.updateStudentProfile}
                                    setStudentData={this.setStudentData}
                                    studentData={studentData}
                                    showCompletedCard={showCompletedCard}
                                    errorMsg={errorMsg}
                                    editProfileFlag={true} 
                                    readonly={isReadonly()}
                                    editable={!isReadonly()}
                                />
                            </div>
                        </Grid>
                    </ProfileBar>
                </div>
            </MainLayout >
        )
    }
}

export default withRouter(withI18next()(withStyles(styles)(editprofile)))
