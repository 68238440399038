import zIndex from "@material-ui/core/styles/zIndex";

export default theme => ({
    headerContainer:{
        display : 'flex',    
        justifyContent : 'space-between',
        marginBottom : '20px'
    },
    galleryContainer : {
        display : 'flex',
        alignItems : 'center',
    },
    galleryButtonContainer : {
        [theme.breakpoints.down('xs')]: {
            paddingTop: '20px',
        }
    },
    galleryIcon: {
        height: '40px',
        width: '40px',
        marginRight: '10px',
        verticleAlign: 'middle'
    },
    container: {
        margin: '30px 0 30px 0'
    },
    card: {
        minWidth: '300px',
        width: '45%',
        padding: '10px 20px 10px 20px'
    },
    button: {
        marginTop: '16px'
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        margin: '20px 0 20px 0'
    },
    gridList: {
        paddingRight : '10px'
        //width: 750

    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    root: {
        height: 180,
    },
    paper: {
        margin: theme.spacing.unit,
    },
    svg: {
        width:500,
        height: 500,
    },
    polygon: {
        fill: theme.palette.common.white,
        stroke: theme.palette.divider,
        strokeWidth: 1,
    },
})