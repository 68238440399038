export default {
    "link": {
        "courses": "Core Courses",
        "events": "Events",
        "cggallery": "Gallery",
        "aboutUs": "About Us",
        "blog": "Blog",
        "home": "Home",
        "receiptSearch": "Search Receipt",
        "pos": "Register Course",
        "attendanceCheck": "Student Check-In",
        "addStudent": "Add Student",
        "studentSearch": "Search Student",
        "classSchedule": "Class Schedule",
        "studentDemoAdd": "Add Student Demo",
        "studentDemoSearch": "Manage Student Demo",
        "teacherReport": "Teacher Report",
        "assessment": "Assessment",
        "manageTeacher":"Manage Teacher",
        "studentCheckList": "Student Check List",
        "accountingSummary": "Accounting Summary",
        "manageCodes": "Manage Code",
        "videos": "Codeo"
    },
    "hello": "Hello, ",
    "en": "EN",
    "th": "TH",
    "signIn": "Sign In"
}
