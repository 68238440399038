import React from 'react'
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom'
import AddStudent from './pages/AddStudent'
import CGGallery from './pages/CGGallery'
import CoreCourse from './pages/CoreCourse'
import EditProfile from './pages/EditProfile'
import Gallery from './pages/Gallery'
import Home from './pages/Home'
//import POS from './pages/POS'
import ReceiptSearch from './pages/Receipt/Search'
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'
import AttendanceCheck from './pages/AttendanceCheck'
import UpdateQr from './pages/UpdateQr'
import StudentSearch from './pages/Student/Search'
import StudentManage from './pages/Student/Manage'
import ClassSchedule from './pages/ClassSchedule'
import AddStudentDemo from './pages/StudentDemo/Add'
import SearchStudentDemo from './pages/StudentDemo/Search'
import Schedule from './pages/Schedule'
import AssessmentSearch from './pages/Assessment/Search'
import AssessmentBadge from './pages/Assessment/Badge'
import TeacherReport from './pages/Teacher/Report'
import AccountingSummary  from './pages/AccountingSummary'
import TeacherSearch from './pages/Teacher/Search'
import TeacherManage from './pages/Teacher/Manage'
import {validateBranchId, validateRole} from './common/util'
import {ROLE, MAIN_BRANCH_ID} from './common/const'
import StudentClassAdd from './pages/StudentClass/Add'
import StudentCheckList from './pages/StudentCheckList'
import TeacherCheck from './pages/TeacherCheck'
import CGVideoSignin from './pages/CGVideo/CGVideoSignin'
import CGVideo from './pages/CGVideo/Watch'
import CGVideoUserForm from './pages/CGVideo/CGVideoUserForm'
import ManageCode from './pages/Code/Manage'

class Router extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <UserRoute path="/" exact component={Home}/>
                    <UserRoute path="/Home" component={Home}/>
                    <UserRoute path="/AddStudent" component={AddStudent}/>
                    <UserRoute path="/CGGallery" component={CGGallery}/>
                    <UserRoute path="/CoreCourses" component={CoreCourse}/>
                    <UserRoute path="/Codeo/:videoHash/Form" component={CGVideoUserForm} />
                    <UserRoute path="/Codeo/:videoHash" component={CGVideo} />
                    <UserRoute path="/Codeo" component={CGVideoSignin} />
                    <UserRoute path="/Code/Search" component={ManageCode} allowRoles={[ROLE.ADMIN, ROLE.GOD]} allowBranchIds={[MAIN_BRANCH_ID]} />
                    <UserRoute path="/EditProfile" component={EditProfile} allowRoles={[ROLE.USER, ROLE.ADMIN, ROLE.GOD]}/>
                    <UserRoute path="/Gallery" component={Gallery} allowRoles={[ROLE.USER, ROLE.ADMIN, ROLE.GOD]}/>
                    <UserRoute path="/Receipt/Search" component={ReceiptSearch} allowRoles={[ROLE.ADMIN, ROLE.ACCOUNT, ROLE.GOD]}/>
                    <UserRoute path="/SignIn" component={SignIn}/>
                    <UserRoute path="/QRSignIn/:uuid" component={SignIn}/>
                    <UserRoute path="/SignUp" component={SignUp}/>
                    <UserRoute path="/AttendanceCheck" component={AttendanceCheck} allowRoles={[ROLE.ADMIN, ROLE.CUSTOMER, ROLE.GOD]}/>
                    <UserRoute path="/UpdateQr" component={UpdateQr} allowRoles={[ROLE.ADMIN, ROLE.GOD]}/>
                    <UserRoute path="/Student/Search" component={StudentSearch} allowRoles={[ROLE.ADMIN, ROLE.GOD]}/>
                    <UserRoute path="/Student/Manage" component={StudentManage} allowRoles={[ROLE.ADMIN, ROLE.GOD]}/>
                    <UserRoute path="/ClassSchedule" component={ClassSchedule} allowRoles={[ROLE.ADMIN, ROLE.GOD]} />
                    <UserRoute path="/StudentDemo/Add" component={AddStudentDemo} />
                    <UserRoute path="/StudentDemo/Search" component={SearchStudentDemo} allowRoles={[ROLE.ADMIN, ROLE.CUSTOMER, ROLE.GOD]}/>
                    <UserRoute path="/Schedule" component={Schedule} allowRoles={[ROLE.USER]}/>
                    <UserRoute path="/Assessment/:classId" component={AssessmentBadge} allowRoles={[ROLE.ADMIN, ROLE.TEACHER, ROLE.GOD]} />
                    <UserRoute path="/Assessment" component={AssessmentSearch} allowRoles={[ROLE.ADMIN, ROLE.TEACHER, ROLE.GOD]} />
                    <UserRoute path="/Teacher/Report" component={TeacherReport} allowRoles={[ROLE.ACCOUNT]}/>
                    <UserRoute path="/StudentClass/Add" component={StudentClassAdd} />
                    <UserRoute path="/Teacher/Search" component={TeacherSearch} allowRoles={[ROLE.ADMIN, ROLE.GOD]}/>
                    <UserRoute path="/Teacher/Manage" component={TeacherManage} allowRoles={[ROLE.ADMIN, ROLE.GOD]}/>
                    <UserRoute path="/StudentCheckList" component={StudentCheckList}  allowRoles={[ROLE.ADMIN, ROLE.ACCOUNT, ROLE.GOD]} />
                    <UserRoute path="/TeacherCheck" component={TeacherCheck} allowRoles={[ROLE.ADMIN, ROLE.CUSTOMER, ROLE.GOD]}/>
                    <UserRoute path="/AccountingSummary" component={AccountingSummary} allowRoles={[ROLE.ACCOUNT, ROLE.GOD]}/>
                    <Redirect to="/"/>
                </Switch>
            </BrowserRouter>
        )
    }
}

export default Router

const UserRoute = ({component: Component, allowRoles, allowBranchIds, ...args}) => (
    <Route
        {...args}
        render={props =>
            validateRole(allowRoles) && validateBranchId(allowBranchIds) ? (
                <Component {...props} />
            ) : (
                <Redirect to={'/'}/>
            )
        }
    />
)
