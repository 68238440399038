/* eslint-disable no-underscore-dangle */

import { SheetsRegistry } from 'jss'
import { createGenerateClassName, createMuiTheme } from '@material-ui/core/styles'

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#00B3C5',
            darkest: '#29384D',
            contrastText: '#fff'
        },
        secondary: {
            main: '#FCD634',
            lightest : '#FCF4D3'
        },
        error: {
            main: '#F37E71'
        }
    },
    typography: {
        fontFamily : "\"Varela Round\", \"Pridi\", \"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
        fontSize: 16,
        htmlFontSize: 16,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        title:{
            fontFamily : "\"Pridi\", \"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
            fontSize: '1.4em'
        },
        body1: {
            fontFamily : "\"Varela Round\", \"K2D\", \"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
            fontSize: '1em',
            lineHeight : '1.65em'
        },
        body2: {
            fontFamily : "\"Varela Round\", \"K2D\", \"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
            fontSize: '1.05em',
            fontWeight : 300,
            lineHeight : '1.65em'
        },
        button : {
            fontFamily : "\"Varela Round\", \"K2D\", \"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
            fontSize: '1em',
        },
        subheading : {
            color : '#00B3C5'
        }
    },
})

function createPageContext() {
    return {
        theme,
        // This is needed in order to deduplicate the injection of CSS in the page.
        sheetsManager: new Map(),
        // This is needed in order to inject the critical CSS.
        sheetsRegistry: new SheetsRegistry(),
        // The standard class name generator.
        generateClassName: createGenerateClassName(),
    };
}

export default function getPageContext() {
    // Make sure to create a new context for every server-side request so that data
    // isn't shared between connections (which would be bad).
    if (!process.browser) {
        return createPageContext();
    }

    // Reuse context on the client-side.
    if (!global.__INIT_MATERIAL_UI__) {
        global.__INIT_MATERIAL_UI__ = createPageContext();
    }

    return global.__INIT_MATERIAL_UI__;
}