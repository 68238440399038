import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { IconButton, Avatar } from '@material-ui/core'
import { PhotoCameraTwoTone, RadioButtonChecked, VideocamOffTwoTone } from '@material-ui/icons'
import CameraPhoto, { FACING_MODES, IMAGE_TYPES } from 'jslib-html5-camera-photo'
import styles from './styles'
import { isUndefined, getCurrentUser } from '../../common/util'

class ProfilePhoto extends Component {
    constructor(props) {
        super(props)
        this.cameraPhoto = null
        this.videoRef = React.createRef()
        this.state = {
            dataUri: '',
            isStartCamera: false,
            openLiveView: false,

        }
    }

    componentDidMount() {
        this.cameraPhoto = new CameraPhoto(this.videoRef.current)
    }

    startCamera = () => {
        this.setState({ openLiveView: true }, () => {
            const facingMode = FACING_MODES.USER
            const resolution = { width: 640 }
            this.cameraPhoto.startCamera(facingMode, resolution)
                .then(() => {
                    this.setState({
                        isStartCamera: true,
                        dataUri: ''
                    })
                })
                .catch((error) => {
                    console.error('Camera not started ! ', error)
                })
        })
    }

    takePhoto = () => {
        const { setDataUri } = this.props
        const config = {
            sizeFactor: 0.5,
            imageType: IMAGE_TYPES.JPG,
            isImageMirror: false
        }
        const dataUri = this.cameraPhoto.getDataUri(config)
        setDataUri(dataUri)
        this.setState({
            isStartCamera: false,
            dataUri: dataUri
        }, () => {
            this.stopCamera()
        })
    }

    stopCamera = (flagCloseLiveView) => {
        if (flagCloseLiveView) {
            this.setState({ openLiveView: false, isStartCamera: false })
        }
        this.cameraPhoto.stopCamera()
            .catch((error) => {
                console.log('No camera to stop ! ', error)
            })
    }

    renderImage = () => {
        const { classes } = this.props
        const { dataUri, isStartCamera } = this.state

        if (dataUri) {
            return (
                <img alt='imgCamera' src={dataUri} />
            )
        }else {
            if (!isStartCamera) {
                const buttonStyle = classes.cameraIcon
                return (
                    this.renderCameraButton(buttonStyle)

                )
            }
        }
    }

    renderCameraButton = (buttonStyle) => {
        const { pathName, showCompletedCard, readonly } = this.props
        const userInfo = getCurrentUser()
        
        if (readonly) return
        
        if (pathName === '/addstudent') {
            return (
                <IconButton color='primary' className={buttonStyle ? buttonStyle : ''} onClick={this.startCamera}>
                    <PhotoCameraTwoTone fontSize='large' />
                </IconButton>
            )
        }

        if (showCompletedCard) {
            return
        }

        if (!isUndefined(userInfo)) {
            if (!isUndefined(userInfo.selectedStudent)) {
                return (
                    <IconButton color='primary' className={buttonStyle ? buttonStyle : ''} onClick={this.startCamera}>
                        <PhotoCameraTwoTone fontSize='large' />
                    </IconButton>
                )
            }

        }
    }

    renderCloseCameraButton = () => {

        return (
            <IconButton color='primary' onClick={() => this.stopCamera(true)}>
                <VideocamOffTwoTone />
            </IconButton >
        )
    }

    renderProfileAvatar() {
        const { classes, imageSrc } = this.props
        const mockAvatarSrc = `${process.env.PUBLIC_URL}/images/CGFace.png`
        return (
            <div className={classes.row}>
                <Avatar
                    src={imageSrc ? imageSrc : mockAvatarSrc}
                    classes={{
                        root: classes.bigAvatar,
                        img: classes.avatarImage
                    }}
                />
            </div>
        )
    }

    renderLiveView() {
        const { classes, pathName } = this.props
        const { isStartCamera, openLiveView } = this.state
        const cameraClass = isStartCamera? classes.camera: classes.hideCamera
        let cameraContainerClass = classes.hideCamera

        if (openLiveView) {
            if (pathName === '/addstudent') {
                cameraContainerClass = classes.cameraContainer
            } else {
                cameraContainerClass = classes.editProfileCameraContainer
            }
        }

        return (
            <div className={cameraContainerClass}>
                <video ref={this.videoRef} className={cameraClass} autoPlay={true} />
                {this.renderImage(classes)}
            </div>
        )
    }

    renderShutterButton(color) {
        const { openLiveView } = this.state
        if (!openLiveView) {
            return
        }

        return (
            <div>
                <IconButton color={color} onClick={this.takePhoto}>
                    <RadioButtonChecked fontSize='large' />
                </IconButton>
                {this.renderCloseCameraButton()}
            </div>
        )

    }

    renderAddStudentProfileAvatar() {
        const { classes } = this.props
        const { isStartCamera, dataUri } = this.state
        const cameraClass = isStartCamera? classes.camera: classes.hideCamera

        return (
            <div>
                <div className={classes.subContainer}>
                    <div className={classes.cameraContainer}>
                        <video className={cameraClass} ref={this.videoRef} autoPlay={true} />
                        {this.renderImage(classes)}
                    </div>
                </div>
                <div className={classes.subContainer}>
                    {isStartCamera ?
                        this.renderShutterButton('secondary') :
                        dataUri ? this.renderCameraButton() : ''
                    }
                </div>
            </div>
        )
    }

    renderEditProfileAvatar() { 
        const { classes, imageSrc } = this.props
        const { isStartCamera, openLiveView, dataUri } = this.state

        return (
            <div>
                <div className={classes.subContainer}>
                    {openLiveView ? null : this.renderProfileAvatar()}
                    {this.renderLiveView()}
                </div>
                <div className={classes.subContainer}>
                    {openLiveView ? null : this.renderCameraButton()}
                    {/* {isStartCamera ? this.renderShutterButton('primary') : ''} */}
                    {isStartCamera ? this.renderShutterButton('secondary') :
                        dataUri ? this.renderCameraButton() : ''
                    }
                </div>
            </div>
        )
    }

    render() {
        const { classes, editProfileFlag, pathName } = this.props

        if (editProfileFlag) { // for AddStudent and EditProfile Page
            if (pathName === '/addstudent') {
                return this.renderAddStudentProfileAvatar()
            } else {
                return this.renderEditProfileAvatar()
            }
        } else {
            return this.renderProfileAvatar()
        }
    }

}

export default withStyles(styles)(ProfilePhoto)
