import React from 'react'
import {Button, Container, Dimmer, Dropdown, Form, Grid, Loader, Modal, Segment} from 'semantic-ui-react'
import { getUserRole } from '../../../../common/util'
import { STUDENT_DEMO_STATUS, STUDENT_DEMO_STATUS_TEXT, ROLE } from '../../../../common/const'
import {formatDate, validateTime} from '../../../../common/util'
import apiClient from '../../../../apis'
import styles from './styles'
import moment from 'moment'
import {withRouter} from 'react-router'

class ChangeStatusModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {},
            teacherList: [],
            branchList: [],
        }
        this.getBranchList()
        this.getTeacherList()
    }

    static getDerivedStateFromProps(props, state) {
        if (props.data.studentDemoId !== state.data.studentDemoId) {
            return {
                data: {...props.data},
                errorMsg: undefined,
                isLoading: false
            }
        }
    }

    getBranchList = async() => {
        const result = await apiClient.branch.getAllBranches()

		if (result !== null){
			let arr = result.dataList.map((p, index) => {
                return {
                    key: p.branchId,
                    text: p.branchName,
                    value: p.branchId,
                }
			})

		  	this.setState({ 
				branchList: arr,
			})
		}
	}

    getTeacherList() {
        this.setState({
            isLoading: true
        })
        apiClient.teacher.getTeacherList()
            .then(res => {
                if (res !== null) {
                    let arr = res.map((p, index) => {
                        return {
                            key: index,
                            text: p.name,
                            value: p.id
                        }
                    })
                    this.setState({
                        teacherList: arr,
                        isLoading: false
                    })
                }
            })
    }

    close = () => {
        this.props.onClose()
    }

    doSave = async () => {
        this.setState({isLoading: true})
        const result = await apiClient.studentDemo.update(this.state.data)
        if (result.status === 'success') {
            this.props.onClose(result.data)
        } else {
            this.setState({isLoading: false, errorMsg: result.message})
        }
    }

    validateData = async () => {
        if (this.state.data.status === STUDENT_DEMO_STATUS.ALREADY_DEMO && (this.state.data.couponExpirationDate === null || this.state.data.couponExpirationDate === '')) {
            this.setState({errorMsg: this.getErrorText('couponExpirationDate')})
        } else if (this.state.data.availableDate === null || this.state.data.availableDate === '') {
            this.setState({errorMsg: this.getErrorText('availableDate')})
        } else if (this.state.data.status === STUDENT_DEMO_STATUS.ALREADY_DEMO && (this.state.data.availableTime === null || this.state.data.availableTime === '')) {
            this.setState({errorMsg: this.getErrorText('availableTime')})
        } else if (this.state.data.status === STUDENT_DEMO_STATUS.ALREADY_DEMO && !validateTime(this.state.data.availableTime)) {
            this.setState({errorMsg: this.getErrorText('availableTimeInvalid')})
        } else if (this.state.data.createdDate === null || this.state.data.createdDate === '') {
            this.setState({errorMsg: this.getErrorText('createdDate')})
        } else if (this.state.data.nickName === null || this.state.data.nickName === '') {
            this.setState({errorMsg: this.getErrorText('nickName')})
        } else if (this.state.data.age === null || this.state.data.age === '') {
            this.setState({errorMsg: this.getErrorText('age')})
        } else if (this.state.data.age > 35) {
            this.setState({errorMsg: this.getErrorText('ageRange')})
        } else if (this.state.data.mobileNumber.length === 0) {
            this.setState({errorMsg: this.getErrorText('mobileNumber')})
        } else if (this.state.data.mobileNumber.length !== 10) {
            this.setState({errorMsg: this.getErrorText('mobileNumberFormat')})
        } else if (this.state.data.school === null || this.state.data.school === '') {
            this.setState({errorMsg: this.getErrorText('school')})
        } else {
            this.doSave()
        }
    }

    getErrorText(name) {
        return ERROR_TEXT[name]
    }

    handleDropdownChange = name => (event, {value}) => {
        this.setState({
            error: {},
            errorMsg: '',
            data: {
                ...this.state.data,
                [name]: value
            }
        })
    }

    handleNumberChange = name => event => {
        if (/^\d*$/.test(event.target.value)) {
            this.handleChange(name)(event)
        }
    }

    handleChange = name => event => {
        this.setState({
            errorMsg: '',
            data: {
                ...this.state.data,
                [name]: event.target.value
            }
        })
    }

    handleStatusClick = event => {
        let couponExpirationDate = this.state.data.couponExpirationDate
        let availableTime = this.state.data.availableTime
        if (this.props.data.status === STUDENT_DEMO_STATUS.CONFIRM) {
            if (event.target.name === STUDENT_DEMO_STATUS.ALREADY_DEMO) {
                couponExpirationDate = moment().add(2, 'week')
                availableTime = ''
            } else {
                couponExpirationDate = undefined
            }
        }
        this.setState({
            data: {
                ...this.state.data,
                status: event.target.name,
                couponExpirationDate: couponExpirationDate,
                availableTime: availableTime
            },
            errorMsg: undefined
        })
    }

    goToAddStudent = () => {
        this.props.history.push(`/addstudent?studentDemoId=${this.props.data.studentDemoId}`)
    }

    renderLeadIn() {
        return (
            <Form>
                <Container textAlign='right' style={styles.buttonGroupContainer}>
                    <Button.Group>
                        <Button name={STUDENT_DEMO_STATUS.REJECT} onClick={this.handleStatusClick}
                                primary={this.state.data.status === STUDENT_DEMO_STATUS.REJECT}>{STUDENT_DEMO_STATUS_TEXT.REJECT}</Button>
                        <Button name={STUDENT_DEMO_STATUS.LEAD_IN} onClick={this.handleStatusClick}
                                primary={this.state.data.status === STUDENT_DEMO_STATUS.LEAD_IN}>{STUDENT_DEMO_STATUS_TEXT.LEAD_IN}</Button>
                        <Button name={STUDENT_DEMO_STATUS.CONFIRM} onClick={this.handleStatusClick}
                                primary={this.state.data.status === STUDENT_DEMO_STATUS.CONFIRM}>{STUDENT_DEMO_STATUS_TEXT.CONFIRM}</Button>
                    </Button.Group>
                </Container>
                <Form fluid>
                    <Form.Group widths='equal'>
                        <Form.Field >
                            <label>Branch</label>
                            <Form size='huge'>
                                <Dropdown 
                                    fluid selection placeholder='branch'
                                    onChange={this.handleDropdownChange('branchId')}
                                    options={this.state.branchList} 
                                    value={this.state.data.branchId}/>
                            </Form>
                        </Form.Field>
                        <Form.Field>
                            <Form.Input size='huge' fluid name='availableDate' label='Available Date'
                                placeholder='Available Date'
                                value={formatDate(this.state.data.availableDate, 'YYYY-MM-DD')}
                                onChange={this.handleChange('availableDate')} type='date'/>
                            </Form.Field>
                        <Form.Field>
                            <Form.Input size='huge' fluid name='availableTime' label='Available Time'
                                placeholder='Available Time (HH:mm)'
                                value={this.state.data.availableTime || ''}
                                onChange={this.handleChange('availableTime')}/>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <Form.Group >
                                <Form.Field width='6'>
                                    <Form.Input fluid name='nickName' label='Nick Name' placeholder='Nick Name'
                                            value={this.state.data.nickName} onChange={this.handleChange('nickName')}/>
                                </Form.Field>
                                <Form.Field  width='3'>
                                    <Form.Input fluid name='age' label='Age' placeholder='Age'
                                            value={this.state.data.age} onChange={this.handleNumberChange('age')}
                                            maxLength={2}/>
                                </Form.Field>
                                <Form.Field  width='7'>
                                    <Form.Input fluid name='mobileNumber' label='Mobile Number' placeholder='Mobile Number'
                                            value={this.state.data.mobileNumber}
                                            onChange={this.handleNumberChange('mobileNumber')} maxLength={10}/>
                                </Form.Field>
                            </Form.Group>
                        </Form.Field>
                        <Form.Field>
                            <Form.Input fluid name='school' label='School' placeholder='School'
                                        value={this.state.data.school} onChange={this.handleChange('school')}/>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field>
                                <Form.TextArea fluid name='remark' label='Remark' placeholder='Remark'
                                       value={this.state.data.remark || ''} onChange={this.handleChange('remark')}/>
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Form>
        )
    }

    renderConfirm() {
        return (
            <Form>
                <Container textAlign='right' style={styles.buttonGroupContainer}>
                    <Button.Group>
                        <Button name={STUDENT_DEMO_STATUS.REJECT} onClick={this.handleStatusClick}
                                primary={this.state.data.status === STUDENT_DEMO_STATUS.REJECT}>{STUDENT_DEMO_STATUS_TEXT.REJECT}</Button>
                        <Button name={STUDENT_DEMO_STATUS.LEAD_IN} onClick={this.handleStatusClick}
                                primary={this.state.data.status === STUDENT_DEMO_STATUS.LEAD_IN}>{STUDENT_DEMO_STATUS_TEXT.LEAD_IN}</Button>
                        <Button name={STUDENT_DEMO_STATUS.CONFIRM} onClick={this.handleStatusClick}
                                primary={this.state.data.status === STUDENT_DEMO_STATUS.CONFIRM}>{STUDENT_DEMO_STATUS_TEXT.CONFIRM}</Button>
                        <Button name={STUDENT_DEMO_STATUS.ALREADY_DEMO} onClick={this.handleStatusClick}
                                primary={this.state.data.status === STUDENT_DEMO_STATUS.ALREADY_DEMO}>{STUDENT_DEMO_STATUS_TEXT.ALREADY_DEMO}</Button>
                    </Button.Group>
                </Container>
                <Form fluid>
                    <Form.Group widths='equal'>
                        <Form.Field disabled={this.state.data.status !== STUDENT_DEMO_STATUS.ALREADY_DEMO}>
                            <Form.Input fluid size='huge'
                                        name='couponExpirationDate' label='Coupon Exp.' placeholder='Coupon Expiration Date'
                                        value={formatDate(this.state.data.couponExpirationDate, 'YYYY-MM-DD')}
                                        onChange={this.handleChange('couponExpirationDate')} type='date'/>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field width={3}>
                            <label>Branch</label>
                                <Dropdown 
                                    fluid selection placeholder='branch'
                                    onChange={this.handleDropdownChange('branchId')}
                                    options={this.state.branchList} 
                                    value={this.state.data.branchId}/>
                        </Form.Field>
                        <Form.Field width={4}>
                            <Form.Input fluid name='availableDate' label='Available Date' placeholder='Available Date'
                                        value={formatDate(this.state.data.availableDate, 'YYYY-MM-DD')}
                                        onChange={this.handleChange('availableDate')} type='date'/>
                        </Form.Field>
                        <Form.Field width={4}>
                            <Form.Input fluid name='availableTime' label='Available Time'
                                        placeholder='Available Time (HH:mm)'
                                        value={this.state.data.availableTime || ''}
                                        onChange={this.handleChange('availableTime')}/>
                        </Form.Field>
                        <Form.Field width={5}>
                            <label
                                style={this.state.data.status !== STUDENT_DEMO_STATUS.ALREADY_DEMO ? styles.formLabelDisable : styles.formLabel}>
                                Teacher
                            </label>
                            <Dropdown disabled={this.state.data.status !== STUDENT_DEMO_STATUS.ALREADY_DEMO}
                                    name='teacherId' placeholder='Teacher'
                                    onChange={this.handleDropdownChange('teacherId')}
                                    value={this.state.data.teacherId} options={this.state.teacherList} fluid selection/>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <Form.Group>
                                <Form.Field  width='6'>
                                    <Form.Input fluid name='nickName' label='Nick Name' placeholder='Nick Name'
                                                value={this.state.data.nickName} onChange={this.handleChange('nickName')}/>
                                </Form.Field>
                                <Form.Field  width='3'>
                                    <Form.Input fluid name='age' label='Age' placeholder='Age'
                                                value={this.state.data.age} onChange={this.handleNumberChange('age')}
                                                maxLength={2}/>
                                </Form.Field>
                                <Form.Field  width='7'>
                                    <Form.Input fluid name='mobileNumber' label='Mobile Number' placeholder='Mobile Number'
                                                value={this.state.data.mobileNumber}
                                                onChange={this.handleNumberChange('mobileNumber')} maxLength={10}/>
                                </Form.Field>
                            </Form.Group>
                        </Form.Field>
                        <Form.Field>
                            <Form.Input fluid name='school' label='School' placeholder='School'
                                        value={this.state.data.school} onChange={this.handleChange('school')}/>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.TextArea fluid='true' name='remark' label='Remark' placeholder='Remark'
                                       value={this.state.data.remark || ''} onChange={this.handleChange('remark')}/>
                    </Form.Group>
                </Form>
            </Form>
        )
    }

    renderAlreadyDemo() {
        return (
            <Form>
                <Container textAlign='right' style={styles.buttonGroupContainer}>
                    <Button color='yellow' onClick={this.goToAddStudent}
                            disabled={this.props.data.status === STUDENT_DEMO_STATUS.WIN}>Register</Button>
                    <Button.Group>
                        <Button name={STUDENT_DEMO_STATUS.LOSE} onClick={this.handleStatusClick}
                                primary={this.state.data.status === STUDENT_DEMO_STATUS.LOSE}
                                disabled={this.props.data.status === STUDENT_DEMO_STATUS.WIN}>
                            {STUDENT_DEMO_STATUS_TEXT.LOSE}
                        </Button>
                        <Button name={STUDENT_DEMO_STATUS.ALREADY_DEMO} onClick={this.handleStatusClick}
                                primary={this.state.data.status === STUDENT_DEMO_STATUS.ALREADY_DEMO}
                                disabled={this.props.data.status === STUDENT_DEMO_STATUS.WIN}>
                            {STUDENT_DEMO_STATUS_TEXT.ALREADY_DEMO}
                        </Button>
                        <Button name={STUDENT_DEMO_STATUS.WIN} onClick={this.handleStatusClick}
                                primary={this.state.data.status === STUDENT_DEMO_STATUS.WIN}>
                            {STUDENT_DEMO_STATUS_TEXT.WIN}
                        </Button>
                    </Button.Group>
                </Container>
                <Form fluid>
                    <Form.Group widths='equal'>
                        <Form.Field disabled={this.state.data.status !== STUDENT_DEMO_STATUS.ALREADY_DEMO}>
                            <Form.Input fluid size='huge'
                                        name='couponExpirationDate' label='Coupon Exp.' placeholder='Coupon Expiration Date'
                                        value={formatDate(this.state.data.couponExpirationDate, 'YYYY-MM-DD')}
                                        onChange={this.handleChange('couponExpirationDate')} type='date'/>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field width ={3} >
                            <label  style={styles.formLabelDisable}>Branch</label>
                            <Dropdown disabled={true}
                                fluid selection placeholder='branch'
                                onChange={this.handleDropdownChange('branchId')}
                                options={this.state.branchList} 
                                value={this.state.data.branchId}/>
                        </Form.Field>
                        <Form.Field width ={4} disabled={true}>
                            <Form.Input fluid  
                                        name='availableDate' label='Available Date' placeholder='Available Date'
                                        value={formatDate(this.state.data.availableDate, 'YYYY-MM-DD')}
                                        onChange={this.handleChange('availableDate')} type='date'/>
                        </Form.Field>
                        <Form.Field width ={4} disabled={true}>
                            <Form.Input fluid 
                                        name='availableTime' label='Available Time' placeholder='Available Time (HH:mm)'
                                        value={this.state.data.availableTime || ''}
                                        onChange={this.handleChange('availableTime')}/>
                        </Form.Field>
                        <Form.Field width ={5}>
                            <label style={styles.formLabelDisable}> Teacher </label>
                            <Dropdown disabled={true}
                                        name='teacherId' placeholder='Teacher'
                                        onChange={this.handleDropdownChange('teacherId')}
                                        value={this.state.data.teacherId} options={this.state.teacherList} fluid selection/>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths = 'equal'>
                        <Form.Field>
                            <Form.Group>
                                <Form.Field  width='6'>
                                    <Form.Input fluid name='nickName' label='Nick Name' placeholder='Nick Name'
                                                value={this.state.data.nickName} onChange={this.handleChange('nickName')}/>
                                </Form.Field>
                                <Form.Field  width='3'>
                                    <Form.Input fluid name='age' label='Age' placeholder='Age'
                                                value={this.state.data.age} onChange={this.handleNumberChange('age')}
                                                maxLength={2}/>
                                </Form.Field>
                                <Form.Field  width='7'>
                                    <Form.Input fluid name='mobileNumber' label='Mobile Number' placeholder='Mobile Number'
                                                value={this.state.data.mobileNumber}
                                                onChange={this.handleNumberChange('mobileNumber')} maxLength={10}/>
                                </Form.Field>
                            </Form.Group>
                        </Form.Field>
                        <Form.Field>
                            <Form.Input fluid name='school' label='School' placeholder='School'
                                        value={this.state.data.school} onChange={this.handleChange('school')}/>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.TextArea fluid='true' name='remark' label='Remark' placeholder='Remark'
                                       value={this.state.data.remark || ''} onChange={this.handleChange('remark')}/>
                    </Form.Group>
                </Form>
            </Form>
        )
    }

    renderByStatus() {
        if ([STUDENT_DEMO_STATUS.REJECT, STUDENT_DEMO_STATUS.LEAD_IN].indexOf(this.props.data.status) > -1) {
            return this.renderLeadIn()
        } else if ([STUDENT_DEMO_STATUS.CONFIRM].indexOf(this.props.data.status) > -1) {
            return this.renderConfirm()
        } else if ([STUDENT_DEMO_STATUS.ALREADY_DEMO, STUDENT_DEMO_STATUS.WIN, STUDENT_DEMO_STATUS.LOSE].indexOf(this.props.data.status) > -1) {
            return this.renderAlreadyDemo()
        }
    }

    render() {
        return (
            <Modal open={this.props.open} onClose={this.close}>
                <Modal.Content>
                    {this.renderByStatus()}
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={this.close}>
                        Cancel
                    </Button>
                    <Button positive icon='checkmark'
                        labelPosition='right' content='Save'
                        onClick={this.validateData}
                    />
                    {this.state.errorMsg &&
                    <Segment style={styles.errorMessageContainer} textAlign='center' floated='left'
                             color='red'>{this.state.errorMsg}</Segment>}
                </Modal.Actions>
                <Dimmer inverted active={this.state.isLoading}>
                    <Loader/>
                </Dimmer>
            </Modal>
        )
    }
}

export default withRouter(ChangeStatusModal)

const ERROR_TEXT = {
    nickName: 'Nick name is required.',
    age: 'Age is required.',
    ageRange: 'Invalid age.',
    mobileNumber: 'Mobile number is required.',
    mobileNumberFormat: 'Invalid mobile number.',
    school: 'School is required.',
    availableDate: 'Available date is required.',
    createdDate: 'Register date is requied.',
    couponExpirationDate: 'Coupon Exp. is required.',
    availableTime: 'Available time is required.',
    availableTimeInvalid: 'Available time format is invalid. (HH:mm)'
}
