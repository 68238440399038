import React from 'react'
import { withRouter } from 'react-router'
import moment from 'moment'
import { Divider, Accordion, Button, Card, Dropdown, Form, Grid, Icon, Input, Message, Modal, Popup } from 'semantic-ui-react'
import { getCurrentUser, formatDateDisplay } from '../../../common/util'
import apiClient from '../../../apis'
import MainLayout from '../../../layouts/MainLayout'
import {DEFAULT_PAGE_SIZE} from '../../../common/const'
import styles from './styles'
import CodeList from './codeList'
import CodesPrintFrom from './printForm'


class ManageCode extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            searchData: {
                q: '',
                activated: '',
                productId: '',
                orderBy: 'createdDate',
                orderType: 'asc',
                page: 1,
                batchId: ''
            },
            generateData: {
                productId: '',
                key: '',
                count: 1
            },
            productOptions: [{key: 'All', text: 'All', value: ''}],
            batchIdOptions: [{key: 'All', text: 'All', value: ''}],
            total: undefined,
            tableData: undefined,
            isLoading: false,
            isShowTool: true,
            isShowAdd: false,
            selectedTeacher: null,
            message: null,
            isGenerating: false,
            isModalOpen: false,
            selected: null,
            note: '',
            extetnding: false
        }
        this.handleSortingChange = this.handleSortingChange.bind(this)
        this.handlePaginationChange = this.handlePaginationChange.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    componentDidMount = () => {
        this.getProducts()
        this.getBatchIds()
        this.searchByInitialSetting()
    }

    getProducts = async() => {
        let rawProducts = await apiClient.video.getProducts()
        const productOptions = [{key: 'All', text: 'All', value: ''}]
        rawProducts.forEach(p => {
            productOptions.push({
                key: p.productId, 
                text: p.productName, 
                value: p.productId
            })
        })

        const products = rawProducts.reduce((acc, p) => {
            acc[p.productId] = { 
                productId: p.productId,
                productName: p.productName,
                productCode: p.productCode
            }
            return acc
        }, {})

        this.setState({ products, productOptions })
    }

    getBatchIds = async() => {
        const batchIds = await apiClient.video.getBatchIds()
        const batchIdOptions = [
            {key: 'All', text: 'All', value: ''},
            ...batchIds.map(v => { return {key: v, text: v, value: v} })
        ]
        this.setState({ batchIdOptions })
    }

    clearUserInfo = () => {
        const userInfo = getCurrentUser()
        localStorage.setItem('user', JSON.stringify({...userInfo, searchData: null, selectedTeacher: null }))
    }

    searchCodes = async() => {
        this.setState({
            isLoading: true
        })

        const data = this.state.searchData
        let searchData = {}
        for (const key in data) {
            if (data[key] !== '' && data[key] !== null && data[key] !== undefined && data[key] !== 'undefined') {
                searchData[key] = await data[key]
            }
        }

        let result = await apiClient.video.searchCodes(searchData)
        if (result) {
            this.setState({
                tableData: result.dataList,
                total: result.total,
                isLoading: false,
            })
        }
    }

    searchByInitialSetting = () => {
        this.setState({
                searchData: {
                    ...this.state.searchData,
                    activated: 'all',
                    orderBy: 'createdDate',
                    orderType: 'asc',
                    page: 1
                }
            },
            this.searchCodes
        )
    }

    pushEditTeacher =  async (teacherId) => {
        const { searchData } = this.state
        const userInfo = getCurrentUser()
        await localStorage.setItem('user', JSON.stringify({...userInfo, selectedTeacher: teacherId, searchData: searchData}))
        this.settingInitialItem()
        this.props.history.push('/Teacher/Manage')
    }

    pushAddTeacher = async () => {
        const { searchData } = this.state
        const userInfo = getCurrentUser()
        await localStorage.setItem('user', JSON.stringify({...userInfo, selectedTeacher: null, searchData: searchData}))
        this.settingInitialItem()
        this.props.history.push('/Teacher/Manage')
    }
    
    settingInitialItem = () => {
        this.setState({
            searchData: {
                q: '',
                activated: '',
                productId: '',
                batchId: '',
                orderBy: 'teacherId',
                orderType: 'asc',
                page: 1,
            },
            isShowTool: true,
            selectedTeacher: null
        },
        this.searchCodes
        )	
    }

    handlePaginationChange = activePage => {
        this.setState({
                searchData: {
                    ...this.state.searchData,
                    page: activePage
                }
            },
        this.searchCodes
        )
    }

    handleInputChange = (e, value, key='searchData') => {
        this.setState({
            [key]: {
                ...this.state[key],
                [value.id]: value.value
            }
        })
    }

    handleGenerateInputChange = (e, value) => {
        this.setState({
            generateData: {
                ...this.state.generateData,
                [value.id]: value.value
            }
        })
    }

    handleSortingChange = (orderBy, orderType) => {
        this.setState(
            {
                searchData: {
                    ...this.state.searchData,
                    orderBy: orderBy,
                    orderType: orderType
                }
            },
            this.searchCodes
        )
    }

    generateCodes = async() => {
        const { productId, key, count } = this.state.generateData
        this.setState({ isGenerating: true })
        let result = await apiClient.video.generateCodes(productId, key, count)
        if (result.success) {
            this.searchCodes()
            this.setState({ message: { text: `${count} codes were generated.`, color: 'green' }})
            this.printCode(result.codes)
        } else {
            this.setState({ message: { text: "There is an error while generating codes.", color: 'red' }})
        }
        this.setState({ isGenerating: false })
    }

    extendCode = async(code) => {
        this.setState({ extending: true })
        await apiClient.video.extendCode(code)
        this.setState({ extending: false })
        this.closeModal()
        this.searchCodes()
    }

    showMessage = () => {
        const { message } = this.state
        if (message) {
            return(
                <Message
                    color={message.color}
                    icon='exclamation circle'
                    content={message.text}
                    size='small'
                    style={{ textAlign:'left' }}
                />
            )
        }
    }

    renderAddCard = () => {
        const { key, count, productId } = this.state.generateData
        const { isGenerating } = this.state
        const options = this.state.productOptions.slice(1)
        return (
            <Card fluid>
                <Card.Content>
                    <Form fluid>
                        <Form.Field>{this.showMessage()}</Form.Field>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Product</label>
                                <Dropdown id='productId' fluid placeholder='All' selection options={options} 
                                    value={productId} onChange={(e, v) => this.handleInputChange(e, v, 'generateData')}/>
                            </Form.Field>
                            <Form.Field>
                                <label >Key</label>
                                <Input id='key' fluid placeholder='' type='text' maxLength="2"
                                value={key} onChange={(e, v) => this.handleInputChange(e, v, 'generateData')} />
                            </Form.Field>
                            <Form.Field>
                                <label >Count</label>
                                <Input id='count' fluid placeholder='' type='number' min="1"
                                value={count} onChange={(e, v) => this.handleInputChange(e, v, 'generateData')} />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Card.Content>
                <Card.Content extra textAlign='right'>
                    <Grid.Column floated='right' style={{display: 'flex'}}>
                        <Button primary onClick={() => this.generateCodes()} disabled={isGenerating}>
                            Generate Codes
                        </Button>
                    </Grid.Column>
                </Card.Content>	
            </Card>
        )
    }

    renderSearchCard = () => {
        const { q, productId, activated, batchId } = this.state.searchData
        return (
            <Card fluid>
                <Card.Content>
                    <Form fluid>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label >Query</label>
                                <Input id='q' fluid placeholder='' type='text'
                                value={q} onChange={this.handleInputChange} />
                            </Form.Field>
                            <Form.Field>
                                <label>Status</label>
                                <Dropdown id='activated' fluid placeholder='All' selection options={statusOptions} 
                                    value={activated} onChange={this.handleInputChange}/>
                            </Form.Field>
                            <Form.Field>
                                <label>Product</label>
                                <Dropdown id='productId' fluid placeholder='All' selection options={this.state.productOptions} 
                                    value={productId} onChange={this.handleInputChange}/>
                            </Form.Field>
                            <Form.Field>
                                <label>BatchId</label>
                                <Dropdown id='batchId' fluid placeholder='All' selection options={this.state.batchIdOptions} 
                                    value={batchId} onChange={this.handleInputChange}/>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Card.Content>
                <Card.Content extra textAlign='right'>
                    <Grid.Column floated='right' style={{display: 'flex'}}>
                        <Button primary onClick={() => this.searchCodes()}>
                            Search
                        </Button>
                    </Grid.Column>
                </Card.Content>	
            </Card>
        )
    }

    closeModal = () => {
        this.setState({ isModalOpen: false, note: '' })
    }

    openModal = code => {
        console.log(code.note)
        this.setState({ selected: code, note: code.note, isModalOpen: true })
    }

    handleNoteChange = e => {
        this.setState({ note: e.target.value })
    }

    saveNote = async() => {
        this.setState({ extending: true })
        await apiClient.video.saveCodeNote(this.state.selected.code, this.state.note)
        await this.searchCodes()
        this.setState({ extending: false })
        this.closeModal()
    }

    printCode = codes => {
        if (codes !== null) {
            this.printForm.print(codes)
        }
    }

    render() {
        const{ isModalOpen, selected, note, extending } = this.state
        return (
            <div>
            <MainLayout id='react-no-print'>
                    <div style={styles.container}>
                        <Grid centered stackable>
                            <Grid.Row>
                                <Grid.Column>
                                    <Accordion fluid>
                                        <Accordion.Title 
                                            active={this.state.isShowAdd}
                                            onClick={() => this.setState({isShowAdd: !this.state.isShowAdd})}
                                        >
                                            <Icon name='dropdown'/>
                                            Generate Codes
                                        </Accordion.Title>
                                        <Accordion.Content active={this.state.isShowAdd}>
                                            {this.renderAddCard()}
                                        </Accordion.Content>
                                    </Accordion>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Accordion fluid>
                                        <Accordion.Title 
                                            active={this.state.isShowTool}
                                            onClick={() => this.setState({isShowTool: !this.state.isShowTool})}
                                        >
                                            <Icon name='dropdown'/>
                                            Search Codes
                                        </Accordion.Title>
                                        <Accordion.Content active={this.state.isShowTool}>
                                            {this.renderSearchCard()}
                                        </Accordion.Content>
                                    </Accordion>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <CodeList
                                        pushEditTeacher={this.pushEditTeacher}
                                        tableData={this.state.tableData}
                                        products={this.state.products}
                                        onPaginationChange={this.handlePaginationChange}
                                        onSortingChange={this.handleSortingChange}
                                        totalPages={Math.ceil(this.state.total / DEFAULT_PAGE_SIZE)}
                                        totalData={this.state.total}
                                        pageSize={DEFAULT_PAGE_SIZE}
                                        activePage={this.state.searchData.page}
                                        sorting={{
                                            column: this.state.searchData.orderBy,
                                            direction: this.state.searchData.orderType
                                        }}
                                        extendCode={this.extendCode}
                                        openModal={this.openModal}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <NoteModal 
                            code={selected} 
                            note={note}
                            isOpen={isModalOpen} 
                            onClose={this.closeModal} 
                            handleSave={this.saveNote}
                            handleNoteChange={this.handleNoteChange}
                            extending={extending}
                            extendCode={this.extendCode}
                        />
                    </div>
            </MainLayout>
		    <CodesPrintFrom ref={(el) => (this.printForm = el)} />
            </div>
        )
    }
}

export default withRouter(ManageCode)

const statusOptions = [
    {key: 'All', text: 'All', value: 'all'},
    {key: 'Y', text: 'Activated', value: 'activated'},
    {key: 'N', text: 'Not Activated', value: 'inactivated'}
]

const NoteModal = ({ code, isOpen, onClose, note, handleNoteChange, handleSave, extendCode, extending }) => {
    if (code === null) {
        return null
    }
    
    const canExtend = code.activatedDate !== null && !code.extended
    const today = moment()
    const currentExpiration = moment(code.expirationDate)
    const newExpirationDate = currentExpiration.isAfter(today) ? currentExpiration.add(7, 'days') : today.add(7, 'days')
    
    formatDateDisplay(code.expirationDate)

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Modal.Content>
                <Form>
                    <h2>Edit Note</h2>
                    <Form.Group widths='equal'>
                    <Form.Field>
                        <Form.TextArea fluid name='remark' label='Note' placeholder='Note'
                            value={note || ''} onChange={handleNoteChange}
                        />
                    </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal' style={{ justifyContent: "flex-start"}}>
                        <Button disabled={extending} negative onClick={onClose} style={{ marginLeft: '6px' }}>
                            Cancel
                        </Button>
                        <Button disabled={extending} positive icon='checkmark'
                            labelPosition='right' content='Save'
                            onClick={handleSave}
                        />
                    </Form.Group>
                </Form>

                <Divider style={{ margin: "48px 0px 24px" }} />
                <Form>
                    <h2>Extend Expiration Date</h2>
                    <Form.Group>
                        {canExtend ? (
                            <div style={{ marginLeft: '6px', fontSize: '14px', fontWeight: '400' }}>
                                <div>Current Expiration Date: {formatDateDisplay(code.expirationDate)}</div>
                                <div style={{ margin: '12px 0px 16px'}}>New Expiration Date: {formatDateDisplay(newExpirationDate)}</div>
                            </div>
                        ): (
                            <div style={{ color: "#666", fontSize: '14px', fontWeight: '600', marginLeft: '6px'}}>Cannot extend expiration date</div>
                        )}
                    </Form.Group>
                    <Form.Group>
                        <Popup 
                            basic
                            content={
                                code.activatedDate === null ? "The code needs to be activated" 
                                : code.extended ? "The code has already been extended"
                                : "Extend expiration date by 7 days from today"
                            }
                            trigger={
                                // <div style={{ display: "inline-block" }}>
                                <div style={{ marginLeft: '6px'}}>
                                <Button
                                    onClick={() => extendCode(code.code)}
                                    disabled={extending || !canExtend}
                                >
                                    Extend
                                </Button>
                                </div>
                            }
                        />
                    </Form.Group>
                </Form>
            </Modal.Content>
        </Modal>
    )
}