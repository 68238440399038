export const styles = {
  container: {
    paddingBottom: '20px'
  },
  profilePhotoGrid: {
    paddingTop: '60px'
  },
  formContainer: {
    padding: '36px'
  },
  dimmer: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: '0',
    left: '0'
  }
}
