export default {
    "title": "Home",
    "lang":"en",
    "advertisement":{
        "1" : "https://premierxoffice.thepremierprep.com/code-genius/home_slider/en_how_to_code.jpg",
        "2" : "https://premierxoffice.thepremierprep.com/code-genius/home_slider/en_soft_opening_soon.jpg"
    },
    "button" : {
        "seemore": "See more.."
    },
    "whatWeDo": {
        "header": "What we do",
        "subHeading": "We teach Code Genius skills",
        "think": {
            "header": "THINK",
            "content": "Develop logical thinking.\nKids will learn to think systematically."
        },
        "solve": {
            "header": "SOLVE",
            "content": "Build problem solving skills.\nKids will learn to break down big problems\ninto smaller, manageable tasks\nto arrive at a solution."
        },
        "innovate": {
            "header": "INNOVATE",
            "content": "Empower kids with creative mindset.\nWe familiarize kids with technology\nand help them become an\ninnovator of tomorrow."
        },
        "concept":"Code Genius creates a high-quality, continuous curriculum that empowers kids with coding.\nWe inspire kids to think and solve problems by themselves so that they can grow to be innovative leaders. These are Code Genius skills."
    },
    "feature": {
        "header": "Why kids should learn to code",
        "subHeading" : "Sayings from global leaders",
        "content1": "\"Learning to write programs stretches your mind, helps you think better, and creates a way of thinking about things that I think is helpful in all domains.\"",
        "footer1": "Bill Gates, Principal founder of Microsoft",
        "content2": "\"Everybody in this country should learn to program a computer because it teaches you how to think.\"",
        "footer2": "Steve Jobs, Co-founder of Apple Inc.",
        "content3": "\"In fifteen years, we’ll be teaching programming just like reading and writing, and wondering why we didn’t do it sooner.\"",
        "footer3": "Mark Zuckerberg, Co-founder of Facebook"
    },
    "quote": "Coding is as important as a second language in the digital age.\nEmpowering kids with the skills of the 21st century is a pivotal decision."
}