import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Card, Image } from 'semantic-ui-react'

import styles from './styles'
import { formatDateDisplay } from '../../common/util'

class Album extends Component {


    render() {
        const { classes, albumData, clickAction } = this.props
        return (
            <Card className={classes.card} link onClick={() => clickAction(albumData.courseId, albumData.courseName)}>
                <Image src={albumData.photoUrl} className={classes.media} ui={false} />
                <Card.Content>
                    <Card.Header>{albumData.courseName}</Card.Header>
                    <Card.Meta>
                        <span className='date'>Last updated date :  {formatDateDisplay(albumData.createdDate)}</span>
                    </Card.Meta>
                </Card.Content>
            </Card>
        )
    }

}

export default withStyles(styles)(Album)