/* eslint-disable default-case */
/* eslint-disable no-fallthrough */
import React from 'react'
import { Input, Modal, Grid, Form, Button, Table, Checkbox, Label, Divider, Segment, Message } from 'semantic-ui-react'
import { COURSE_TYPE } from '@common/const'
import apiClient from '@apis'
import moment from 'moment'
import styles from './styles'
import { convertNumber, formatDate, getColorFromDow } from '@common/util'

const ALL_AGE = 'All Age'
class SelectClassModal extends React.Component {
    state = {}

    courseTypeAndCoursesMap = {}
    courseIdAndCourseDetailsMap = {}

    courseDetails = []
    filteredCourseDetails = []
    static getDerivedStateFromProps(props, state) {
        // if ((!props.open && state.courseType !== '') || (props.studentId !== state.studentId) || (props.branchId !== state.branchId) || (props.registerDate !== state.registerDate) || (props.selectedCourseDetailIds.length !== state.selectedCourseDetailIds.length) || (props.classes !== state.classes)) {
        if (!props.open) {    
            return {
                courseType: '',
                courseId: '',
                courseOptions: [],
                courseDetailType: '',
                courseDetailTypeOptions: [],
                language: '',
                languageOptions: [],
                age: '',
                ageOptions: [],
                courseDetailId: '',
                courseDetailOptions: [],
                classes: [],
                dow: {},
                classCount: 10,
                startTime: '',
                endTime: '',
                classFiltering: 'notFull',
                studentId: props.studentId,
                branchId: props.branchId,
                teachers: props.teachers,
                rooms: props.rooms,
                selectedCourseDetailIds: props.selectedCourseDetailIds || [],
                registerDate: props.registerDate,
                isSearchClass: false,
                isEditMode: false
            } 
        } else {
            return {}
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.courseDetail && this.state.classes.length === 0 && !prevProps.courseDetail) {
            const isCreateNew = this.props.classes.find(clazz => clazz.isNew) 
            let classes = isCreateNew ? this.props.classes : (await this.getClasses()).map(cdata => {
                return this.props.classes.find( data => cdata.classId === data.classId ) || cdata
            })
            this.setState({ 
                courseDetailId: this.props.courseDetail.courseDetailId,
                courseDetailOptions: [{ key: this.props.courseDetail.courseDetailId, value: this.props.courseDetail.courseDetailId, text: this.props.courseDetail.displayName }],
                isSeaching: false, 
                isSearchClass: true, 
                classes, 
                teacherId: classes[0].teacherId,
                roomId: classes[0].roomId,
                isEditMode: true 
            })
        }
    }

    handleChange = (fieldName) => async (e, { value }) => {
        let newState = { ...this.getDefaultValueByFieldChanged(fieldName), [fieldName]: value }
        switch(fieldName) {
            case("courseType"):
                newState = await this.handleCourseTypeChange(newState)
            case("courseId"):
                newState = await this.handleCourseChange(newState)
            case("courseDetailType"):
                newState = await this.handleCourseDetailTypeChange(newState)
            case("language"):
                newState = await this.handleLanguageChange(newState)
            case("age"):
                newState = await this.handleAgeChange(newState)
            case("courseDetailId"):
                newState = await this.handleCourseDetailChange(newState)
        }

        this.setState(newState)
    }

    handleDowChange = (day) => async (e, { value }) => {
        let dow = this.state.dow
        dow[day] = !dow[day]
        this.setState({ dow: dow })
    }

    handleTeacherChange = (e, { value }) => {
        let classes = this.state.classes
        classes.forEach(clazz => clazz.teacherId = value)
        this.setState({ classes: classes, teacherId: value })
    }

    handleRoomChange = (e, { value }) => {
        let classes = this.state.classes
        const room = this.state.rooms.find(data => data.roomId === value)
        classes.forEach(clazz => clazz.roomId = value)
        this.setState({ classes: classes, roomId: value, room: room.name })
    }

    handleShowSelectedClick = async (e, { value }) => {
        this.setState({ showSelected: !this.state.showSelected })
    }

    handleSelectClassClick = (classId) => async (e, { value }) => {
        const updatedClasses = this.state.classes.map(clazz => {
            if (clazz.classId == classId) {
                return {...clazz, selected: !clazz.selected}
            } else {
                return clazz
            }
        })
        this.setState({ classes: updatedClasses })
    }

    handleCourseTypeChange = async (newState) => {
        const courses = await this.getCourses(newState.courseType)
        const courseOptions = courses.map(data => ({ key: data.courseId, value: data.courseId, text: data.courseName }))
        return { ...newState, courseOptions }
    }

    handleCourseChange = async (newState) => {
        const courses = await this.getCourses(newState.courseType)
        this.selectedCourse = courses.find(course => course.courseId === newState.courseId)
        const courseDefaultTimes = (this.selectedCourse && this.selectedCourse.courseDefaultTimes) || 10
        this.courseDetails = await this.getCourseDetails(newState.courseId)
        this.filteredCourseDetails = this.getFilteredCourseDetail('courseId', newState)
        const nextOptions = this.filteredCourseDetails
            .map(data => data.courseDetailType)
            .filter(data => data && data.length > 0)
            .distinct()
            .map(data => ({ key: data, value: data, text: data }))
        const nextStateValue = nextOptions.length === 1? nextOptions[0].value: ''
        return { ...newState, courseDetailType: nextStateValue, courseDetailTypeOptions: nextOptions, classCount: courseDefaultTimes }
    }

    handleCourseDetailTypeChange = async (newState) => {
        this.filteredCourseDetails = this.getFilteredCourseDetail('courseDetailType', newState)
        const nextOptions = this.filteredCourseDetails
            .map(data => data.language)
            .filter(data => data && data.length > 0)
            .distinct()
            .map(data => ({ key: data, value: data, text: data }))
        const nextStateValue = nextOptions.length === 1? nextOptions[0].value: ''
        return { ...newState, language: nextStateValue, languageOptions: nextOptions }
    }

    handleLanguageChange = async (newState) => {
        this.filteredCourseDetails = this.getFilteredCourseDetail('language', newState)
        const nextOptions = this.filteredCourseDetails
            .map(data => data.age)
            .distinct()
            .map(data => ({ key: data, value: data, text: data }))
        const nextStateValue = nextOptions.length === 1? nextOptions[0].value: ''
        return { ...newState, age: nextStateValue, ageOptions: nextOptions }
    }

    handleAgeChange = async (newState) => {
        this.filteredCourseDetails = this.getFilteredCourseDetail('age', newState)
        const nextOptions = await this.getCourseDetailOptions(newState, this.filteredCourseDetails) 
        return { ...newState, courseDetailOptions: nextOptions }
    }

    handleCourseDetailChange = async (newState) => {
        return { ...newState, classes: [] }
    }

    getCourseDetailOptions = async (state, courseDetails) => {
        return Promise.all(courseDetails.map(async detail => {
            return { key: detail.courseDetailId, value: detail.courseDetailId, text: detail.displayName }
        }))
    }

    getDefaultValueByFieldChanged = (fieldName) => {
        const defaultState = { ...this.state }
        switch(fieldName) {
            case("courseType"):
                defaultState.courseId = ''
                defaultState.courseOptions = []
            case("courseId"):
                defaultState.courseDetailType = ''
                defaultState.courseDetailTypeOptions = []
            case("courseDetailType"):
                defaultState.language = ''
                defaultState.languageOptions = []
            case("language"):
                defaultState.age = ''
                defaultState.ageOptions = []
            case("age"):
                defaultState.courseDetailId = ''
                defaultState.courseDetailOptions = []
        }
        return defaultState
    }

    getFilteredCourseDetail = (fieldName, state) => {
        let filteredCourseDetails = this.courseDetails
        switch(fieldName) {
            case("age"):
                filteredCourseDetails = filteredCourseDetails.filter(data => data.age === state.age)
            case("language"):
                filteredCourseDetails = filteredCourseDetails.filter(data => data.language === state.language)
            case("courseDetailType"):
                filteredCourseDetails = filteredCourseDetails.filter(data => data.courseDetailType === state.courseDetailType)
            // case("courseId"):
            // case("courseType"):
        }

        return filteredCourseDetails
    }

    getCourses = async (courseType) => {
        if (courseType) {
            const result = this.courseTypeAndCoursesMap[courseType]?this.courseTypeAndCoursesMap[courseType]:((await apiClient.course.getCourseByCriteria({ courseType })).dataList)
            this.courseTypeAndCoursesMap[courseType] = result
            return result
        }
        return []
    }

    getCourseDetails = async (courseId) => {
        if (courseId) {
            const results = this.courseIdAndCourseDetailsMap[courseId]?this.courseIdAndCourseDetailsMap[courseId]:((await apiClient.courseDetail.findByCriteria({ courseId })).dataList)
            const courses = await this.getCourses(this.state.courseType)
            const course = courses.find(c => c.courseId === courseId)
            this.courseIdAndCourseDetailsMap[courseId] = results.map(detail => {
                let displayName = `${course.courseName}`
                if (detail.language) displayName += ` (${detail.language})`
                if (detail.age && detail.age !== ALL_AGE) displayName += ` Age ${detail.age}`
                if (detail.courseDetailType) displayName += ` [${detail.courseDetailType}]`
                return { ...detail, age: detail.age || ALL_AGE, displayName, isFixPrice: false }
            })
            return this.courseIdAndCourseDetailsMap[courseId].filter(data => this.state.selectedCourseDetailIds.indexOf(data.courseDetailId) < 0)
        }
        return []
    }

    generatePrivateClassDate = () => {
        const dows = Object.keys(this.state.dow).filter( day => this.state.dow[day]).sort()
        if (dows.length === 0) return Array(this.state.classCount).map(_ => '')
        
        const currentDate = moment(this.state.registerDate)
        let weekDay0 = moment([currentDate.year(), currentDate.month(), currentDate.date()]).day(0)
        let result = []
        for (let i = 0; result.length < this.state.classCount && i<20; i++) {
            if (i > 0 && (i % dows.length) === 0) {
                weekDay0 = weekDay0.add(7, 'days')
            }
            const dow = parseInt(dows[i % dows.length], 10) - 1
            const dayDate = weekDay0.day(dow)
            if (currentDate.isBefore(dayDate) || currentDate.isSame(dayDate)) {
                result.push(dayDate.format('YYYY-MM-DD'))
            }
        }
        return result
    }

    getClasses = async (isCreatePrivate) => {
        const courseDetailId = (this.props.courseDetail && this.props.courseDetail.courseDetailId) || this.state.courseDetailId
        let results = []
        this.setState({ isSearchClass: false, classes: [], classFiltering: 'notFull', showSelected: false, isSeaching: true })
        const { dow, registerDate, startTime, endTime } = this.state
        if (isCreatePrivate) {
            const dates = this.generatePrivateClassDate()
            for (let i = 0; i < this.state.classCount; i++) {
                results.push({ 
                    classId: `${courseDetailId}-new-${i}`, 
                    courseDetailId: courseDetailId, 
                    branchId: this.state.branchId, 
                    isDup: 0, 
                    classSize: 1, 
                    studentCount: 0, 
                    isNew: true,
                    classDate: dates[i],
                    startTime: startTime,
                    endTime: endTime
                })
            }
            if (!this.props.courseDetailId) {
                this.setState({ isSeaching: false, isSearchClass: true, classes: this.autoSelectClasses(results) })
            }
        } else {
            if (courseDetailId && courseDetailId !== '') {
                const dowStr = Object.keys(dow).filter( day => dow[day]).join(',')
                results = (await apiClient.class.findClassForRegistration({ courseDetailId, dow: dowStr, registerDate, startTime, endTime, studentId: this.state.studentId, branchId: this.state.branchId })).dataList
                if (!this.props.courseDetailId) this.setState({ isSeaching: false, isSearchClass: true, classes: this.autoSelectClasses(results) })
            } else {
                this.setState({ isSeaching: false })
            }
        }
        return results
        
    }

    autoSelectClasses = (classes) => {
        let cnt = 0
        if (this.state.classCount) {
            return classes.map(clazz => {
                if (clazz.classSize > clazz.studentCount && cnt < this.state.classCount && clazz.isDup === 0) {
                    cnt++
                    return {...clazz, selected: true}
                } else {
                    return {...clazz, selected: false}
                }
            })
        } else {
            return classes
        }   
    }

    filterClasses = () => {
        return this.state.classes.filter(clazz => {
            if (this.state.showSelected && !clazz.selected) {
                return false
            }
            if (this.state.classFiltering === 'notFull' && clazz.classSize <= clazz.studentCount) {
                return false
            }
            return true
        })
    }

    isPrivate(courseDetailType) {
        const resolvedCourseDetailType = courseDetailType || this.state.courseDetailType
        return resolvedCourseDetailType && resolvedCourseDetailType.toLowerCase() === 'private'
    }

    isCodeExpert(courseType) {
        const resolvedCourseType = courseType || this.state.courseType
        return resolvedCourseType && resolvedCourseType.toLowerCase() === 'code expert'
    }

    isCamp(courseType) {
        const resolvedCourseType = courseType || this.state.courseType
        return resolvedCourseType && resolvedCourseType.toLowerCase() === 'camp'
    }

    isCreateNewPrivate() {
        return this.state.classes && this.state.classes.find(clazz => clazz.isNew)
    }

    close = () => {
        this.props.onClose()
    }

    doSave = () => {
        const courseDetail = this.filteredCourseDetails.find( data => data.courseDetailId === this.state.courseDetailId )
        const classes = this.state.classes.filter( data => data.selected )
        this.props.onSave(courseDetail, classes)
    }

    renderSummaryForFixPrice = (selectedCourseDetail, selectedClasses) => {
        let totalHours = 0
        selectedClasses.forEach(clazz => {
            let hours = 0
            if (clazz.startTime && clazz.endTime) {
                try {
                    const startTime = moment(clazz.startTime, 'hh:mm')
                    const endTime = moment(clazz.endTime, 'hh:mm')
                    hours = endTime.diff(startTime, 'minute') / 60.0
                } catch (e) {}
            }
            totalHours += hours
        })
        const courseHours = selectedCourseDetail.hours || 0
        let hourColor = ''
        if (totalHours === courseHours) {
            hourColor = 'green'
        } else if (totalHours < courseHours) {
            hourColor = 'yellow'
        } else {
            hourColor = 'red'
        }

        return (
            <Segment>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={6} textAlign="right">Hours:</Grid.Column>
                        <Grid.Column width={6} textAlign="right" color={hourColor}>{convertNumber(totalHours, 1)}/{convertNumber(courseHours, 1)}</Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={6} textAlign="right">Selected:</Grid.Column>
                        <Grid.Column width={6} textAlign="right">{selectedClasses.length || '-'}</Grid.Column>
                        <Grid.Column width={4} textAlign="left">Class</Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={6} textAlign="right">Total:</Grid.Column>
                        <Grid.Column width={6} textAlign="right">{(selectedCourseDetail && convertNumber(selectedCourseDetail.price, 2)) || '-'}</Grid.Column>
                        <Grid.Column width={4} textAlign="left">THB</Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        )
    }

    renderSummary = (selectedCourseDetail, selectedClasses) => {
        return (
            <Segment>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={6} textAlign="right">Price:</Grid.Column>
                        <Grid.Column width={6} textAlign="right">{(selectedCourseDetail && convertNumber(selectedCourseDetail.price, 2)) || '-'}</Grid.Column>
                        <Grid.Column width={4} textAlign="left">THB</Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={6} textAlign="right">Selected:</Grid.Column>
                        <Grid.Column width={6} textAlign="right">{selectedClasses.length || '-'}</Grid.Column>
                        <Grid.Column width={4} textAlign="left">Class</Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={6} textAlign="right">Total:</Grid.Column>
                        <Grid.Column width={6} textAlign="right">{(selectedCourseDetail && convertNumber(selectedCourseDetail.price * selectedClasses.length, 2)) || '-'}</Grid.Column>
                        <Grid.Column width={4} textAlign="left">THB</Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        )
    }

    renderLeftSection() {
        const selectedCount = this.state.classes.filter(clazz => { return clazz.selected })
        const selectedCourseDetail = this.filteredCourseDetails.find( data => data.courseDetailId === this.state.courseDetailId )
        return (
            <React.Fragment>
                <Grid stackable>
                    <Grid.Column width={6}>
                        <Form.Dropdown
                            disabled={this.state.isEditMode}
                            placeholder='Course Group'
                            fluid
                            selection
                            value={this.state.courseType}
                            onChange={this.handleChange("courseType")}
                            options={COURSE_TYPE_OPTIONS}
                        />
                    </Grid.Column>
                    <Grid.Column width={10}>
                        <Form.Dropdown
                            disabled={this.state.isEditMode}
                            placeholder='Course Name'
                            fluid
                            search
                            selection
                            value={this.state.courseId}
                            onChange={this.handleChange("courseId")}
                            options={this.state.courseOptions || []}
                        />
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Form.Dropdown
                            placeholder='Type'
                            fluid
                            selection
                            value={this.state.courseDetailType}
                            onChange={this.handleChange("courseDetailType")}
                            options={this.state.courseDetailTypeOptions || []}
                            disabled={!this.state.courseDetailTypeOptions || this.state.courseDetailTypeOptions.length === 0 || this.state.isEditMode}
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Form.Dropdown
                            placeholder='Lang'
                            fluid
                            selection
                            value={this.state.language}
                            onChange={this.handleChange("language")}
                            options={this.state.languageOptions || []}
                            disabled={!this.state.languageOptions || this.state.languageOptions.length === 0 || this.state.isEditMode}
                        />
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Form.Dropdown
                            placeholder='Age'
                            fluid
                            selection
                            value={this.state.age}
                            onChange={this.handleChange("age")}
                            options={this.state.ageOptions || []}
                            disabled={!this.state.ageOptions || this.state.ageOptions.length === 0 || this.state.isEditMode}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Form.Dropdown
                            placeholder='Course Detail Name'
                            fluid
                            selection
                            search
                            value={this.state.courseDetailId}
                            onChange={this.handleChange("courseDetailId")}
                            options={this.state.courseDetailOptions || []}
                            disabled={!this.state.courseDetailOptions || this.state.courseDetailOptions.length === 0 || this.state.isEditMode}
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Form.Checkbox
                            label='Mon'
                            fitted
                            checked={this.state.dow["2"]}
                            onChange={this.handleDowChange("2")}
                            disabled={this.state.isEditMode}
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Form.Checkbox
                            label='Tue'
                            fitted
                            checked={this.state.dow["3"]}
                            onChange={this.handleDowChange("3")}
                            disabled={this.state.isEditMode}
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Form.Checkbox
                            label='Wed'
                            fitted
                            checked={this.state.dow["4"]}
                            onChange={this.handleDowChange("4")}
                            disabled={this.state.isEditMode}
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Form.Checkbox
                            label='Thu'
                            fitted
                            checked={this.state.dow["5"]}
                            onChange={this.handleDowChange("5")}
                            disabled={this.state.isEditMode}
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Form.Checkbox
                            label='Fri'
                            fitted
                            checked={this.state.dow["6"]}
                            onChange={this.handleDowChange("6")}
                            disabled={this.state.isEditMode}
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Form.Checkbox
                            label='Sat'
                            fitted
                            checked={this.state.dow["7"]}
                            onChange={this.handleDowChange("7")}
                            disabled={this.state.isEditMode}
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Form.Checkbox
                            label='Sun'
                            fitted
                            checked={this.state.dow["1"]}
                            onChange={this.handleDowChange("1")}
                            disabled={this.state.isEditMode}
                        />
                    </Grid.Column>
                    <Grid.Column width={6}>
                        Start
                        <Input
                            lable = 'Start'
                            type ='time'
                            fluid
                            value={this.state.startTime}
                            onChange={this.handleChange('startTime')}
                            disabled={this.state.isEditMode}
                        />
                        
                    </Grid.Column>
                    <Grid.Column width={6}>
                        End
                        <Input
                            lable = 'End'
                            type ='time'
                            fluid
                            value={this.state.endTime}
                            onChange={this.handleChange('endTime')}
                            disabled={this.state.isEditMode}
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        #Classes
                        <Form.Input
                            placeholder='จำนวน'
                            fluid
                            value={this.state.classCount}
                            onChange={this.handleChange("classCount")}
                            disabled={this.state.isEditMode || this.state.courseType === 'Camp'}
                        />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Button primary onClick={() => this.getClasses()} disabled={this.state.courseDetailId.length === 0 || this.state.isEditMode}>Search</Button>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Button positive onClick={() => this.getClasses(true)} disabled={this.state.courseDetailId.length === 0 || this.state.isEditMode || !this.isPrivate()}>Create</Button>
                        <p>ใช้สร้างคลาสใหม่ สำหรับ private class</p> 
                    </Grid.Column>
                </Grid>
                <Divider horizontal>Summary</Divider>
                {selectedCourseDetail && selectedCourseDetail.isFixPrice ? this.renderSummaryForFixPrice(selectedCourseDetail, selectedCount) : this.renderSummary(selectedCourseDetail, selectedCount)}
            </React.Fragment>
        )
    }

    handlePrivateDataChange = (classId, dataKey) => (e, { value }) => {
        let classes = this.state.classes
        let classIdx = classes.map(clazz => clazz.classId).indexOf(classId)
        let clazz = classes[classIdx]
        clazz[dataKey] = value
        classes[classIdx] = clazz
        classes.sort((a, b) => {
            return (a.classDate || '9999999999').localeCompare(b.classDate || '9999999999')
        })
        this.setState({ classes: classes })
    }

    renderRightSectionForCreatePrivate() {
        const classes = this.state.classes
        const selectedCourseDetail = this.filteredCourseDetails.find( data => data.courseDetailId === this.state.courseDetailId )
        return (
            <div>
                <div style={styles.classesContainer}>
                    { classes.length === 0 && this.state.isSearchClass ? <Message content='No Class Found'/> :
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.Cell textAlign='center' width={1}></Table.Cell>
                                    <Table.Cell textAlign='center' width={6}>Date</Table.Cell>
                                    <Table.Cell textAlign='center' width={3}>Start</Table.Cell>
                                    <Table.Cell textAlign='center' width={3}>End</Table.Cell>
                                    <Table.Cell textAlign='center' width={3}>Size</Table.Cell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {classes.map( (clazz) => {
                                    return (
                                        <Table.Row key={clazz.classId}>
                                            <Table.Cell width={1}>
                                                <Checkbox 
                                                    disabled={true}
                                                    checked={clazz.selected} 
                                                    onChange={this.handleSelectClassClick(clazz.classId)}/>
                                            </Table.Cell>
                                            <Table.Cell width={7}>
                                                <Input type='date' size='mini'
                                                    value={formatDate(clazz.classDate, 'YYYY-MM-DD')} 
                                                    onChange={this.handlePrivateDataChange(clazz.classId, 'classDate')} 
                                                    label={clazz.classDate && { color: getColorFromDow(clazz.classDate), size: 'mini' }}
                                                    labelPosition='right corner'
                                                />
                                            </Table.Cell>
                                            <Table.Cell width={3}>
                                                <Input fluid size='mini'
                                                    value={clazz.startTime}
                                                    onChange={this.handlePrivateDataChange(clazz.classId, 'startTime')}
                                                />
                                            </Table.Cell>
                                            <Table.Cell width={3}>
                                                <Input fluid size='mini'
                                                    value={clazz.endTime}
                                                    onChange={this.handlePrivateDataChange(clazz.classId, 'endTime')}
                                                />
                                            </Table.Cell>
                                            <Table.Cell width={2}>
                                                <Input fluid size='mini'
                                                    value={clazz.classSize}
                                                    onChange={this.handlePrivateDataChange(clazz.classId, 'classSize')}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                            </Table.Body>
                        </Table>
                    }
                </div>
                <br/>
                { selectedCourseDetail && selectedCourseDetail.isFixPrice &&
                    <Grid columns={2}>
                        <Grid.Column>
                            <Form.Dropdown
                                placeholder='Teacher'
                                fluid
                                selection
                                search
                                value={this.state.teacherId}
                                onChange={this.handleTeacherChange}
                                options={(this.state.teachers || []).map(teacher => ({ key: teacher.id, value: teacher.id, text: teacher.name }))}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Dropdown
                                placeholder='Room'
                                selection
                                fluid
                                search
                                value={this.state.roomId}
                                onChange={this.handleRoomChange}
                                options={(this.state.rooms || []).map(room => ({ key: room.roomId, value: room.roomId, text: room.name }))}
                            />
                        </Grid.Column>
                    </Grid>
                }
            </div>
        )
    }

    renderRightSection() {
        if (this.isCreateNewPrivate()) return this.renderRightSectionForCreatePrivate()
        
        const selectedCount = this.state.classes.filter(clazz => { return clazz.selected }).length
        const filterClasses = this.filterClasses()
        return (
            <div>
                <Form>
                    <Form.Group inline>
                        <Form.Radio
                            label='Show All'
                            name='classFiltering'
                            value='showAll'
                            checked={this.state.classFiltering === 'showAll'}
                            onChange={this.handleChange('classFiltering')}
                        />
                        <Form.Radio
                            label='Not Full'
                            name='classFiltering'
                            value='notFull'
                            checked={this.state.classFiltering === 'notFull'}
                            onChange={this.handleChange('classFiltering')}
                        />
                        <Form.Checkbox
                            label='Show only selected'
                            fitted
                            checked={this.state.showSelected}
                            onChange={this.handleShowSelectedClick}
                        />
                        <Label>{selectedCount}/{this.state.classCount || 0}</Label>
                    </Form.Group>
                </Form>
                <div style={styles.classesContainer}>
                    { filterClasses.length === 0 && this.state.isSearchClass ? <Message content='No Class Found'/> :
                        <Table celled>
                            <Table.Body>
                                {filterClasses.map( clazz => {
                                    const isDisabled = clazz.isDup > 0
                                    return (
                                        <Table.Row key={clazz.classId} disabled={isDisabled}>
                                            <Table.Cell>
                                                <Checkbox 
                                                    disabled={isDisabled}
                                                    checked={clazz.selected} 
                                                    onChange={this.handleSelectClassClick(clazz.classId)}/>
                                            </Table.Cell>
                                            <Table.Cell>{clazz.classDate && moment(clazz.classDate, 'YYYY-MM-DD').format('YYYY-MM-DD (ddd)')}</Table.Cell>
                                            <Table.Cell>{clazz.startTime && clazz.endTime && `${clazz.startTime.slice(0, -3)} - ${clazz.endTime.slice(0, -3)}`}</Table.Cell>
                                            {this.isCamp() && <Table.Cell>{clazz.classGroup}</Table.Cell>}
                                            <Table.Cell>{clazz.studentCount}/{clazz.classSize}</Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                            </Table.Body>
                        </Table>
                    }
                </div>
            </div>
        )

    }

    render() {
        const selectedCount = this.state.classes.filter(clazz => { return clazz.selected }).length
        return (
            <Modal open={this.props.open} onClose={this.close} size='large'>
                <Modal.Content>
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                {this.renderLeftSection()}
                            </Grid.Column>
                            <Grid.Column>
                                {this.renderRightSection()}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={this.close}>Cancel</Button>
                    <Button positive onClick={this.doSave} disabled={selectedCount <= 0}>{this.state.isEditMode ? 'Save' : 'Add'}</Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

export default SelectClassModal

const COURSE_TYPE_OPTIONS = Object.values(COURSE_TYPE).map( option => ({ key: option, value: option, text: option }) )