export default {
    classesContainer: {
        maxHeight: '470px',
        minHeight: '470px',
        height: '470px',
        marginTop: '5px',
        overflow: 'auto'
    },
    noPadding: {
        padding: '0'
    }
}