import React, {Component} from 'react'
import {Button, Grid, Icon, Label, Pagination, Table} from 'semantic-ui-react'
import {withRouter} from 'react-router'
import * as CONST from "../../common/const"
import moment from 'moment'

class ScheduleListMobile extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isAdmin: props.isAdmin,
            deadline: props.deadline,
            isPrivateClass: props.isPrivateClass,
            tableData: props.tableData,
            tableCurrentColumn: props.sorting.column,
            tableDirection: props.sorting.direction,
            activePage: props.activePage || 1,
            totalPages: props.totalPages || 0,
            totalData: props.totalData || 0,
            pageSize: props.pageSize || 0
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.tableData !== state.tableData) {
            return {
                isAdmin: props.isAdmin,
                deadline: props.deadline,
                tableData: props.tableData,
                isPrivateClass: props.isPrivateClass,
                tableCurrentColumn: props.sorting.column,
                tableDirection: props.sorting.direction,
                activePage: props.activePage || 1,
                totalPages: props.totalPages || 0,
                totalData: props.totalData || 0,
                pageSize: props.pageSize || 0,
            }
        }
        return null
    }

    handlePaginationChange = (e, data) => {
        this.props.onPaginationChange(data.activePage)
    }

    handleSort = clickedColumn => {
        let tableDirection = 'asc'
        if (this.state.tableCurrentColumn === clickedColumn) {
            tableDirection = this.state.tableDirection === 'asc' ? 'desc' : 'asc'
        }
        this.props.onSortingChange(clickedColumn, tableDirection)
    }

    handleChangeStatus = (data, status) => {
        this.props.onChangeStatus(data, status)
    }

    handleAddPrivateClass = () => {
        this.props.onAddPrivateClass()
    }

    render() {
        const {tableCurrentColumn, tableDirection, tableData, totalPages, activePage, isPrivateClass, pageSize, isAdmin, deadline} = this.state
        const status = CONST.STUDENT_CLASS_STATUS
        const statusText = CONST.STUDENT_CLASS_STATUS_TEXT
        const icon = CONST.BUTTON_ICON

        return (
            <div>
                <Table size='small' celled sortable style={{fontSize: '.8em'}}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell textAlign='center' width={2}
                                              sorted={tableCurrentColumn === 'classDate' ? convertSorting(tableDirection) : null}
                                              onClick={() => this.handleSort('classDate')}>
                                Date/Time</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width={2}>Branch</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width={2}>Course Name</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {!tableData || tableData.length == 0 ?
                            <Table.Row><Table.Cell verticalAlign='middle' warning textAlign='center' colSpan='9'
                                                   style={{width: '1000px'}}>No Data Found</Table.Cell>
                            </Table.Row> :
                            tableData.map((item, index) => (
                                <Table.Row key={index} style={{
                                    backgroundColor: item.nextClass === 1 ? '#00B3C5' : '',
                                    color: item.nextClass === 1 ? '#FFFFFF' : ''
                                }}>
                                    <Table.Cell verticalAlign='middle' textAlign='left'>
                                        #{((activePage - 1) * pageSize) + 1 + index} {item.date} / {item.time + ' - ' + item.endTime}
                                    </Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign='left'>
                                        {item.branchName}
                                    </Table.Cell>
                                    
                                    {isAdmin ?
                                        <Table.Cell verticalAlign='middle' textAlign='left'>{item.courseName}
                                            {item.status === status.ATTENDED ?
                                                <Label size={'tiny'} style={{float: 'right'}} color='green'>
                                                    {item.status}</Label> : null}
                                            {item.status === status.ACTIVE ?
                                                <Label size={'tiny'} style={{float: 'right'}} color='blue'>
                                                    {item.status}</Label> : null}
                                            {item.status === status.CONFIRMED ?
                                                <Label size={'tiny'} style={{float: 'right'}} color='yellow'>
                                                    {item.status}</Label> : null}
                                            {item.status === status.PENDING || item.status === status.UNSCHEDULE ?
                                                <Label size={'tiny'} style={{float: 'right'}}>
                                                    {item.status}</Label> : null}
                                            {item.status === status.CANCEL ?
                                                <Label size={'tiny'} style={{float: 'right'}} color='grey'>
                                                    {item.status}</Label> : null}
                                            {item.status === status.REFUND ?
                                                <Label size={'tiny'} style={{float: 'right'}} color='black'>
                                                    {item.status}</Label> : null}
                                            </Table.Cell> 
                                        : <Table.Cell verticalAlign='middle' textAlign='left'>{item.courseName}
                                            {item.status === status.ATTENDED ?
                                                <Label size={'tiny'} style={{float: 'right'}} color='green'>
                                                    {status.ATTENDED}</Label> : null}
                                            {item.status === status.ACTIVE || item.status === status.CONFIRMED ?
                                                <Label size={'tiny'} style={{float: 'right'}} color='blue'>
                                                    {status.ACTIVE}</Label> : null}
                                            {item.status === status.PENDING || item.status === status.UNSCHEDULE ?
                                                <Label size={'tiny'} style={{float: 'right'}}>
                                                    {item.status}</Label> : null}
                                            {item.status === status.CANCEL ?
                                                <Label size={'tiny'} style={{float: 'right'}} color='grey'>
                                                    {item.status}</Label> : null}
                                            {item.status === status.REFUND ?
                                                <Label size={'tiny'} style={{float: 'right'}} color='black'>
                                                    {item.status}</Label> : null}
                                            </Table.Cell> 
                                    }
                                    {isAdmin  && moment(deadline).isBefore(item.date)?
                                        (
                                            <Table.Cell verticalAlign='middle' textAlign='center'
                                                        style={{whiteSpace: 'nowrap'}}>
                                                {item.status === status.ACTIVE ?
                                                    <div>
                                                        <Button icon compact color='yellow'
                                                                onClick={() => this.handleChangeStatus(item, status.CONFIRMED)}>
                                                            <Icon name={icon.CONFIRM}/>
                                                        </Button>
                                                        <Button icon compact negative
                                                                //disabled={item.courseType !== CONST.COURSE_TYPE.CORE_COURSE}
                                                                onClick={() => this.handleChangeStatus(item, status.CANCEL)}>
                                                            <Icon name={icon.CANCEL}/>
                                                        </Button>
                                                        {item.courseType === 'Core Course' ?
                                                            <Button icon compact size='mini'
                                                                    onClick={() => this.handleChangeStatus(item, status.SKIP)}>
                                                                <Icon name={icon.SKIP}/>
                                                            </Button> : null
                                                        }
                                                    </div>
                                                    : null}
                                                {item.status === status.CONFIRMED ?
                                                    <div>
                                                        <Button icon compact positive
                                                                onClick={() => this.handleChangeStatus(item, status.ATTENDED)}>
                                                            <Icon name={icon.ATTEND}/>
                                                        </Button>
                                                        <Button icon compact negative
                                                                //disabled={item.courseType !== CONST.COURSE_TYPE.CORE_COURSE}
                                                                onClick={() => this.handleChangeStatus(item, status.CANCEL)}>
                                                            <Icon name={icon.CANCEL}/>
                                                        </Button>
                                                        <Button icon compact positive
                                                                onClick={() => this.handleChangeStatus(item, status.ACTIVE)}>
                                                            <Icon name={icon.ACTIVE}/>
                                                        </Button>   
                                                    </div>
                                                    : null}
                                                {item.status === status.ATTENDED ?
                                                    <div>
                                                        <Button icon compact negative
                                                                //disabled={item.courseType !== CONST.COURSE_TYPE.CORE_COURSE}
                                                                onClick={() => this.handleChangeStatus(item, status.CANCEL)}>
                                                            <Icon name={icon.CANCEL}/>
                                                        </Button>
                                                        <Button icon compact positive
                                                                onClick={() => this.handleChangeStatus(item, status.ACTIVE)}>
                                                            <Icon name={icon.ACTIVE}/>
                                                        </Button>   
                                                    </div>
                                                    : null}
                                                {item.status === status.CANCEL ?
                                                    <div>
                                                        <Button icon compact positive
                                                                onClick={() => this.handleChangeStatus(item, status.ATTENDED)}>
                                                            <Icon name={icon.ATTEND}/>
                                                        </Button>

                                                        <Button icon compact color='blue' size='mini'
                                                                onClick={() => this.handleChangeStatus(item, status.ACTIVE)}>
                                                            <Icon name={icon.ACTIVE}/>
                                                        </Button>                                                        
                                                    </div>
                                                    : null}
                                                {item.status === status.PENDING ?
                                                    <div>
                                                        <Button icon compact color='blue' size='mini'
                                                                onClick={() => this.handleChangeStatus(item, status.ACTIVE)}>
                                                            <Icon name={icon.ACTIVE}/>
                                                        </Button>                                                        
                                                    </div>
                                                    : null}
                                            </Table.Cell>
                                        ) : null
                                    }
                                    {isAdmin  && moment(deadline).isBefore(item.date)?
                                        (
                                            <Table.Cell verticalAlign='middle' textAlign='center'
                                                        style={{whiteSpace: 'nowrap'}}>
                                                <Button icon compact color='grey'
                                                        disabled={item.courseType !== CONST.COURSE_TYPE.CORE_COURSE || (item.status !== status.ACTIVE && item.status !== status.CONFIRMED && item.status !== status.PENDING && item.status !== status.UNSCHEDULE)}
                                                        onClick={() => this.handleChangeStatus(item, 'EDIT')}>
                                                    <Icon name={icon.EDIT}/>
                                                </Button>
                                            </Table.Cell>
                                        ) : null
                                    }
                                </Table.Row>
                            ))
                        }
                    </Table.Body>
                </Table>
                {isPrivateClass ? 
                    (<Grid>
                        <Grid.Row columns={1}>
                            <Grid.Column textAlign='left' verticalAlign='middle'>
                                <Button icon compact primary
                                    onClick={() => this.handleAddPrivateClass()}>
                                    Add Private Class 
                                </Button>
                            </Grid.Column>
                            <Grid.Column/>
                        </Grid.Row>
                    </Grid>)
                : null}
                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column textAlign='right' verticalAlign='middle'>
                            {totalPages < 1 || !totalPages
                                ? null
                                : <Pagination
                                    activePage={activePage}
                                    boundaryRange={'0'}
                                    onPageChange={this.handlePaginationChange}
                                    size='mini'
                                    siblingRange={'0'}
                                    totalPages={totalPages}
                                    ellipsisItem={null}
                                    firstItem={'<<'}
                                    lastItem={'>>'}
                                    prevItem={'<'}
                                    nextItem={'>'}
                                />
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}

export default withRouter(ScheduleListMobile)

const convertSorting = type => {
    return type === 'asc' ? 'ascending' : 'descending'
}
