export default (theme) => ({
    container: {
        backgroundColor: theme.palette.grey[200],
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    logoContainer: {
        backgroundColor: theme.palette.primary.darkest,
        height: 'calc(100% + 1em)',
        width: '90%',
        padding: '1em',
        zIndex: 1,
        position: 'relative'
    },
    controllerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    toolBar: {
        justifyContent: 'flex-end',
        padding: '0'
    },
    menuBar: {
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        padding: '0',
        margin: '0 0 0 auto'
    },
    languageSelector: {
        margin: '0 2em',
        display: 'flex'
    },
    languageButton: {
        padding: '0.1em 0.3em',
        margin: '0',
        minHeight: '0',
        minWidth: '0'
    },
    seperator: {
        display: 'inline-block',
        width: '2px',
        backgroundColor: theme.palette.grey[500],
        margin: '0.25em 0.5em'
    },
    menuLink: {
        '&:hover': {
			color: theme.palette.primary.main
		}
    },
    menuButton: {
        '&:hover': {
			color: theme.palette.grey[50]
		}
    }
});