import React from 'react'
import {
    Button,
    Checkbox,
    Dimmer,
    Divider,
    Dropdown,
    Form,
    Grid,
    Header,
    Label,
    List,
    Loader,
    Modal,
    Segment,
    Message
} from 'semantic-ui-react'
import {formatDateDisplay, formatTimeDisplay, getCurrentUser,} from '../../../common/util'
import {DOWS_TEXT, STUDENT_CLASS_STATUS} from '../../../common/const'
import apiClient from '../../../apis'
import styles from './styles'
import {withRouter} from 'react-router'

class ClassDetailModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: {},
            teacherList: [],
            roomList: []
        }
        this.getTeacherList()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.data.branchId && prevState.data.classId !== this.state.data.classId) {
            this.getRoomList(this.state.data.branchId)
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.data.classId !== state.data.classId) {
            let taIds = []
            if (props.data.taIds) {
                taIds = props.data.taIds.split(',').map(Number)
            }
            let isUseTA = props.data.studentCount >= 5 || props.data.taIds ? true : false
            return {
                data: {...props.data, taIds: taIds, isUseTA: isUseTA},
                errorMsg: undefined,
                isLoading: false,
            }
        }
        return {}
    }

    getTeacherList() {
        this.setState({
            isLoading: true,
        })
        apiClient.teacher.getTeacherList().then((res) => {
            if (res !== null) {
                let arr = res.map((p, index) => {
                    return {
                        key: index,
                        text: p.name,
                        value: p.id,
                    }
                })
                this.setState({
                    teacherList: arr,
                    isLoading: false,
                })
            }
        })
    }

    getRoomList(branchId) {
        this.setState({
            isLoading: true
        })
        apiClient.room.getRoomList(branchId)
            .then(res => {
                if (res !== null) {
                    let arr = res.map((p, index) => {
                        return {
                            key: index,
                            text: p.name,
                            value: p.id
                        }
                    })
                    this.setState({
                        roomList: arr,
                        isLoading: false
                    })
                }
            })
    }

    close = () => {
        this.props.onClose()
    }

    doSave = async () => {
        this.setState({isLoading: true})
        let currentUser = getCurrentUser()

        let form = {
            classId: this.state.data.classId,
            teacherId: this.state.data.teacherId,
            taIds: this.state.data.isUseTA ? this.state.data.taIds : [],
            roomId: this.state.data.roomId,
            userId: currentUser.userId
        }
        let result = await apiClient.teacher.setClassTeacher(form)
        if (result.status === 'success') {
            result = await apiClient.room.setClassRoom(form)
            if (result.status === 'success') {
                this.props.onClose(form)
            } else {
                this.setState({isLoading: false, errorMsg: result.message})
            }
        } else {
            this.setState({isLoading: false, errorMsg: result.message})
        }
    }

    validateData = async () => {
        if (!this.state.data.teacherId) {
            this.setState({errorMsg: 'Please select teacher.'})
        } else if (this.state.data.taIds.length > 0 && this.state.data.taIds.indexOf(this.state.data.teacherId) !== -1) {
            this.setState({errorMsg: 'Teacher assistant duplicate with main teacher.'})
        } else if (!this.state.data.roomId) {
            this.setState({errorMsg: 'Please select room.'})
        } else {
            this.doSave()
        }
    }

    handleDropDownChange = (name) => (event, {value}) => {
        this.setState({
            error: {},
            errorMsg: '',
            data: {
                ...this.state.data,
                [name]: value,
            },
        })
    }

    handleCheckboxChange = (name) => event => {
        const changeValue = !this.state.data[name]
        this.setState({
            error: {},
            errorMsg: '',
            data: {
                ...this.state.data,
                [name]: changeValue,
            }
        })
    }

    render() {
        return (
            <Modal                
                open={this.props.open}
                onClose={this.close}
                closeOnDimmerClick={false}>
                <Modal.Header>Class Information</Modal.Header>
                <Modal.Content>
                    <Form size='large'>
                        <Grid centered>
                            <Grid.Column mobile={16} computer={9}>
                                <Segment.Group>
                                    <Segment>
                                        <Grid.Row>
                                            <Header as='h3'>
                                                {this.state.data.courseName}
                                                <Label
                                                    pointing='left'
                                                    content={
                                                        this.state.data.classId
                                                    }
                                                />
                                            </Header>
                                           
                                        </Grid.Row>
                                        </Segment>
                                        <Segment>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Grid.Row>
                                                    <label>
                                                        {DOWS_TEXT[
                                                            new Date(
                                                                this.state.data.start
                                                            ).getDay()
                                                        ] + ' '}
                                                        {formatDateDisplay(
                                                            this.state.data.start
                                                        )}
                                                    </label>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <label>
                                                        {formatTimeDisplay(
                                                            this.state.data.start
                                                        ) +
                                                            '-' +
                                                            formatTimeDisplay(
                                                                this.state.data.end
                                                            )}
                                                    </label>
                                                </Grid.Row>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row color='red'>

                                                <label>Student</label>
                                                <Label
                                                    size='small'
                                                    horizontal
                                                    style={{marginLeft: '10px'}}>
                                                    {this.state.data.studentCount} /{' '}
                                                    {this.state.data.classSize}
                                                </Label>       
                                        </Grid.Row>
                                        </Segment>
                                        <Segment>
                                            <List divided relaxed>
                                                <List.Item>
                                                    <List.Icon name='angle double right' verticalAlign='middle' color='blue'/>
                                                    <List.Content>
                                                        <List.Header>
                                                            {STUDENT_CLASS_STATUS.ACTIVE} ({this.state.data.studentActiveCount})
                                                        </List.Header>
                                                        <List.Description > {this.state.data.studentActiveName}</List.Description>
                                                    </List.Content>
                                                </List.Item>
                                                <List.Item>
                                                    <List.Icon name='checked calendar' verticalAlign='middle' color='yellow'/>
                                                    <List.Content>
                                                        <List.Header>
                                                            {STUDENT_CLASS_STATUS.CONFIRMED} ({this.state.data.studentConfirmCount})
                                                        </List.Header>
                                                        <List.Description > {this.state.data.studentConfirmName}</List.Description>
                                                    </List.Content>
                                                </List.Item>
                                                <List.Item>
                                                    <List.Icon name='sign in' verticalAlign='middle' color='green'/>
                                                    <List.Content>
                                                        <List.Header>
                                                            {STUDENT_CLASS_STATUS.ATTENDED} ({this.state.data.studentAttendCount})
                                                        </List.Header>
                                                        <List.Description > {this.state.data.studentAttendName}</List.Description>
                                                    </List.Content>
                                                </List.Item>
                                            </List>
                                        </Segment>
                                </Segment.Group>
                            </Grid.Column>
                        
                            <Grid.Column mobile={16} computer={7}>
                                <Grid.Row>
                                    <Form size='large'>
                                        <Form.Field>
                                            <label>
                                                Teacher
                                            </label>
                                            <Dropdown
                                                fluid
                                                search
                                                selection
                                                name='teacherId'
                                                placeholder='Main Teacher'
                                                onChange={this.handleDropDownChange(
                                                    'teacherId'
                                                )}
                                                value={this.state.data.teacherId}
                                                options={this.state.teacherList}
                                            />
                                            </Form.Field>
                                        <Form.Field>
                                        <Checkbox
                                            label={<label style={styles.formLabel}> Use Teacher Assistant</label>}
                                            checked={this.state.data.isUseTA}
                                            onChange={this.handleCheckboxChange('isUseTA')}/>
                                            <Dropdown
                                                fluid
                                                search
                                                selection
                                                multiple
                                                name='taIds'
                                                placeholder='Teacher Assistant'
                                                disabled={!this.state.data.isUseTA}
                                                onChange={this.handleDropDownChange(
                                                    'taIds'
                                                )}
                                                value={this.state.data.taIds} options={this.state.teacherList}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Room</label>
                                            <Dropdown
                                                fluid search selection
                                                name='roomId' placeholder='Room'
                                                onChange={this.handleDropDownChange('roomId')}
                                                value={this.state.data.roomId} options={this.state.roomList}
                                            />
                                        </Form.Field>
                                    </Form>
                                </Grid.Row>
                            </Grid.Column>
                        </Grid>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    {this.state.errorMsg && (
                        <Message
                            error
                            compact
                            size='tiny'
                            style={styles.errorMessageContainer} 
                            content={this.state.errorMsg}
                        />
                    )}
                    <Button negative onClick={this.close} content='Cancel' />
                    <Button
                        positive
                        icon='checkmark'
                        labelPosition='right'
                        content='Save'
                        onClick={this.validateData}
                    />
                    
                </Modal.Actions>
                <Dimmer inverted active={this.state.isLoading}>
                    <Loader/>
                </Dimmer>
            </Modal>
        )
    }
}

export default withRouter(ClassDetailModal)
