import {postRequest, getRequest} from '../lib/request'

export function getRoomByBranchId(branchId) {
    return getRequest(`/room/branch/${branchId}`)
}

export function getRoomList(branchId) {
    const data = {
        branchId: branchId
    }

    return getRequest(`/room/getRoomList`, data)
}

export function setClassRoom(form) {
    const data = {
        classId: form.classId,
        roomId: form.roomId,
    }

    return postRequest(`/room/setClassRoom`, data)
}
