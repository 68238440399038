export default (theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    photoTileContainer: {
        [theme.breakpoints.down('sm')]: {
            margin: '4px 2px'
        }

    },
})