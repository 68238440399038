import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import { CardMedia, Grid } from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import AccessTimeIcon from '@material-ui/icons/WatchLater'
import ChatBubbleIcon from '@material-ui/icons/ChatBubble'
import SvgIcon from '@material-ui/core/SvgIcon'
import { mdiFacebookBox } from '@mdi/js'
import { COMPANY_INFO, WEB_VERSION } from '../../common/const'

const lineSvg =
    'M 9 4 C 6.24 4 4 6.24 4 9 L 4 41 C 4 43.76 6.24 46 9 46 L 41 46 C 43.76 46 46 43.76 46 41 L 46 9 C 46 6.24 43.76 4 41 4 L 9 4 z M 25 11 C 33.27 11 40 16.359219 40 22.949219 C 40 25.579219 38.959297 27.960781 36.779297 30.300781 C 35.209297 32.080781 32.660547 34.040156 30.310547 35.660156 C 27.960547 37.260156 25.8 38.519609 25 38.849609 C 24.68 38.979609 24.44 39.039062 24.25 39.039062 C 23.59 39.039062 23.649219 38.340781 23.699219 38.050781 C 23.739219 37.830781 23.919922 36.789063 23.919922 36.789062 C 23.969922 36.419063 24.019141 35.830937 23.869141 35.460938 C 23.699141 35.050938 23.029062 34.840234 22.539062 34.740234 C 15.339063 33.800234 10 28.849219 10 22.949219 C 10 16.359219 16.73 11 25 11 z M 23.992188 18.998047 C 23.488379 19.007393 23 19.391875 23 20 L 23 26 C 23 26.552 23.448 27 24 27 C 24.552 27 25 26.552 25 26 L 25 23.121094 L 27.185547 26.580078 C 27.751547 27.372078 29 26.973 29 26 L 29 20 C 29 19.448 28.552 19 28 19 C 27.448 19 27 19.448 27 20 L 27 23 L 24.814453 19.419922 C 24.602203 19.122922 24.294473 18.992439 23.992188 18.998047 z M 15 19 C 14.448 19 14 19.448 14 20 L 14 26 C 14 26.552 14.448 27 15 27 L 18 27 C 18.552 27 19 26.552 19 26 C 19 25.448 18.552 25 18 25 L 16 25 L 16 20 C 16 19.448 15.552 19 15 19 z M 21 19 C 20.448 19 20 19.448 20 20 L 20 26 C 20 26.552 20.448 27 21 27 C 21.552 27 22 26.552 22 26 L 22 20 C 22 19.448 21.552 19 21 19 z M 31 19 C 30.448 19 30 19.448 30 20 L 30 26 C 30 26.552 30.448 27 31 27 L 34 27 C 34.552 27 35 26.552 35 26 C 35 25.448 34.552 25 34 25 L 32 25 L 32 24 L 34 24 C 34.553 24 35 23.552 35 23 C 35 22.448 34.553 22 34 22 L 32 22 L 32 21 L 34 21 C 34.552 21 35 20.552 35 20 C 35 19.448 34.552 19 34 19 L 31 19 z'

const Footer = ({ classes, t }) => {
    return (
        <Grid container className={classes.container}>
            <Grid item md={3} xs={12} className={classes.logoContainer}>
                <CardMedia
                    className={classes.logo}
                    component='img'
                    image={`${process.env.PUBLIC_URL}/images/logo_bw_300.png`}
                    title={t('common:company.name')}
                />
            </Grid>
            <Grid item md={3} xs={12} className={classes.contentContainer}>
                <a
                    href={COMPANY_INFO.LINK.GOOGLE_MAP}
                    className={`${classes.headerContainer} ${classes.link}`}
                    target='_blank'>
                    <LocationOnIcon className={classes.icon} />
                    {t('location')}
                </a>
                <div>{t('common:company.address.line1')}</div>
                <div>{t('common:company.address.line2')}</div>
                <div>{t('common:company.address.line3')}</div>
                <div>{t('common:company.address.line4')}</div>
            </Grid>
            <Grid item md={3} xs={12} className={classes.contentContainer}>
                <div className={classes.headerContainer}>
                    <AccessTimeIcon className={classes.icon} />
                    {t('openHour')}
                </div>
                <div className={classes.content}>
                    {t('common:company.openHour.weekDay')}
                </div>
                <div className={classes.content}>
                    {t('common:company.openHour.weekEnd')}
                </div>
            </Grid>
            <Grid item md={3} xs={12} className={classes.contentContainer}>
                <div className={classes.headerContainer}>
                    <ChatBubbleIcon className={classes.icon} />
                    {t('contact')}
                </div>
                <div>{COMPANY_INFO.TEL}</div>
                <div>
                    <a
                        className={classes.link}
                        href={'mailto:' + COMPANY_INFO.EMAIL}>
                        {COMPANY_INFO.EMAIL}
                    </a>
                </div>
                <div>
                    <a
                        href={COMPANY_INFO.LINK.FACEBOOK}
                        className={classes.link}
                        target='_blank'>
                        <SvgIcon
                            className={`${classes.icon} ${classes.brandIcon}`}>
                            <path d={mdiFacebookBox} />
                        </SvgIcon>
                    </a>
                    {/* <a href={COMPANY_INFO.LINK.TWITTER} className={classes.link} target='_blank'>
                        <SvgIcon className={`${classes.icon} ${classes.brandIcon}`}>
                            <path d={mdiTwitterBox} />
                        </SvgIcon>
                    </a> */}
                    <a
                        href={COMPANY_INFO.LINK.LINE}
                        className={classes.link}
                        target='_blank'>
                        <SvgIcon
                            className={`${classes.icon} ${classes.brandIcon} ${classes.lineBrandIcon}`}
                            viewBox='0 0 50 50'>
                            <path d={lineSvg} />
                        </SvgIcon>
                    </a>
                    {/* <a href={COMPANY_INFO.LINK.INSTAGRAM} className={classes.link} target='_blank'>
                        <SvgIcon className={`${classes.icon} ${classes.brandIcon}`} >
                            <path d={mdiInstagram} />
                        </SvgIcon>
                    </a> */}
                </div>
            </Grid>
            <Grid>
                <div className={classes.webVersionContainer}>{WEB_VERSION.VERSION}</div>
            </Grid>
        </Grid>
    )
}

export default withTranslation('footer')(withStyles(styles)(Footer))
