import React from 'react'
import { Accordion, Button, Card, Dropdown, Form, Grid, Icon, Input } from 'semantic-ui-react'
import { withRouter } from 'react-router'
import { withI18next } from '../../../lib/withI18next'
import apiClient from '../../../apis'
import { styles } from './styles'
import MainLayout from '../../../layouts/MainLayout'
import StudentList from './StudentList'
import { DEFAULT_PAGE_SIZE } from '../../../common/const'
import { getUserBranchId } from '../../../common/util'

class StudentSearch extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            searchData: {
                name: '',
                studentId: '',
                school: '',
                mobileNumber: '',
                ads: '',
                orderBy: 'studentId',
                orderType: 'desc',
                page: 1
            },
            total: undefined,
            tableData: undefined,
            isLoading: false,
            roleOptions: undefined,
            isShowTool: true
        }
        this.handleSortingChange = this.handleSortingChange.bind(this)
        this.handlePaginationChange = this.handlePaginationChange.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    componentDidMount() {
        this.searchStudent()
        this.getSchoolList()
    }

    searchStudent = () => {
        this.setState({
            isLoading: true
        })
        const data = this.state.searchData
        let searchData = {}
        for (const key in data) {
            if (data[key] !== '' && data[key] !== null && data[key] !== undefined && data[key] !== 'undefined') {
                searchData[key] = data[key]
            }
        }
        const branchId = getUserBranchId()
        apiClient.student.searchStudentByBranchId(searchData, branchId)
            .then(res => {
                this.setState({
                    tableData: res.dataList,
                    total: res.total,
                    isLoading: false
                })
            })
    }

    getSchoolList = () => {
        this.setState({
            isLoading: true
        })

        apiClient.student.getSchoolList()
            .then(res => {
                if (res !== null) {
                    let arr = res.map((p, index) => {
                        return {
                            key: index,
                            text: p.name,
                            value: p.name
                        }
                    })
                    arr.unshift({key: -1, text: 'All', value: ''})
                    this.setState({
                        schoolList: arr,
                        isLoading: false
                    })
                }
            })
    }

    handlePaginationChange = activePage => {
        this.setState({
            searchData: {
                ...this.state.searchData,
                page: activePage,
            }
        }, this.searchStudent)
    }

    searchByInitialSetting = () => {
        this.setState({
            searchData: {
                ...this.state.searchData,
                orderBy: 'studentId',
                orderType: 'desc',
                page: 1
            }
        }, this.searchStudent)
    }

    handleInputChange = (e, value) => {
        this.setState({
            searchData: {
                ...this.state.searchData,
                [value.id]: value.value
            }
        })
    }

    handleSortingChange = (orderBy, orderType) => {
        this.setState({
            searchData: {
                ...this.state.searchData,
                orderBy: orderBy,
                orderType: orderType
            }
        }, this.searchStudent)
    }

    renderSearchCard = () => {
        const { name, studentId, school, mobileNumber, ads } = this.state.searchData

        return (
            <Card fluid>
                <Card.Content>
                    <Form fluid>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Name</label>
                                <Input id='name' fluid placeholder='' type='text' value={name}
                                    onChange={this.handleInputChange}/>
                            </Form.Field>
                            <Form.Field>
                                <label>Student ID</label>
                                <Input id='studentId' fluid placeholder='' type='text' value={studentId}
                                    onChange={this.handleInputChange}/>
                            </Form.Field>
                            <Form.Field>
                                <label>School</label>
                                <Dropdown id='school' fluid search selection placeholder='All'
                                    options={this.state.schoolList} value={school}
                                    onChange={this.handleInputChange}/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Mobile Number</label>
                                <Input id='mobileNumber' fluid placeholder='' type='text' value={mobileNumber}
                                    onChange={this.handleInputChange}/>
                            </Form.Field>
                            <Form.Field>
                                <label>ADS</label>
                                <Dropdown id='ads' fluid placeholder='All' selection options={adsOptions} value={ads}
                                        onChange={this.handleInputChange}/>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Card.Content>
                <Card.Content extra textAlign='right'>
                    <Button primary content='Search' onClick={() => this.searchByInitialSetting()} />
                </Card.Content>
            </Card>
        )
    }

    render() {
        const { isShowTool, searchData, tableData, total } = this.state
        console.log(tableData)

        return (
            <MainLayout id='react-no-print'>
                <div style={styles.container}>
                    <Grid centered stackable>
                        <Grid.Row>
                            <Grid.Column>
                                <Accordion fluid>
                                    <Accordion.Title active={isShowTool}
                                                     onClick={() => this.setState({isShowTool: !isShowTool})}>
                                        <Icon name='dropdown'/>
                                        Search
                                    </Accordion.Title>
                                    <Accordion.Content active={isShowTool}>
                                        {this.renderSearchCard()}
                                    </Accordion.Content>
                                </Accordion>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <StudentList
                                    tableData={tableData}
                                    onPaginationChange={this.handlePaginationChange}
                                    onSortingChange={this.handleSortingChange}
                                    totalPages={Math.ceil(total / DEFAULT_PAGE_SIZE)}
                                    totalData={total}
                                    pageSize={DEFAULT_PAGE_SIZE}
                                    activePage={searchData.page}
                                    sorting={{
                                        column: searchData.orderBy,
                                        direction: searchData.orderType
                                    }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </MainLayout>
        )
    }
}

export default withRouter(withI18next()(StudentSearch))

const adsOptions = [
    {key: 'All', text: 'All', value: ''},
    {key: 'Y', text: 'Y', value: '1'},
    {key: 'N', text: 'N', value: '0'}
]