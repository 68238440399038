import {getRequest, postRequest} from '../lib/request'

export function getAllBranches() {
    return getRequest(`/branch`)
}

export function getBranchesByBranchId(branchId) {
    return getRequest(`/branch`, { branchId })
}

export function getBranchList() {
    return getRequest(`/branch/getBranchList`)
}
