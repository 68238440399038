import React, { Component } from 'react'
import { Grid, Icon, Breadcrumb, Container, Image, Table, Card, Label, Message, Popup } from 'semantic-ui-react'
import { withRouter } from 'react-router'
import MainLayout from '../../../layouts/MainLayout'
import apiClient from '../../../apis'
import { formatDateDisplay, formatTimeDisplay } from '../../../common/util'
import styles from './styles'
import _ from 'lodash'
import Spinner from '../../../components/common/Spinner'
import badgeData from './data'
import { STUDENT_CLASS_STATUS } from '../../../common/const'

class AssessmentBadge extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      groupBySkill: true,
      errorMessage: null,
      activeIndexs: [],
      searchParameter: {},
      classStudents: [],
      classes: null,
      classId: props.match.params.classId,
      classStatusCount: 0
    }
  }
  componentDidMount() {
    this.searchClassStudent()
  }

  searchClassStudent = () => {
    this.setState({
      isLoading: true
    })
    const { classId } = this.state
    apiClient.assessment.getClassStudent(classId).then(res => {
      this.setState({
        badges: res.badges,
        classStudents: res.classStudents.filter(s=>s.status === STUDENT_CLASS_STATUS.ATTENDED),
        classes: res.classes,
        isLoading: false,
        classStatusCount: res.classStudents.filter(cs => cs.badgeIds).length
      })
    })
  }

  updateStudentBadge = (studentId, badgeId) => {
    let { classStudents } = this.state
    let student = classStudents.find(s => s.studentId === studentId)
    if (!student.studentBadges.includes(badgeId)) {
      student.studentBadges = [badgeId]
      apiClient.assessment.updateStudentBadge(student).then(res => {
        this.setState({
          classStudents,
          classStatusCount: classStudents.filter(cs => cs.studentBadges && cs.studentBadges.length > 0).length
        })
      }).catch(error => {
        const badge = badgeData.find(b => b.id === badgeId)
        const errorMessage = `Failed to set ${badge.name} badge to ${student.firstName} ${student.lastName} (${student.nickName})`
        this.setState({
          errorMessage
        })
      })
    }
  }

  handleDismiss = () => {
    this.setState({ errorMessage: null })
  }

  renderBreadcrumb = () => {
    const { classes } = this.state
    if (!classes) {
      return (
        <Breadcrumb>
          <Breadcrumb.Section href='/assessment/'>Assessment</Breadcrumb.Section>
        </Breadcrumb>
      )
    }
    const selectedClass = classes[0]
    return (
      <Breadcrumb>
        <Breadcrumb.Section href='/assessment/'>Assessment</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>
          <a href={`/assessment/${selectedClass.classId}`}>{`(${selectedClass.classId}) ${selectedClass.courseName}`}</a>
        </Breadcrumb.Section>
      </Breadcrumb>
    )
  }

  renderClassInfo = () => {
    const { classes, classStatusCount, classStudents } = this.state
    if (!classes) {
      return null
    }
    const selectedClass = classes[0]

    return (
      <Card.Group centered>
        <Card>
          <Card.Content>
            <Card.Header >
              {selectedClass.courseName}
            </Card.Header>
            <Card.Meta>
              <span>
                {formatDateDisplay(selectedClass.classDate)} /{' '}
                {formatTimeDisplay(selectedClass.startTime)} -{' '}
                {formatTimeDisplay(selectedClass.endTime)}
              </span>
            </Card.Meta>
            <Card.Description >
              {!selectedClass.teachers
                ? null
                : selectedClass.teachers.map((teacher, i) => (
                  <span key={i}>
                    <Icon name='user outline'></Icon>{' '}
                    {teacher.nickName} &nbsp;
                  </span>
                ))}
            </Card.Description>
          </Card.Content>
          <Card.Content textAlign='center'>
            <Label
              color={
                classStatusCount === classStudents.length
                  ? 'green'
                  : 'red'
              }
              size='big'
              icon='tasks'
              content={`Assessment Done ${classStatusCount}/${classStudents.length}`}
            />
          </Card.Content>
        </Card>
      </Card.Group>
    )
  }

  renderMessage = () => {
    const { errorMessage } = this.state
    if (errorMessage) {
      return (
        <Message onDismiss={this.errorMessage} compact color='red' header='Error' content={errorMessage} />
      )
    }
  }

  renderTableHeader = () => {
    return (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Student</Table.HeaderCell>
          <Table.HeaderCell>Badges</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    )
  }

  renderTableBody = () => {
    const { classStudents } = this.state

    const students = _.orderBy(classStudents, ['firstName'], ['asc'])
    return (
      <Table.Body>
        {!students || students.length === 0 ?
          <Table.Row>
            <Table.Cell verticalAlign='middle' warning textAlign='center' colSpan='6'>No Data Found</Table.Cell>
          </Table.Row> :
          students.map((student, i) => (
            <Table.Row key={i}>
              <Table.Cell style={styles.studentName}>{student.nickName} ({student.firstName})<br /> Age : {student.age}</Table.Cell>
              <Table.Cell>
                {badgeData.map((badge, i) => (
                  <Popup
                    key={i+badge.name}
                    size='tiny'
                    content={badge.name}
                    trigger={
                      <div style={styles.badge} key={i}>
                        <div style={student.studentBadges.includes(badge.id) ? styles.badgeSelected : null}></div>
                        <Image title={badge.name} style={styles.badge} src={badge.url} onClick={() => this.updateStudentBadge(student.studentId, badge.id)} />
                      </div>}
                  />
                ))}
              </Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    )
  }

  renderTable = () => {
    return (
      <Table basic='very'>
        {this.renderTableHeader()}
        {this.renderTableBody()}
      </Table>
    )
  }

  render() {
    const { isLoading } = this.state
    if (isLoading) {
      return (
        <MainLayout>
          <Spinner />
        </MainLayout>
      )
    }
    return (
      <MainLayout>
        <Container style={styles.container}>
          <Grid centered stackable>
            <Grid.Row>
              <Grid.Column>
                {this.renderBreadcrumb()}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                {this.renderClassInfo()}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                {this.renderMessage()}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                {this.renderTable()}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </MainLayout>
    )
  }
}

export default withRouter(AssessmentBadge)
