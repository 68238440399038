import { postRequest } from '../lib/request'

export function signup(email, password, firstName, lastName, facebookId, mobileNumber, nickName, channel) {
    const data = {
        email: email,
        password: password,
        firstName: firstName,
        lastName: lastName,
        facebookId: facebookId,
        mobileNumber: mobileNumber,
        nickName: nickName,
        channel: channel
    }
    return postRequest(`/signup`, data)
}

export function addStudent(data) {
    return postRequest(`/signup/addStudent`, data)
}

export function changePassword(email) {
    const data = {
        email: email
    }

    return postRequest(`/public/changepassword/request`, data, false)
}

export function resendVerification(email) {
    const data = {
        email: email
    }

    return postRequest(`/public/resendverification`, data, false)
}