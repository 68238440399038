import { postRequest, getRequest } from '../lib/request'

export function getClass(parameters) {
  return getRequest(`/assessment`, parameters)
}

export function getClassStudent(classId) {
  return getRequest(`/assessment/${classId}`)
}

export function updateStudentBadge(student) {
  const data = {
    studentId: student.studentId,
    classId: student.classId,
    badgeIds: student.studentBadges
  }
  return postRequest(`/assessment/${student.classId}`, [data])
}