import React, { Component } from 'react'
import { Button, Grid, Pagination, Table, Message, Card, Confirm, TextArea, Form, Input } from 'semantic-ui-react'
import { withRouter } from 'react-router'
import { formatDateDisplay, getUserRole, validateSuperAdminRole, formatDate } from '../../../common/util'
import { ROLE, STUDENT_CLASS_STATUS } from '../../../common/const'
import apiClient from '../../../apis'
import moment from 'moment'
import apis from '../../../apis'

//Comment out the codes about payment for pending receipt (no more pending receipt allowed)

class ReceiptList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			tableData: props.tableData,
			tableCurrentColumn: props.sorting.column,
			tableDirection: props.sorting.direction,
			activePage: props.activePage || 1,
			totalPages: props.totalPages || 0,
			totalData: props.totalData || 0,
			summary: props.summary || 0,
			pageSize: props.pageSize || 0,
			fromDate: props.fromDate,
			toDate: props.toDate,
			isShowPaymentModel: false,
			isShowDeleteModal: false,
			isShowRefundModal: false,
			cancelReason: '',
			refundAmount: 0,
			refundRemark: '',
			refundApprovedDate: moment(),
			selectedReceiptNo: '',
			selectedReceipt: null
		}
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			tableData: nextProps.tableData,
			tableCurrentColumn: nextProps.sorting.column,
			tableDirection: nextProps.sorting.direction,
			activePage: nextProps.activePage || 1,
			totalPages: nextProps.totalPages || 0,
			totalData: nextProps.totalData || 0,
			summary: nextProps.summary || 0,
			pageSize: nextProps.pageSize || 0,
			fromDate: nextProps.fromDate,
			toDate: nextProps.toDate,
			isShowPaymentModel: false,
			isShowDeleteModal: false,
			isShowRefundModal: false,
			cancelReason: '',
			refundAmount: 0,
			refundRemark: '',
			refundApprovedDate: moment(),
			selectedReceiptNo: '',
			refundModal: null
		})
	}

	handlePaginationChange = (e, data) => {
		this.props.onPaginationChange(data.activePage)
	}

	handleSort = (clickedColumn) => {
        let tableDirection = 'asc'
        if (this.state.tableCurrentColumn === clickedColumn) {
            tableDirection = this.state.tableDirection === 'asc' ? 'desc' : 'asc'
        }
        this.props.onSortingChange(clickedColumn, tableDirection)
    }

	renderSummary() {
		const { summary, fromDate, toDate} = this.state
		const period = Math.abs(Date.parse(toDate) / 86400000 - Date.parse(fromDate) / 86400000)
		const isWarning = period <= 31 && period >=1?false:true
		if (isWarning === true) {
			return (
				<Message
					icon = 'warning circle'
					color='yellow'
					content='Please select receipt date range between 1 - 31 days to view summary and export PDF.'
					size='small'>
				</Message>)
		}
		else {
			return (
				<Card fluid>
					<Card.Content extra textAlign='right'>
						<b>Summary: {formatCurrency(parseFloat(summary), 2)}&nbsp;&nbsp;</b>
						<Button color='black' onClick={() => this.props.onPrintReceipt(null, null, 'EXPORT')}>
							Export
						</Button>
					</Card.Content>
				</Card>
			)
		}
	}

  	handleCancel = () => this.setState({ selectedReceiptId: undefined, isShowPaymentModel: false, isShowDeleteModal: false, isShowRefundModal: false, selectedReceiptNo: undefined })

	// openPaymentModel = (receiptId, branchId) => {
	// 	this.setState({ selectedReceiptId: receiptId, selectedReceiptBranchId: branchId, isShowPaymentModel: true })
	// }

	openDeleteModel = (receiptId, branchId, receiptNo) => {
		this.setState({ 
			selectedReceiptId: receiptId,
			selectedReceiptNo: 'CG0'+branchId+receiptNo,
			isShowDeleteModal: true 
		})
	}

	openRefundModal = (receiptId, branchId, receiptNo) => {
		apis.receipt.getReceipt(receiptId)
			.then(res => {
				this.setState({ 
					selectedReceiptId: receiptId,
					selectedReceiptNo: 'CG0'+branchId+receiptNo,
					isShowRefundModal: true,
					selectedReceipt: res.data
				})
			})
	}

	// doPayment = async () => {
    //     this.setState({ isLoading: true })
    //     try {
	// 		const {selectedReceiptId, selectedReceiptBranchId} = this.state
    //         const result = await apiClient.receipt.settlePayment({ receiptId: selectedReceiptId })
    //         if (result.status === 'success') {
    //             this.setState({ isLoading: false, selectedReceiptId: undefined, isShowPaymentModel: false }, () => this.props.onPrintReceipt(selectedReceiptBranchId, selectedReceiptId, result.data))
    //         } else {
    //             throw result.message
    //         }
    //     } catch(e) {
    //         const errorMsg = e.message || e.error || e
    //         this.setState({ isLoading: false, selectedReceiptId: undefined, isShowPaymentModel: false })
    //         alert(errorMsg)
    //     }
    // }

	deleteReceipt = async () => {
        this.setState({ isLoading: true })
        try {
			const { selectedReceiptId, cancelReason } = this.state
            const result = await apiClient.receipt.deleteReceipt(selectedReceiptId, cancelReason)
            if (result.status === 'success') {
                this.setState(
					{ isLoading: false, selectedReceiptId: undefined, isShowPaymentModel: false, isShowDeleteModal: false }, 
					() => this.props.refresh()
				)
            } else {
                throw result.message
            }
        } catch(e) {
            const errorMsg = e.message || e.error || e
            this.setState({ isLoading: false, selectedReceiptId: undefined, isShowPaymentModel: false, isShowDeleteModal: false })
            alert(errorMsg)
        }
	}

	refundReceipt = async () => {
        this.setState({ isLoading: true })
		this.refundModal.refund()
        // try {
		// 	const { selectedReceiptId, refundAmount, refundRemark, refundApprovedDate } = this.state
        //     const result = await apiClient.receipt.refundReceipt({selectedReceiptId, refundAmount, refundRemark, refundApprovedDate})
		// 	console.log(result)
        //     if (result.status === 'success') {
        //         this.setState(
		// 			{ isLoading: false, selectedReceiptId: undefined, isShowPaymentModel: false, isShowDeleteModal: false, isShowRefundModal: false }, 
		// 			() => this.props.refresh()
		// 		)
        //     } else {
        //         throw result.message
        //     }
        // } catch(e) {
        //     const errorMsg = e.message || e.error || e
        //     this.setState({ isLoading: false, selectedReceiptId: undefined, isShowPaymentModel: false, isShowDeleteModal: false })
        //     alert(errorMsg)
        // }
	}

	render() {
		const {
			tableCurrentColumn,
			tableDirection,
			tableData,
			totalPages,
			activePage,
			totalData,
			pageSize,
			selectedReceipt
		} = this.state

		console.log(selectedReceipt)

		return (
			<Grid style={{ margin: '0' }}>
				{ getUserRole() === ROLE.ACCOUNT? this.renderSummary():'' }
				<Table celled sortable compact style={{padding: '0'}} size='small'>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell textAlign='center' width='1'
								sorted={tableCurrentColumn === 'receiptId' ? convertSorting(tableDirection) : null}
								onClick={() => this.handleSort('receiptId')}>ID</Table.HeaderCell>
							<Table.HeaderCell textAlign='center' width='2'
									sorted={tableCurrentColumn === 'receiptNo' ? convertSorting(tableDirection) : null}
									onClick={() => this.handleSort('receiptNo')}>Receipt NO</Table.HeaderCell>
							<Table.HeaderCell textAlign='center' width='2' 
								sorted={tableCurrentColumn === 'issuedDate' ? convertSorting(tableDirection) : null}
								onClick={() => this.handleSort('issuedDate')}>Issued Date</Table.HeaderCell>
							<Table.HeaderCell textAlign='center' width='2' 
								sorted={tableCurrentColumn === 'branchName' ? convertSorting(tableDirection) : null}
								onClick={() => this.handleSort('branchName')}> Branch </Table.HeaderCell>
							<Table.HeaderCell textAlign='center' width='2' 
								sorted={tableCurrentColumn === 'studentId' ? convertSorting(tableDirection) : null}
								onClick={() => this.handleSort('studentId')}>Student ID</Table.HeaderCell>
							<Table.HeaderCell textAlign='center'
								sorted={tableCurrentColumn === 'name' ? convertSorting(tableDirection) : null}
								onClick={() => this.handleSort('name')}>Name</Table.HeaderCell>
							<Table.HeaderCell textAlign='center' width='2'>Amount</Table.HeaderCell>
							<Table.HeaderCell textAlign='center' width='2'>Status</Table.HeaderCell>
							<Table.HeaderCell width='1'></Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{!tableData || tableData.length === 0 ? (
							<Table.Row>
								<Table.Cell
									verticalAlign='middle'
									warning
									textAlign='center'
									colSpan='10'
									style={{ width: '1000px' }}
								>
									No Data Found
								</Table.Cell>
							</Table.Row>
						) : (
							tableData.map((item, index) => (
								<Table.Row key={index}>
									<Table.Cell verticalAlign='middle' textAlign='center'>
										{item.receiptId}
									</Table.Cell>
									<Table.Cell verticalAlign='middle' textAlign='center'>
											{item.receiptNo}
									</Table.Cell>
									<Table.Cell verticalAlign='middle' textAlign='center'>
										{formatDateDisplay(item.issuedDate)}
									</Table.Cell>
									<Table.Cell verticalAlign='middle' textAlign='center'>
										{item.branchName}
									</Table.Cell>
									<Table.Cell verticalAlign='middle' textAlign='center'>
										{item.studentId}
									</Table.Cell>
									<Table.Cell verticalAlign='middle' textAlign={window.innerWidth > 760 ? 'left':'center'}>
										{item.name}</Table.Cell>
									<Table.Cell verticalAlign='middle' textAlign={window.innerWidth > 760 ? 'right':'center'}>
										{formatCurrency(parseFloat(item.amount), 2)}
									</Table.Cell>
									<Table.Cell verticalAlign='middle' textAlign='center'>
										<span style={{ color: item.receiptStatus === 'CANCELED' ? 'red' : null }}>
											{item.receiptStatus}
										</span>
									</Table.Cell>
									<Table.Cell verticalAlign='middle' textAlign='center'>
											{ (item.receiptStatus === 'PAID' || item.receiptStatus === 'CANCELED') && 
												<button className='ui icon button'
														onClick={() => this.props.onPrintReceipt(item.branchId, item.receiptId, null)}
														style={{backgroundColor: 'white', padding: '0px'}}>
													<i className='print icon' style={{fontSize: '14px'}}></i>
												</button>
											}
											{/* { item.receiptStatus === 'PENDING' && 
												<button className='ui icon button'
														onClick={() => this.openPaymentModel(item.receiptId, item.branchId)}
														style={{backgroundColor: 'white', padding: '0px'}}>
													<i className='money bill alternate outline icon' style={{fontSize: '14px', color:'coral'}}></i>
												</button>
											} */}
											{
												item.receiptStatus !== 'CANCELED' && validateSuperAdminRole() &&
													<button className='ui icon button'
															onClick={() => this.openDeleteModel(item.receiptId, item.branchId, item.receiptNo)}
															style={{backgroundColor: 'white', padding: '0px', marginLeft: '4px'}}>
														<i className='trash alternate outline icon' style={{fontSize: '14px'}}></i>
													</button>
											}
											{ item.receiptStatus === 'PAID' && 
												<button className='ui icon button'
														onClick={() => this.openRefundModal(item.receiptId, item.branchId, item.receiptNo)}
														style={{backgroundColor: 'white', padding: '0px'}}>
													<i className='money bill alternate outline icon' style={{fontSize: '14px'}}></i>
												</button>
											}
									</Table.Cell>
								</Table.Row>
							))
						)}
					</Table.Body>
				</Table>
				<Grid.Row columns={2}>
					<Grid.Column textAlign={window.innerWidth > 760 ? 'left':'center'} verticalAlign='middle' computer='8' tablet='4' mobile='16'>
						{totalData < 1 || !totalData ? null : (
							`${(activePage - 1) * pageSize + 1}-${(activePage - 1) * pageSize +
								tableData.length} of ${totalData}`
						)}					
					</Grid.Column>
					<Grid.Column textAlign={window.innerWidth > 760 ? 'right':'center'} verticalAlign='middle' computer='8' tablet='12' mobile='16'>
						{totalPages < 1 || !totalPages ? null : (
							<Pagination
							defaultActivePage={activePage}
							pointing
							secondary
							boundaryRange={window.innerWidth > 760 ? 1:0}
							siblingRange={window.innerWidth > 760 ? 1:0}
							ellipsisItem={window.innerWidth > 760 ? undefined : null}
							totalPages={totalPages}
							onPageChange={this.handlePaginationChange}
						  />
						)}
					</Grid.Column>
				</Grid.Row>
				{/* <Confirm
					open={this.state.isShowPaymentModel}
					content={`Do you want to make a payment for receipt ID: ${this.state.selectedReceiptId}`}
					onCancel={this.handleCancel}
					onConfirm={this.doPayment}
				/> */}
				<Confirm
					open={this.state.isShowDeleteModal}
					content={<div className="content">
						`Are you sure you want to delete the receipt (receipt No: ${this.state.selectedReceiptNo})?`
						<Form style={{ marginTop: '24px' }}>
							<label>Reason</label>
							<TextArea 
								fluid='true' 
								name='reason' 
								label='Reason' 
								placeholder='reason'
								value={this.state.cancelReason || ''} 
								onChange={e => this.setState({ cancelReason: e.target.value })}
							/>
						</Form>
					</div>}
					confirmButton='Delete'
					onCancel={this.handleCancel}
					onConfirm={this.deleteReceipt}
				/>

				<Confirm
					open={this.state.isShowRefundModal}
					content={
						<RefundModal 
							receipt={selectedReceipt} 
							ref={e => this.refundModal = e}
							refresh={this.props.refresh}
						/>
					}
					confirmButton='Refund'
					onCancel={this.handleCancel}
					onConfirm={this.refundReceipt}
				/>
				{/* <Modal
				/> */}
			</Grid>
		)
	}
}

function formatCurrency(num, decimal) {
	return num.toFixed(decimal).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export default withRouter(ReceiptList)

const convertSorting = (type) => {
	return type === 'asc' ? 'ascending' : 'descending'
}

class RefundModal extends Component {
	state = {
		refundClasses: {},
		refundAmount: {},
		refundRemark: '',
		refundApprovedDate: moment(),
	}

	refund = async () => {
		const { receipt } = this.props
		const receiptItems = this.getItems().reduce((acc, i) => {
			acc[i.receiptItemId] = i
			return acc
		}, {})
        try {
			const { refundAmount, refundClasses, refundRemark, refundApprovedDate } = this.state
			const refundItems = Object.keys(refundAmount).map(receiptItemId => {
				const ri = receiptItems[receiptItemId]
				const amount = refundAmount[receiptItemId]
				const numClass = refundClasses[receiptItemId]
				return {
					receiptItemId, 
					amount, 
					numClass,
					realizedAmount: ((ri.unitPrice - ri.unitItemDiscount) * numClass) - amount
				}
			})

			// console.log(refundItems)

            const result = await apiClient.receipt.refundReceipt({selectedReceiptId: receipt.receipt.receiptId, refundItems, refundRemark, refundApprovedDate})
			console.log(result)
            if (result.status === 'success') {
                this.setState(
					{ isLoading: false, selectedReceiptId: undefined, isShowPaymentModel: false, isShowDeleteModal: false, isShowRefundModal: false }, 
					() => this.props.refresh()
				)
            } else {
                throw result.message
            }
        } catch(e) {
            const errorMsg = e.message || e.error || e
            this.setState({ isLoading: false, selectedReceiptId: undefined, isShowPaymentModel: false, isShowDeleteModal: false })
            alert(errorMsg)
        }
	}

	getAmount = (receiptItemId) => {
		const { refundAmount } = this.state
		return receiptItemId in refundAmount ? refundAmount[receiptItemId] : ''
	}

	setAmount = (receiptItemId, amount) => {
		const { refundAmount } = this.state
		refundAmount[receiptItemId] = parseFloat(amount)
		this.setState({ refundAmount })
	}
	
	getNumClass = (receiptItemId) => {
		const { refundClasses } = this.state
		return receiptItemId in refundClasses ? refundClasses[receiptItemId] : ''
	}

	setNumClass = (receiptItemId, num) => {
		const { refundClasses } = this.state
		refundClasses[receiptItemId] = parseFloat(num)
		this.setState({ refundClasses })
	}

	getTotalRefund = () => {
		return Object.values(this.state.refundAmount)
			.reduce((acc, a) => acc + parseFloat(a), 0)
	}

	getItems = () => {
		const { receipt } = this.props
		const priceToActive = {}
		// receipt.receiptItems.forEach(ri => { priceToActive[ri.unitPrice] = 0 })
		receipt.receiptClasses.forEach(rc => {
			if (!(rc.receiptPrice in priceToActive)) {
				priceToActive[rc.receiptPrice] = 0
			}

			if (rc.status === STUDENT_CLASS_STATUS.ACTIVE) {
				priceToActive[rc.receiptPrice] += 1
			}
		})

		const totalAmount = receipt.receiptItems.reduce((acc, i) => acc + (i.unitPrice * i.amount), 0)
		const receiptItems = receipt.receiptItems.map(ri => {
			const unitItemDiscount = receipt.receipt.discount * ri.unitPrice / totalAmount 
			return {
				...ri,
				active: priceToActive[ri.unitPrice],
				unitItemDiscount: unitItemDiscount,
				itemDiscount: unitItemDiscount * ri.amount
			}
		})
		return receiptItems
	}

	render() {
		const { receipt } = this.props
		return (
			<div className="content">
				<h4>Are you sure you want to issue a refund on this receipt (receipt No: {receipt.receipt.receiptId})?</h4>
				<Form>
					<Form.Field>
						<label>Refund Approved Date</label>
						<Form.Input
							type='date'
							fluid
							onChange={e => this.setState({ refundApprovedDate: e.target.value })}
							value={formatDate(this.state.refundApprovedDate, 'YYYY-MM-DD')}
						/>
					</Form.Field>
					
					<h3>Receipt Items</h3>
					{this.getItems().map((ri, idx) => {
						return (
							<Form.Group key={idx} widths="equal">
								<Form.Field 
									width={6}
									style={{ 
										marginTop: "auto", 
										marginBottom: idx === 0 ? "8px" : "auto" 
									}}
								>
									<h4>{ri.displayName}</h4>
								</Form.Field>
								<Form.Input 
									label={idx === 0 ? "# Active" : null}
									fluid 
									placeholder='0'
									type="number"
									disabled
									value={ri.active} 
									width={1}
								/>
								<Form.Input 
									label={idx === 0 ? "# Class" : null}
									fluid 
									placeholder='0'
									type="number"
									min={0}
									max={ri.active}
									value={this.getNumClass(ri.receiptItemId)} 
									onChange={e => this.setNumClass(ri.receiptItemId, e.target.value)}
									width={1}
								/>
								<Form.Input 
									label={idx === 0 ? "Amount" : null}
									fluid 
									placeholder='0'
									type="number"
									value={this.getAmount(ri.receiptItemId)} 
									onChange={e => this.setAmount(ri.receiptItemId, e.target.value)}
									width={1}
								/>
							</Form.Group>
						)
					})}

					<Form.Field>
						<h3>Total Refund: {this.getTotalRefund()} THB</h3>
					</Form.Field>

					<Form.Field>
						<label>Remark</label>
						<TextArea 
							fluid='true' 
							name='remark' 
							label='Remark' 
							placeholder='remark'
							value={this.state.refundRemark || ''} 
							onChange={e => this.setState({ refundRemark: e.target.value })}
						/>
					</Form.Field>
				</Form>
			</div>
		)
	}
}