import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import  LanguageDetector from 'i18next-browser-languagedetector'
import enLang from './locales/en'
import thLang from './locales/th'

const options = {
    fallbackLng: 'en',
    load: 'languageOnly', // we only provide en, de -> no region specific locals like en-US, de-DE

    // have a common namespace used around the full app
    ns: ['common'],
    defaultNS: 'common',

    debug: false, // process.env.NODE_ENV !== 'production',
    saveMissing: true,

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ',',
        format: (value, format, lang) => {
            if (format === 'uppercase') return value.toUpperCase()
            return value
        }
    },

    resources: {
        en: enLang,
        th: thLang
    }
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next)


// initialize if not already initialized
if (!i18n.isInitialized) i18n.init(options)
export default {}