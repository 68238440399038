export const styles = {
  nextButton: {
    fontFamily:
      '"Varela Round", "K2D", "Roboto", "Helvetica", "Arial", sans-serif',
    width: '100%'
  },
  backButton: {
    fontFamily:
      '"Varela Round", "K2D", "Roboto", "Helvetica", "Arial", sans-serif',
    color: '#000000',
    backgroundColor: '#FCD634',
    width: '100%'
  },
  subCompletedCard: {
    background: 'none',
    boxShadow: 'none'
  },
  header: {
    fontFamily:
      '"Varela Round", "K2D", "Roboto", "Helvetica", "Arial", sans-serif'
  },
  subHeader: {
    fontFamily:
      '"Varela Round", "Pridi", "Roboto", "Helvetica", "Arial", sans-serif'
  }
}
