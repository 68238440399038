import React from 'react'
import { withRouter } from 'react-router'
import { Accordion, Button, Card, Dropdown, Form, Grid, Icon, Input} from 'semantic-ui-react'
import { getCurrentUser, isUndefined } from '../../../common/util'
import { withI18next } from '../../../lib/withI18next'
import apiClient from '../../../apis'
import styles from './styles'
import MainLayout from '../../../layouts/MainLayout'
import {DEFAULT_PAGE_SIZE} from '../../../common/const'
import TeacherList from './teacherList'


class TeacherSearch extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			searchData: {
				teacherName: '',
				teacherId: '',
				teacherMobile: '',
				teacherStatus: '',
				teacherType: '',
				orderBy: 'teacherId',
				orderType: 'asc',
				page: 1,
			},
			total: undefined,
			tableData: undefined,
			isLoading: false,
			isShowTool: true,
			selectedTeacher: null
		}
		this.handleSortingChange = this.handleSortingChange.bind(this)
		this.handlePaginationChange = this.handlePaginationChange.bind(this)
		this.handleInputChange = this.handleInputChange.bind(this)
	}

	componentDidMount() {
		const userInfo = getCurrentUser()
        if (isUndefined(userInfo)) {
            this.props.history.push('/home')
		}
		if (userInfo.searchData) {
        	this.setState({
				searchData: userInfo.searchData,
				},
				this.searchTeacher
			)
			this.clearUserInfo()
        }else{
			this.searchByInitialSetting()
		}
	}

	clearUserInfo = () => {
		const userInfo = getCurrentUser()
		localStorage.setItem('user', JSON.stringify({...userInfo, searchData: null, selectedTeacher: null }))
	}

	searchTeacher = async() => {
		this.setState({
			isLoading: true
		})
        const data = this.state.searchData
		let searchData = {}
		for (const key in data) {
			if (data[key] !== '' && data[key] !== null && data[key] !== undefined && data[key] !== 'undefined') {
				searchData[key] = await data[key]
			}
		}
		let result = await apiClient.teacher.searchTeacher(searchData)
		if (result) {
			this.setState({
				tableData: result.dataList,
				total: result.total,
				isLoading: false
			})
		}
	}

	searchByInitialSetting = () => {
		this.setState({
				searchData: {
					...this.state.searchData,
					orderBy: 'teacherId',
					orderType: 'asc',
					page: 1
				}
			},
			this.searchTeacher
		)
	}

	pushEditTeacher =  async (teacherId) => {
		const { searchData } = this.state
		const userInfo = getCurrentUser()
		await localStorage.setItem('user', JSON.stringify({...userInfo, selectedTeacher: teacherId, searchData: searchData}))
		this.settingInitialItem()
        this.props.history.push('/Teacher/Manage')
	}

	pushAddTeacher = async () => {
		const { searchData } = this.state
		const userInfo = getCurrentUser()
		await localStorage.setItem('user', JSON.stringify({...userInfo, selectedTeacher: null, searchData: searchData}))
		this.settingInitialItem()
        this.props.history.push('/Teacher/Manage')
	}
	
	settingInitialItem = () => {
        this.setState({
			searchData: {
				teacherName: '',
				teacherId: '',
				teacherMobile: '',
				teacherStatus: '',
				teacherType: '',
				orderBy: 'teacherId',
				orderType: 'asc',
				page: 1,
			},
			isShowTool: true,
			selectedTeacher: null
		},
		this.searchTeacher
		)	
	}

	handlePaginationChange = activePage => {
		this.setState({
				searchData: {
					...this.state.searchData,
					page: activePage
				}
			},
		this.searchTeacher
		)
	}

	handleInputChange = (e, value) => {
		this.setState({
			searchData: {
				...this.state.searchData,
				[value.id]: value.value
			}
		})
	}

	handleSortingChange = (orderBy, orderType) => {
		this.setState(
			{
				searchData: {
					...this.state.searchData,
					orderBy: orderBy,
					orderType: orderType
				}
			},
			this.searchTeacher
		)
	}

	renderSearchCard = () => {
		const { teacherName, teacherId, teacherMobile, teacherStatus, teacherType } = this.state.searchData
		
		return (
			<Card fluid>
                <Card.Content>
                    <Form fluid>
						<Form.Group widths='equal'>
							<Form.Field>
                            	<label >Name</label>
								<Input id='teacherName' fluid placeholder='' type='text'
								value={teacherName} onChange={this.handleInputChange} />
                        	</Form.Field>
							<Form.Field>
                                <label>Teacher ID</label>
								<Input id='teacherId' fluid placeholder='' type='text'
									value={teacherId} onChange={this.handleInputChange}/>
                        	</Form.Field>
							<Form.Field>
								<label>Mobile Number</label>
								<Input id='teacherMobile' fluid placeholder='' type='text' 
									value={teacherMobile}  onChange={this.handleInputChange}/>
							</Form.Field>
						</Form.Group>
						<Form.Group widths='equal'>
							<Form.Field>
                                <label>Status</label>
								<Dropdown id='teacherStatus' fluid placeholder='All' selection options={stutusObtion} 
									value={teacherStatus} onChange={this.handleInputChange}/>
                            </Form.Field>
							<Form.Field>
                                <label>Type</label>
								<Dropdown id='teacherType' fluid placeholder='All' selection options={typeObtion} 
									value={teacherType} onChange={this.handleInputChange}/>
                            </Form.Field>
							<Form.Field/>
						</Form.Group>	
					</Form>
				</Card.Content>
				<Card.Content extra textAlign='right'>
					<Grid.Column floated='right' style={{display: 'flex'}}>
						<Button primary onClick={() => this.pushAddTeacher()}>
                        		Add Teacher
                    	</Button>
						<Button primary onClick={() => this.searchTeacher()}>
                    			Search
                		</Button>
						{/* <Button negative onClick={() => this.settingInitialItem()}>
                        		Clear
                    	</Button>		 */}
					</Grid.Column>
				</Card.Content>	
                
            </Card>
		)
	}

	render() {
		return (	
			<MainLayout id='react-no-print'>
					<div style={styles.container}>
						<Grid centered stackable>
							<Grid.Row>
								<Grid.Column>
									<Accordion fluid>
										<Accordion.Title 
											active={this.state.isShowTool}                
											onClick={() => this.setState({isShowTool: !this.state.isShowTool})}
										>
											<Icon name='dropdown'/>
											Search
										</Accordion.Title>
										<Accordion.Content active={this.state.isShowTool}>
											{this.renderSearchCard()}
										</Accordion.Content>
									</Accordion>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column>
									<TeacherList
										pushEditTeacher={this.pushEditTeacher}
										tableData={this.state.tableData}
										onPaginationChange={this.handlePaginationChange}
										onSortingChange={this.handleSortingChange}
										totalPages={Math.ceil(this.state.total / DEFAULT_PAGE_SIZE)}
										totalData={this.state.total}
										pageSize={DEFAULT_PAGE_SIZE}
										activePage={this.state.searchData.page}
										sorting={{
											column: this.state.searchData.orderBy,
											direction: this.state.searchData.orderType
										}}
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</div>
			</MainLayout>
		)
	}
}


export default withRouter(withI18next()(TeacherSearch))

const stutusObtion = [
	{key: 'All', text: 'All', value: ''},
	{key: 'Y', text: 'Active', value: 'Active'},
	{key: 'N', text: 'Cancel', value: 'Cancel'}
]

const typeObtion = [
	{key: 'All', text: 'All', value: ''},
	{key: 'Y', text: 'Full-time', value: '1'},
	{key: 'N', text: 'Part-time', value: '0'}
]