import React, { Component } from 'react'
import { withRouter } from 'react-router'

import { Grid, Form, Input, Message } from 'semantic-ui-react'

import apiClient from '../../apis'
import CGButton from '../common/CGButton/index'
import { Spinner } from '../common'
import { validateOnlyNumber } from '../../common/util'
import { styles } from './styles'

class AddUserInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: props.email,
      password: props.password,
      facebookId: props.facebookId,
      firstName: '',
      lastName: '',
      nickName: '',
      mobileNumber: '',
      address: '',
      errorMsg: '',
      showCompletedCard: false,
      isLoading: false
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  onChangeMobileNumber = event => {
    const newMobileNumber = validateOnlyNumber(event.target.value)
    this.setState({
      mobileNumber: newMobileNumber
    })
  }

  validateData = () => {
    let errorMsg = ''

    if (this.state.firstName === null || this.state.firstName === '') {
      errorMsg = 'กรุณากรอกชื่อ'
      this.setState({ errorMsg: errorMsg })
      this.firstName.focus()
    } else if (this.state.lastName === null || this.state.lastName === '') {
      errorMsg = 'กรุณากรอกนามสกุล'
      this.setState({ errorMsg: errorMsg })
      this.lastName.focus()
    } else if (this.state.nickName === null || this.state.nickName === '') {
      errorMsg = 'กรุณากรอกชื่อเล่น'
      this.setState({ errorMsg: errorMsg })
      this.nickName.focus()
    } else if (this.state.mobileNumber.length === 0) {
      errorMsg = 'กรุณากรอกเบอร์โทรศัพท์มือถือ'
      this.setState({ errorMsg: errorMsg })
      this.mobileNumber.focus()
    } else if (this.state.mobileNumber.length !== 10) {
      errorMsg = 'กรุณากรอกเบอร์โทรศัพท์มือถือให้ถูกต้อง'
      this.setState({ errorMsg: errorMsg })
      this.mobileNumber.focus()
    } else {
      this.signUp()
    }
  }

  signUp = async () => {
    this.setState({
      isLoading: true
    })
    const result = await apiClient.signup.signup(
      this.state.email,
      this.state.password,
      this.state.firstName,
      this.state.lastName,
      this.state.facebookId,
      this.state.mobileNumber,
      this.state.nickName
    )
    if (result.success) {
      if (this.state.facebookId) {
        //TODO: edit to user's profile screeen
        this.props.history.push('/home')
      } else {
        this.setState({
          showCompletedCard: true,
          isLoading: false
        })
      }
    } else {
      this.setState({
        errorMsg: result.message,
        isLoading: false
      })
    }
  }

  showError = () => {
    if (this.state.errorMsg !== '') {
      return (
        <Message
          color="red"
          icon="exclamation circle"
          content={this.state.errorMsg}
          size="small"
        />
      )
    }
  }

  showCompletedCard = classes => {
    return (
      <Form>
        <Form.Field>
          <Message
            icon="mail outline"
            header="Please verify your account"
            content="by clicking the link the email you have received."
            color="teal"
            style={styles.subCompletedCard}
          />
        </Form.Field>
        <Form.Field>
          <CGButton
            style={{ width: '100%' }}
            onClick={() => this.props.history.push('/home')}
          >
            OK
          </CGButton>
        </Form.Field>
      </Form>
    )
  }

  backButtonAction = () => {
    this.props.hideFunction()
  }

  render() {
    const { classes } = this.props

    if (this.state.isLoading) {
      return <Spinner />
    }
    if (this.state.showCompletedCard) {
      return this.showCompletedCard(classes)
    }

    return (
      <div style={{ marginTop: '10px' }}>
        <Form>
          <Form.Field>{this.showError()}</Form.Field>
          <Form.Field>
            <Input
              ref={field => (this.firstName = field)}
              placeholder="ชื่อ"
              value={this.state.firstName}
              onChange={this.handleChange('firstName')}
            />
          </Form.Field>
          <Form.Field>
            <Input
              ref={field => (this.lastName = field)}
              placeholder="นามสกุล"
              value={this.state.lastName}
              onChange={this.handleChange('lastName')}
            />
          </Form.Field>
          <Form.Field>
            <Input
              ref={field => (this.nickName = field)}
              placeholder="ชื่อเล่น"
              value={this.state.nickName}
              onChange={this.handleChange('nickName')}
            />
          </Form.Field>
          <Form.Field>
            <Input
              ref={field => (this.mobileNumber = field)}
              placeholder="เบอร์โทรศัพท์มือถือ"
              value={this.state.mobileNumber}
              onChange={this.handleChange('mobileNumber')}
            />
          </Form.Field>
          <Form.Field>
            <Grid>
              <Grid.Column width={6}>
                <CGButton
                  style={styles.backButton}
                  onClick={() => this.props.history.push('/signin')}
                >
                  Back
                </CGButton>
              </Grid.Column>
              <Grid.Column width={10} style={{ paddingLeft: 0 }}>
                <CGButton
                  style={styles.nextButton}
                  onClick={() => this.validateData()}
                >
                  Next
                </CGButton>
              </Grid.Column>
            </Grid>
          </Form.Field>
        </Form>
      </div>
    )
  }
}

export default withRouter(AddUserInfo)
