export default {
    container: {
        padding: '30px 30px',
        fontSize: '14px'
    },
    fromToDateInput: {
        width: '50%'
    },
    fromDateInput: {
        width: '50%',
        borderRadius: '0'
    },
    ToDateLabel: {
        borderRadius: '0'
    },
    ToDateInput: {
        width: '50%'
    },
    labelField: {
        display: 'block',
        color: 'rgba(0,0,0,.87)',
        fontSize: '.92857143em',
        fontWeight: '700',
        textTransform: 'none'
    },
    labelFieldStatus: {
        color: 'rgba(0,0,0,.87)',
        fontSize: '.92857143em',
        fontWeight: '300',
        textTransform: 'none'
    }
}
