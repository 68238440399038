export default {
    container: {
        margin: '2em',
    },
    courseName: {
        fontWeight: 'bold',
        display: 'block'
    },
    teacher: {
        display: 'block'
    }
}
