import React from 'react'
import {Button, Dimmer, Dropdown, Form, Grid, Loader, Message, Modal, Input, Table } from 'semantic-ui-react'
import apiClient from '../../../apis'
import styles from './styles'
import { withRouter } from 'react-router'
import { formatDate, formatTimeDisplay, validateTime } from '../../../common/util';
import { DEFAULT_BRANCH_ID } from '../../../common/const';
import moment from 'moment'

class ChangePrivateClassModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            thisDate: new Date().getTime(),
            data: {},
            branchId: null,
            privateData: {},
            classMember: {},
            isOtherMember: false,
            oldTimeDate: {},
            oldPrivateData: {},
            date: '',
            classHour: '',
            branchList: [],
            roomList: [],
            roomName: '',
            receiptList: [],
            courseList: [],
            lessonList: [],
            teacherList: [],
            confrimDelete: false,
            warning: false,
            errorMsg: '',
            showEditModal: false,
            showAddPrivateModal: false,
            allStudentId: null
        }
    }

    componentDidMount() {
        if ( this.state.data.classId !== undefined && this.state.data.classId !== null) {
            this.getCourseList()
            this.getReceiptListInCourse()
            this.getBranchList()
            this.getTeacherList()
            this.initPrivateData()
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.showEditModal !== state.showEditModal  && props.showEditModal === true && props.data !== {}) {
            return {
                showEditModal: true,
                showAddPrivateModal: false,
                data: {...props.data}
            }
        }else if (props.showAddPrivateModal !== state.showAddPrivateModal && props.showAddPrivateModal === true ) {
            return {
                showEditModal: false,
                showAddPrivateModal: true,
                data: {...props.data},
                branchId: props.branchId
            }
        }
        return {}
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.data.classId && prevState.data.classId !== this.state.data.classId) {
            this.getCourseList()
            this.getReceiptListInCourse()
            this.getBranchList()
            this.getTeacherList()
            this.initPrivateData()
        }else if (this.state.data.courseId && prevState.data.courseId !== this.state.data.courseId) {
            this.getBranchList()
            this.getReceiptListInCourse()
            this.getTeacherList()
            this.getLessonListByCourseId()
            this.initPrivateData()
        }
    }

    getCourseList = async() => {
        const { data } = this.state
        this.setState({
            isLoading: true
        })
        
        const result = await apiClient.course.getAllCourseAtBranch(data.branchId)

        if (result !== null) {
            let arr = result.map((p, index) => {
                return {
                    key: index,
                    text: p.courseName,
                    value: p.courseId,
                    room: p.room
                }
            })
            this.setState({
                courseList: arr,
            })
        }
    }

    getBranchList = async() => {
        this.setState({
            isLoading: true
        })
        const result = await apiClient.branch.getAllBranches()

		if (result !== null) {
			let arr = result.dataList.map((p, index) => {
                return {
                    key: index,
                    text: p.branchName,
                    value: p.branchId,
                }
            })

		  	this.setState({ 
				branchList: arr,
            })
		}
    }

    getReceiptListInCourse = async() => {
        const { data } = this.state
        this.setState({
            isLoading: true
        })
        const result = await apiClient.course.getReceiptListInCourse(data.courseId, data.studentId)

        if (result !== null) {
			let arr = result.map((p, index) => {
                return {
                    key: index,
                    text: p.receiptId,
                    value: p.receiptId,
                }
            })

		  	this.setState({ 
				receiptList: arr,
            })
		}
    }

    getTeacherList = async() => {
        this.setState({
            isLoading: true
        })
        const result = await apiClient.teacher.getTeacherList()

        if (result !== null) {
            let arr = result.map((p, index) => {
                return {
                    key: index,
                    text: p.name,
                    value: p.id
                }
            })
            arr.unshift({key: -1, text: 'None', value: null})
            this.setState({
                teacherList: arr,
            })
        }
    }

    initPrivateData = async() => {
        const  { data, thisDate, defaultLesson, showAddPrivateModal } = this.state

        if (!showAddPrivateModal) {
            let newClassId = data.isEdit ? data.classId : data.classDetail.classId
            const classData = await apiClient.classes.getClassById(newClassId)
            const receiptData = await apiClient.classes.getLastClass(data.courseId, data.studentId )
            const teacherInClass = await apiClient.teacher.getTeacherByClassId(newClassId)
            const hourValue = await this.calcDiffTime(classData.startTime, classData.endTime)

            this.setState({
                roomName: classData.room,
                privateData: {
                    classId: newClassId,
                    branchId: classData.branchId,
                    lessonId: classData.lessonId,
                    roomId: classData.roomId,
                    classSize: classData.classSize,
                    classDetailId: classData.classDetailId,
                    date: formatDate(classData.classDate, 'YYYY-MM-DD'),
                    startTime: formatTimeDisplay(classData.startTime),
                    endTime: formatTimeDisplay(classData.endTime),
                    teacherIdMain: teacherInClass.main,
                    teacherIdAssist: teacherInClass.assist,
                    receiptId: receiptData.receiptId,
                    remark: data.remark,
                    classGroup: teacherInClass.classGroup
                },
                oldPrivateData: {
                    classId: newClassId,
                    branchId: classData.branchId,
                    lessonId: classData.lessonId,
                    roomId: classData.roomId,
                    classSize: classData.classSize,
                    classDetailId: classData.classDetailId,
                    date: formatDate(classData.classDate, 'YYYY-MM-DD'),
                    startTime: formatTimeDisplay(classData.startTime),
                    endTime: formatTimeDisplay(classData.endTime),
                    teacherIdMain: teacherInClass.main,
                    teacherIdAssist: teacherInClass.assist,
                    remark: data.remark,
                    classGroup: teacherInClass.classGroup,
                    receiptId: receiptData.receiptId
                },
                oldTimeDateData: {
                    date: formatDate(data.date, 'YYYY-MM-DD'),
                    time: data.time
                },
                classHour: Math.round(hourValue * 100) /100
            })

            this.getRoomList(classData.branchId)
            this.getLessonListByCourseId()
        }else{
            const classData = await apiClient.classes.getLastClass(data.courseId, data.studentId )
            const teacherInClass = await apiClient.teacher.getTeacherByClassId(classData.classId)
            const hourValue = await this.calcDiffTime(classData.time, classData.endTime)

            this.setState({
                roomName: classData.room,
                privateData: {
                    classId: null,
                    branchId: classData.branchId,
                    lessonId: defaultLesson ? defaultLesson: null,
                    roomId: classData.roomId,
                    classSize: 1,
                    classDetailId: classData.classDetailId,
                    date: formatDate(thisDate, 'YYYY-MM-DD'),
                    startTime: formatTimeDisplay(classData.time),
                    endTime: formatTimeDisplay(classData.endTime),
                    teacherIdMain: teacherInClass.main,
                    teacherIdAssist: teacherInClass.assist,
                    remark: null,
                    classGroup: '',
                    receiptId: classData.receiptId,
                    courseName: classData.courseName
                },
                checkPrivateData: {
                    classId: classData.classId,
                    branchId: classData.branchId
                },
                classHour: Math.round(hourValue * 100) /100
            })
            this.getCourseFromAddClass(classData.branchId)
            this.getRoomList(classData.branchId)
        }
        
    }

    getCourseFromAddClass = async(branchId) => {
        const result = await apiClient.course.getAllCourseAtBranch(branchId)

        if (result !== null) {
            let arr = result.map((p, index) => {
                return {
                    key: index,
                    text: p.courseName,
                    value: p.courseId,
                    room: p.room
                }
            })
            this.setState({
                courseList: arr,
            })
        }
    }


    getRoomList = async(branchId) => {
        const result = await apiClient.room.getRoomList(branchId)

        if (result !== null) {
			let arr = result.map((p, index) => {
                return {
                    key: p.id,
                    text: p.name,
                    value: p.id,
                }
            })

		  	this.setState({ 
                roomList: arr
            },this.getCourseHistory)
        }
    }

    getLessonListByCourseId = async() => {
        const { data } = this.state
        const courseId = data.courseId
        const result = await apiClient.course.getLessonListByCourseId(courseId)

        if (result !== null) {
			let arr = result.map((p, index) => {
                return {
                    key: index,
                    text: p.lessonName,
                    value: p.lessonId,
                }
            })

		  	this.setState({ 
                lessonList: arr,
            },this.getCourseHistory)
		}

    }

    getCourseHistory() {
        apiClient.course.getClassHistory(DEFAULT_BRANCH_ID, this.state.data.courseId, this.state.data.studentId)
            .then(res => {
                this.setState({
                    classHistory: res,
                    isLoading: false
                })
            })

        this.render()
    }

    close = () => { 
        this.setState({
            showEditModal: false,
            showAddPrivateModal: false,
            data: {},
        },
        this.props.onClose() 
        )
    }

    closeWarning = () => {
        const { confrimDelete, isOtherMember } = this.state
        if (confrimDelete === true ) {
            this.setState({ confrimDelete: false,  errorMsg: '', warning: false})
        }else if (isOtherMember === true) {
            this.setState({ isOtherMember: false,  errorMsg: 'This change will affect many students.', warning: false})
        }else{
            this.setState({ warning: false })
        }
        
    }

    validateParameter = () => {
        const { privateData, showAddPrivateModal } = this.state

        if (privateData.teacherIdMain === null || privateData.teacherIdMain === '') {
            this.setState({errorMsg: 'Please select main teacher.'})
        }else if (privateData.lessonId === null || privateData.lessonId === '') {
            this.setState({errorMsg: 'please select lesson.'})
        }else if (privateData.startTime === null || privateData.startTime === ''){
            this.setState({errorMsg: 'Please input start time.'})
        }else if (privateData.endTime === null || privateData.endTime === ''){
            this.setState({errorMsg: 'Please input end time.'})
        }else if (parseInt(privateData.classSize) != privateData.classSize){
            this.setState({errorMsg: 'Please only insert number into class size.'})
        }else if (!validateTime(privateData.startTime)){
            this.setState({errorMsg: 'Please check the selected start time.'})
        }else if (!validateTime(privateData.endTime)) {
            this.setState({errorMsg: 'Please check the selected start time.'})
        }else if (privateData.classSize <= 0){
            this.setState({errorMsg: 'Please insert positive number into class size.'})
        }else if (privateData.roomId === null ){
            this.setState({errorMsg: 'Please select room.'})
        }else if (privateData.teacherIdMain === privateData.teacherIdAssist){
            this.setState({errorMsg: 'Please check main teacher and assist teacher.'})
        }else if (moment(privateData.endTime, 'HH:mm:ss') <= moment(privateData.startTime, 'HH:mm:ss')){
            this.setState({errorMsg: 'Please check the selected Date and Time.'})
        }else{
            if (showAddPrivateModal){
                this.getAllStudentByClassId()
            }else{
                this.validateData() 
            }
        }
       
    }

    validateData = () => {
        const { privateData, oldPrivateData, data } = this.state

        const isDateChange = privateData.date !== oldPrivateData.date
        const isStartTimeChange = privateData.startTime !== oldPrivateData.startTime
        const isEndTimeChange = privateData.endTime !== oldPrivateData.endTime
        const isLessonChange = privateData.lessonId !== oldPrivateData.lessonId
        const isClassSizeChange = privateData.lessonId !== oldPrivateData.classSize
        const isBranchChange = privateData.branchId !== oldPrivateData.branchId
        const isRoomChange = privateData.roomId !== oldPrivateData.roomId
        const isTeacherMainChange = privateData.teacherIdMain !== oldPrivateData.teacherIdMain
        const isTeacherAssistChange = privateData.teacherIdAssist !== oldPrivateData.teacherIdAssist
        const isRemarkChange = privateData.remark !== oldPrivateData.remark
        const isCorrectTeacher = privateData.teacherIdMain !== privateData.teacherIdAssist

        if (isCorrectTeacher){
            if( isDateChange || isStartTimeChange || isEndTimeChange ){
                const isCorrectMoment =  moment(privateData.endTime, 'HH:mm:ss') > moment(privateData.startTime, 'HH:mm:ss')
                if ( isCorrectMoment ){
                    this.getAllStudentByClassId()
                }else{
                    this.setState({errorMsg: 'Please check the selected Date and Time.'})
                }
            }else if ( isLessonChange || isRemarkChange || isClassSizeChange || isBranchChange || isTeacherMainChange || isTeacherAssistChange || isRoomChange ) {
                this.getAllStudentByClassId()
            }else{
                this.setState({
                    showEditModal: false,
                    showAddPrivateModal: false,
                    data: {},
                },
                this.props.onClose({classId: data.classId, status: 'SUCCESS'})
                )
            }
        }else{
            this.setState({errorMsg: 'Please check main teacher and assist teacher.'})
        }
    }

    getAllStudentByClassId = async() => {
        const { oldPrivateData, checkPrivateData, showAddPrivateModal } = this.state
        const classId = showAddPrivateModal? checkPrivateData.classId:oldPrivateData.classId
        const branchId = showAddPrivateModal? checkPrivateData.branchId:oldPrivateData.branchId
        const result = await apiClient.course.getAllStudentByClassId(classId, branchId)
        
        if (result) {
            const data = result.memberList
            let all = data.map(p => {
                return p.studentId
            })
            let arr = data.map((p, index) => {
                return {
                    key: index,
                    text: p.name,
                    value: p.studentId,
                }
            })
            this.setState({
                allStudentId: all,
                classMember: arr
            })
        }
        
        const total = result.count
        if (total === 1) {
            this.doSave()
        }else if (total > 1) {
            this.setState({
                isOtherMember: true,
                warning: true,
                errorMsg: 'This change will affect these students.'
            })
        }else if (total < 1 && !showAddPrivateModal){
            this.setState({
                warning: true,
                errorMsg: "This class doesn't appear in database. Please check branch and room !"
            })
        }else{
            this.doSave()
        }
    }

    doSave = async () => {
        const { data, privateData, roomName, showAddPrivateModal, isOtherMember, allStudentId } = this.state
        this.setState({ warning: false, isLoading: true,  errorMsg: ''})
        let result = {}
        let status = ''

        let form = {
            ...privateData, 
            isOtherMember: isOtherMember,
            allStudentId: allStudentId,
            studentId: data.studentId, 
            status: showAddPrivateModal ? 'ACTIVE': data.status, 
            room: roomName
        }

        if (showAddPrivateModal){
            result = await apiClient.course.createPrivateClass(form)

            if (result.status === 'SUCCESS') {
                this.setState({
                    showEditModal: false,
                    showAddPrivateModal: false,
                    data: {},
                },
                this.props.onClose()
                )
            } else {
                this.setState({isLoading: false, errorMsg: result.message})
            }
        }else{
            result = await apiClient.course.editPrivateClass(form)

            if (result.status === 'SUCCESS') {
                this.setState({
                    showEditModal: false,
                    showAddPrivateModal: false,
                    data: {},
                },
                this.props.onClose({classId: data.classId, status: status})
                )
            } else {
                this.setState({isLoading: false, errorMsg: result.message})
            }
        }
    }
        
    doDelete = async () => {
        const { privateData } = this.state
        this.setState({ confrimDelete: false, errorMsg: '',  isLoading: true })
        let form = {...privateData }
        const result = await apiClient.classes.deleteClassPrivate(form)

        if (result.status === 'Success') {
            this.setState({
                showEditModal: false,
                showAddPrivateModal: false,
                data: {},
            },
            this.props.onClose()
            )
        } else {
            this.setState({isLoading: false, errorMsg: result.message})
        }
    }

    handleDeleteModal = () => { 
        this.setState({
            confrimDelete: true,
            errorMsg: 'Are you sure you want to delete this class ?'
        })
    }
    
    handleChange = name => event => {
        let value = event.target.value
        this.setState({
            errorMsg: '',
            data: {
                ...this.state.data,
                [name]: value
            }
        })
    }


    handleDropDownChange = name => (event, {value}) => {
        const { roomList } = this.state

        if (name === 'branchId') {
            this.setState({
                errorMsg: '',
                roomName: '',
                privateData: {
                    ...this.state.privateData,
                    roomId: null,
                    [name]: value
                }
            })   
            this.getRoomList(value)
        }else if (name === 'roomId'){
            let roomData = this.state.roomList.find(e => e.value === value)

            this.setState({
                roomName: roomData.text,
                privateData: {
                    ...this.state.privateData,
                    [name]: value
                }
            })
        }else if (name === 'teacherIdMain' && value === null) {
            this.setState({
                privateData: {
                    ...this.state.privateData,
                    teacherIdAssist: null,
                    [name]: value
                }
            })
        }
        else{
            this.setState({
                errorMsg: '',
                privateData: {
                    ...this.state.privateData,
                    [name]: value
                }
            })
        }
    }

    handleInputChange  = name => event => {
        const { privateData } = this.state
        let value = event.target.value

        this.setState({
            privateData: {
                ...this.state.privateData,
                [name]: value
            }
        })

        if (name === 'startTime') {
            let hourValue = this.calcDiffTime(value, privateData.endTime)
            this.setState({ classHour :  Math.round(hourValue * 100) /100})
        }else if (name === 'endTime'){
            let hourValue = this.calcDiffTime(privateData.startTime, value)
            this.setState({ classHour :  Math.round(hourValue * 100) /100})
        }

    }

    calcDiffTime(start, end) {
        let startDate, endDate

        try {
            start = start.split(':')
            end = end.split(':')
            startDate = new Date(0, 0, 0, start[0], start[1], 0);
            endDate = new Date(0, 0, 0, end[0], end[1], 0);
        } catch (e) {
            return 0
        }

        let diff = endDate.getTime() - startDate.getTime();
        let hours = Math.floor(diff / 1000 / 60 / 60);
        diff -= hours * 1000 * 60 * 60;
        let minutes = Math.floor(diff / 1000 / 60);

        return hours + minutes / 60
    }

    renderModalAlert() {
        const {classMember, warning, errorMsg, isOtherMember, confrimDelete} = this.state

        return (
            <Modal size={'tiny'} open={ warning || confrimDelete } onClose={this.closeWarning}>
                <Modal.Header>Warning</Modal.Header>
                <Modal.Content>
                    <p>{errorMsg}</p>
                    {isOtherMember === true ?   
                        <Grid style={{margin: '0'}}>
                            <Table celled compact size='small'>
                                <Table.Row>
                                    <Table.HeaderCell textAlign='center' width={2}> Id </Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center' width={10}> Name </Table.HeaderCell>
                                </Table.Row>
                                {classMember.map((item, index) => (
                                    <Table.Row key={index}>  
                                        <Table.Cell textAlign='center' verticalAlign='middle'>{item.value}</Table.Cell>
                                        <Table.Cell textAlign='center' verticalAlign='middle'>{item.text}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table>
                        </Grid>
                    :null }
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.closeWarning}>Cancel</Button>
                    {confrimDelete ? 
                        (<Button
                            onClick={this.doDelete}
                            negative
                            content='Delete'
                        />
                        ):(<Button
                            onClick={this.doSave}
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content='Confirm'
                        />)
                    }
                </Modal.Actions>
            </Modal>
        )
    }

    render() {
        const { showAddPrivateModal, data, privateData, oldTimeDateData, branchList, roomList, courseList, lessonList, receiptList,
            errorMsg, isLoading, teacherList, thisDate, classHour, confrimDelete } = this.state

        return (
            <Modal open={this.props.open} onClose={this.closeWarning}>
                <Modal.Header>{showAddPrivateModal ?  'Add Class':'Edit Class'}</Modal.Header>
                <Modal.Content>
                    {privateData ?
                        <Form>
                            {!showAddPrivateModal && oldTimeDateData ? 
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Message>
                                            <Message.Header>Old Class Date</Message.Header>
                                            <p>{oldTimeDateData.date} ({oldTimeDateData.time})</p>
                                        </Message>
                                    </Form.Field>
                                </Form.Group>: null}
                            <Form.Group widths='equal'>
                                <Form.Field>
                                    <label style={styles.formLabelDisable}>Course Name</label>
                                    <Dropdown disabled={true} fluid selection
                                            name='courseId' placeholder='Course Name'
                                            onChange={this.handleChange('courseId')}
                                            value={data.courseId} options={courseList}/>
                                </Form.Field>
                                <Form.Field>
                                    <label style={styles.formLabel}>Lesson Name</label>
                                    <Dropdown fluid selection
                                            name='lessonId' placeholder='Lesson Name'
                                            onChange={this.handleDropDownChange('lessonId')}
                                            value={privateData.lessonId || null} options={lessonList}/>
                                </Form.Field>
                                <Form.Field>
                                        <label style={ !showAddPrivateModal || receiptList.length === 1 ? styles.formLabelDisable:styles.formLabel}> Receipt ID </label>
                                        <Dropdown disabled= { !showAddPrivateModal || receiptList.length === 1 } 
                                                fluid selection name='receiptId' placeholder='Receipt ID'
                                                onChange={this.handleDropDownChange('receiptId')}
                                                value={privateData.receiptId} options={receiptList}/>
                                    </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field width={4}>
                                    <label style={styles.formLabel}>Day</label>
                                    <Input
                                        labelPosition='left'
                                        name='date'
                                        type='date'
                                        min={formatDate(thisDate, 'YYYY-MM-DD')}
                                        value={formatDate(privateData.date, 'YYYY-MM-DD')}
                                        onChange={this.handleInputChange('date')}
                                        size='small'
                                    >
                                        <input style={styles.formLabel}/>
                                    </Input>
                                </Form.Field>
                                <Form.Field width={4}>
                                    <Form.Input fluid name='startTime' label='Start time'
                                                placeholder='HH:mm'  value={ privateData.startTime || null}
                                                onChange={this. handleInputChange('startTime')}/>
                                </Form.Field>
                                <Form.Field width={4}>
                                    <Form.Input fluid name='endTime' label='End time'
                                                placeholder='HH:mm'  value={ privateData.endTime || null}
                                                onChange={this.handleInputChange('endTime')}/>       
                                </Form.Field>
                                <Form.Field width={2} disabled={true}>
                                    <Form.Input fluid name='classHour' label='Class hours'
                                                placeholder='Hours'  value={classHour || null }/>
                                </Form.Field>
                                <Form.Field width={2}>
                                    <Form.Input fluid name='classSize' label='Class Size'
                                                placeholder='Sizes'  value={ privateData.classSize || null }
                                                onChange={this. handleInputChange('classSize')}/>
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field width={3}>
                                    <label style={styles.formLabel}>Branch</label>
                                    <Dropdown fluid selection name='branchId'
                                            placeholder='Select Branch'
                                            onChange={this.handleDropDownChange('branchId')}
                                            value={ privateData.branchId } options={branchList} />
                                </Form.Field>
                                <Form.Field width={3}>
                                    <label style={styles.formLabel}>Room</label>
                                    <Dropdown fluid selection name='roomId'
                                            placeholder='Select Room'
                                            onChange={this.handleDropDownChange('roomId')}
                                            value={privateData.roomId} options={roomList} />
                                </Form.Field>
                                <Form.Field width={5}>
                                    <label style={styles.formLabel}>Teacher</label>
                                    <Dropdown fluid selection name='teacherIdMain'
                                            placeholder='Select Teacher'
                                            onChange={this.handleDropDownChange('teacherIdMain')}
                                            value={privateData.teacherIdMain || null} options={teacherList} />
                                </Form.Field>
                                <Form.Field width={5}>
                                    <label style={privateData.teacherIdMain ? styles.formLabel: styles.formLabelDisable}>Assist</label>
                                    <Dropdown fluid selection 
                                            disabled= {!privateData.teacherIdMain}
                                            name='teacherIdAssist'
                                            placeholder='Select Teacher'
                                            onChange={this.handleDropDownChange('teacherIdAssist')}
                                            value={privateData.teacherIdAssist || null} options={teacherList} />
                            </Form.Field>
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Field>
                                    <Form.TextArea name='remark' label='Remark' placeholder='Remark'
                                                value={privateData.remark || null}
                                                onChange={this.handleInputChange('remark')}/>
                                </Form.Field>
                            </Form.Group>
                        </Form>: null}
                    {this.renderModalAlert()}
                    {!showAddPrivateModal ? 
                        <div>
                            <Button compact content='Delete' icon='trash alternate' labelPosition='right' onClick={this.handleDeleteModal}/>
                        </div>
                    : null}
                </Modal.Content>
                <Modal.Actions style={{textAlign: 'left'}}>
                    <div style={{width: '70%', display: 'inline-block'}}>
                        {errorMsg && !confrimDelete &&
                            <Message compact warning size='tiny' style={styles.errorMessageContainer}>
                                <Message.Header>Warning</Message.Header>
                                <p>{errorMsg}</p>
                            </Message>
                        }
                    </div>
                    <div style={{width: '30%', display: 'inline-block', textAlign: 'right'}}>
                        <Button onClick={this.close}> Cancel </Button>
                        <Button positive icon='checkmark' labelPosition='right'
                                content='Save' onClick={this.validateParameter}/>
                    </div>
                </Modal.Actions>
                <Dimmer inverted active={isLoading}><Loader/></Dimmer>
            </Modal>
        )
    }

}


export default withRouter(ChangePrivateClassModal)

