import React from 'react'

export default ({ type, children, ...props }) => {
    return (
        <div {...props}>
            <style type='text/css'>
                {`@media print{@page {size: ${type || 'portrait'}; margin: 0;display: block }}`}
            </style>
            {children}
        </div>
    )
}