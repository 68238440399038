export const styles = {
  iconStepContainer: {
    backgroundColor: '#FCD634',
    width: '140px',
    height: '140px',
    borderRadius: '50%',
    margin: 'auto',
    textAlign: 'center',
    verticalAlign: 'middle',
    border: '2px solid #FFFFFF',
    boxShadow: '0px 0px 0px 4px #FCD634'
  },
  iconMobile: {
    width: '125px',
    height: '125px'
  },
  iconStepText: {
    position: 'absolute',
    top: '35%',
    left: '25%',
    fontFamily: 'Varela Round',
    fontSize: '1.2em',
    lineHeight: '1.05em',
    width: '50%'
  },
  iconMobileText: {
    width: '80px',
    position: 'relative',
    left: '17%',
    top: '33%'
  },
  stepConnector: {
    content: 'close-quote',
    position: 'absolute',
    height: '5px',
    width: '100px',
    backgroundColor: '#00B3C5',
    top: '50%',
    left: '85%'
  },
  mobileConnector: {
    content: 'close-quote',
    position: 'absolute',
    height: '30px',
    width: '5px',
    backgroundColor: '#00B3C5',
    top: '93%',
    left: '49%',
    marginLeft: '5px'
  },
  logo: {
    width: '140px',
    height: '140px',
    margin: 'auto'
  }
}
