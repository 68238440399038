export const styles = {
  roadmap: {
    position: 'relative',
    margin: '0 auto',
    marginTop: '35px'
  },
  timeline: {
    content: 'close-quote',
    position: 'absolute',
    width: '5px',
    height: 'calc(100% - 205px)',
    backgroundColor: '#FCD634',
    top: '15px',
    bottom: '0',
    left: '50%',
    marginLeft: '-3px'
  },
  timelineMobile: {
    left: '17%',
    height: 'calc(100% - 5%)'
  },
  avatar: {
    maxWidth: '200px',
  },
  avatarBorder: {
    border: '7px solid #FCD634',
    borderRadius: '50%'
  },
  roadmapContainer: {
    marginTop: '5%',
    padding: '0px 75px 30px 75px',
    position: 'relative',
    backgroundColor: 'inherit',
    width: '50%'
  },
  content: {
    padding: '30px 10px',
    backgroundColor: 'white',
    position: 'relative',
    borderRadius: '6px'
  },
  leftContent: {
    left: '10%',
    textAlign: 'right'
  },
  rightContent: {
    left: '-10%'
  },
  header: {
    fontFamily: `"Varela Round", "Pridi", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    color: '#00B3C5'
  },
  subHeader: {
    fontFamily: `"Varela Round", "K2D", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    lineHeight: '1.65em'
  }
}
