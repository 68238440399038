import { getToken } from '../common/util'
import config from '../common/config'

function stringifyParams(params) {
    const keys = Object.keys(params)
    if (keys.length === 0) {
        return ""
    }
    const q = keys.reduce((acc, key) => {
        if (params[key]) {
            acc += `&${key}=${params[key]}`
        }
        return acc
    }, "")
    return q.substring(1)
}

export async function getRequest(urlPath, params = {}) {
    const queryString = stringifyParams(params)
    
    return new Promise((resolve, reject) => {
        fetch(`${config.baseApiUrl}${urlPath}?${queryString}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + getToken()
            }
        })
        .then(async res => {
            const data = await res.json()
            if (res.ok) {
                resolve(data)
            } else {
                reject(data)
            }
        })
        .catch(err => {
            reject(err)
        })
    })
}

export async function postRequest(urlPath, data = {}) {
    return new Promise((resolve, reject) => {
        fetch(`${config.baseApiUrl}${urlPath}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + getToken()
            },
            body: JSON.stringify(data)
        })
        .then(async res => {
            const data = await res.json()
            if (res.ok) {
                resolve(data)
            } else {
                reject(data)
            }
        })
        .catch(err => {
            reject(err)
        })
    })
}
