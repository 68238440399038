import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Grid } from '@material-ui/core'

import { getCurrentUser, isUndefined, isEmpty } from '../../common/util'
import MainLayout from '../../layouts/MainLayout'
import { withI18next } from '../../lib/withI18next'
import { Spinner } from '../../components/common'
import GroupAlbum from '../../components/GroupAlbum'
import GalleryBreadcrumb from '../../components/GalleryBreadcrumb'
import ProfileBar from '../../components/ProfileBar'
import apiClient from '../../apis'
import styles from './styles'

class gallery extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            showAlbum: false,
            lastedPhotoList: null,
            albumList: null,
            studentId: null,
            breadCrumb: [],
        }

    }

    getSelectedStudent = () => {
        const userInfo = getCurrentUser()

        if (!isUndefined(userInfo)) {
            if (isUndefined(userInfo.selectedStudent)) {
                this.setState({ isLoading: false })
            } else {
                // this.getAllPhotoByStudentId(userInfo.selectedStudent.studentId)
                this.getAlbumByStudentId(userInfo.selectedStudent.studentId)
            }
        }
    }

    componentDidMount() {
        this.getSelectedStudent()
    }

    getAlbumByStudentId = async (studentId) => {
        this.setState({
            isLoading: true,
        })
        const list = await apiClient.photo.getAlbumByStudentId(studentId)
        this.setState({
            isLoading: false,
            showAlbum: true,
            albumList: [...list.albumList],
            studentId,
        })
    }

    handleShowAlbum = () => {
        this.setState({
            isLoading: true,
        })
        this.getAlbumByStudentId(this.state.studentId)
    }

    handleShowAll = () => {
        this.setState({
            isLoading: true,
        })
        this.getAllPhotoByStudentId(this.state.studentId)
    }

    handleGalleryBreadcrumb = (position, title, action) => {
        const { breadCrumb } = this.state
        breadCrumb[position - 1] = { title, action }
        this.setState({ breadCrumb })
    }

    handleGalleryBreadcrumbActionCallback = (position) => {
        this.setState({ breadCrumb: this.state.breadCrumb.slice(0, position) })
    }

    renderNoPhotoText() {
        return <Typography align='center' color='error'>Photos are coming soon.</Typography>
    }

    renderShowAlbum() {
        const { albumList, studentId } = this.state
        return (
            <GroupAlbum albumList={albumList} studentId={studentId} modifyBreadcrumb={this.handleGalleryBreadcrumb} />
        )

    }

    render() {
        const { classes } = this.props
        const { isLoading, studentId, albumList, breadCrumb } = this.state

        if (isLoading) {
            return (
                <MainLayout>
                    <ProfileBar >
                        <Grid item xs={12}>
                            <Spinner />
                        </Grid>
                    </ProfileBar>
                </MainLayout >
            )
        }

        if (isUndefined(studentId)) {
            return (
                <MainLayout>
                    <ProfileBar >
                        <Grid item xs={12}>
                            <Typography align='center' color='error'>Please contact admin for update account with your student.</Typography>
                        </Grid>
                    </ProfileBar>
                </MainLayout >
            )
        }

        return (
            <MainLayout>
                <div>
                    <ProfileBar getSelectedStudent={this.getSelectedStudent}>
                        <Grid container className={classes.headerContainer}>
                            <div className={classes.galleryContainer}>
                                <GalleryBreadcrumb path={breadCrumb} updateBreadcrumb={this.handleGalleryBreadcrumbActionCallback} />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                isUndefined(albumList) || isEmpty(albumList) ?
                                    (this.renderNoPhotoText())
                                    : (this.renderShowAlbum())
                            }
                        </Grid>
                    </ProfileBar>
                </div>
            </MainLayout >
        )
    }
}

export default withI18next(['gallery'])(withStyles(styles)(gallery))