import { postRequest, getRequest } from '../lib/request'

export async function deleteClassPrivate(form) {
    const data = {... form}

    return postRequest(`/classes/deleteClassPrivate`, data)
}

export async function getStudentClassByCourse(studentId, courseId) {
    return getRequest(`/classes/groupByCourse/${courseId}/student/${studentId}`)
}

export async function getClassById(newClassId) {
    const data = {
        classId: newClassId
    }

    return getRequest(`/classes/getClassById`, data)
}

export async function getLastClass( courseId, studentId){
    const data = {
        courseId: courseId,
        studentId: studentId
    }

    return getRequest(`/classes/getLastClassByCourseIdAndStudentId`, data)
}