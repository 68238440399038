import {getRequest, postRequest} from '../lib/request'


export function getCoreCourse() {
    return getRequest(`/course/getCoreCourse`)
}

export function getAllCourseAtBranch(branchId) {
    const data = {
        branchId: branchId
    }
    return getRequest(`/course/getAllCourseAtBranch`, data)
}

export function getCourseByCriteria(criteria) {
    return getRequest(`/course/getCourseByCriteria`, criteria)
}

export function getClassList(branchId, courseId, dow, startTime, room, studentId) {
    const data = {
        branchId: branchId,
        courseId: courseId,
        dow: dow,
        startTime: startTime,
        room: room,
        studentId: studentId
    }

    return getRequest(`/course/getClassList`, data)
}

export function getLessonListByCourseId(courseId){
    const data = {
        courseId: courseId
    }

    return getRequest(`/course/getLessonListByCourseId`, data)
}

export function getReceiptListInCourse(courseId, studentId){
    const data = {
        courseId: courseId,
        studentId: studentId
    }

    return getRequest(`/course/getReceiptListInCourse`, data)
}

export function registerClass(studentId, branchId, registeredCourseList, discount, remark, headline, subHeadline1, subHeadline2, body1, body2, body3, footer) {

    const data = {
        studentId: studentId,
        branchId: branchId,
        registeredCourseList: registeredCourseList,
        discount: discount,
        remark: remark,
        headline: headline,
        subHeadline1: subHeadline1,
        subHeadline2: subHeadline2,
        body1: body1,
        body2: body2,
        body3: body3,
        footer: footer,
    }

    return postRequest(`/course/registerClass`, data)
}

export function getReceiptInfo(receiptId) {
    const data = {
        receiptId: receiptId
    }

    return getRequest(`/course/getReceiptInfo`, data)
}

export function getReceiptExport(form) {
    const data = {
        branchId: form.branchId,
        receiptId: form.receiptId,
        name: form.name,
        fromDate: form.fromDate,
        toDate: form.toDate
    }
    return getRequest(`/course/getReceiptExport`, data)
}

export function searchReceipt(criteria) {
    return getRequest(`/course/receipt/search`, criteria)
}

export function getCourseList(studentId) {
    const data = {
        studentId: studentId
    }

    return getRequest(`/course/getCourseList`, data)
}

export function getSchedulePlan(form) {
    const data = {
        studentId: form.studentId,
        course: form.course,
        status: form.status,
        isFirst: form.isFirst,
        isAdmin: form.isAdmin,
        orderBy: form.orderBy,
        orderType: form.orderType,
        page: form.page,
        branchId: form.branchId
    }
    return getRequest(`/course/getSchedulePlan`, data)
}

export function getCourseDetail(studentId, courseId) {
    const data = {
        studentId: studentId,
        courseId: courseId
    }

    return getRequest(`/course/getCourseDetail`, data)
}

export function setStatus(form) {
    const data = {
        studentId: form.studentId,
        classId: form.classId,
        status: form.status,
        userId: form.userId
    }

    return postRequest(`/course/setStatus`, data)
}

export function getNextClassByCancel(studentId, courseId, classId, branchId) {
    const data = {
        studentId: studentId,
        courseId: courseId,
        classId: classId,
        branchId: branchId
    }

    return getRequest(`/course/getNextClassByCancel`, data)
}

export function setClassFromCancel(form) {
    const data = {
        studentId: form.studentId,
        classId: form.classId,
        receiptId: form.receiptId,
        receiptPrice: form.receiptPrice,
        receiptCourseName: form.receiptCourseName,
        status: form.status,
        remark: form.remark,
        previousClassId: form.previousClassId
    }

    return postRequest(`/course/setClassFromCancel`, data)
}

export function setClassFromEdit(form) {
    const data = {
        studentId: form.studentId,
        classId: form.classId,
        newClassId: form.newClassId,
        status: form.status,
        remark: form.remark
    }

    return postRequest(`/course/setClassFromEdit`, data)
}

export function editPrivateClass(form) {
    const data = { ...form}

    return postRequest(`/course/editPrivateClass`, data)
}

export function createPrivateClass(form) {
    const data = { ...form}

    return postRequest(`/course/createPrivateClass`, data)
}

export function getClassTimeList(courseId, dow) {
    const data = {
        courseId: courseId,
        dow: dow
    }

    return getRequest(`/course/getClassTimeList`, data)
}

export function getClassEditList(branchId, courseId, dow, startTime, studentId) {
    const data = {
        branchId: branchId,
        courseId: courseId,
        dow: dow,
        startTime: startTime,
        studentId: studentId
    }

    return getRequest(`/course/getClassEditList`, data)
}

export function getClassHistory(branchId, courseId, studentId) {
    const data = {
        branchId: branchId,
        courseId: courseId,
        studentId: studentId
    }

    return getRequest(`/course/getClassHistory`, data)
}

export function getCourseDOWList(branchId, courseId) {
    const data = {
        branchId: branchId,
        courseId: courseId
    }

    return getRequest(`/course/getCourseDOWList`, data)
}

export function getAllStudentByClassId(classId, branchId) {
    const data = {
        classId: classId,
        branchId: branchId
    }
 
    return getRequest(`/course/getAllStudentByClassId`, data)
}

export function getStudentCheckList(data) {
    return getRequest(`/course/getStudentCheckList`, data)
}

export function getClassSchedule(criteria) {
    return getRequest(`/course/getClassSchedule`, criteria)
}

export function syncCalendar(criteria) {
    return getRequest(`/course/syncCalendar`, criteria)
}

export function deleteCalendarEvent(criteria) {
    return getRequest(`/course/deleteCalendarEvent`, criteria)
}

export function setIsFeedback(studentId, classId, isFeedback) {
    const data = {
        studentId: studentId,
        classId: classId,
        isFeedback: isFeedback
    }

    return postRequest(`/course/setIsFeedback`, data)
}
