export const styles = {
  card: {
    minWidth: '300px',
    width: '45%',
    padding: '10px 20px 20px 20px',
    margin: '30px'
  },
  divider: {
    margin: '16px 8px 0px 8px'
  },
  signinButton: {
    width: '100%'
  },
  signupButton: {
    color: '#000000',
    backgroundColor: '#FCD634',
    width: '100%'
  },
  nextButton: {
    fontFamily:
      '"Varela Round", "K2D", "Roboto", "Helvetica", "Arial", sans-serif',
    width: '100%'
  },
  backButton: {
    fontFamily:
      '"Varela Round", "K2D", "Roboto", "Helvetica", "Arial", sans-serif',
    color: '#000000',
    backgroundColor: '#FCD634',
    width: '100%'
  },
  subCompletedCard: {
    background: 'none',
    boxShadow: 'none'
  },
  header: {
    fontFamily:
      '"Varela Round", "K2D", "Roboto", "Helvetica", "Arial", sans-serif'
  },
  subHeader: {
    fontFamily:
      '"Varela Round", "Pridi", "Roboto", "Helvetica", "Arial", sans-serif'
  }
}
