import React, { Component } from 'react'
import { Grid, Pagination, Table, Button, Popup } from 'semantic-ui-react'
import { withRouter } from 'react-router'
import { formatDateDisplay } from '../../../common/util'

class CodeList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableData: props.tableData,
            tableCurrentColumn: props.sorting.column,
            tableDirection: props.sorting.direction,
            activePage: 1,
            totalPages: 0,
            totalData: 0,
            pageSize: 0,
            showEditModal: false,
            selectedTeacher: null,
            extending: false
        }
    }

    static getDerivedStateFromProps(props, state) {
        if ( props !== state)
        return {
            tableData: props.tableData,
            tableCurrentColumn: props.sorting.column,
            tableDirection: props.sorting.direction,
            activePage: props.activePage,
            totalPages: props.totalPages,
            totalData: props.totalData,
            pageSize: props.pageSize
        }
    }

    handlePaginationChange = (e, data) => {
        this.props.onPaginationChange(data.activePage)
    }

    handleSort = (clickedColumn) => {
        let tableDirection = 'asc'
        if (this.state.tableCurrentColumn === clickedColumn) {
            tableDirection = this.state.tableDirection === 'asc' ? 'desc' : 'asc'
        }
        this.props.onSortingChange(clickedColumn, tableDirection)
    }

    onEditCode = (code) => {
        console.log(code)
    }

    formatProductName = (productId) => {
        if (!this.props.products) {
            return productId
        }

        return this.props.products[productId].productName
    }

    extendCode = async(code) => {
        this.setState({ extending: true })
        await this.props.extendCode(code)
        this.setState({ extending: false })
    }

    render() {
        const { tableCurrentColumn, tableDirection, tableData, totalPages, activePage, totalData, pageSize, extending } = this.state
        return (
            <Grid style={{ margin: '0' }}>
                <Table celled sortable fluid compact="very" style={{padding: '0', fontSize: "0.8em"}} size='small'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell textAlign='center' width='2'
                                            sorted={tableCurrentColumn === 'code' ? convertSorting(tableDirection) : null}
                                            onClick={() => this.handleSort('code')}>Code</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='3'
                                            sorted={tableCurrentColumn === 'productId' ? convertSorting(tableDirection) : null}
                                            onClick={() => this.handleSort('productId')}>Product</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='1'
                                            sorted={tableCurrentColumn === 'userId' ? convertSorting(tableDirection) : null}
                                            onClick={() => this.handleSort('userId')}>User ID</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='1'
                                            sorted={tableCurrentColumn === 'batchId' ? convertSorting(tableDirection) : null}
                                            onClick={() => this.handleSort('batchId')}>Batch ID</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='1.5'
                                            sorted={tableCurrentColumn === 'createdDate' ? convertSorting(tableDirection) : null}
                                            onClick={() => this.handleSort('createdDate')}>Created Date</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='1.5'
                                            sorted={tableCurrentColumn === 'activatedDate' ? convertSorting(tableDirection) : null}
                                            onClick={() => this.handleSort('activatedDate')}>Activated Date</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='1.5'
                                            sorted={tableCurrentColumn === 'expirationDate' ? convertSorting(tableDirection) : null}
                                            onClick={() => this.handleSort('expirationDate')}>Expiration Date</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='1.5'
                                            sorted={tableCurrentColumn === 'studentName' ? convertSorting(tableDirection) : null}
                                            onClick={() => this.handleSort('mobileNumber')}>Name</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='1'
                                            sorted={tableCurrentColumn === 'studentAge' ? convertSorting(tableDirection) : null}
                                            onClick={() => this.handleSort('studentAge')}>Age</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='2'
                                            sorted={tableCurrentColumn === 'studentPhone' ? convertSorting(tableDirection) : null}
                                            onClick={() => this.handleSort('studentPhone')}>Tel</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='2'
                                            sorted={tableCurrentColumn === 'studentSchool' ? convertSorting(tableDirection) : null}
                                            onClick={() => this.handleSort('studentSchool')}>School</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='3'
                                              sorted={tableCurrentColumn === 'note' ? convertSorting(tableDirection) : null}
                                              onClick={() => this.handleSort('note')}>Note</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='0.5'></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {!tableData || tableData.length === 0 ? (
                            <Table.Row>
                                <Table.Cell verticalAlign='middle' warning textAlign='center' colSpan='11'style={{ width: '1000px' }}>
                                    No Data Found </Table.Cell>
                            </Table.Row>) : (
                            tableData.map((item, index) => (
                                <Table.Row key={index}>
                                    <Table.Cell verticalAlign='middle' textAlign='center'> {item.code} </Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign={window.innerWidth > 760 ? 'left':'center'}> 
                                        {this.formatProductName(item.productId)} 
                                    </Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign={window.innerWidth > 760 ? 'left':'center'}> 
                                        {item.userId}
                                    </Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign={window.innerWidth > 760 ? 'left':'center'}> 
                                        {String(item.batchId).padStart(5, '0')}
                                    </Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign='center'>
                                        {formatDateDisplay(item.createdDate)}
                                    </Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign='center'>
                                        {formatDateDisplay(item.activatedDate)}
                                    </Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign='center'>
                                        {formatDateDisplay(item.expirationDate)}
                                    </Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign={window.innerWidth > 760 ? 'left':'center'}> 
                                        {item.studentName}
                                    </Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign='center'>{item.studentAge}</Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign='center'>{item.studentPhone}</Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign='center'>{item.studentSchool}</Table.Cell>
                                    <Table.Cell verticalAlign='middle'>{item.note}</Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign='center'>
                                        <div>
                                            <Button
                                                onClick={() => this.props.openModal(item)}
                                                style={{backgroundColor: 'white'}}>
                                                <i className='pencil icon' style={{fontSize: '1em'}}/>
                                            </Button>
                                        </div>
                                    </Table.Cell>
                                    {/* <Table.Cell verticalAlign='middle' textAlign='center'>
                                        <Button
                                            onClick={() => this.onEditCode(item.code)}
                                            style={{backgroundColor: 'white'}}>
                                        <i className='pencil icon' style={{fontSize: '1em'}}/>
                                        </Button>
                                    </Table.Cell> */}
                                </Table.Row>)
                            ))
                        }
                    </Table.Body>
                </Table>
                <Grid.Row columns={2}>
                    <Grid.Column textAlign={window.innerWidth > 760 ? 'left':'center'} verticalAlign='middle' computer='8' tablet='4' mobile='16'>
                        {totalData < 1 || !totalData
                            ? null
                            : `${((activePage - 1) * pageSize) + 1}-${((activePage - 1) * pageSize) + tableData.length} of ${totalData} `
                        }
                    </Grid.Column>
                    <Grid.Column textAlign={window.innerWidth > 760 ? 'right':'center'} verticalAlign='middle' computer='8' tablet='12' mobile='16'>
                        {totalPages < 1 || !totalPages ? null : (
                            <Pagination
                                defaultActivePage = {activePage}
                                pointing
                                secondary
                                boundaryRange={window.innerWidth > 760 ? 1:0}
                                siblingRange={window.innerWidth > 760 ? 1:0}
                                ellipsisItem={window.innerWidth > 760 ? undefined : null}
                                totalPages={totalPages}
                                onPageChange={this.handlePaginationChange}
                            />
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

export default withRouter(CodeList)

const convertSorting = (type) => {
    return type === 'asc' ? 'ascending' : 'descending'
}
