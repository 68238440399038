import React from 'react'
import { Accordion, Button, Dropdown, Form, Grid, Icon, Message, Radio, Segment } from 'semantic-ui-react'
import { withRouter } from 'react-router'
import apiClient from '../../apis'
import styles from './styles'
import MainLayout from '../../layouts/MainLayout'
import TeacherCheckList from './TeacherCheckList';
import { ROLE } from '../../common/const';
import { getCurrentUser } from '../../common/util'

class TeacherCheck extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            formData: {
                date: '',
                teacherId: '',
                checkIn: '',
                checkOut: ''
            },
            checkInOutData: {
                teacherId: '',
                check: 'IN',
                userId: ''
            },
            tableData: [],
            teacherList: [],
            currentRole: '',
            msgStatus: '',
            msgMessage: '',
            isLoading: false,
            isShowTool: true
        }
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    componentDidMount() {
        this.getTeacherList()

        let userDetail = getCurrentUser()
        if (userDetail.role === ROLE.ADMIN || userDetail.role === ROLE.GOD) {
            this.getTeacherCheckList()
        }

        let currentDate = new Date();
        this.setState({
            checkInOutData: {
                ...this.state.checkInOutData,
                check: currentDate.getHours() > 12 ? 'OUT' : 'IN',
                userId: userDetail.userId
            },
            currentRole: userDetail.role
        })
    }

    onSave = (form) => {
        this.setState({
            msgStatus: '',
            msgMessage: '',
            isLoading: true
        })

        apiClient.teacher.setTeacherCheck(form)
            .then(res => {
                this.setState({
                    msgStatus: res.status,
                    msgMessage: res.msg
                })
                setTimeout(() => {
                    this.setState({ msgStatus: '', msgMessage: '' })
                }, 5000)

                this.getTeacherCheckList()
            })
    }

    getTeacherList = () => {
        this.setState({
            isLoading: true
        })

        apiClient.teacher.getTeacherList()
            .then(res => {
                if (res !== null) {
                    let arr = res.map((p, index) => {
                        return {
                            key: index,
                            text: p.name,
                            value: p.id
                        }
                    })
                    this.setState({
                        teacherList: arr,
                        isLoading: false
                    })
                }
            })
    }

    getTeacherCheckList = () => {
        this.setState({
            isLoading: true
        })

        apiClient.teacher.getTeacherCheckList()
            .then(res => {
                this.setState({
                    tableData: res,
                    isLoading: false
                })
            })
    }

    handleInputChange = (e, value) => {
        if (value.name === 'checkInOut') {
            this.setState({
                checkInOutData: {
                    ...this.state.checkInOutData,
                    check: value.value
                }
            })
        } else {
            this.setState({
                checkInOutData: {
                    ...this.state.checkInOutData,
                    [value.id]: value.value
                }
            })
        }
    }

    renderDetailCard = () => {
        return (
            <TeacherCheckList
                tableData={this.state.tableData}
                getTeacherCheckList={this.getTeacherCheckList}
            />
        )
    }

    showError = () => {
        if (this.state.msgMessage !== '') {
            return (
                <Message
                    success={this.state.msgStatus === 'SUCCESS'}
                    error={this.state.msgStatus === 'ERROR'}
                    icon='exclamation circle'
                    content={this.state.msgMessage}
                    size='small'
                />
            )
        }
    }

    render() {
        const { teacherId } = this.state.checkInOutData

        return (
            <MainLayout id='react-no-print'>
                <div style={styles.container}>
                    <Grid centered stackable>
                        <Grid.Row>
                            <Grid.Column>{this.showError()}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Segment padded>
                                <Grid.Column>
                                    <Form size='large'>
                                        <Grid>
                                            <Grid.Row centered>
                                                <Grid.Column>

                                                    <Form.Field>
                                                        <label>Teacher</label>
                                                        <Dropdown id='teacherId' placeholder='Select' fluid search selection
                                                            options={this.state.teacherList}
                                                            onChange={this.handleInputChange} />
                                                    </Form.Field>

                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row centered>
                                                <Form.Group inline>
                                                    <label>Check in - out</label>
                                                    <Form.Radio

                                                        label='In' name='checkInOut' value='IN'
                                                        checked={this.state.checkInOutData.check === 'IN'}
                                                        onChange={this.handleInputChange}
                                                    />
                                                    <Form.Radio

                                                        label='Out' name='checkInOut' value='OUT'
                                                        checked={this.state.checkInOutData.check === 'OUT'}
                                                        onChange={this.handleInputChange}
                                                    />
                                                </Form.Group>
                                            </Grid.Row>
                                            <Grid.Row centered>
                                                <Grid.Column>
                                                    <Button fluid positive disabled={!teacherId}
                                                        onClick={() => this.onSave(this.state.checkInOutData)} content='Save' />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Form>
                                </Grid.Column>
                            </Segment>
                        </Grid.Row>
                        {(this.state.currentRole === ROLE.ADMIN || this.state.currentRole === ROLE.GOD)  &&
                            <Grid.Row centered>
                                <Grid.Column>
                                    <Accordion>
                                        <Accordion.Title active={this.state.isShowTool}
                                            onClick={() => this.setState({ isShowTool: !this.state.isShowTool })}>
                                            <Icon name='dropdown' />
                                        Show Detail
                                    </Accordion.Title>
                                        <Accordion.Content active={this.state.isShowTool}>
                                            {this.renderDetailCard()}
                                        </Accordion.Content>
                                    </Accordion>
                                </Grid.Column>
                            </Grid.Row>}
                    </Grid>
                </div>
            </MainLayout>
        )
    }
}

export default withRouter(TeacherCheck)
