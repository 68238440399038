export const styles = {
  column: {
    height: '350px',
    padding: '2px'
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
}
