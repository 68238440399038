export default {
    container: {
        padding: '50px 30px',
        display: 'flex'
    },
    nationalityItem: {
        marginRight: '25px'
    },
    availableDateLabel: {
        fontWeight: '500',
        alignSelf: 'center',
        lineHeight: '1.5em'
    },
    nationalityButton: {
        margin: '10px'
    },
    primaryColor: {
        color: '#00B3C5'
    },
    successContainer: {
        padding: '2em',
        margin: 'auto',
        lineHeight: '2em',
        fontSize: '1.2em',
        width: 'auto'
    },
    goToHomeButton: {
        marginTop: '1em'
    },
}