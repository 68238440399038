import React, { Component } from 'react'
import { Button, Grid, Pagination, Table,} from 'semantic-ui-react'
import { withRouter } from 'react-router'


class TeacherList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			tableData: props.tableData,
			tableCurrentColumn: props.sorting.column,
			tableDirection: props.sorting.direction,
			activePage: 1,
			totalPages: 0,
			totalData: 0,
			pageSize: 0,
			showEditModal: false,
			selectedTeacher: null
		}
	}

	static getDerivedStateFromProps(props, state) {
		if ( props !== state)
		return {
			tableData: props.tableData,
			tableCurrentColumn: props.sorting.column,
			tableDirection: props.sorting.direction,
			activePage: props.activePage,
			totalPages: props.totalPages,
			totalData: props.totalData,
			pageSize: props.pageSize
		}
	}

	handlePaginationChange = (e, data) => {
		this.props.onPaginationChange(data.activePage)
	}

	handleSort = (clickedColumn) => {
        let tableDirection = 'asc'
        if (this.state.tableCurrentColumn === clickedColumn) {
            tableDirection = this.state.tableDirection === 'asc' ? 'desc' : 'asc'
        }
        this.props.onSortingChange(clickedColumn, tableDirection)
    }

	onEditTeacher = (teacherId) => {
		this.props.pushEditTeacher(teacherId)
	}

	render() {
		const { tableCurrentColumn,tableDirection,tableData,totalPages,activePage,totalData,pageSize } = this.state
		
		return (
			<Grid style={{ margin: '0' }}>
				<Table celled sortable compact style={{padding: '0'}} size='small'>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell textAlign='center' width='2'
                                            sorted={tableCurrentColumn === 'teacherId' ? convertSorting(tableDirection) : null}
                                            onClick={() => this.handleSort('teacherId')}>Teacher ID</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='3'
                                            sorted={tableCurrentColumn === 'name' ? convertSorting(tableDirection) : null}
                                            onClick={() => this.handleSort('name')}>Name</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='2'
                                            sorted={tableCurrentColumn === 'nickName' ? convertSorting(tableDirection) : null}
                                            onClick={() => this.handleSort('nickName')}>Nickname</Table.HeaderCell>
							<Table.HeaderCell textAlign='center' width='2'
											sorted={tableCurrentColumn === 'mobileNumber' ? convertSorting(tableDirection) : null}
											onClick={() => this.handleSort('mobileNumber')}>Tel</Table.HeaderCell>
							<Table.HeaderCell textAlign='center' width='2'
											sorted={tableCurrentColumn === 'isFulltime' ? convertSorting(tableDirection) : null}
											onClick={() => this.handleSort('isFulltime')}>Type</Table.HeaderCell>
							<Table.HeaderCell textAlign='center' width='1'></Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{!tableData || tableData.length === 0 ? (
							<Table.Row>
								<Table.Cell verticalAlign='middle' warning textAlign='center' colSpan='9'style={{ width: '1000px' }}>
									No Data Found </Table.Cell>
							</Table.Row>) : (
							tableData.map((item, index) => (
								<Table.Row key={index}>
									<Table.Cell verticalAlign='middle' textAlign='center'> {item.teacherId} </Table.Cell>
									<Table.Cell verticalAlign='middle' textAlign={window.innerWidth > 760 ? 'left':'center'}> 
										{item.name} 
									</Table.Cell>
									<Table.Cell verticalAlign='middle' textAlign={window.innerWidth > 760 ? 'left':'center'}> 
										{item.nickName} 
									</Table.Cell>
									<Table.Cell verticalAlign='middle'textAlign='center'> {item.mobileNumber}</Table.Cell>
									<Table.Cell verticalAlign='middle' textAlign='center'>
                                        {item.isFulltime === 1 ? 'Full-time' : 'Part-time'}</Table.Cell>
									<Table.Cell verticalAlign='middle' textAlign='center'>
										<Button
											onClick={() => this.onEditTeacher(item.teacherId)}
											style={{backgroundColor: 'white'}}>
										<i className='pencil icon' style={{fontSize: '1em'}}/>
                                        </Button>
                                    </Table.Cell>
								</Table.Row>)
							))
						}
					</Table.Body>
				</Table>
				<Grid.Row columns={2}>
                    <Grid.Column textAlign={window.innerWidth > 760 ? 'left':'center'} verticalAlign='middle' computer='8' tablet='4' mobile='16'>
                        {totalData < 1 || !totalData
                            ? null
                            : `${((activePage - 1) * pageSize) + 1}-${((activePage - 1) * pageSize) + tableData.length} of ${totalData} `
                        }
                    </Grid.Column>
                    <Grid.Column textAlign={window.innerWidth > 760 ? 'right':'center'} verticalAlign='middle' computer='8' tablet='12' mobile='16'>
						{totalPages < 1 || !totalPages ? null : (
							<Pagination
                                defaultActivePage = {activePage}
								pointing
                                secondary
                                boundaryRange={window.innerWidth > 760 ? 1:0}
                                siblingRange={window.innerWidth > 760 ? 1:0}
                                ellipsisItem={window.innerWidth > 760 ? undefined : null}
                                totalPages={totalPages}
								onPageChange={this.handlePaginationChange}
                            />
						)}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
		)
	}
}

export default withRouter(TeacherList)

const convertSorting = (type) => {
	return type === 'asc' ? 'ascending' : 'descending'
}
