import { getRequest, postRequest } from '../lib/request'
import { getToken, setToken, removeToken } from '../common/util'

export function authenticate(email, password, facebookId) {
    const data = {
        email: email,
        password: password,
        facebookId: facebookId
    }
    return postRequest(`/auth/authenticate`, data)
}

export function authenticateWithUuid(uuid) {
    const data = {
        uuid: uuid
    }
    return postRequest(`/auth/readonly-authenticate`, data)
}

export async function validateToken() {
    let token = getToken()
    if (!token) {
        return false
    }

    const data = { token }
    let res = await postRequest(`/auth/validateToken`, data, false)

    if(res.success){
        if(token !== res.token){
            setToken(res.token)  
        }
        return true
    }else{
        removeToken()
        return false
    }
}

export function logout(req) {
    return getRequest('/auth/logout')
}
