export default {
    errorMessageContainer: {
        padding: '0.8em',
        margin: '0',
    },
    formLabel: {
        color: 'rgba(0,0,0,.87)',
        fontSize: '1.05em',
        fontWeight: '600',
        textTransform: 'none'
    },
    formLabelDisable: {
        display: 'block',
        margin: '0 0 .28571429rem 0',
        color: 'rgba(0,0,0,.87)',
        fontSize: '.92857143em',
        fontWeight: '700',
        textTransform: 'none',
        opacity: '.45'
    },
    buttonGroupContainer: {
        paddingBottom:'14px'
    }
}
