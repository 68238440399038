import { withTranslation } from 'react-i18next'
import i18n from '../i18n'

const defaultNamesapces = ['navbar', 'footer', 'common']

export const withI18next = (namespaces = ['common']) => ComposedComponent => {
	const Extended = withTranslation(namespaces, { i18n, wait: process.browser })(
		ComposedComponent
	)

	Extended.getInitialProps = async ctx => {
		const composedInitialProps = ComposedComponent.getInitialProps
			? await ComposedComponent.getInitialProps(ctx)
			: {}

		const namespacesWithDefault = namespaces
			.slice()
			.concat(defaultNamesapces)
		const i18nInitialProps = ctx.req
			? i18n.getInitialProps(ctx.req, namespacesWithDefault)
			: {}

		return {
			...composedInitialProps,
			...i18nInitialProps
		}
	}

	return Extended
}
