import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

import { styles } from './styles'

class CGButton extends React.Component {
  renderLink() {
    const { children, style, ...args } = this.props
    return (
      <Button as={Link} style={{ ...styles.link, ...style }} {...args}>
        {children}
      </Button>
    )
  }
  renderButton() {
    const { children, style, ...args } = this.props
    return (
      <Button style={{ ...styles.button, ...style }} {...args}>
        {children}
      </Button>
    )
  }
  render() {
    const { type } = this.props
    if (type === 'link') {
      return this.renderLink()
    } else {
      return this.renderButton()
    }
  }
}

export default CGButton
