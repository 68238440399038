import {getRequest, postRequest} from '../lib/request'

export function getUser() {
    return getRequest(`/user/me`)
}

export function updateUserUuid(userId, uuid) {
    return postRequest(`/user/updateUuid`, {userId, uuid})
}

export function updateStudentUserUuid(studentId, uuid) {
    return postRequest(`/user/updateUuid`, {studentId, uuid})
}

export function getStudentUserId(studentId) {
    return getRequest(`/user/getStudentUserId`, {studentId})
}
