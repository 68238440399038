import React from 'react'
import { withI18next } from '../../lib/withI18next'

import MainLayout from '../../layouts/MainLayout'
import CGHeadline from '../../components/common/CGHeadline'
import AdvancedPhotoGrid from '../../components/AdvancedPhotoGrid'

import tileData from './data'
import { styles } from './styles'

class cggallery extends React.Component {
  render() {
    const { t } = this.props
    return (
      <MainLayout showLang={true}>
        <div style={styles.header}>
          <CGHeadline header={t('header')} />
        </div>
        <AdvancedPhotoGrid tileData={tileData} />
      </MainLayout>
    )
  }
}

export default withI18next(['cggallery'])(cggallery)
