import React from 'react'
import { withI18next } from '../../lib/withI18next'
import { Grid, Image, Embed } from 'semantic-ui-react'
import Carousel from 'nuka-carousel'

import MainLayout from '../../layouts/MainLayout'
import CGHeadline from '../../components/common/CGHeadline'
import Roadmap from '../../components/Roadmap'
import AdvancedPhotoGrid from '../../components/AdvancedPhotoGrid'

import tileData from './previewPhotoList'
import { styles } from './styles'

class home extends React.Component {
  static sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  }

  render() {
    const { t } = this.props
    return (
      <MainLayout showLang={true}>
        <Carousel
          slideWidth={1}
          wrapAround={true}
          autoplay={true}
          speed={850}
          renderCenterRightControls={({ nextSlide }) => (
            <span style={styles.sliderControl} onClick={nextSlide}>
              &gt;
            </span>
          )}
          renderCenterLeftControls={({ previousSlide }) => (
            <span style={styles.sliderControl} onClick={previousSlide}>
              &lt;
            </span>
          )}
        >
          <img style={styles.adContent} src={t('advertisement.2')} alt="ad2" onClick={() => this.props.history.push('/StudentDemo/Add')} />
          <img style={styles.adContent} src={t('advertisement.1')} alt="ad1" />
        </Carousel>
        <div>
          <Grid style={styles.whatWeDoSection}>
            <Grid.Row centered>
              <CGHeadline
                header={t('whatWeDo.header')}
                subHeader={t('whatWeDo.subHeading')}
              />
            </Grid.Row>
            <Grid.Row>
              <Grid.Column largeScreen={5} mobile={16} centered>
                <WhatWeDo
                  icon={`${process.env.PUBLIC_URL}/images/Home/think.png`}
                  header={t('whatWeDo.think.header')}
                  content={t('whatWeDo.think.content')}
                />
              </Grid.Column>
              <Grid.Column largeScreen={6} mobile={16} centered>
                <WhatWeDo
                  icon={`${process.env.PUBLIC_URL}/images/Home/solve.png`}
                  header={t('whatWeDo.solve.header')}
                  content={t('whatWeDo.solve.content')}
                />
              </Grid.Column>
              <Grid.Column largeScreen={5} mobile={16} centered>
                <WhatWeDo
                  icon={`${process.env.PUBLIC_URL}/images/Home/innovate.png`}
                  header={t('whatWeDo.innovate.header')}
                  content={t('whatWeDo.innovate.content')}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
              <Grid.Column largeScreen={12} mobile={16} centered>
                <div
                  style={{
                    ...styles.textSecondary,
                    color: '#00b3c5'
                  }}
                >
                  {t('whatWeDo.concept')}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <div>
          <AdvancedPhotoGrid
            tileData={tileData}
            previewFlag={true}
            height={280}
          />
        </div>
        <div>
          <Grid style={{ margin: '0 0 5em 0' }}>
            <Grid.Row
              only="large screen"
              largeScreen={12}
              style={styles.quoteSection}
            >
              <Grid.Column largeScreen={12} style={styles.quoteContainer}>
                {t('quote')}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row
              only="tablet mobile"
              mobile={16}
              style={{ ...styles.quoteSection, backgroundImage: 'none' }}
            >
              <Grid.Column
                style={{ ...styles.quoteContainer, fontSize: '1em' }}
              >
                {t('quote')}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <div style={styles.roadmapSection}>
          <Roadmap />
        </div>
        <div style={styles.videoSection}>
          <CGHeadline
            header={t('feature.header')}
            subHeader={t('feature.subHeading')}
          />
          <Grid style={{ marginTop: '1em' }}>
            <Grid.Row>
              <Grid.Column
                largeScreen={5}
                mobile={16}
                style={styles.featureContentBlock}
              >
                <Feature
                  content={t('feature.content1')}
                  footer={t('feature.footer1')}
                />
              </Grid.Column>
              <Grid.Column
                largeScreen={6}
                mobile={16}
                style={{ ...styles.featureContentBlock }}
              >
                <Feature
                  content={t('feature.content2')}
                  footer={t('feature.footer2')}
                />
              </Grid.Column>
              <Grid.Column
                largeScreen={5}
                mobile={16}
                style={styles.featureContentBlock}
              >
                <Feature
                  content={t('feature.content3')}
                  footer={t('feature.footer3')}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid style={{ marginBottom: '2.5em' }}>
            <Grid.Column only="large screen" largeScreen={5} />
            <Grid.Column largeScreen={6} mobile={16}>
              <Embed id="nKIu9yen5nc" source="youtube" active />
            </Grid.Column>
            <Grid.Column only="large screen" largeScreen={5} />
          </Grid>
        </div>
      </MainLayout>
    )
  }
}

export default withI18next(['home'])(home)

const WhatWeDo = ({ classes, icon, header, content }) => (
  <div style={styles.whatWeDoContainer}>
    <div>
      <Image src={icon} style={styles.whatWeDoIcon} title={header} />
    </div>
    <div style={styles.textPrimary}>{header}</div>
    <div style={styles.textSecondary}>{content}</div>
  </div>
)

const Feature = ({ classes, content, footer }) => (
  <div style={styles.featureContentContainer}>
    <div style={{ ...styles.featureContent }}>{content}</div>
    <div style={{ ...styles.featureFooter }}>{footer}</div>
  </div>
)
