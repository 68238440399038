import React from 'react'
import {Accordion, Button, Card, Dropdown, Form, Grid, Icon, Input, Label} from 'semantic-ui-react'
import {withRouter} from 'react-router'
import apiClient from '../../../apis'
import MainLayout from '../../../layouts/MainLayout'
import DataList from './DataList'
import {STUDENT_DEMO_STATUS, STUDENT_DEMO_STATUS_TEXT, ROLE} from '../../../common/const'
import { validateRole, getCurrentUser, getUserRole } from '../../../common/util'
import styles from './styles'
import ChangeStatusModal from './ChangeStatusModal'


class Search extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            searchData: {
                name: '',
                branchId: '',
                mobileNumber: '',
                statuses: [],
                orderBy: 'availableDate',
                orderType: 'desc',
                page: 1,
                pageSize: 100
            },
            isAdmin: validateRole([ROLE.ADMIN]),
            adminBranch: getCurrentUser().branchId,
            branchList: [],
            total: undefined,
            tableData: undefined,
            isLoading: false,
            roleOptions: undefined,
            isShowTool: true,
            editData: {}
        }
        this.handleSortingChange = this.handleSortingChange.bind(this)
        this.handlePaginationChange = this.handlePaginationChange.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    componentDidMount() {
        this.getBranchList()
        this.doSearch()
    }

    getBranchList = async () => {
        const {adminBranch, branchList} = this.state
        const result = await apiClient.branch.getAllBranches()

        if (result !== null){
			let arr = result.dataList.map(p => {
                return {
                    key: p.branchId,
                    text: p.branchName,
                    value: p.branchId,
                }
			})

			if (getUserRole() === ROLE.ADMIN || getUserRole() === ROLE.GOD){
				arr.unshift({key: 0, text: 'All', value: ''})
            }
            
            if  ( adminBranch === 0 ) {
                this.setState({ 
                    searchData: {
                        ...this.state.searchData,
                        branchId: '',
                    },
                    branchList: arr,
                    isLoading: false,
                })
            }else{
                this.setState({ 
                    searchData: {
                        ...this.state.searchData,
                        branchId: adminBranch,
                    },
                    branchName: arr[adminBranch].text,
                    branchList: arr,
                    isLoading: false,
                })
            }
		}
    }


    doSearch = async () => {
        this.setState({
            isLoading: true
        })
        const data = this.state.searchData
        let searchData = {}
        for (const key in data) {
            if (data[key] !== '' && data[key] !== null && data[key] !== undefined && data[key] !== 'undefined') {
                searchData[key] = data[key]
            }
        }

        searchData.branchId = (searchData.branchId === 'All') ? '' : searchData.branchId
        searchData.branchId = (getCurrentUser().branchId && getCurrentUser().branchId !== 0) ? getCurrentUser().branchId : searchData.branchId
        
        const result = await apiClient.studentDemo.search(searchData)
        this.setState({
            tableData: result.data,
            total: result.total,
            isLoading: false
        })
    }

    handlePaginationChange = activePage => {
        this.setState({
            searchData: {
                ...this.state.searchData,
                page: activePage,
            }
        }, this.doSearch)
    }

    searchByInitialSetting = () => {
        this.setState({
            searchData: {
                ...this.state.searchData,
                orderBy: 'availableDate',
                orderType: 'desc',
                page: 1
            }
        }, this.doSearch)
    }

    handleInputChange = (e, {name, value}) => {
        this.setState({
            searchData: {
                ...this.state.searchData,
                [name]: value
            }
        })
    }

    handleDropdownChange = (e, value) => {
        this.setState({
            searchData: {
                ...this.state.searchData,
                [value.name]: value.value
            }
        })
    }

    handleSortingChange = (orderBy, orderType) => {
        this.setState({
            searchData: {
                ...this.state.searchData,
                orderBy: orderBy,
                orderType: orderType
            }
        }, this.doSearch)
    }

    handleEditItemClick = (data) => {
        this.setState({
            showEditModal: true,
            editData: data
        })
    }

    handleEditItemClose = (data) => {
        this.doSearch()
        this.setState({
            showEditModal: false,
            editData: {},
            tableData: data?this.state.tableData.map( d => {
                if (d.studentDemoId === data.studentDemoId) {
                    return data
                }
                return d
            }):this.state.tableData
        })
    }

    handleEditItemSave = (data) => {
        this.setState({ isLoading: true })
        this.handleEditItemClose()
        this.setState({ isLoading: false })
    }

    renderSearchCard = () => {
        const {branchList, adminBranch} = this.state
        const {branchId, name, statuses, availableDateFrom, availableDateTo, createdDateFrom, createdDateTo, couponDateFrom, couponDateTo} = this.state.searchData
        
        return (
            <Card fluid>
                <Card.Content>
                    <Form fluid>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <Form.Group widths='equal'>
                                    {adminBranch === 0 ? 
                                        (<Form.Field>
                                            <label>Branch</label>
                                                <Dropdown
                                                    name='branchId'
                                                    placeholder= 'All'
                                                    fluid search selection
                                                    value={branchId}
                                                    onChange={this.handleDropdownChange}
                                                    options={branchList}
                                                />
                                        </Form.Field>)
                                    : null}
                                    <Form.Field>
                                        <label>Name</label>
                                        <Input name='name' fluid placeholder='' type='text' value={name}
                                                onChange={this.handleInputChange}/>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Status</label>
                                        <Dropdown
                                            name='statuses'
                                            placeholder='All'
                                            onChange={this.handleInputChange}
                                            value={statuses}
                                            options={statusOptions}
                                            fluid
                                            multiple
                                            selection
                                        />
                                    </Form.Field>
                                </Form.Group>
                            </Form.Field>
                            <Form.Field>
                                <Form.Group  widths='equal'>
                                    <Form.Field>
                                        <label>Registered Date</label>
                                        <Grid.Column computer={8}>
                                            <Input
                                                labelPosition='left'
                                                name='createdDateFrom'
                                                type='date'
                                                value={createdDateFrom}
                                                onChange={this.handleInputChange}
                                                size='small'
                                                style={styles.fromToDateInput}
                                            >
                                                <Label>From</Label>
                                                <input style={styles.fromDateInput} />
                                            </Input>
                                            <Input
                                                labelPosition='left'
                                                name='createdDateTo'
                                                type='date'
                                                value={createdDateTo}
                                                onChange={this.handleInputChange}
                                                size='small'
                                                style={styles.fromToDateInput}
                                            >
                                                <Label style={styles.ToDateLabel}>To</Label>
                                                <input style={styles.ToDateInput} />
                                            </Input>
                                        </Grid.Column>
                                    </Form.Field>
                                </Form.Group>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Available Date</label>
                                <Grid.Column computer={8}>
                                    <Input
                                        labelPosition='left'
                                        name='availableDateFrom'
                                        type='date'
                                        value={availableDateFrom}
                                        onChange={this.handleInputChange}
                                        size='small'
                                        style={styles.fromToDateInput}
                                    >
                                        <Label>From</Label>
                                        <input style={styles.fromDateInput} />
                                    </Input>
                                    <Input
                                        labelPosition='left'
                                        name='availableDateTo'
                                        type='date'
                                        value={availableDateTo}
                                        onChange={this.handleInputChange}
                                        size='small'
                                        style={styles.fromToDateInput}
                                    >
                                        <Label style={styles.ToDateLabel}>To</Label>
                                        <input style={styles.ToDateInput} />
                                    </Input>
                                </Grid.Column>
                            </Form.Field>
                            <Form.Field>
                                <label>Coupon Exp.</label>
                                <Grid.Column computer={8}>
                                    <Input
                                        labelPosition='left'
                                        name='couponDateFrom'
                                        type='date'
                                        value={couponDateFrom}
                                        onChange={this.handleInputChange}
                                        size='small'
                                        style={styles.fromToDateInput}
                                    >
                                        <Label>From</Label>
                                        <input style={styles.fromDateInput} />
                                    </Input>
                                    <Input
                                        labelPosition='left'
                                        name='couponDateTo'
                                        type='date'
                                        value={couponDateTo}
                                        onChange={this.handleInputChange}
                                        size='small'
                                        style={styles.fromToDateInput}
                                    >
                                        <Label style={styles.ToDateLabel}>To</Label>
                                        <input style={styles.ToDateInput} />
                                    </Input>
                                </Grid.Column>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Card.Content>
                <Card.Content extra textAlign='right'>
                    <Button primary onClick={() => this.searchByInitialSetting()}>
                        Search
                    </Button>
                </Card.Content>
            </Card>
        )
    }

    render() {
        return (
            <MainLayout>
                <div style={styles.container}>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column>
                                <Accordion fluid>
                                    <Accordion.Title 
                                        active={this.state.isShowTool}                
                                        onClick={() => this.setState({isShowTool: !this.state.isShowTool})}
                                    >
                                        <Icon name='dropdown'/>
                                        Search
                                    </Accordion.Title>
                                    <Accordion.Content active={this.state.isShowTool}>
                                        {this.renderSearchCard()}
                                    </Accordion.Content>
                                </Accordion>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <DataList
                                    tableData={this.state.tableData}
                                    onPaginationChange={this.handlePaginationChange}
                                    onSortingChange={this.handleSortingChange}
                                    totalPages={Math.ceil(this.state.total / this.state.searchData.pageSize)}
                                    totalData={this.state.total}
                                    pageSize={this.state.searchData.pageSize}
                                    activePage={this.state.searchData.page}
                                    sorting={{
                                        column: this.state.searchData.orderBy,
                                        direction: this.state.searchData.orderType
                                    }}
                                    onEditItemClick={this.handleEditItemClick}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
                <ChangeStatusModal 
                    open={this.state.showEditModal} 
                    onClose={this.handleEditItemClose} 
                    onSave={this.handleEditItemSave} 
                    data={this.state.editData}/>
            </MainLayout>
        )
    }
}

export default withRouter(Search)

const statusOptions = Object.values(STUDENT_DEMO_STATUS).map(status => ({
    key: status, text: STUDENT_DEMO_STATUS_TEXT[status], value: status
}))