import React from 'react'
import { withRouter } from 'react-router'
import { withI18next } from '../../lib/withI18next'
import {
  Grid,
  Segment,
  Form,
  Input,
  Icon,
  Message,
  Divider
} from 'semantic-ui-react'

import { Spinner } from '../../components/common'
import FacebookButton from '../../components/common/FacebookButton'
import MainLayout from '../../layouts/MainLayout'
import CGButton from '../../components/common/CGButton/index'
import AddUserInfo from '../../components/AddUserInfo'
import apiClient from '../../apis'
import { ROLE } from '../../common/const'
import { setToken, getCurrentUser } from '../../common/util'

import { styles } from './styles'

class signin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      showPassword: false,
      errorMsg: '',
      signUpWithFacebook: false,
      facebookInfo: null,
      isLoading: false
    }
  }

  componentDidMount() {
    const uuid = this.props.match.params.uuid
    if (uuid && uuid.length > 0) {
        this.signIn({ uuid })
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  submitSignIn = async facebook => {
    this.signIn({ facebook })
  }

  signIn = async ({ facebook, uuid }) => {
    this.setState({
      isLoading: true
    })
    let result
    if (uuid) {
      result = await apiClient.auth.authenticateWithUuid(uuid)
    } else {
      result = await apiClient.auth.authenticate(this.state.email, this.state.password, facebook.id)
    }
    this.setState({
      isLoading: false
    })
    if (result.success) {
      setToken(result.token)
      switch(getCurrentUser().role) {}
      if (getCurrentUser().role == ROLE.ADMIN || getCurrentUser().role == ROLE.GOD)
        this.props.history.push('/StudentClass/Add')
      else if (getCurrentUser().role == ROLE.CUSTOMER)
        this.props.history.push('/home')
      else if(getCurrentUser().role == ROLE.ACCOUNT)
        this.props.history.push('/receipt/search')
      else
        this.props.history.push('/schedule')
    } else {
      if (facebook && facebook.id) {
        this.setState({
          signUpWithFacebook: true,
          facebookInfo: facebook
        })
      } else {
        this.setState({
          errorMsg: result.message
        })
      }
    }
  }

  hideAddUserInfo = () => {
    this.setState({
      signUpWithFacebook: false,
      facebookInfo: null
    })
  }

  showError = () => {
    if (this.state.errorMsg !== '') {
      return (
        <Message
          color='red'
          icon='exclamation circle'
          content={this.state.errorMsg}
          size='small'
          style={{textAlign:'left'}}
        />
      )
    }
  }

  renderContent() {
    if (this.state.isLoading) {
      return <Spinner />
    }
    if (this.state.signUpWithFacebook) {
      return (
        <AddUserInfo
          email={this.state.facebookInfo.email}
          facebookId={this.state.facebookInfo.id}
          hideFunction={this.hideAddUserInfo}
        />
      )
    } else {
      return (
        <Form>
          <Form.Field>{this.showError()}</Form.Field>
          <Form.Field>
            <Input
              placeholder="Email Address"
              value={this.state.email}
              onChange={this.handleChange('email')}
            />
          </Form.Field>
          <Form.Field>
            <Input
              placeholder="Password"
              type={this.state.showPassword ? 'text' : 'password'}
              value={this.state.password}
              onChange={this.handleChange('password')}
              icon={
                <Icon
                  name={this.state.showPassword ? 'unhide' : 'hide'}
                  link
                  onClick={this.handleClickShowPassword}
                />
              }
            />
          </Form.Field>
          <Form.Field>
            <CGButton style={styles.signinButton} onClick={this.submitSignIn}>
              Sign In
            </CGButton>
          </Form.Field>
          <Form.Field>
            <FacebookButton
              name="Sign In with Facebook"
              submitFacebookSignIn={this.submitSignIn}
              style={styles.signinButton}
            />
          </Form.Field>
          <Form.Field>
            <Divider style={styles.divider} />
          </Form.Field>
          <Form.Field>
            <CGButton type="link" to={'/signup'} style={styles.signupButton}>
              Create a new account
            </CGButton>
          </Form.Field>
        </Form>
      )
    }
  }

  render() {
    return (
      <MainLayout showLang={false}>
        <div>
          <Grid>
            <Grid.Row centered>
              <Segment style={styles.card}>{this.renderContent()}</Segment>
            </Grid.Row>
          </Grid>
        </div>
      </MainLayout>
    )
  }
}

export default withRouter(withI18next(['signin'])(signin))
