import React from "react"
import { withI18next } from "../../lib/withI18next"
import { Grid } from "semantic-ui-react"

import apiClient from "../../apis"
import CameraPhoto from "jslib-html5-camera-photo"
import MainLayout from "../../layouts/MainLayout"
import ProfilePhoto from "../../components/ProfilePhoto"
import StudentForm from "../../components/StudentForm"
import { Spinner } from "../../components/common"
import { withRouter } from "react-router"
import queryString from "query-string"
import { styles } from './styles'
import { Dimmer } from 'semantic-ui-react'

class addstudent extends React.Component {
	constructor(props) {
		super(props);
		this.cameraPhoto = null;
		this.videoRef = React.createRef();
		const criteria = queryString.parse(props.location.search);
		this.studentDemoId = criteria.studentDemoId;
		this.state = {
			studentData: null,
			dataUri: "",
			isLoading: false,
			showCompletedCard: false,
			errorMsg: ""
		};
	}

	async componentDidMount() {
		this.cameraPhoto = new CameraPhoto(this.videoRef.current);
	}

	setDataUri = dataUri => {
		// Set PhotoUri to state
		this.setState({ dataUri: dataUri });
	};

	setStudentData = studentData => {
		this.setState({ studentData: studentData });
	};

	clearSaveErrorMsg = () => {
		this.setState({ errorMsg: "" });
	};

	addStudent = async () => {
		const { studentData, dataUri } = this.state;
		this.setState({
			isLoading: true
		});
		const result = await apiClient.signup.addStudent({
			...studentData,
			photo: dataUri,
			studentDemoId: this.studentDemoId
		});

		this.setState({
			showCompletedCard: result.status === 'success',
			isLoading: false
		});

		return result
	};

	render() {
		const { isLoading, showCompletedCard, studentData } = this.state;

		return (
			<MainLayout>
				<Dimmer style={styles.dimmer} inverted active={isLoading}>
                    <Spinner />
                </Dimmer>
				<Grid>
					<Grid.Column
						mobile={16}
						tablet={16}
						largeScreen={5}
						style={styles.profilePhotoGrid}
					>
						{showCompletedCard ? null : (
							<ProfilePhoto
								setDataUri={this.setDataUri}
								editProfileFlag={true}
								pathName="/addstudent"
							/>
						)}
					</Grid.Column>
					<Grid.Column
						mobile={16}
						tablet={16}
						largeScreen={showCompletedCard ? 16 : 10}
						style={styles.formContainer}
					>
						<StudentForm
							clearSaveErrorMsg={this.clearSaveErrorMsg}
							setStudentData={this.setStudentData}
							studentData={studentData}
							addStudent={this.addStudent}
							studentDemoId={this.studentDemoId}
							editable
						/>
					</Grid.Column>
				</Grid>
			</MainLayout>
		);
	}
}

export default withRouter(withI18next()(addstudent))
