export default theme => ({
    layout: {
        fontFamily : "\"Varela Round\", \"Pridi\", \"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
        fontSize: 16,
        htmlFontSize: 16,
        width: 'auto',
        height: '100%',
        [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
            width: 1100,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        backgroundColor: 'white',
        border: 'solid',
        borderWidth: '0px 1px 0px 1px',
        borderColor: theme.palette.grey[200]
    }
})