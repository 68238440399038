import React from 'react'
import MainLayout from '../../../layouts/MainLayout'
import { withI18next } from '../../../lib/withI18next'
import StudentDemoForm from '../../../components/StudentDemoForm'

class AddStudentDemoPage extends React.Component {
	render() {
		return (
			<MainLayout>
				<StudentDemoForm />
			</MainLayout>
		)
	}
}

export default withI18next(['studentDemo'])(AddStudentDemoPage)
