export default {
    container: {
        padding: '30px 30px',
        fontSize: '1em'
    },

    successContainer: {
        padding: '2em',
        margin: 'auto',
        lineHeight: '2em',
        fontSize: '1.2em',
        width: 'auto'
    },

    successButton: {
        marginTop: '1em'
    },
    
    popover: {
        position: 'absolute',
        zIndex: '2',
        bottom: '0'
    },

    dimmer: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: '0',
        left: '0'
    },

    contentContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        padding: 0
    },

    errorMessageContainer: {
        padding: '0.7em',
        margin: 0,
    }
}