import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import { withI18next } from '../../lib/withI18next'
import apiClient from '../../apis'
import styles from './styles'
import { CGButton, Spinner } from '../../components/common'
import QrReader from 'react-qr-reader'

class UpdateQr extends React.Component {

    state = {}

    renderQrReader = () => {
        if (this.state.showQrReader) {
            return <QrReader
                delay={300}
                onError={(err) => {
                    this.setState({showQrReader: false})
                }}
                onScan={async (data) => { 
                    if (data) {
                        this.setState({ showQrReader: false, updatingQrCode: true })
                        const result = await apiClient.user.updateStudentUserUuid(this.state.studentId, data)
                        this.setState({ updatingQrCode: false, qrCode: data, result: result })
                    }
                }}
                facingMode='user'
                style={{ width: '100%' }}
            />
        } else if (this.state.updatingQrCode) {
            return <Spinner />
        }
    }

    handleChange = event => {
        this.setState({
            studentId: event.target.value
        })
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.container}>
                <h1>Update QR</h1>
                <div>
                    <TextField
                        inputRef={field => this.firstName = field}
                        label='StudentId'
                        value={this.state.studentId}
                        onChange={this.handleChange}
                        margin='dense'
                        inputProps={{
                            maxLength: 20,
                        }}
                    />
                    <CGButton 
                        variant='contained' 
                        onClick={() => {
                            if (this.state.studentId && this.state.studentId != "")
                                this.setState({showQrReader: !this.state.showQrReader, result: undefined})
                            else 
                                this.setState({showQrReader: false})
                        }}
                    >
                        {this.state.showQrReader?'Cancel':'Scan'}
                    </CGButton>
                </div>
                {this.renderQrReader()}
                <div>{this.state.result && this.state.result.message}</div>
            </div>
        )
    }
}

export default withI18next()(withStyles(styles)(UpdateQr))