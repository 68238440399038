import React, { Component } from 'react'

import { styles } from './styles'

class CGHeadline extends Component {
  render() {
    return (
      <div style={styles.container}>
        <div style={styles.header}>
          {this.props.header ? this.props.header : ''}
        </div>
        <div style={styles.subHeader}>
          {this.props.subHeader ? this.props.subHeader : ''}
        </div>
      </div>
    )
  }
}

export default CGHeadline
