import {postRequest, getRequest} from '../lib/request'

export function getTeacherList() {
    return getRequest(`/teacher/getTeacherList`)
}

export function getUserListAvailable() {
    return getRequest(`/teacher/getUserListAvailable`)
}

export function searchTeacher(searchData)  {
    const data = {
        teacherName: searchData.teacherName,
		teacherId: searchData.teacherId,
		teacherMobile: searchData.teacherMobile,
		teacherStatus: searchData.teacherStatus,
		teacherType: searchData.teacherType,
		orderBy: searchData.orderBy,
		orderType: searchData.orderType,
		page: searchData.page,
    }
    return getRequest(`/teacher/search`, data)
}

export async function getTeacherByClassId(newClassId) {
    const data = {
        classId: newClassId
    }

    return getRequest(`/teacher/getTeacherByClassId`, data)
}

export function getTeacherProfile(teacherId){
    return getRequest(`/teacher/profile/${teacherId}`)
}

export function updateTeacherProfile(data) {
    return postRequest(`/teacher/updateTeacher`, data)
}

export function getTeacherReport(criteria) {
    return getRequest(`/teacher/getTeacherReport`, criteria)
}

export function setClassTeacher(form) {
    const data = {
        classId: form.classId,
        teacherId: form.teacherId,
        taIds: form.taIds,
        userId: form.userId
    }

    return postRequest(`/teacher/setClassTeacher`, data)
}

export function addTeacher(teacherData) {
    const data = {
        firstName: teacherData.firstName,
        lastName: teacherData.lastName,
        nickName: teacherData.nickName,
        email: teacherData.email,
        mobileNumber: teacherData.mobileNumber,
        status: teacherData.status,
        color: teacherData.color,
        isFullTime: teacherData.isFullTime,
        userId: teacherData.userId
    }
    return postRequest(`/teacher/addTeacher`, data)
}

export function getTeacherCheckList() {
    return getRequest(`/teacher/getTeacherCheckList`)
}

export function setTeacherCheck(data) {
    return postRequest(`/teacher/setTeacherCheck`, data)
}

export function setTeacherByAdmin(data) {
    return postRequest(`/teacher/setTeacherByAdmin`, data)
}
