import React, { Fragment } from 'react'
import { Breadcrumb, Icon } from 'semantic-ui-react'
import styles from './styles'

function GalleryBreadcrumb({ updateBreadcrumb, classes, path }) {
    const max = path.length
    return (
        <Breadcrumb size='huge'>
            {
                path.map((p, idx) => {
                    if (idx === 0) {
                        return (
                            <Fragment key={`breadcrumb-${idx}`}>
                                <Icon size='large' color='yellow'  name='images' />
                                <Breadcrumb.Section link style={max === 1 ? styles.pathActive : styles.pathNotActive} href='/gallery'>
                                    {p.title}
                                </Breadcrumb.Section>
                            </Fragment>
                        )
                    } else {
                        return (
                            <Fragment key={`breadcrumb-${idx}`}>
                                <Breadcrumb.Divider icon='right chevron' />
                                <Breadcrumb.Section style={(idx + 1 === max) ? styles.pathActive : styles.pathNotActive} active>
                                    {p.title}
                                </Breadcrumb.Section>
                            </Fragment>
                        )
                    }
                })
            }
        </Breadcrumb >
    )
}

export default GalleryBreadcrumb
