import React from 'react'
import JssProvider from 'react-jss/lib/JssProvider'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider } from '@material-ui/core/styles'
import getPageContext from './lib/getPageContext'
import Router from './Router'

import 'semantic-ui-css/semantic.min.css'
import './i18n'
import './App.css'
import './common/nativeHelper'

class MyApp extends React.Component {
    pageContext = null;
    constructor(props) {
        super(props);
        this.pageContext = getPageContext()
    }
    
    render() {
        return (
            <div>
                {/* Wrap every page in Jss and Theme providers */}
                <JssProvider
                    registry={this.pageContext.sheetsRegistry}
                    generateClassName={this.pageContext.generateClassName}
                >
                {/* MuiThemeProvider makes the theme available down the React
                    tree thanks to React context. */}
                    <MuiThemeProvider
                        theme={this.pageContext.theme}
                        sheetsManager={this.pageContext.sheetsManager}
                    >
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                        <CssBaseline />
                        <Router/>
                    </MuiThemeProvider>
                </JssProvider>
            </div>
        )
    }
}

export default MyApp