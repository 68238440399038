import {getRequest, postRequest} from '../lib/request'

export function refundReceipt(data) {
    return postRequest(`/receipt/refundReceipt`, data)
}

export function createReceipt(data) {
    return postRequest(`/receipt`, data)
}

export function settlePayment(data) {
    return postRequest(`/receipt/settle-payment`, data)
}

export function deleteReceipt(receiptId, reason) {
    return postRequest(`/receipt/${receiptId}/delete`, { reason })
}

export function getReceipt(receiptId) {
    return getRequest(`/receipt/${receiptId}`)
}

export function getAccountingSummary(data) {
    return getRequest(`/course/getAccountingSummary`, data)
}

export function getAllReceipts(data) {
    return getRequest(`/course/getAllReceipts`, data)
}

export function getAmorInPeriodSummary(data) {
    return getRequest(`/course/getAmorInPeriodSummary`, data)
}