import React, {Component} from 'react'
import {Grid, Tab} from 'semantic-ui-react'
import {withRouter} from 'react-router'
import {withI18next} from '../../../lib/withI18next';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles'
import MainLayout from '../../../layouts/MainLayout';
import ProfileBar from '../../../components/ProfileBar';
import apiClient from '../../../apis';
import {formatDate, getCurrentUser, getUserBranchId, isUndefined} from '../../../common/util';
import StudentForm from '../../../components/StudentForm';
import Spinner from '../../../components/common/Spinner';
import ScheduleForm from "../../../components/ScheduleForm";

class ManageStudent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            studentId: null,
            studentData: null,
            showCompletedCard: false,
            errorMsg: '',
            dataUri: '',
            editable: false
        }
    }

    componentDidMount() {
        const userInfo = getCurrentUser()

        if (isUndefined(userInfo)) {
            this.props.history.push('/home')
        }

        if (isUndefined(userInfo.selectedStudent)) {
            this.setState({isLoading: false})
        } else {
            this.getStudentProfile(userInfo.selectedStudent.studentId)
            this.checkEditable(userInfo.selectedStudent.studentId)
        }
    }

    async getStudentProfile(studentId) {
        let studentProfile = await apiClient.student.getStudentProfile(studentId)
        studentProfile.dob = formatDate(studentProfile.dob, 'YYYY-MM-DD')

        const photoUrl = await apiClient.student.getStudentPhoto(studentId)
        this.setDataUri(photoUrl)

        this.setState({
            isLoading: false,
            studentId: studentId,
            studentData: studentProfile
        })
    }

    async checkEditable(studentId) {
        const branchId = getUserBranchId()
        if (branchId === 0) {
            this.setState({ editable: true })
        } else {
            const activeBranches = await apiClient.student.getStudentActiveBranch(studentId)
            this.setState({ editable: activeBranches.includes(branchId) })
        }
    }

    setDataUri = (dataUri) => { // Set PhotoUri to state
        this.setState({dataUri: dataUri})
    }

    setStudentData = (studentData) => {
        this.setState({studentData: studentData})
    }

    updateStudentProfile = async () => {
        const {studentData, dataUri, studentId} = this.state
        this.setState({
            isLoading: true
        })
        const result = await apiClient.student.updateStudentProfile({
            ...studentData,
            photo: !dataUri || dataUri.indexOf('http') === 0 ? '' : dataUri,
            studentId: studentId
        })

        if (result.success) {
            this.setState({
                showCompletedCard: true,
                errorMsg: '',
                isLoading: false
            })
        } else {
            this.setState({
                errorMsg: result.message,
                isLoading: false
            })
        }

        return result
    }

    render() {
        const {classes} = this.props
        const {isLoading, studentData, errorMsg, dataUri, studentId, editable} = this.state

        if (isLoading) {
            return (
                <MainLayout>
                    <Spinner/>
                </MainLayout>
            )
        }

        const panes = [
            {
                menuItem: 'Edit Profile',
                render: () => <Tab.Pane>
                    <StudentForm
                        updateStudentProfile={this.updateStudentProfile}
                        setStudentData={this.setStudentData}
                        studentData={studentData}
                        editProfileFlag={true}
                        editable={editable}
                    />
                </Tab.Pane>
            },
            {
                menuItem: 'Edit Schedule',
                render: () => <Tab.Pane>
                    <ScheduleForm
                        studentId={studentId}
                    />
                </Tab.Pane>
            }
        ]

        return (
            <MainLayout>
                <div>
                    <ProfileBar setDataUri={this.setDataUri} dataUri={dataUri}>
                        <Grid item xs={12}>
                            <div className={classes.formContainer}>
                                <Tab panes={panes}/>
                            </div>
                        </Grid>
                    </ProfileBar>
                </div>
            </MainLayout>
        )
    }
}

export default withRouter(withI18next()(withStyles(styles)(ManageStudent)))
