export default {
    "title": "Core Courses",
    "description": "",
    "roadmap": {
        "header": "Core Courses",
        "subheader": "Explore what's right for your kids"
    },
    "detail" : {
        "header" : "Course Detail",
        "structure" : "Structure"
    },
    "schedule" : {
        "header" : "Course Schedule"
    },
    "overview": {
        "firstLevel": {
            "name": "Little Genius",
            "description": "Install the basic skills of Code Genius to young imaginative minds through engaging activities.",
            "section": {
                "first": "Jujubes sweet roll sweet topping gingerbread gingerbread cheesecake. Chocolate bear claw jujubes sugar plum liquorice soufflé sweet.",
                "second": "Jelly dragée candy bonbon danish jelly beans tootsie roll. Jujubes sweet roll sweet topping gingerbread gingerbread cheesecake. Chocolate bear claw jujubes sugar plum liquorice soufflé sweet.",
                "third": "Jujubes sweet roll sweet topping gingerbread gingerbread cheesecake. Chocolate bear claw jujubes sugar plum liquorice soufflé sweet."
            },
            "structure": "Dog||ant||elephant"
        },
        "secondLevel": {
            "name": "Genius Explorer",
            "description": "Inspire kids to explore through exciting creative activities to strengthen the Code Genius skills.",
            "section": {
                "first": "Jujubes sweet roll sweet topping gingerbread gingerbread cheesecake. Chocolate bear claw jujubes sugar plum liquorice soufflé sweet.",
                "second": "Jelly dragée candy bonbon danish jelly beans tootsie roll. Jujubes sweet roll sweet topping gingerbread gingerbread cheesecake. Chocolate bear claw jujubes sugar plum liquorice soufflé sweet.",
                "third": "Jujubes sweet roll sweet topping gingerbread gingerbread cheesecake. Chocolate bear claw jujubes sugar plum liquorice soufflé sweet."
            },
            "structure": "Dog||ant||elephant"
        },
        "thirdLevel": {
            "name": "Genius Innovator",
            "description": "Unlock kids' creativity to apply the Code Genius skills confidently through challenging activities.",
            "section": {
                "first": "Jujubes sweet roll sweet topping gingerbread gingerbread cheesecake. Chocolate bear claw jujubes sugar plum liquorice soufflé sweet.",
                "second": "Jelly dragée candy bonbon danish jelly beans tootsie roll. Jujubes sweet roll sweet topping gingerbread gingerbread cheesecake. Chocolate bear claw jujubes sugar plum liquorice soufflé sweet.",
                "third": "Jujubes sweet roll sweet topping gingerbread gingerbread cheesecake. Chocolate bear claw jujubes sugar plum liquorice soufflé sweet."
            },
            "structure": "Dog||ant||elephant"
        },
        "fourthLevel": {
            "name": "Code Expert",
            "description": "Discover the world of computer science. Learn programming from the foundation. Solve real-world problems with practical programming tools.",
            "section": {
                "first": "Jujubes sweet roll sweet topping gingerbread gingerbread cheesecake. Chocolate bear claw jujubes sugar plum liquorice soufflé sweet.",
                "second": "Jelly dragée candy bonbon danish jelly beans tootsie roll. Jujubes sweet roll sweet topping gingerbread gingerbread cheesecake. Chocolate bear claw jujubes sugar plum liquorice soufflé sweet.",
                "third": "Jujubes sweet roll sweet topping gingerbread gingerbread cheesecake. Chocolate bear claw jujubes sugar plum liquorice soufflé sweet."
            },
            "structure": "Dog||ant||elephant"
        }
    },
    "button": {
        "schedule": "Schedule",
        "viewCourseDetail" : "View Course Detail",
        "hideCourseDetail" : "Hide Course Detail"
    },
    "en": "EN",
    "th": "TH",
    "signIn": "Sign In"
}