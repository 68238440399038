import React from 'react'
import MainLayout from '@layouts/MainLayout'
import apiClient from '@apis'
import { Dropdown, Container, Button, Input, Accordion, Label, Icon, Grid, Step, Form, Segment, Dimmer, Loader, Header } from 'semantic-ui-react'
import _ from 'lodash'
import { formatDate, convertNumber, getColorFromDow, getUserBranchId } from '@common/util'
import styles from './styles'
import moment from 'moment'
import SelectClassModal from '../SelectClassModal'
import ReceiptPrintFormV2 from '../../../components/ReceiptPrintFormV2'

class StudentClassAdd extends React.Component {

    state = {
        studentKeyword: '',
        studentOptions: [],
        branchOptions: [],
        selectedStudentId: undefined,
        selectedBranchId: undefined,
        courseDetails: [],
        registerDate: moment(),
        activeIndexes: [],
        step: 1,
        discount: '',
        discountPrecent: 0,
        creditCardBank: '',
        creditCardDate: moment(),
        creditCardAmt: 0,
        transferBank: 'KBANK',
        transferDate: moment(),
        transferTime: moment().format('HH:MM'),
        transferAmt: 0,
        remark: '',
        receiptRemark: ''
    }

    students = []
    branches = []

    componentDidMount() {
        this.getBranch()
        this.getStudent()
        this.getTeacher()
    }
    
    getTeacher = async () => {
        const result = await apiClient.teacher.getTeacherList()
        this.setState({ teachers: result })
    }

    getBranch = async () => {
        const branchId = getUserBranchId()
        const result = await apiClient.branch.getBranchesByBranchId(branchId)
        this.branches = result.dataList

        console.log('all branches')
        console.log(this.branches)

        const branchOptions = this.branches.map( branch => {
            return { key: branch.branchId, value: branch.branchId, text: `สาขา ${branch.branchName}` }
        })
        this.setState({ branchOptions })
    }

    getRoom = async (branchId) => {
        const result = await apiClient.room.getRoomByBranchId(branchId)
        this.setState({ rooms: result })
    }

    getStudent = _.debounce(async () => {
        const result = await apiClient.student.searchStudent({ name: this.state.studentKeyword, pageSize: 30, page: 1, orderBy: 'name', orderType: 'asc' })
        this.students = result.dataList
        const studentOptions = this.students.map( student => {
            return { key: student.studentId, value: student.studentId, text: `[${student.studentId}] `+student.name + ` (${student.nickName})` }
        })
        this.setState({ studentOptions })
    }, 300)

    handleSearchQueryChange = (e, { searchQuery }) => {
        this.setState({ studentKeyword: searchQuery }, this.getStudent)
    }

    handleStudentChange = (e, { value, options }) => {
        const option = options.find(option => option.value === value)
        this.setState({ studentKeyword: option.text, selectedStudentId: value })
    }

    handleBranchChange = (e, { value }) => {
        let creditCardBank = ''

        if(value) this.getRoom(value)
        
        if(value == 2) {
            creditCardBank = 'KBANK-CG Nich/61330033'
        } else if(value == 3) {
            creditCardBank = 'KBANK-CG EmQ/61788058'
        } else {
            creditCardBank = 'KBANK-CG PRG/61637598'
        }
        this.setState({ selectedBranchId: value, 
                        creditCardBank: creditCardBank })
    }

    editSelectedClass = (courseDetail, classes) => {
        this.setState({
            editCourseDetail: courseDetail,
            editClasses: classes,
            isShowSelectClassModal: true
        })
    }

    showSelectClassModal = () => {
        this.setState({
            editCourseDetail: undefined,
            isShowSelectClassModal: true
        })
    }

    hideSelectClassModal = () => {
        this.setState({
            editCourseDetail: undefined,
            isShowSelectClassModal: false
        })
    }

    addNewClasses = (courseDetail, classes) => {
        let courseDetails = [...this.state.courseDetails]
        const foundedIndex = courseDetails.findIndex(data => data.courseDetail.courseDetailId === courseDetail.courseDetailId)
        if (foundedIndex > -1) {
            courseDetails[foundedIndex] = { courseDetail, classes }
        } else {
            courseDetails.push({ courseDetail, classes })
        }
        
        this.setState({
            courseDetails: courseDetails,
        }, this.hideSelectClassModal)
    }

    doRegister = async () => {
        let { selectedBranchId, selectedStudentId, courseDetails, discount, remark, creditCardBank, creditCardDate, creditCardAmt, transferBank, transferDate, transferTime, transferAmt, receiptRemark} = this.state

        if (creditCardAmt === 0) {
            creditCardBank = undefined
            creditCardDate = undefined
        }

        if (transferAmt === 0) {
            transferBank = undefined
            transferDate = undefined
            transferTime = undefined
        }
        

        const student = this.students.find(data => data.studentId === selectedStudentId)
        const branch = this.branches.find(data => data.branchId === selectedBranchId)
        const receiptInfo = { discount, remark, creditCardBank, creditCardDate, creditCardAmt, transferBank, transferDate, transferTime, transferAmt, receiptRemark}
        this.setState({ isLoading: true })

        //Currently, the program creates receipt and change receiptStatus to PAID >> api\services\receipt\createReceiptAndRegisterClass
        try {
            const result = await apiClient.receipt.createReceipt({ branch, student, courseDetails, receiptInfo })

            if (result.status === 'success') {
                //this.setState({ receiptId: result.data.receiptId, step: this.state.step + 1, isPaid: true, isLoading: false })
                this.setState({ receiptId: result.data.receiptId, step: this.state.step + 1, isLoading: false })
            } else {
                throw result.message
            }
        } catch(e) {
            const errorMsg = e.message || e.error || e
            this.setState({ isLoading: false })
            alert(errorMsg)
        }
    }

    //Not used
    // doPayment = async () => {
    //     const { receiptId } = this.state
    //     this.setState({ isLoading: true })
    //     try {
    //         const result = await apiClient.receipt.settlePayment({ receiptId })
    //         if (result.status === 'success') {
    //             this.setState({ isPaid: true, isLoading: false })
    //         } else {
    //             throw result.message
    //         }
    //     } catch(e) {
    //         const errorMsg = e.message || e.error || e
    //         this.setState({ isLoading: false })
    //         alert(errorMsg)
    //     }
    // }

    printReceipt = () => {
        this.printForm.print(this.state.selectedBranchId, this.state.receiptId)
    }

    handleDateChange = (e) => {
        this.setState({ registerDate: e.target.value})
    }

    handleRemarkChange = (e) => {
        this.setState({ remark: e.target.value})
    }

    handleReceiptRemarkChange = (e) => {
        this.setState({ receiptRemark: e.target.value})
    }

    handleAccordionClick = (e, accordionProps) => {
        const { index } = accordionProps
        const { activeIndexes } = this.state
        let newIndexes = []
        if (activeIndexes.indexOf(index) >= 0) {
            newIndexes = activeIndexes.filter(data => data !== index)
        } else {
            newIndexes = [...activeIndexes, index]
        }
        this.setState({ activeIndexes: newIndexes })
    }

    handleRemoveCourseDetail = (courseDetailId) =>  (e) => {
        e.stopPropagation()
        const newCourseDetails = this.state.courseDetails.filter(data => data.courseDetail.courseDetailId !== courseDetailId)
        const newActiveIndexes = this.state.activeIndexes.filter(data => data !== courseDetailId)
        this.setState({ activeIndexes: newActiveIndexes, courseDetails: newCourseDetails })
    }

    handleEditCourseDetail = (courseDetail, classes) => (e) => {
        e.stopPropagation()
        this.editSelectedClass(courseDetail, classes)
    }

    handleDiscountChange = (subTotal) => (e) => {
        let data = e.target.value
        if (data.length > 0 && isNaN(data)) return
        const discountPercent = parseFloat(data * 100.0) / subTotal
        this.setState({ discount: data, discountPercent })
    }

    handleDiscountPercentChange = (subTotal) => (e) => {
        let data = e.target.value
        if (data.length > 0 && isNaN(data)) return
        const discount = subTotal * (parseFloat(data) / 100.0)
        this.setState({ discountPercent: data, discount })
    }

    handleTransferPaymentChange = (subTotal) => (e) => {
        let data = e.target.value
        if (data.length > 0 && isNaN(data)) return
        const grandTotal = subTotal - this.state.discount
        const creditCardAmt = parseFloat(grandTotal - data)
        this.setState({ transferAmt: data, creditCardAmt })
    }

    handleCreditCardPaymentChange = (subTotal) => (e) => {
        let data = e.target.value
        if (data.length > 0 && isNaN(data)) return
        const grandTotal = subTotal - this.state.discount
        const transferAmt = parseFloat(grandTotal - data)
        this.setState({ creditCardAmt: data, transferAmt })
    }

    handleTranferInfoChange = (val) => (e, {value}) => {
        if (val === 'Bank') this.setState({ transferBank: value})
        
        if (val === 'Date') this.setState({ transferDate: value})
        
        if (val === 'Time') this.setState({ transferTime: value})
    }

    handleCreditCardInfoChange = (val) => (e, {value}) => {
        if (val === 'Bank') this.setState({ creditCardBank: value})

        if (val === 'Date') this.setState({ creditCardDate: value})
        
    }

    renderClassList = (editable) => {
        if (this.state.courseDetails.length === 0) return <div></div>
        return ( 
            <Container>
                <Grid>
                    <Grid.Column width={6}></Grid.Column>
                    <Grid.Column width={3} textAlign='center'>Price</Grid.Column>
                    <Grid.Column width={2} textAlign='center'>Amount</Grid.Column>
                    <Grid.Column width={3} textAlign='center'>Total</Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid>
                <Accordion fluid styled exclusive={false}>
                    {this.state.courseDetails.map((data) => {
                        // let totalHours = 0
                        // if (data.courseDetail.isFixPrice) {
                        //     data.classes.forEach(clazz => {
                        //         let hours = 0
                        //         if (clazz.startTime && clazz.endTime) {
                        //             try {
                        //                 const startTime = moment(clazz.startTime, 'hh:mm')
                        //                 const endTime = moment(clazz.endTime, 'hh:mm')
                        //                 hours = endTime.diff(startTime, 'minute') / 60.0
                        //             } catch (e) {}
                        //         }
                        //         totalHours += hours
                        //     })
                        // }
                        // const courseHours = data.courseDetail.hours || 0
                        return ( <React.Fragment key={data.courseDetail.courseDetailId}>
                            <Accordion.Title
                                key={data.courseDetail.courseDetailId}
                                index={data.courseDetail.courseDetailId}
                                onClick={this.handleAccordionClick}
                                active={this.state.activeIndexes.indexOf(data.courseDetail.courseDetailId) >= 0}
                            >
                                <Grid verticalAlign='middle'>
                                    <Grid.Column width={6}><Icon name='dropdown' />  {data.courseDetail.displayName}</Grid.Column>
                                    <Grid.Column width={3} textAlign='center'>{convertNumber(data.courseDetail.price, 2)}</Grid.Column>
                                    <Grid.Column width={2} textAlign='center'>{data.courseDetail.isFixPrice ? 1 : data.classes.length}</Grid.Column>
                                    <Grid.Column width={3} textAlign='right'>{data.courseDetail.isFixPrice ? convertNumber(data.courseDetail.price, 2) : convertNumber(data.courseDetail.price * data.classes.length, 2)}</Grid.Column>
                                    <Grid.Column width={2} textAlign='right'>
                                        {editable && <Button inverted circular size='mini' icon='edit' color='yellow' onClick={this.handleEditCourseDetail(data.courseDetail, data.classes)}/>}
                                        {editable && <Button inverted circular size='mini' icon='delete' color='red' onClick={this.handleRemoveCourseDetail(data.courseDetail.courseDetailId)}/>}
                                    </Grid.Column>
                                </Grid>
                            </Accordion.Title>
                            <Accordion.Content active={this.state.activeIndexes.indexOf(data.courseDetail.courseDetailId) >= 0}>
                                {data.classes.map((cls, idx) => !cls.classDate ? <span key={idx}/> :
                                    <Label basic key={cls.classDate + (cls.startTime || '') + (cls.endTime || '')}>
                                        <Label empty color={getColorFromDow(cls.classDate)} size='mini' circular/> {cls.classDate.substring(0, 10)}
                                        <Label.Detail>{cls.startTime.substring(0, 5)}</Label.Detail>
                                    </Label>
                                )}
                            </Accordion.Content>
                        </React.Fragment>
                    )})}
                </Accordion>
            </Container>
        )
    }

    renderChooseClassStep = () => {
        return(
            <Container>
                <Container style={styles.actionContainer} textAlign='right'>
                    <Dropdown
                        placeholder='Branch'
                        selection
                        value={this.state.selectedBranchId}
                        onChange={this.handleBranchChange}
                        options={this.state.branchOptions}
                    />
                    <Input label='Register Date' type='date' placeholder='Register Date' onChange={this.handleDateChange} value={formatDate(this.state.registerDate, 'YYYY-MM-DD')}/>
                </Container>
                <Dropdown
                    placeholder='Student'
                    fluid
                    search
                    selection
                    searchQuery={this.state.studentKeyword}
                    onSearchChange={this.handleSearchQueryChange}
                    onChange={this.handleStudentChange}
                    options={this.state.studentOptions}
                />
                <Container style={styles.actionContainer} textAlign='right'>
                    <Button color='green' disabled={!this.state.selectedStudentId || !this.state.selectedBranchId} onClick={this.showSelectClassModal}>Add New Class</Button>
                </Container>
                {this.renderClassList(true)}
                { (this.state.courseDetails && this.state.courseDetails.length > 0) && 
                    <Container style={styles.actionContainer}>
                        <Grid columns={1}>
                            <Grid.Column textAlign='right'>
                                <Button primary onClick={ () => this.setState({ step: this.state.step + 1})}>Next</Button>
                            </Grid.Column>
                        </Grid>
                    </Container>
                }
            </Container>
        )
    }

    renderReceiptData = (disabled, subTotal) => {
        const student = this.state.studentOptions.find(data => data.value === this.state.selectedStudentId)
        const branch = this.branches.find(data => data.branchId === this.state.selectedBranchId)
        
        return (
            <Container>
                <Container>
                    <Grid columns={2}>
                        <Grid.Column>Branch: {branch.branchName}</Grid.Column>
                        <Grid.Column>Student: {student.text}</Grid.Column>
                    </Grid>
                </Container>
                {this.renderClassList()}
                <Container style={styles.actionContainer}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Form>
                                    <Form.TextArea label = 'Remark for Code Genius Staff' disabled={disabled} fluid rows={5} value={this.state.remark} placeholder='Remark' onChange={this.handleRemarkChange} />
                                </Form>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Grid verticalAlign='middle'>
                                    <Grid.Row>
                                        <Grid.Column width={6} textAlign='right'>Sub Total</Grid.Column>
                                        <Grid.Column width={7} textAlign='right'>{convertNumber(subTotal, 2)}</Grid.Column>
                                        <Grid.Column width={3} textAlign='center'>THB</Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={6} textAlign='right'>Discount</Grid.Column>
                                        <Grid.Column width={7} textAlign='right'>
                                            <Input disabled={disabled} input={<input style={styles.alignRight}/>} fluid value={this.state.discountPercent} placeholder='0' onChange={this.handleDiscountPercentChange(subTotal)} />
                                        </Grid.Column>
                                        <Grid.Column width={3} textAlign='center'>%</Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={6} textAlign='right'>Discount</Grid.Column>
                                        <Grid.Column width={7} textAlign='right'>
                                            <Input disabled={disabled} input={<input style={styles.alignRight}/>} fluid value={this.state.discount} placeholder='0' onChange={this.handleDiscountChange(subTotal)} />
                                        </Grid.Column>
                                        <Grid.Column width={3} textAlign='center'>THB</Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={6} textAlign='right'>Grand Total</Grid.Column>
                                        <Grid.Column width={7} textAlign='right'>{convertNumber(subTotal - (this.state.discount || 0), 2)}</Grid.Column>
                                        <Grid.Column width={3} textAlign='center'>THB</Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid> 
                </Container> 
                
            </Container>
        )
    }

    renderPaymentInfo = (subTotal) => {

        const transferOption = [{ value: 'KBANK', text: 'KBANK' }, 
                                { value: 'KTC', text: 'KTC' },
                                { value: 'SCB', text: 'SCB' },]

        const creditCardOption = [{ value: 'KBANK-CG PRG/61637598', text: 'KBANK-CG PRG/61637598' },
                                { value: 'KBANK-CG EmQ/61788058', text: 'KBANK-CG EmQ/61788058' },
                                { value: 'KBANK-CG Nich/61330033', text: 'KBANK-CG Nich/61330033' },
                                { value: 'KBANK-ES Nich/61193667', text: 'KBANK-ES Nich/61193667' }, 
                                { value: 'KTC-ES TSQ/50436635', text: 'KTC-ES TSQ/50436635' },
                                { value: 'KTC-ES Nich/50434246', text: 'KTC-ES Nich/50434246' },
                                { value: 'KTC- PM/50434245', text: 'KTC- PM/50434245' },]

        return (
            <Container style={styles.actionContainer}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={2} textAlign='left'>
                            <Header as='h4'>Bank Transfer</Header>
                        </Grid.Column>
                        <Grid.Column width={5} textAlign='center'>
                            <Dropdown
                                label='Bank'
                                placeholder='Bank'
                                fluid
                                selection
                                search
                                value={this.state.transferBank}
                                onChange={this.handleTranferInfoChange('Bank')}
                                options={transferOption}
                            />
                        </Grid.Column>
                        <Grid.Column width={5} textAlign='center'>
                            <Input
                                label='Date'
                                type='date'
                                fluid
                                onChange={this.handleTranferInfoChange('Date')}
                                value={formatDate(this.state.transferDate, 'YYYY-MM-DD')}
                            />
                        </Grid.Column>
                        <Grid.Column width={4} textAlign='center'>
                            <Input
                                label='Time'
                                type='time'
                                fluid
                                value={this.state.transferTime}
                                onChange={this.handleTranferInfoChange('Time')}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={8}></Grid.Column>
                        <Grid.Column width={8}>
                            <Grid>
                                <Grid.Column width={9} textAlign='right'>Amount</Grid.Column>
                                <Grid.Column width={5} textAlign='right'>
                                    <Input input={<input style={styles.alignRight} />} fluid value={this.state.transferAmt} placeholder='0' onChange={this.handleTransferPaymentChange(subTotal)} />
                                </Grid.Column>
                                <Grid.Column width={2} textAlign='right'> THB </Grid.Column>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column width={2} textAlign='left'>
                            <Header as='h4'>Credit Card</Header>
                        </Grid.Column>
                        <Grid.Column width={5} textAlign='center'>
                            <Dropdown
                                label='Bank'
                                placeholder='Bank'
                                fluid
                                selection
                                search
                                value={this.state.creditCardBank}
                                onChange={this.handleCreditCardInfoChange('Bank')}
                                options={creditCardOption}
                            />
                        </Grid.Column>
                        <Grid.Column width={5} textAlign='center'>
                            <Input
                                label='Date'
                                type='date'
                                fluid
                                onChange={this.handleCreditCardInfoChange('Date')}
                                value={formatDate(this.state.creditCardDate, 'YYYY-MM-DD')}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={8}></Grid.Column>
                        <Grid.Column width={8}>
                            <Grid>
                                <Grid.Column width={9} textAlign='right'>Amount</Grid.Column>
                                <Grid.Column width={5} textAlign='right'>
                                    <Input input={<input style={styles.alignRight} />} fluid value={this.state.creditCardAmt} placeholder='0' onChange={this.handleCreditCardPaymentChange(subTotal)} />
                                </Grid.Column>
                                <Grid.Column width={2} textAlign='right'> THB </Grid.Column>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column width={4}>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Form>
                                <Form.TextArea
                                    label='Remark About Payment for Accounting Dept.'
                                    fluid
                                    rows={2}
                                    value={this.state.receiptRemark}
                                    placeholder='Remark'
                                    onChange={this.handleReceiptRemarkChange} />
                            </Form>
                        </Grid.Column>
                    </Grid.Row>

                </Grid>

            </Container>)
    }

    renderConfirmationStep = () => {
        let subTotal = this.state.courseDetails.map(data => {
            if (data.courseDetail.isFixPrice) {
                return data.courseDetail.price
            } else {
                return data.courseDetail.price * data.classes.length
            }
        }).reduce((a,b) => a + b, 0)
        
        let disabledRegisButton = ((subTotal - this.state.discount) !== (parseFloat(this.state.creditCardAmt) + parseFloat(this.state.transferAmt))) || (this.state.transferAmt !== 0 & this.state.transferBank === '') || (this.state.creditCardAmt !== 0 & this.state.creditCardBank === '')

        return (
            <Container>
                
                <Header as='h1'>Summary</Header>
                {this.renderReceiptData(false, subTotal)}

                <br></br>
                <hr></hr>
                <Header as='h1'>Payment Information</Header>

                {this.renderPaymentInfo(subTotal)}

                <Container style={styles.actionContainer}>
                    <Grid columns={2}>
                        <Grid.Column textAlign='left'>
                            <Button primary onClick={ () => this.setState({ step: this.state.step - 1})}>Back</Button>
                        </Grid.Column>
                        <Grid.Column textAlign='right'>
                            <Button positive onClick={this.doRegister} disabled={disabledRegisButton}>Register And Pay</Button>
                        </Grid.Column>
                    </Grid>
                </Container>
            </Container>
        )

    }

    renderPaymentStep = () => {
        
        let subTotal = this.state.courseDetails.map(data => {
            if (data.courseDetail.isFixPrice) {
                return data.courseDetail.price
            } else {
                return data.courseDetail.price * data.classes.length
            }
        }).reduce((a,b) => a + b, 0)

        return (
            <Container>
                {this.renderReceiptData(true, subTotal)}

                <Container style={styles.actionContainer}>
                    <Grid columns={2}>
                        <Grid.Column textAlign='left' />
                        <Grid.Column textAlign='right'>
                            {/* {this.state.isPaid 
                                ? <Button primary onClick={this.printReceipt}>Print Receipt</Button>
                                : <Button positive onClick={this.doPayment}>Accept payment</Button>} */}
                            <Button primary onClick={this.printReceipt}>Print Receipt</Button>
                        </Grid.Column>
                    </Grid>
                </Container>
            </Container>
        )
    }

    renderBackButton = () => {
        if (this.state.step === 1) return
        return <Button primary onClick={ () => this.setState({ step: this.state.step - 1})}>Back</Button>
    } 

    renderNextButton = () => {
        if (this.state.step === 3 || !this.state.courseDetails || this.state.courseDetails.length === 0) return
        return <Button primary onClick={ () => this.setState({ step: this.state.step + 1})}>Next</Button>
    } 

    render() {
        return (
            <div>
                <MainLayout id='react-no-print'>
                    <Container style={styles.container}>
                        <Step.Group ordered fluid>
                            <Step completed={this.state.step > 1} active={this.state.step === 1}>
                                <Step.Content>
                                    <Step.Title>Add Class</Step.Title>
                                </Step.Content>
                            </Step>

                            <Step completed={this.state.step > 2} active={this.state.step === 2}>
                                <Step.Content>
                                    <Step.Title>Register and Pay</Step.Title>
                                </Step.Content>
                            </Step>

                            <Step completed={this.state.step > 3} active={this.state.step === 3}>
                                <Step.Content>
                                    <Step.Title>Print Receipt</Step.Title>
                                </Step.Content>
                            </Step>
                        </Step.Group>
                        <Segment basic>
                            <Dimmer inverted active={this.state.isLoading}>
                                <Loader size='Huge'/>
                            </Dimmer>
                            {this.state.step === 1 && this.renderChooseClassStep()}
                            {this.state.step === 2 && this.renderConfirmationStep()}
                            {this.state.step === 3 && this.renderPaymentStep()}
                        </Segment>
                    </Container>
                    <SelectClassModal 
                        studentId={this.state.selectedStudentId} 
                        branchId={this.state.selectedBranchId}
                        rooms={this.state.rooms}
                        teachers={this.state.teachers}
                        open={this.state.isShowSelectClassModal} 
                        onClose={this.hideSelectClassModal} 
                        onSave={this.addNewClasses}
                        courseDetail={this.state.editCourseDetail}
                        classes={this.state.editClasses}
                        selectedCourseDetailIds={this.state.courseDetails.map(data => data.courseDetail.courseDetailId)}
                        registerDate={formatDate(this.state.registerDate, 'YYYY-MM-DD')}/>
                </MainLayout>
                <ReceiptPrintFormV2 ref={(el) => (this.printForm = el)} />
            </div>
        )
    }
}

export default StudentClassAdd