export const styles = {
  card: {
    minWidth: '300px',
    width: '45%',
    padding: '10px 20px 20px 20px',
    margin: '30px'
  },
  divider: {
    margin: '16px 8px 0px 8px'
  },
  signinButton: {
    width: '100%'
  },
  signupButton: {
    color: '#000000',
    backgroundColor: '#FCD634',
    width: '100%'
  },
}
