import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import CloseIcon from '@material-ui/icons/Close'
import { withI18next } from '../../lib/withI18next'
import apiClient from '../../apis'
import styles from './styles'
import { CGButton, Spinner } from '../../components/common'
import QrReader from 'react-qr-reader'
import { formatDate, getCurrentUser } from '../../common/util'
import * as CONST from '../../common/const'
import { withRouter } from "react-router"
import queryString from 'query-string'

class AttendanceCheck extends React.Component {

    state = {}

    constructor(props) {
        super(props)
        const criteria = queryString.parse(props.location.search)
        this.showStudentIdInput = criteria.showStudentId === 'true'
        let currentUser = getCurrentUser()
        this.state = {
            userId: currentUser.userId,
        }
    }

    checkAttendance = async (uuid) => {
        this.setState({ isLoading: true })
        let result = await apiClient.student.checkAttendance({ uuid: uuid, userId: this.state.userId })
        this.setState({ isLoading: false, result: result })
    }

    checkAttendanceByStudentId = async () => {
        if (this.state.studentId != null && this.state.studentId !== '') {
            this.setState({ isLoading: true })
            let result = await apiClient.student.checkAttendance({ studentId: this.state.studentId })
            this.setState({ isLoading: false, result: result, studentId: undefined })
        }
    }

    handleOnchange = (event) => {
        this.setState({
            studentId: event.target.value
        })
    }

    renderStudentIdInput = () => {
        return (
            <div>
                <input placeholder='Student ID' value={this.state.studentId} onChange={this.handleOnchange} />
                <button onClick={this.checkAttendanceByStudentId}>Submit</button>
            </div>
        )
    }

    renderQrReader = () => {
        const { classes } = this.props
        if (this.state.showQrReader) {
            if (this.showStudentIdInput) {
                return this.renderStudentIdInput()
            }
            return (
                <div className={classes.qrContainer}>
                    <div className={classes.qrHeader}>Show your <b>Genius Code</b></div>
                    <QrReader
                        delay={300}
                        onError={(err) => {
                            console.error(err)
                            this.setState({showQrReader: false})
                        }}
                        onScan={(data) => { 
                            if (data) {
                                this.setState({showQrReader: false})
                                this.checkAttendance(data)
                            }
                        }}
                        facingMode='user'
                        className={classes.qrScanner}
                    />
                    <Fab 
                        color='default'
                        aria-label='Close' 
                        fontSize='small'
                        className={classes.closeIcon}
                        onClick={() => this.setState({showQrReader: false})}>
                        <CloseIcon />
                    </Fab>
                </div>
            )
        }
    }

    renderScanner = () => {
        if (!this.state.result) {
            return (
                <React.Fragment>
                    {this.renderQrReader()}
                    {!this.state.showQrReader && 
                        <CGButton 
                            color='secondary'
                            variant='contained' 
                            onClick={() => this.setState({showQrReader: true})}
                        >
                            Join Class
                        </CGButton>
                    }
                </React.Fragment>
            )
        }
    }

    renderSuccess = () => {
        const { classes } = this.props
        const { student, currClass } = this.state.result
        return (
            <div className={`${classes.resultText} ${classes.noLineHeight}`}>
                <div className={classes.grouping}>
                    Welcome, {student.nickName}
                </div>
                <div className={classes.grouping}>
                    <div>Let's get into</div>
                    <div className={`${classes.hilightText} ${classes.noLineHeight}`}>" {currClass.room} Room "</div>
                </div>
                <div className={classes.grouping}>
                    <div>Enjoy your</div>
                    <div>{currClass.courseName} class</div>
                </div>
            </div>
        )
    }

    renderNextClass = () => {
        const { classes } = this.props
        const { student } = this.state.result
        return (
            <div className={classes.resultText}>
                <div>Hi, {student.nickName}</div>
                <div>You don't have class now.</div>
                {this.renderClassTable()}
            </div>
        )
    }

    renderNoClass = () => {
        const { classes } = this.props
        const { student } = this.state.result
        return (
            <div className={classes.resultText}>
                <div>Hi, {student.nickName}</div>
                <div>You don't have class now.</div>
                {this.renderClassTable()}
                <div className={`${classes.grouping} ${classes.noLineHeight}`}>
                    <div className={classes.hilightText}>Let's continue</div>
                    <div className={classes.hilightText}>your journey with us.</div>
                </div>
            </div>
        )
    }

    renderNotFound = () => {
        const { classes } = this.props
        return (
            <div className={classes.resultText}>
                <div className={classes.hilightText}>404 :(</div>
                <div>Sorry, we can't find your QR code.</div>
            </div>
        )
    }

    renderClassTable = () => {
        const { classes } = this.props
        const { nextClass, prevClass } = this.state.result
        return (
            <table className={classes.classTable}>
                <tbody>
                    { prevClass && 
                        <tr>
                            <td className={`${classes.classTableCell} ${classes.classTableHeader}`}>
                                Lastest Class
                                { prevClass.status === CONST.STUDENT_CLASS_STATUS.CANCEL && <div className={classes.noteMessage}>(Cancel)</div>}
                            </td>
                            <td className={classes.classTableCell}>{formatDate(prevClass.classDate, 'D MMM YY (ddd)')}</td>
                            <td className={classes.classTableCell}>{formatTime(prevClass.startTime)}</td>
                            <td className={classes.classTableCell}>{prevClass.courseName}</td>
                        </tr>
                    }
                    { nextClass &&
                        <tr>
                            <td className={`${classes.classTableCell} ${classes.classTableHeader}`}>Next Class</td>
                            <td className={classes.classTableCell}>{formatDate(nextClass.classDate, 'D MMM YY (ddd)')}</td>
                            <td className={classes.classTableCell}>{formatTime(nextClass.startTime)}</td>
                            <td className={classes.classTableCell}>{nextClass.courseName}</td>
                        </tr>
                    }
                </tbody>
            </table>
        )
    }

    renderResult = () => {
        const { classes } = this.props
        const { result } = this.state
        if (result) {
            let renderResult
            if (!result.student) {
                renderResult = this.renderNotFound()
            } else if (result.hasClass) {
                renderResult = this.renderSuccess()
            } else if (result.nextClass) {
                renderResult = this.renderNextClass()
            } else {
                renderResult = this.renderNoClass()
            }
            return (
                <React.Fragment>
                    {renderResult}
                    <CGButton 
                        color='secondary'
                        variant='contained' 
                        className={classes.okButton}
                        onClick={() => this.setState({ result: undefined })}
                    >
                        OK
                    </CGButton>
                </React.Fragment>
            )
        }
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.container}>
                <div className={classes.leftContainer}>
                    <img className={classes.topImage} alt='top' src={`${process.env.PUBLIC_URL}/images/Attendance/top.png`} />
                    <img className={classes.bottomImage} alt='bottom' src={`${process.env.PUBLIC_URL}/images/Attendance/bottom.png`} />
                    <img className={classes.robotImage} alt='robot' src={`${process.env.PUBLIC_URL}/images/CGRobot.gif`} />
                </div>
                <div className={classes.rightContainer}>
                    <img className={classes.logoImage} alt='robot' src={`${process.env.PUBLIC_URL}/images/Attendance/logo.png`} />
                    {
                        this.state.isLoading? <Spinner />:
                        <React.Fragment>
                            {this.renderScanner()}
                            {this.renderResult()}
                        </React.Fragment>
                    }
                </div>
            </div>
        )
    }
}

function formatTime(time) {
    const timeSplit = time.split(':')
    return timeSplit[0] + ':' + timeSplit[1]
}

export default withRouter(withI18next()(withStyles(styles)(AttendanceCheck)))