export default {
    container: {
        margin: '2em',
    },
    paginationContainer: {
        textAlign: 'right'
    },
    dateCriteriaContainer: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    dateContainer: {
        display: 'inline-block',
        marginBottom: '0.7em'
    }
}