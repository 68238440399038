export default theme => ({
    container: {
        maxWidth: '300px',
        width: '100%',
        height: '100vh',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
})