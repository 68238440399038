import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

const CGButton = withStyles({ label: { textTransform: 'none' } })(({ children, color, ...args }) => (
        <Button color={color||'primary'} {...args}>
            {children}
        </Button>
))

export { CGButton }