import React from 'react'
import {Button, Dropdown, Form, Grid, Input} from 'semantic-ui-react'
import MainLayout from '../../../layouts/MainLayout'
import {withRouter} from 'react-router';
import {withI18next} from '../../../lib/withI18next';
import styles from './styles'
import {withStyles} from '@material-ui/core/styles';
import TeacherReportForm from '../../../components/TeacherReportForm';
import apiClient from '../../../apis';

class TeacherReport extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            searchData: {
                teacherId: '',
                fromDate: '',
                toDate: '',
            },
            teacherList: [],
            isLoading: false
        }
    }

    componentDidMount() {
        this.getTeacherList()
        this.setDefaultCriteria()
    }

    getTeacherList = () => {
        this.setState({
            isLoading: true
        })

        apiClient.teacher.getTeacherList()
            .then(res => {
                if (res !== null) {
                    let arr = res.map((p, index) => {
                        return {
                            key: index,
                            text: p.name,
                            value: p.id
                        }
                    })
                    arr.unshift({key: -1, text: 'All', value: ''})
                    this.setState({
                        teacherList: arr,
                        isLoading: false
                    })
                }
            })
    }

    exportReport = () => {
        const data = this.state.searchData
        let searchData = {}
        for (const key in data) {
            if (data[key] !== '' && data[key] !== null && data[key] !== undefined && data[key] !== 'undefined') {
                searchData[key] = data[key]
            }
        }
        this.printForm.print(searchData)
    }

    handleInputChange = (e, value) => {
        this.setState({
            searchData: {
                ...this.state.searchData,
                [value.id]: value.value
            }
        })
    }

    setDefaultCriteria() {
        let today = new Date();
        let dd = today.getDate()
        let mmFrom = today.getMonth() + 1
        let mmTo = today.getMonth() + 1
        let yyyy = today.getFullYear()

        if (dd >= 21) {
            today.setMonth(today.getMonth() + 1);
            mmTo = today.getMonth() + 1
        } else {
            today.setMonth(today.getMonth() - 1);
            mmFrom = today.getMonth() + 1
        }

        this.setState({
            searchData: {
                fromDate: yyyy + '-' + (mmFrom < 10 ? '0' + mmFrom : mmFrom) + '-21',
                toDate: yyyy + '-' + (mmTo < 10 ? '0' + mmTo : mmTo) + '-20'
            }
        })
    }

    render() {
        const {teacherId, fromDate, toDate} = this.state.searchData
        const {classes} = this.props

        return (
            <div>
                <MainLayout id='react-no-print'>
                    <div className={classes.container}>
                        <Grid centered stackable>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form>
                                        <div className='three fields'>
                                            <div className='field'>
                                                <label>Teacher</label>
                                                <Dropdown id='teacherId' fluid search selection placeholder='All'
                                                          options={this.state.teacherList} value={teacherId}
                                                          onChange={this.handleInputChange}/>
                                            </div>
                                            <div className='field'>
                                                <label>From Date</label>
                                                <Input id='fromDate' fluid placeholder='mm/dd/yyyy' type='date'
                                                       value={fromDate} onChange={this.handleInputChange}/>
                                            </div>
                                            <div className='field'>
                                                <label>To Date</label>
                                                <Input id='toDate' fluid placeholder='mm/dd/yyyy' type='date'
                                                       value={toDate} onChange={this.handleInputChange}/>
                                            </div>
                                        </div>
                                        <div className='two fields'>
                                            <div className='field'></div>
                                            <div className='right field' style={{textAlign: 'right'}}>
                                                <Button className='ui primary button'
                                                        onClick={() => this.exportReport()}>
                                                    Export
                                                </Button>
                                            </div>
                                        </div>
                                    </Form>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </MainLayout>
                <TeacherReportForm ref={el => this.printForm = el}/>
            </div>
        )
    }
}

export default withRouter(withI18next()(withStyles(styles)(TeacherReport)))

