import React, { Component } from 'react'
import { Button, Icon } from 'semantic-ui-react'

import { FACEBOOK } from '../../../common/const'
import { styles } from './styles'

class FacebookButton extends Component {
  componentDidMount() {
    ;(function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src = '//connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')

    window.fbAsyncInit = function() {
      window.FB.init({
        appId: FACEBOOK.TOKEN,
        cookie: true, // enable cookies to allow the server to access the session
        xfbml: true, // parse social plugins on this page
        version: 'v3.1' // use version 3.1
      })
    }
  }

  facebookLogin = () => {
    const { submitFacebookSignIn } = this.props
    window.FB.login(function(resp) {
      if (resp.status === 'connected') {
        window.FB.api('/me?fields=id,first_name,last_name,email', function(
          resp
        ) {
          submitFacebookSignIn(resp)
        })
      }
    })
  }

  render() {
    const { style } = this.props
    return (
      <Button
        color="facebook"
        style={{ ...styles.facebookButton, ...style }}
        onClick={this.facebookLogin}
      >
        <Icon name="facebook" /> {this.props.name}
      </Button>
    )
  }
}

export default FacebookButton
