export const styles = {
  button: {
    fontFamily:
      '"Varela Round", "K2D", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '1em',
    color: '#FFFFFF',
    backgroundColor: '#00B3C5'
  },
  link: {
    fontFamily:
      '"Varela Round", "K2D", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '1em',
    color: '#00B3C5',
    background: 'none'
  }
}
