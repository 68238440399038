export default {
    container: {
        padding: '50px 30px',
        fontSize: '14px',
        minHeight: '50vh'
    },
    actionContainer: {
        margin: '20px 0 10px'
    },
    alignRight: {
        textAlign: 'right'
    }
}