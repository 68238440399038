import React from 'react'
import { withRouter } from 'react-router'
import {
  Grid,
  Segment,
  Form,
  Input,
  Message,
} from 'semantic-ui-react'

import { Spinner } from '../../../components/common'
import MainLayout from '@layouts/MainLayout'
import CGButton from '../../../components/common/CGButton/index'
import apiClient from '../../../apis'

import { styles } from './styles'

class CGVideoUserForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      age: '',
      school: '',
      mobileNumber: '',
      errorMsg: '',
      isLoading: false
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  validateData = () => {
    let errorMsg = ''

    if (this.state.name === null || this.state.name === '') {
      // errorMsg = 'กรุณากรอกชื่อ'
      errorMsg = "Please fill 'Name'"
      this.name.focus()
    } else if (this.state.age === null || this.state.age === '') {
      // errorMsg = 'กรุณากรอกอายุ'
      errorMsg = "Please fill 'Age'"
      this.age.focus()
    } else if (this.state.mobileNumber.length === 0) {
      // errorMsg = 'กรุณากรอกเบอร์โทรศัพท์มือถือ'
      errorMsg = "Please fill 'Mobile Number'"
      this.mobileNumber.focus()
    // } else if (this.state.mobileNumber.length !== 10) {
    //   errorMsg = "Please fill 'Mobile Number' correctly"
    //   // errorMsg = 'กรุณากรอกเบอร์โทรศัพท์มือถือให้ถูกต้อง'
    //   this.setState({ errorMsg: errorMsg })
    //   this.mobileNumber.focus()
    } else if (this.state.school === null || this.state.school === '') {
      errorMsg = "Please fill 'School'"
      // errorMsg = 'กรุณากรอกโรงเรียน'
      this.school.focus()
    } else {
      this.activate()
    }

    this.setState({ errorMsg: errorMsg })
  }

  activate = async () => {
    this.setState({
      isLoading: true
    })

    const hash = this.props.match.params.videoHash
    const code = atob(hash)

    const result = await apiClient.video.activateCode(
      code,
      this.state.name,
      this.state.age,
      this.state.mobileNumber,
      this.state.school
    )

    if (result.success) {
      this.props.history.push(`/Codeo/${hash}`)
    } else {
      this.setState({
        errorMsg: result.message,
        isLoading: false
      })
    }
  }

  showError = () => {
    if (this.state.errorMsg !== '') {
      return (
        <Message
          color="red"
          icon="exclamation circle"
          content={this.state.errorMsg}
          size="small"
        />
      )
    }
  }

  render() {
    if (this.state.isLoading) {
      return <Spinner />
    }

    return (
      <MainLayout showLang={false}>
        <div>
          <Grid>
            <Grid.Row centered>
              <Segment style={styles.card}>
                <div style={{ marginTop: '10px' }}>
                  <Form>
                    <Form.Field><h2>Student Information</h2></Form.Field>
                    <Form.Field>{this.showError()}</Form.Field>
                    <Form.Field>
                      <Input
                        ref={field => (this.name = field)}
                        // placeholder="ชื่อ"
                        placeholder="Name"
                        value={this.state.name}
                        onChange={this.handleChange('name')}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Input
                        ref={field => (this.age = field)}
                        type="number"
                        // placeholder="อายุ"
                        placeholder="Age"
                        value={this.state.age}
                        onChange={this.handleChange('age')}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Input
                        ref={field => (this.mobileNumber = field)}
                        // placeholder="เบอร์โทรศัพท์มือถือ"
                        placeholder="Mobile Number"
                        value={this.state.mobileNumber}
                        onChange={this.handleChange('mobileNumber')}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Input
                        ref={field => (this.school = field)}
                        // placeholder="โรงเรียน"
                        placeholder="School"
                        value={this.state.school}
                        onChange={this.handleChange('school')}
                      />
                    </Form.Field>
                    <Form.Field>
                      <CGButton
                        style={styles.nextButton}
                        onClick={() => this.validateData()}
                      >
                        Submit
                      </CGButton>
                    </Form.Field>
                  </Form>
                </div>
              </Segment>
            </Grid.Row>
          </Grid>
        </div>
      </MainLayout>
    )
  }
}

export default withRouter(CGVideoUserForm)