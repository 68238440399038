import { getRequest } from '../lib/request'

export function getObjectFromAWS() {
    return getRequest(`/photo/getObject`)
}

export function getPhotoUrlByStudentId(studentId) {
    return getRequest(`/photo/getPhoto/student/${studentId}`)
}

export function getAlbumByStudentId(studentId) {
    return getRequest(`/photo/getAlbum/student/${studentId}`)
}

export function getPhotoUrlByCourseIdAndStudentId(courseId, studentId, pageSize, pageNumber) {
    const data = {
        pageSize,
        pageNumber
    }
    return getRequest(`/photo/getPhoto/course/${courseId}/student/${studentId}`, data)
}