import React from 'react'
import { CGButton } from './CGButton'
import { withI18next } from '../../lib/withI18next'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

const CGBackButton = withI18next(['gallery'])((props) => {
    const { action, t } = props
    return (
        <CGButton onClick={action}><ChevronLeftIcon /> {t('button.back')}</CGButton>
    )
})

export { CGBackButton }