export const styles = {
  container: {
    color: '#F37E71'
  },
  header: {
    fontSize: '2.2em',
    textAlign: 'center',
    lineHeight: '1.16em',
    fontFamily: `"Pridi", "Roboto", "Helvetica", "Arial", sans-serif`
  },
  subHeader: {
    textAlign: 'center',
    fontSize: '1em',
    lineHeight: '1.65em',
    fontFamily: `"Varela Round", "K2D", "Roboto", "Helvetica", "Arial", sans-serif`
  }
}
