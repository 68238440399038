import { postRequest, getRequest } from '../lib/request'

export function add(data) {
    return postRequest(`/student-demo`, data)
}

export function update(data) {
    return postRequest(`/student-demo/${data.studentDemoId}`, data)
}

export function search(data) {
    return getRequest(`/student-demo`, data)
}

export function get(id) {
    return getRequest(`/student-demo/${id}`)
}