export default theme => ({
    table: {
        minWidth: 700,
        marginBottom: '15px'
    },
    heading: {
        width: '100%',
        marginBottom: '15px'
    },
    headingReceipt: {
        //temporary style for comment code in ReceiptGen
        marginTop: '75px'
    },
    head: {
        color: theme.palette.common.black,
        fontSize: 20
    },
    logo: {
        // position: 'absolute',
        // width: '25%',
        // top: '20px'
        width: '220px',
        height: '117px'
    },
    noBorder: {
        border: '0px',
        padding: '20px'
    },
    classText: {
        margin: '0 2px'
    },
    courseContainer: {
        padding: '7px 0'
    }
})
