import React from 'react'
import {
    Accordion,
    Button,
    Card,
    Dimmer,
    Dropdown,
    Form,
    Grid,
    Icon,
    Input,
    Label,
    Loader,
    Modal
} from 'semantic-ui-react'
import {withRouter} from 'react-router'
import apiClient from '../../apis'
import MainLayout from '../../layouts/MainLayout'
import DataList from './DataList'
import PendingDataList from './PendingDataList'
import {BUTTON_ICON, STUDENT_CLASS_STATUS, STUDENT_CLASS_STATUS_TEXT} from '../../common/const'
import styles from './styles'
import {getCurrentUser, getUserBranchId} from '../../common/util';

class Search extends React.Component {

    constructor(props) {
        super(props)
        let currentUser = getCurrentUser()
        this.state = {
            searchData: {
                classDateFrom: '',
                classDateTo: '',
                classTimeFrom: '',
                classTimeTo: '',
                courseId: [],
                branchId: 1,
                studentId: '',
                classId: '',
                name: '',
                status: []
            },
            changeData: {
                studentId: '',
                classId: '',
                status: '',
                isFeedback: 0,
                userId: currentUser.userId
            },
            tableData: undefined,
            isLoading: false,
            isShowTool: true,
            isPending: false,
            isResultPending: false,
            courseList: [],
            branchList: []
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleChangeStatus = this.handleChangeStatus.bind(this)
        this.handleChangeIsFeedback = this.handleChangeIsFeedback.bind(this)
    }

    componentDidMount() {
        this.getCourseList()
        this.getBranchList()

        this.setState({
            searchData: {
                ...this.state.searchData,
                classDateFrom: this.currentDate(new Date()),
                classDateTo: this.currentDate(new Date()),
            }
        }, this.doSearch)
    }

    currentDate(date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    doSearch = async () => {
        this.setState({
            isLoading: true,
            tableData: undefined
        })

        if (this.state.searchData.status.indexOf(STUDENT_CLASS_STATUS.PENDING) !== -1) {
            await this.setState({
                searchData: {
                    ...this.state.searchData,
                    classDateFrom: '',
                    classDateTo: '',
                    classTimeFrom: '',
                    classTimeTo: '',
                }
            })
        } else if (!this.state.searchData.classDateFrom || !this.state.searchData.classDateTo) {
            await this.setState({
                searchData: {
                    ...this.state.searchData,
                    classDateFrom: this.currentDate(new Date()),
                    classDateTo: this.currentDate(new Date()),
                }
            })
        }

        const data = this.state.searchData
        let searchData = {}
        for (const key in data) {
            if (data[key] !== '' && data[key] !== null && data[key] !== undefined && data[key] !== 'undefined') {
                searchData[key] = data[key]
            }
        }
        const result = await apiClient.course.getStudentCheckList(searchData)
        let resultData = result.data.map((p, index) => {
            p.isActionLoading = false
            p.isFeedbackLoading = false
            return p
        })
        this.setState({
            tableData: resultData,
            isResultPending: this.state.isPending,
            isLoading: false
        })
    }

    getCourseList = async () => {
        const result = await apiClient.course.getCourseList(null)
        let arr = result.map((p, index) => {
            return {
                key: index,
                text: p.courseName,
                value: p.courseId
            }
        })
        this.setState({
            courseList: arr
        })
    }

    getBranchList = async () => {
        const branchId = getUserBranchId()
        const result = await apiClient.branch.getBranchesByBranchId(branchId)
        let arr = result.dataList.map((p, index) => {
            return {
                key: index,
                text: p.branchName,
                value: p.branchId
            }
        })
        this.setState({
            branchList: arr
        })
    }

    refreshRecord = async (classId, studentId) => {
        const result = await apiClient.course.getStudentCheckList({
            classId: classId,
            studentId: studentId,
            branchId: this.state.searchData.branchId
        })
        if (result.data.length > 0) {
            for (let i = 0; i < this.state.tableData.length; i++) {
                if (this.state.tableData[i].classId === classId && this.state.tableData[i].studentId === studentId) {
                    this.state.tableData[i].isFeedback = result.data[0].isFeedback
                    this.state.tableData[i].status = result.data[0].status
                    this.state.tableData[i].currentAttend = result.data[0].currentAttend
                    this.state.tableData[i].currentTotal = result.data[0].currentTotal
                    this.state.tableData[i].isActionLoading = false
                    this.state.tableData[i].isFeedbackLoading = false
                    break
                }
            }
        }
        this.setState({
            isLoading: false
        })
    }

    handleInputChange = async (e, {name, value}) => {
        let isPending = false
        if (name === 'status' && (value.indexOf(STUDENT_CLASS_STATUS.PENDING) !== -1 || value.indexOf(STUDENT_CLASS_STATUS.UNSCHEDULE) !== -1)) {
            value = [value[value.length - 1]]
            isPending = (value[0] === STUDENT_CLASS_STATUS.PENDING || value[0] === STUDENT_CLASS_STATUS.UNSCHEDULE) ? true : false
        }

        this.setState({
            searchData: {
                ...this.state.searchData,
                [name]: value
            },
            isPending: isPending
        })
    }

    handleChangeStatus = async (data, status) => {
        const changeStatus = {data: data, status: status}
        if ((data.status === STUDENT_CLASS_STATUS.CONFIRMED && status === STUDENT_CLASS_STATUS.ACTIVE)
            || (data.status === STUDENT_CLASS_STATUS.ATTENDED && status === STUDENT_CLASS_STATUS.CONFIRMED)) {
            this.setState({
                warning: true,
                warningMsg: 'Do you want to revert the status ?',
                modalId: REVERT_STATUS,
                changeStatus: changeStatus
            })
        } else {
            await this.setState({changeStatus: changeStatus})
            this.changeStatus()
        }
    }

    changeStatus = async () => {
        let data = this.state.changeStatus.data
        let status = this.state.changeStatus.status

        // set loading by record
        for (let i = 0; i < this.state.tableData.length; i++) {
            if (this.state.tableData[i].classId === data.classId && this.state.tableData[i].studentId === data.studentId) {
                this.state.tableData[i].isActionLoading = true
                break
            }
        }

        await this.closeWarningModal()

        let setStatusResult = null
        if (status !== STUDENT_CLASS_STATUS.SKIP) {
            await this.setState({
                changeData: {
                    ...this.state.changeData,
                    classId: data.classId,
                    studentId: data.studentId,
                    status: status
                }
            })
            setStatusResult = await apiClient.course.setStatus(this.state.changeData)
        }

        //ยกเลิก การสร้างคลาสใหม่เมื่อกด cancel
        // if (status === STUDENT_CLASS_STATUS.CANCEL && setStatusResult.status === 'success') { // case cancel auto insert new class
        //     const nextClassResult = await apiClient.course.getNextClassByCancel(data.studentId, data.courseId, data.classId, data.branchId)
        //     const form = {
        //         studentId: data.studentId,
        //         classId: nextClassResult.classDetail.classId,
        //         receiptId: nextClassResult.classDetail.receiptId,
        //         receiptPrice: nextClassResult.classDetail.receiptPrice,
        //         receiptCourseName: nextClassResult.classDetail.receiptCourseName,
        //         status: STUDENT_CLASS_STATUS.ACTIVE,
        //         remark: '',
        //         previousClassId: data.classId
        //     }
        //     await apiClient.course.setClassFromCancel(form)
        // } else 
        
        if (status === STUDENT_CLASS_STATUS.SKIP) { // case skip update class
            const nextClassResult = await apiClient.course.getNextClassByCancel(data.studentId, data.courseId, data.classId, data.branchId)
            const form = {
                studentId: data.studentId,
                classId: data.classId,
                newClassId: nextClassResult.classDetail.classId,
                status: data.status,
                remark: '[NO_SAVE]'
            }
            await apiClient.course.setClassFromEdit(form)

            // remove old record
            let tableData = this.state.tableData
            await this.setState({isLoading: true, tableData: undefined})
            for (let i = 0; i < tableData.length; i++) {
                if (tableData[i].classId === data.classId && tableData[i].studentId === data.studentId) {
                    tableData.splice(i, 1)
                    await this.setState({isLoading: false, tableData: tableData})
                    break
                }
            }
        }

        if (status !== STUDENT_CLASS_STATUS.SKIP) {
            this.refreshRecord(data.classId, data.studentId)
        }
    }

    handleChangeIsFeedback = async (data, isFeedback) => {
        await this.setState({
            changeData: {
                ...this.state.changeData,
                classId: data.classId,
                studentId: data.studentId,
                isFeedback: isFeedback
            }
        })

        if (isFeedback !== 1) {
            this.setState({warning: true, warningMsg: 'Confirm to uncheck feedback?', modalId: FEED_BACK})
        } else {
            this.setIsFeedback()
        }
    }

    setIsFeedback = async () => {
        for (let i = 0; i < this.state.tableData.length; i++) {
            if (this.state.tableData[i].classId === this.state.changeData.classId && this.state.tableData[i].studentId === this.state.changeData.studentId) {
                this.state.tableData[i].isFeedbackLoading = true
                break
            }
        }
        await this.closeWarningModal()
        const result = await apiClient.course.setIsFeedback(this.state.changeData.studentId, this.state.changeData.classId, this.state.changeData.isFeedback)
        this.refreshRecord(this.state.changeData.classId, this.state.changeData.studentId)
    }

    renderSearchCard = () => {
        const {classDateFrom, classDateTo, classTimeFrom, classTimeTo, courseId, studentId, name, studentEmerMobile, warning, status, branchId} = this.state.searchData

        return (
            <Card fluid>
                <Card.Content>
                    <Form>
                        <Grid columns='equal'>
                            <Grid.Column width={4}>
                                <label style={styles.labelField}>Branch</label>
                                <Dropdown
                                    name='branchId'
                                    placeholder='Select'
                                    onChange={this.handleInputChange}
                                    value={branchId}
                                    options={this.state.branchList}
                                    fluid
                                    selection
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <label style={styles.labelField}>Class Date</label>
                                <Input
                                    labelPosition='left'
                                    name='classDateFrom'
                                    type='date'
                                    value={classDateFrom}
                                    onChange={this.handleInputChange}
                                    size='small'
                                    style={styles.fromToDateInput}
                                    disabled={this.state.isPending}
                                >
                                    <Label>From</Label>
                                    <input style={styles.fromDateInput}/>
                                </Input>
                                <Input
                                    labelPosition='left'
                                    name='classDateTo'
                                    type='date'
                                    value={classDateTo}
                                    onChange={this.handleInputChange}
                                    size='small'
                                    style={styles.fromToDateInput}
                                    disabled={this.state.isPending}
                                >
                                    <Label style={styles.ToDateLabel}>To</Label>
                                    <input style={styles.ToDateInput}/>
                                </Input>
                            </Grid.Column>
                            <Grid.Column>
                                <label style={styles.labelField}>Class Time</label>
                                <Input
                                    labelPosition='left'
                                    name='classTimeFrom'
                                    type='time'
                                    value={classTimeFrom}
                                    onChange={this.handleInputChange}
                                    size='small'
                                    style={styles.fromToDateInput}
                                    disabled={this.state.isPending}
                                >
                                    <Label>From</Label>
                                    <input style={styles.fromDateInput}/>
                                </Input>
                                <Input
                                    labelPosition='left'
                                    name='classTimeTo'
                                    type='time'
                                    value={classTimeTo}
                                    onChange={this.handleInputChange}
                                    size='small'
                                    style={styles.fromToDateInput}
                                    disabled={this.state.isPending}
                                >
                                    <Label style={styles.ToDateLabel}>To</Label>
                                    <input style={styles.ToDateInput}/>
                                </Input>
                            </Grid.Column>
                        </Grid>
                        <Grid>
                            <Grid.Column width={5}>
                                <label style={styles.labelField}>Course</label>
                                <Dropdown
                                    name='courseId'
                                    placeholder='All'
                                    onChange={this.handleInputChange}
                                    value={courseId}
                                    options={this.state.courseList}
                                    fluid
                                    multiple
                                    selection
                                    search
                                />
                            </Grid.Column>
                            <Grid.Column width={2}>
                                <label style={styles.labelField}>Student ID</label>
                                <Input name='studentId' fluid placeholder='' type='text' value={studentId}
                                       onChange={this.handleInputChange}/>
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <label style={styles.labelField}>Name</label>
                                <Input name='name' fluid placeholder='' type='text' value={name}
                                       onChange={this.handleInputChange}/>
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <label style={styles.labelField}>Mobile Number</label>
                                <Input name='studentEmerMobile' fluid placeholder='' type='text' value={studentEmerMobile}
                                       onChange={this.handleInputChange}/>
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <label style={styles.labelField}>Status</label>
                                <Dropdown
                                    name='status'
                                    placeholder='All'
                                    onChange={this.handleInputChange}
                                    value={status}
                                    options={statusOptions}
                                    fluid
                                    multiple
                                    selection
                                />
                            </Grid.Column>
                        </Grid>
                    </Form>
                </Card.Content>
                <Card.Content>
                    <Grid>
                        <Grid.Column floated='left'>
                            <div style={{whiteSpace: 'nowrap', marginTop: '7px'}}>
                                <Button icon compact color='blue' size='mini'>
                                    <Icon name={BUTTON_ICON.ACTIVE}/></Button>
                                <label style={styles.labelFieldStatus}>{STUDENT_CLASS_STATUS_TEXT.ACTIVE}</label>

                                <Button icon compact color='yellow' size='mini' style={{marginLeft: '10px'}}>
                                    <Icon name={BUTTON_ICON.CONFIRM}/></Button>
                                <label style={styles.labelFieldStatus}>{STUDENT_CLASS_STATUS_TEXT.CONFIRM}</label>

                                <Button icon compact color='green' size='mini' style={{marginLeft: '10px'}}>
                                    <Icon name={BUTTON_ICON.ATTEND}/></Button>
                                <label style={styles.labelFieldStatus}>{STUDENT_CLASS_STATUS_TEXT.ATTEND}</label>

                                <Button icon compact size='mini' style={{marginLeft: '10px'}}>
                                    <Icon name={BUTTON_ICON.SKIP}/></Button>
                                <label style={styles.labelFieldStatus}>{STUDENT_CLASS_STATUS_TEXT.SKIP}</label>

                                <Button icon compact color='red' size='mini' style={{marginLeft: '10px'}}>
                                    <Icon name={BUTTON_ICON.CANCEL}/></Button>
                                <label style={styles.labelFieldStatus}>{STUDENT_CLASS_STATUS_TEXT.CANCEL}</label>
                            </div>
                        </Grid.Column>
                        <Grid.Column floated='right'>
                            <div style={{float: 'right'}}>
                                <Button primary disabled={this.state.isLoading} onClick={() => this.doSearch()}>
                                    Search
                                </Button>
                            </div>
                        </Grid.Column>
                    </Grid>
                </Card.Content>
            </Card>
        )
    }

    closeWarningModal = () => this.setState({warning: false, warningMsg: '', modalId: '', changeStatus: {}})

    renderModalAlert() {
        return (
            <Modal size={'mini'} open={this.state.warning} onClose={this.closeWarningModal}>
                <Modal.Header>Warning</Modal.Header>
                <Modal.Content>
                    <p>{this.state.warningMsg}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={this.closeWarningModal}>Cancel</Button>
                    {this.state.modalId === FEED_BACK ?
                        <Button
                            onClick={this.setIsFeedback}
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content='Confirm'
                        /> : null}
                    {this.state.modalId === REVERT_STATUS ?
                        <Button
                            onClick={this.changeStatus}
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content='Yes'
                        /> : null}
                </Modal.Actions>
            </Modal>
        )
    }

    render() {
        return (
            <MainLayout isFullScreen={true}>
                <div style={styles.container}>
                    <Grid centered stackable>
                        <Grid.Row>{this.renderModalAlert()}</Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Accordion fluid>
                                    <Accordion.Title
                                        active={this.state.isShowTool}
                                        onClick={() => this.setState({isShowTool: !this.state.isShowTool})}
                                    >
                                        <Icon name='dropdown'/>
                                        Search
                                    </Accordion.Title>
                                    <Accordion.Content active={this.state.isShowTool}>
                                        {this.renderSearchCard()}
                                    </Accordion.Content>
                                </Accordion>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            {this.state.isLoading ?
                                <Grid.Column>
                                    <Dimmer inverted active={this.state.isLoading}><Loader/></Dimmer>
                                </Grid.Column> :
                                <Grid.Column>
                                    {this.state.isResultPending ?
                                        <PendingDataList
                                            tableData={this.state.tableData}
                                            onPaginationChange={this.handlePaginationChange}
                                            onSortingChange={this.handleSortingChange}
                                        /> :
                                        <DataList
                                            tableData={this.state.tableData}
                                            onPaginationChange={this.handlePaginationChange}
                                            onSortingChange={this.handleSortingChange}
                                            onChangeStatus={this.handleChangeStatus}
                                            onChangeIsFeedback={this.handleChangeIsFeedback}
                                        />
                                    }
                                </Grid.Column>
                            }
                        </Grid.Row>
                    </Grid>
                </div>
            </MainLayout>
        )
    }
}

export default withRouter(Search)

const statusOptions = [
    {
        key: STUDENT_CLASS_STATUS.ACTIVE,
        text: STUDENT_CLASS_STATUS_TEXT.ACTIVE,
        value: STUDENT_CLASS_STATUS.ACTIVE
    },
    {
        key: STUDENT_CLASS_STATUS.CONFIRMED,
        text: STUDENT_CLASS_STATUS_TEXT.CONFIRM,
        value: STUDENT_CLASS_STATUS.CONFIRMED
    },
    {
        key: STUDENT_CLASS_STATUS.ATTENDED,
        text: STUDENT_CLASS_STATUS_TEXT.ATTEND,
        value: STUDENT_CLASS_STATUS.ATTENDED
    },
    {
        key: STUDENT_CLASS_STATUS.CANCEL,
        text: STUDENT_CLASS_STATUS_TEXT.CANCEL,
        value: STUDENT_CLASS_STATUS.CANCEL
    },
    {
        key: STUDENT_CLASS_STATUS.PENDING,
        text: STUDENT_CLASS_STATUS_TEXT.PENDING,
        value: STUDENT_CLASS_STATUS.PENDING
    },
    {
        key: STUDENT_CLASS_STATUS.UNSCHEDULE,
        text: STUDENT_CLASS_STATUS_TEXT.UNSCHEDULE,
        value: STUDENT_CLASS_STATUS.UNSCHEDULE
    },
    {
        key: STUDENT_CLASS_STATUS.REFUND,
        text: STUDENT_CLASS_STATUS_TEXT.REFUND,
        value: STUDENT_CLASS_STATUS.REFUND
    }
]

const REVERT_STATUS = 'REVERT_STATUS'
const FEED_BACK = 'FEED_BACK'
