import React from 'react'
import { withRouter } from 'react-router'
import {
  Segment,
  Icon,
  Container,
  Menu
} from 'semantic-ui-react'
import _ from 'lodash'
// import Vimeo from '@u-wave/react-vimeo'

import { Spinner } from '../../../components/common'
import MainLayout from '@layouts/MainLayout'
import apiClient from '../../../apis'

import './index.css'

class CGVideo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      videos: [],
      isLoading: false,
      selected: 0
    }
  }

  componentDidMount() {
    const hash = this.props.match.params.videoHash
    const code = atob(hash)
    if (code && code.length > 0) {
        this.signIn({ code })
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  signIn = async ({ code }) => {
    this.setState({
      isLoading: true
    })
    let result
    result = await apiClient.video.authenticate(code)
    this.setState({
      isLoading: false
    })

    if (result.success && result.first) {
      const hash = this.props.match.params.videoHash
      this.props.history.push(`/Codeo/${hash}/Form`)
    } 
    else if (result.success) {
      this.setState({
        videos: result.videos,
        product: result.product,
        code: result.code
      })
    } else {
      // Fail to authenticate.
      const random = Math.round(100000 + Math.random() * 900000)
      this.props.history.push(`/Codeo?e=${random}`)
    }
  }

  // getVideoUrl = async (fullUrl = '') => {
  //   let finalUrl = ''

  //   const regexPlayer = /^https?:\/\/player\.vimeo.+/
  //   const regexRootVideo = /^https?:\/\/vimeo.+/

  //   if (regexPlayer.test(fullUrl)) {
  //     finalUrl = `https://player.vimeo.com/video/${_.last(
  //       fullUrl.split('/')
  //     )}?title=0&byline=0&portrait=0&autopause=0&app_id=122963`
  //   } else if (regexRootVideo.test(fullUrl)) {
  //     const res = await fetch(`https://vimeo.com/api/oembed.json?url=${fullUrl}`, { method: 'GET' })
  //     const data = await res.json()
  //     const iframeHtml = _.get(data, 'html', '')
  //     const urlExtracted = iframeHtml.match(/^<iframe.*src=([^ ]+)/)[1].replace(/\"/g, '')
  //     finalUrl = urlExtracted
  //   } else {
  //     finalUrl = fullUrl
  //   }

  //   return finalUrl
  // }

  renderContent() {
    const { isLoading, videos, selected, product, code } = this.state
    if (isLoading || videos === null || videos.length <= 0) {
      return <Spinner />
    }

    // 561232405, 561232896

    // const videoId = this.getVideoUrl(videos[selected].vimeoLink)
    const videoId = videos[selected].vimeoLink
    const url = `https://player.vimeo.com/video/${videoId}`
    // const url = 'https://player.vimeo.com/video/115783408?speed=0&app_id=122963&h=def2b8dd8f'
    // const url = 'https://player.vimeo.com/video/561232405'
    // const url = 'https://player.vimeo.com/video/561232405'
    // const url = 'https://player.vimeo.com/video/561232896'

    return (
      <div>
        <h1>{product.productName}</h1>
        <div className="playerWrapper">
          <div className="playerContainer">
            <div className="player">
              <iframe 
                className="playeriframe" 
                src={url} 
                title='v' 
                frameBorder='0' 
                allow='autoplay; fullscreen' allowFullScreen 
              />
            </div>
          </div>
        </div>
        <CountdownTimer expirationDate={code.expirationDate} />
        {/* <iframe src={url} title='v' frameBorder='0' allow='autoplay; fullscreen' allowFullScreen /> */}
        {/* <Vimeo className="cgvideoiframe" video={videos[selected].vimeoLink} width="100%" height="432" style={{ width: '123px'}} /> */}
        {/* <h2>{videos[selected].vimeoLink}</h2> */}
        {this.renderMenu()}
      </div>
    )
  }

  selectVideo = idx => {
    this.setState({ selected: idx })
  }

  renderMenu() {
    const { isLoading, videos, selected } = this.state
    if (isLoading) {
      return null
    }

    return (
      <Menu vertical fluid>
        {videos.map((v, idx) => {
          return (
            <Menu.Item
              key={idx}
              active={idx === selected}
              style={{ 
                backgroundColor: idx === selected ? 'teal' : 'white',
                color: idx === selected ? 'white' : 'teal',
                fontSize: '1.2rem' 
              }}
              onClick={() => this.selectVideo(idx)}
            >
              <Icon 
                color={idx === selected ? 'white' : 'teal'}
                name={idx === selected ? 'pause circle' : 'play circle'}
                style={{ float: 'none', marginRight: '5px', }} 
                size="large"
              />
              {v.videoName}
            </Menu.Item>
          )
        })}
      </Menu>
    )
  }

  render() {
    return (
      <MainLayout showLang={false}>
        <Container className="video-container" style={{ marginTop: '36px' }}>
          <Segment className="video-container" basic textAlign='left'>{this.renderContent()}</Segment>
        </Container>
      </MainLayout>
    )
  }
}

export default withRouter(CGVideo)

class CountdownTimer extends React.Component {
  state = {
    day: 0,
    hour: 0,
    minute: 0
  }

  componentDidMount() {
    this.countDown()
    this.timer = setInterval(this.countDown, 1000 * 60)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  countDown = () => {
    const { expirationDate } = this.props
    const until = new Date(expirationDate) - Date.now()

    this.setState({ 
      day: Math.floor(until / (1000 * 60 * 60 * 24)), 
      hour: Math.floor(until / (1000 * 60 * 60)) % 24,
      minute: Math.floor(until / (1000 * 60)) % 60,
      // sec: Math.floor(until / (1000)) % 60
    })
  }

  render() {
    const { day, hour, minute } = this.state
    const text = `Expired in ${day} ${day > 1 ? "days" : "day"} : ${hour} ${hour > 1 ? "hours" : "hour"} : ${minute} ${minute > 1 ? "minutes" : "minute"}`
    return (
      <div style={{ 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center",
        marginTop: "12px"
      }}>
        <div style={{ 
          padding: "6px 24px",
          color: "#fff",
          fontSize: "14px",
          backgroundColor: "#eb5234",
          width: "max-content",
          display: "flex", 
          justifyContent: "center", 
          alignItems: "center",
          borderRadius: "20px"
        }}>
          {text}
        </div>
      </div>
    )

  }
}