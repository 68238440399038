import React, {Component} from 'react'
import {Grid, Image, Table} from 'semantic-ui-react'
import {withRouter} from 'react-router'
import {formatDateDisplay, formatTimeDisplay} from '../../common/util'
import './custom-style.css';

class PendingDataList extends Component {

    render() {
        const {tableData} = this.props
        let isUnschedule = false

        let result = []
        for (let i = 0; tableData && i < tableData.length; i++) {
            let data = tableData[i]
            if (data.lastPreviousClassDate) isUnschedule = true

            let isShow = true, rowSpan = 1
            if (i === 0 || (i > 0 && (data.studentId !== tableData[i - 1].studentId))) { // check rowSpan (first row or current <> previous)
                for (let j = i + 1; j < tableData.length; j++) {
                    if (data.studentId === tableData[j].studentId) // current = next -> incress rowSpan
                        rowSpan++
                    else
                        break
                }
            } else { // not show column name and mobile student
                isShow = false
            }

            result.push(
                <Table.Row key={i}>
                    {isShow ?
                        <Table.Cell verticalAlign='top' rowSpan={rowSpan} className='zui-table-base'>
                            {data.studentId} {data.studentFirstName} {data.studentNickName ? '(' + data.studentNickName + ') ' : ' '}
                            {data.ads === 0 ? <Image style={{height: '1rem'}} inline
                                                     src={`${process.env.PUBLIC_URL}/icons/no_camera.ico`}/> : null}
                        </Table.Cell> : null
                    }
                    {isShow ?
                        <Table.Cell textAlign='center' verticalAlign='top' rowSpan={rowSpan} className='zui-table-base'>
                            {data.emerMobileNumber}
                        </Table.Cell> : null
                    }
                    <Table.Cell textAlign='left' verticalAlign='top'>
                        {data.fullCourseName}
                    </Table.Cell>
                    <Table.Cell textAlign='center' verticalAlign='top'>
                        {formatTimeDisplay(data.startTime)}
                    </Table.Cell>
                    <Table.Cell textAlign='center' verticalAlign='top'>
                        {formatDateDisplay(data.classDate)}
                    </Table.Cell>
                    {isUnschedule &&
                    <Table.Cell textAlign='center' verticalAlign='top'>
                        {formatDateDisplay(data.lastPreviousClassDate)}
                    </Table.Cell>
                    }
                    <Table.Cell textAlign='center' verticalAlign='middle'>
                        {data.currentAttend + ' / ' + data.currentTotal}
                    </Table.Cell>
                    <Table.Cell verticalAlign='top'>{data.remark}</Table.Cell>
                </Table.Row>
            )
        }

        return (
            <Grid style={{margin: '0'}}>
                <Table celled structured compact style={{padding: '0'}} size='small'
                       className='zui-table-highlight'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell textAlign='center' width='2'>Student</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='1'>Tel</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='2'>Course</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='1'>Time</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='1'>Date</Table.HeaderCell>
                            {isUnschedule &&
                            <Table.HeaderCell textAlign='center' width='1'>Last previous class date</Table.HeaderCell>
                            }
                            <Table.HeaderCell textAlign='center' width='1'>Current</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='3'>Remark</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {result && result.length > 0 ? result :
                            <Table.Row>
                                <Table.Cell verticalAlign='middle' warning textAlign='center' colSpan='10'>
                                    No Data Found
                                </Table.Cell>
                            </Table.Row>
                        }
                    </Table.Body>
                </Table>
            </Grid>
        )
    }
}

export default withRouter(PendingDataList)
