import * as authClient from './auth'
import * as branch from './branch'
import * as signup from './signup'
import * as student from './student'
import * as course from './course'
import * as courseDetail from './courseDetail'
import * as photo from './photo'
import * as user from './user'
import * as classes from './classes'
import * as studentDemo from './studentDemo'
import * as assessment from './assessment'
import * as teacher from './teacher'
import * as classz from './class'
import * as receipt from './receipt'
import * as room from './room'
import * as video from './video'

export default {
    auth: authClient,
    branch: branch,
    signup: signup,
    student: student,
    course: course,
    courseDetail: courseDetail,
    photo : photo,
    user : user,
    classes: classes,
    studentDemo: studentDemo,
    teacher: teacher,
    class: classz,
    branch: branch,
    receipt: receipt,
    assessment: assessment,
    teacher: teacher,
    room: room,
    video: video
}
