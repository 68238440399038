import _ from 'lodash'
import moment from 'moment'
import jwt from 'jsonwebtoken'
import { Room } from '@material-ui/icons'
import { ROLE } from './const'

const THAI_MONTHS = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม']

export function getCurrentUser() {
    try {
        return JSON.parse(localStorage.getItem('user'))
    } catch (e) {
        return undefined
    }
}

export function isReadonly() {
    const userInfo = getCurrentUser()
    let readonly = true
    if (userInfo && userInfo.permission && userInfo.permission.indexOf('WRITE') !== -1) {
        readonly = false
    }
    return readonly
}

export function getUserRole() {
    const currentUser = getCurrentUser()
    if (currentUser) {
        return currentUser.role
    }
    return undefined
}

export function getUserBranchId() {
    const currentUser = getCurrentUser()
    if (currentUser) {
        return currentUser.branchId
    }
    return undefined
}

export function validateSuperAdminRole() {
    const userRole = getUserRole()
    const userBranchId = getUserBranchId()
    if (userRole === ROLE.ADMIN && userBranchId === 0 || userRole === ROLE.GOD) {
        return true
    } else { 
        return false
    }
}

export function validateRole(roles) {
    if (isUndefined(roles) || roles.length === 0) {
        return true
    }

    if (!(roles instanceof Array)) {
        roles = [roles]
    }

    const userRole = getUserRole()
    if (isUndefined(userRole) || roles.indexOf(userRole) === -1) {
        return false
    } else {
        return true
    }
}

export function validateBranchId(branchIds) {
    if (isUndefined(branchIds) || branchIds.length === 0) {
        return true
    }

    if (!(branchIds instanceof Array)) {
        branchIds = [branchIds]
    }

    const userBranchId = getUserBranchId()
    if (isUndefined(userBranchId) || branchIds.indexOf(userBranchId) === -1) {
        return false
    } else {
        return true
    }
}

export function getToken() {
    return localStorage.getItem('access_token')
}

export function setToken(token) {
    const decodedToken = jwt.decode(token)
    localStorage.setItem('access_token', token)
    localStorage.setItem('user', JSON.stringify(decodedToken))
}

export function removeToken() {
    localStorage.setItem('access_token', undefined)
    localStorage.setItem('user', undefined)
}

export function isUndefined(val) {
    return val === null || val === undefined || val === NaN
}

export function isEmpty(val) {
    return _.isEmpty(val)
}

export function split(string, separator, limit) {
    return _.split(string, separator, limit)
}

export function validateOnlyNumber(number) {
    const numbers = '0123456789'
    let newNumber = ''
    if (number.length === 0) {
        newNumber = number
    } else {
        for (let i = 0; i < number.length; i++) {
            if (numbers.indexOf(number[i]) > -1) {
                newNumber = newNumber + number[i]
            }
        }
    }
    return newNumber
}

export function formatTimeInput(time) {
    return moment(time, 'hh:mm:ss').format('hh:mm A')
}

export function formatDateInput(date) {
    return moment(date).format('MMM, Do')
}

export function formatDateDisplay(date) {
    return date ? moment(date).format('DD MMM YYYY') : '-'
}

export function formatDateTimeDisplay(date) {
    return date ? moment(date).format('DD MMM YYYY, hh:mm A') : '-'
}

export function formatTimeDisplay(date) {
    return date?moment(date, 'HH:mm:ss').format('HH:mm'):'-'
}

export function formatDate(date, style, placeholder = '') {
    return date ? moment(date).format(style) : placeholder
}

export function formatThaiDate(dateStr) {
    const date = moment(dateStr)
    const day = date.date()
    const month = THAI_MONTHS[date.month()]
    const year = date.year() + 543;
    return `${day} ${month} ${year}`

}

export function convertNumber(number, decimalLength) {
    const numberWithOutDecimal = number - number % 1
    const numberWithComma = numberWithOutDecimal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    const decimal = (number % 1).toFixed(decimalLength)

    return (numberWithComma) + (decimal).substring(1, decimal.length)
}

export function validateTime(time) {
    let isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(time);
    if (isValid) return true
    else return false
}

export function getColorFromDow(date) {
    const dow = moment(date).weekday()
    return ['red', 'yellow', 'pink', 'green', 'orange', 'blue', 'purple'][dow]
}