import React from "react"
import {PrintPage, ReceiptGenV2} from "../common"
import apiClient from "../../apis"

let PrintTemplate;

class ReceiptPrintFormV2 extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            isMounted: false,
            receiptNo: "",
            receiptId: "",
            headline: "",
            subHeadline1: "",
            subHeadline2: "",
            body1: "",
            body2: "",
            body3: "",
            footer: "",
            name: "",
            address: "",
            issuedDate: "",
            mobileNumber: "",
            discount: "",
            receiptItems: []
        }
    }

    componentDidMount() {
        PrintTemplate = require("react-print")
        this.setState({isMounted: true})
    }

    async print(branchId, receiptId) {
        if (receiptId !== null) {
            await this.loadData(receiptId);
        }
    }

    loadData = async (receiptId) => {
        if (!receiptId) return
        let result = await apiClient.receipt.getReceipt(receiptId)
        const data = result.data
        if (!data) {
            return
        } else {
            this.setState({ ...data.receipt, receiptItems: data.receiptItems }, () => window.print())
        }
    }

    renderReceiptGen() {
        return (
            <PrintTemplate>
                <PrintPage>
                    <ReceiptGenV2
                        headline={this.state.headline}
                        subHeadline1={this.state.subHeadline1}
                        subHeadline2={this.state.subHeadline2}
                        body1={this.state.body1}
                        body2={this.state.body2}
                        body3={this.state.body3}
                        footer={this.state.footer}
                        name={this.state.name}
                        address={this.state.address}
                        receiptId={this.state.receiptId}
                        receiptNo={this.state.receiptNo}
                        date={this.state.issuedDate}
                        mobileNumber={this.state.mobileNumber}
                        discount={this.state.discount}
                        branchId={this.state.branchId}
                        receiptItems={this.state.receiptItems}
                    />
                </PrintPage>
            </PrintTemplate>
        )
    }

    render() {
        if (!this.state.isMounted) return <div/>

        return (
            <PrintTemplate>
                <PrintPage>
                    {this.renderReceiptGen()}
                </PrintPage>
            </PrintTemplate>
        )
    }
}

export default ReceiptPrintFormV2
