export const FACEBOOK = {
    TOKEN: '1787335918050474'
}

export const WEB_VERSION = {
    VERSION: '20200927'
}

export const COMPANY_INFO = {
    LINK: {
        FACEBOOK: 'https://www.facebook.com/codegeniusacademy/',
        LINE: 'https://lin.ee/n9bUX1Q',
        TWITTER: 'https://twitter.com',
        INSTAGRAM: 'https://instagram.com',
        GOOGLE_MAP: 'https://goo.gl/maps/oZ1z6g86PZBEYF3L9'
    },
    NAME: 'บริษัท พรีเมียร์ เอ็ดดูเคชั่น จำกัด สาขา 3',
    TAX: 'เลขประจำตัวผู้เสียภาษี 0105558061978',
    ADDRESS_1: '991 อาคารศูนย์การค้าสยามพารากอน ช้ัน 4 ห้อง 453',
    ADDRESS_2: 'ถ.พระราม 1 แขวงปทุมวัน เขตปทุมวัน กรุงเทพมหานคร',
    TEL: '092-5255-465',
    EMAIL: 'hello@codegeniusacademy.com'
}

export const MAIN_BRANCH_ID = 0

export const ROLE = {
    USER: 0,
    ADMIN: 1,
    CUSTOMER: 2,
    ACCOUNT: 3,
    TEACHER: 4,
    ADMIN_BRANCH: 5,
    SUPER_ADMIN: 6,
    GOD: 7 

}

export const STUDENT_CLASS_STATUS = {
    ACTIVE: 'ACTIVE',
    CONFIRMED: 'CONFIRMED',
    ATTENDED: 'ATTENDED',
    CANCEL: 'ABSENCE',
    PENDING: 'PENDING',
    //ABSENCE: 'ABSENCE',
    SKIP: 'SKIP',
    UNSCHEDULE: 'UNSCHEDULED',
    REFUND: 'REFUNDED',
}

export const STUDENT_CLASS_STATUS_TEXT = {
    ACTIVE: 'ACTIVE',
    CONFIRM: 'CONFIRM',
    ATTEND: 'ATTEND',
    CANCEL: 'ABSENCE',
    PENDING: 'PENDING',
    //ABSENCE: 'ABSENCE',
    SKIP: 'SKIP',
    UNSCHEDULE : 'UNSCHEDULED',
    REFUND: 'REFUNDED',
    DELETED: 'D'
}

export const BUTTON_ICON = {
    ACTIVE: 'undo',
    CONFIRM: 'calendar check',
    ATTEND: 'sign-in',
    CANCEL: 'calendar times',
    EDIT: 'pencil',
    SKIP: 'mail forward'
}

export const DEFAULT_BRANCH_ID = 1
export const DOWS_TEXT = ['Sun.', 'Mon.', 'Tue.', 'Wed.', 'Thu.', 'Fri.', 'Sat.']
export const DEFAULT_PAGE_SIZE = 10

export const STUDENT_DEMO_STATUS = {
    LEAD_IN: 'LEAD_IN',
    CONFIRM: 'CONFIRM',
    REJECT: 'REJECT',
    ALREADY_DEMO: 'ALREADY_DEMO',
    WIN: 'WIN',
    LOSE: 'LOSE'
}

export const STUDENT_DEMO_STATUS_TEXT = {
    LEAD_IN: 'Lead In',
    CONFIRM: 'Confirmed',
    REJECT: 'Reject',
    ALREADY_DEMO: 'Already Demo',
    WIN: 'Win',
    LOSE: 'Lose'
}

export const COURSE_TYPE = {
    CORE_COURSE: 'Core Course',
    CAMP: 'Camp',
    CODE_EXPERT: 'Code Expert',
    ONLINE_CORE_COURSE: 'Online Core Course',
    OTHER: 'Other'
}

export const EDIT_DEADLINE_DATE = 5
