import React, { Component } from 'react'
import { Table, Card, Label, Button, Pagination, Form, Input, Grid, Accordion, Icon, Container, Dropdown, Breadcrumb } from 'semantic-ui-react'
import { withRouter } from 'react-router'
import styles from './styles'
import moment from 'moment'
import MainLayout from '../../../layouts/MainLayout';
import apiClient from '../../../apis';
import { formatDateDisplay, formatTimeDisplay, validateRole, getCurrentUser } from '../../../common/util'
import { ROLE } from '../../../common/const'
import Spinner from '../../../components/common/Spinner';

class AssessmentSearch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      searchData: [],
      searchParameter: {
        classStartDate: moment(new Date()).subtract(1, 'month').format('YYYY-MM-DD'),
        classEndDate: moment(new Date()).format('YYYY-MM-DD'),
        assessmentStatus: 'NotDone',
        orderBy: 'cl.classDate',
        orderType: 'desc'
      },
      totalPages: 0,
      totalData: 0,
      activePage: 1,
      pageSize: 10,
      isShowTool: true,
      isTeacher : validateRole([ROLE.TEACHER]),
      isTeacherAllBranch : getCurrentUser().branchId === 0 ? true : false,
      teacherList: [],
      courseList: [],
      branchList: [
        { key: 'All', value: 'All', text: 'All' }
      ],
      assessmentStatusList: [
        { key: 'All', value: 'All', text: 'All' },
        { key: 'Done', value: 'Done', text: 'Done' },
        { key: 'Not Done', value: 'NotDone', text: 'Not Done' }
      ]
    }
  }

  componentDidMount() {
    this.searchAssessment()
    this.getTeacherList()
    this.getCourseList()
    this.getBranchList()
  }

  getCourseList = () => {
    let { courseList } = this.state
    apiClient.course.getCoreCourse().then(res => {
      for (const course of res) {
        courseList.push({
          key: course.courseId,
          value: course.courseId,
          text: course.courseName
        })
      }
      this.setState({
        courseList: courseList
      })
    })
  }

  getBranchList = () => {
    let { branchList } = this.state
    apiClient.branch.getAllBranches().then(res => {
      for (const branch of res.dataList) {
        branchList.push({
          key: branch.branchId,
          value: branch.branchId,
          text: branch.branchName
        })
      }
      this.setState({
        branchList: branchList,
      })
    })
  }

  getTeacherList = () => {
    let { teacherList } = this.state
    apiClient.teacher.getTeacherList().then(res => {
      for (const teacher of res) {
        teacherList.push({
          key: teacher.id,
          value: teacher.id,
          text: teacher.name
        })
      }
      this.setState({
        teacherList: teacherList
      })
    })
  }

  searchAssessment = () => {
    this.setState({
      isLoading: true
    })
    let { pageSize, activePage, searchParameter } = this.state
    searchParameter.pageSize = pageSize
    searchParameter.pageNumber = activePage
    searchParameter.courseType = 'Core Course'
    searchParameter.branchId = (searchParameter.branchId === 'All') ? '' : searchParameter.branchId
    searchParameter.branchId = (getCurrentUser().branchId && getCurrentUser().branchId !== 0) ? getCurrentUser().branchId : searchParameter.branchId
    searchParameter.teacherId = (getCurrentUser().teacherId) ? getCurrentUser().teacherId : null
    apiClient.assessment.getClass(searchParameter).then(res => {
      this.setState({
        searchData: res.classes,
        totalPages: res.totalPages,
        totalData: res.totalData,
        isLoading: false
      })
    })
  }

  handlePaginationChange = (e, { activePage }) => {
    this.setState({
      activePage: activePage
    }, this.searchAssessment)
  }

  handleInputChange = (e, { name, value }) => {
    this.setState({
      searchParameter: {
        ...this.state.searchParameter,
        [name]: value
      }
    })
  }

  submitSearch = () => {
    this.setState({
      searchParameter: {
        ...this.state.searchParameter
      }
    }, this.searchAssessment)
  }

  renderBreadcrumb = () => {
    return (
      <Breadcrumb>
        <Breadcrumb.Section active>
          <a href='/assessment'>Assessment</a>
        </Breadcrumb.Section>
      </Breadcrumb>
    )
  }

  renderSearchForm = () => {
    const { teacherList, courseList, branchList, assessmentStatusList, isTeacher } = this.state
    const { classStartDate, classEndDate, teacherId, courseId, branchId, assessmentStatus } = this.state.searchParameter
    return (
      <Accordion fluid>
        <Accordion.Title active={this.state.isShowTool} onClick={() => this.setState({ isShowTool: !this.state.isShowTool })}>
          <Icon name='dropdown' />Search
        </Accordion.Title>
        <Accordion.Content active={this.state.isShowTool}>
          <Card fluid>
            <Card.Content>
              <Form>
                <Grid>
                  <Grid.Row>
                    {!isTeacher ?
                      <Grid.Column width={4}>
                        <label>Branch</label>
                        <Dropdown placeholder='All' fluid search selection name='branchId' value={branchId} 
                          onChange={this.handleInputChange} options={branchList} />
                      </Grid.Column> : null
                    }
                    <Grid.Column width={isTeacher?8:4}>
                      <label>Status</label>
                      <Dropdown placeholder='Select Status' fluid search selection name='assessmentStatus' value={assessmentStatus} onChange={this.handleInputChange} options={assessmentStatusList} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <label>Class Date</label><br />
                      <Input labelPosition='left' name='classStartDate' type='date' size='small'
                        value={classStartDate} onChange={this.handleInputChange} style={styles.dateInput}>
                        <Label>From</Label>
                        <input style={styles.dateInput} />
                      </Input>
                      <Input labelPosition='left' name='classEndDate' type='date' size='small'
                        value={classEndDate} onChange={this.handleInputChange} style={styles.dateInput}>
                        <Label style={styles.resetBorder}>To</Label>
                        <input style={Object.assign({}, styles.dateInput, styles.resetBorder)} />
                      </Input>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    {!isTeacher ?
                      <Grid.Column width={8}>
                        <label>Teacher</label>
                        <Dropdown placeholder='Select Teacher' fluid search selection name='teacherId' value={teacherId} onChange={this.handleInputChange} options={teacherList} />
                      </Grid.Column> : null
                    }
                    <Grid.Column width={8}>
                      <label>Course Name</label>
                      <Dropdown placeholder='Select Course' fluid search selection name='courseId' value={courseId} onChange={this.handleInputChange} options={courseList} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Card.Content>
            <Card.Content extra textAlign='right'>
              <Button className='ui primary button' onClick={() => this.submitSearch()}>Search</Button>
            </Card.Content>
          </Card>
        </Accordion.Content>
      </Accordion>
    )
  }

  renderTableHeader = () => {
    const { isTeacherAllBranch } = this.state
    return (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textAlign='center'>#</Table.HeaderCell>
          {isTeacherAllBranch ? <Table.HeaderCell textAlign='center'>Branch</Table.HeaderCell> : null}
          <Table.HeaderCell textAlign='center'>Course</Table.HeaderCell>
          <Table.HeaderCell textAlign='center'>Class Date</Table.HeaderCell>
          <Table.HeaderCell textAlign='center'>Time</Table.HeaderCell>
          <Table.HeaderCell textAlign='center'>Teacher</Table.HeaderCell>
          <Table.HeaderCell textAlign='center'>Assessment Done</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    )
  }

  renderTableBody = () => {
    const { searchData, isTeacherAllBranch } = this.state

    return (
      <Table.Body>
        {!searchData || searchData.length === 0 ?
          <Table.Row>
            <Table.Cell verticalAlign='middle' warning textAlign='center' colSpan={isTeacherAllBranch ? 8 : 7}>No Data Found</Table.Cell>
          </Table.Row> :
          searchData.map((item, index) => (
            <Table.Row key={index}>
              <Table.Cell textAlign='center'>{item.classId}</Table.Cell>
              {isTeacherAllBranch ? <Table.Cell textAlign='center'>{item.branchName}</Table.Cell> : null}
              <Table.Cell>{item.courseName}</Table.Cell>
              <Table.Cell textAlign='center'>{formatDateDisplay(item.classDate)}</Table.Cell>
              <Table.Cell textAlign='center'>{`${formatTimeDisplay(item.startTime)} - ${formatTimeDisplay(item.endTime)}`}</Table.Cell>
              <Table.Cell>
                {(!item.teachers)
                  ? '-'
                  : item.teachers.map((teacher, i) => <div key={i}><Icon name='user outline'></Icon>&nbsp; {teacher.nickName}</div>)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                <Label color={item.assessmentDone === item.totalAssessment && item.totalAssessment > 0 ? 'green' : null}>
                  {item.totalAssessment > 0 ? `${item.assessmentDone}/${item.totalAssessment}` : `-`}
                </Label>
              </Table.Cell>
              <Table.Cell textAlign='center'>
                <Button size='large' icon='edit' style={{backgroundColor: 'white', padding: '0px'}}
                    href={`/assessment/${item.classId}`} />
              </Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    )
  }

  renderFooter = () => {
    const { totalPages, totalData, activePage, pageSize, searchData } = this.state
    return (
      <Grid.Row columns={2}>
        <Grid.Column textAlign='left' verticalAlign='middle'>
          {totalData < 1 || !totalData
            ? null
            : `${((activePage - 1) * pageSize) + 1}-${((activePage - 1) * pageSize) + searchData.length} of ${totalData}`}
        </Grid.Column>
        <Grid.Column textAlign='right' verticalAlign='middle'>
          {totalPages < 1 || !totalPages
            ? null
            : this.renderPagination()}
        </Grid.Column>
      </Grid.Row>
    )
  }

  renderTable = () => {

    return (
      <Table size='small' celled>
        {this.renderTableHeader()}
        {this.renderTableBody()}
      </Table>
    )
  }

  renderPagination = () => {
    const { totalPages, activePage } = this.state
    return (
      !totalPages || totalPages < 1
        ? null
        : <Pagination
          activePage={activePage}
          firstItem={null}
          lastItem={null}
          pointing
          secondary
          totalPages={totalPages}
          onPageChange={this.handlePaginationChange}
        />
    )
  }

  render() {
    const { isLoading } = this.state
    if (isLoading) {
      return (
        <MainLayout>
          <Spinner />
        </MainLayout>
      )
    }
    return (
      <MainLayout>
        <Container style={styles.container}>
          <Grid centered stackable>
            <Grid.Row>
              <Grid.Column>
                {this.renderBreadcrumb()}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                {this.renderSearchForm()}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                {this.renderTable()}
              </Grid.Column>
            </Grid.Row>
            {this.renderFooter()}
          </Grid>
        </Container>
      </MainLayout>
    )
  }
}

export default withRouter(AssessmentSearch)
