import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import Footer from '../../components/Footer'
import NavBar from '../../components/NavBar'
import styles from './styles'

class MainLayout extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        document.title = this.props.title || 'Code Genius'
    }

    render() {
        const {id, classes, children, showLang = false, isFullScreen = false} = this.props

        return (
            <div className={classes.layout} id={id} style={isFullScreen ? {width: '100%'} : {}}>
                <NavBar showLang={showLang}/>
                {children}
                <Footer/>
            </div>
        )
    }
}

export default withStyles(styles)(MainLayout)
