import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Grid, Header, Image, Responsive } from 'semantic-ui-react'

import { styles } from './styles'

class RoadMapDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderLevelDescription(level, isEven) {
    return (
      <div
        style={
          isEven
            ? { ...styles.rightContent, ...styles.content }
            : { ...styles.leftContent, ...styles.content }
        }
      >
        <Header size="medium" style={styles.header}>
          {this.props.t('overview.' + level + '.name')}
        </Header>
        <Header.Subheader size="medium" style={styles.subHeader}>
          {this.props.t('overview.' + level + '.description')}
        </Header.Subheader>
      </div>
    )
  }

  renderMobileDescription(level) {
    return (
      <div style={{ ...styles.rightContent, paddingRight: '2em' }}>
        <Header size="medium" style={styles.header}>
          {this.props.t('overview.' + level + '.name')}
        </Header>
        <Header.Subheader
          size="large"
          style={{ ...styles.subHeader, fontSize: '14px' }}
        >
          {this.props.t('overview.' + level + '.description')}
        </Header.Subheader>
      </div>
    )
  }

  renderLevelImage(level, isMobile) {
    return (
      <Image
        src={`${process.env.PUBLIC_URL}/images/CoreCourses/${level}.jpg`}
        style={
          isMobile
            ? { ...styles.avatar, ...styles.avatarBorder, marginLeft: '1em' }
            : { ...styles.avatar, ...styles.avatarBorder }
        }
        fluid
        centered
      />
    )
  }

  renderLeftContent(level, isEven) {
    return (
      <Grid.Row>
        <Grid.Column width={6}>
          {this.renderLevelDescription(level, isEven)}
        </Grid.Column>
        <Grid.Column width={4}>{this.renderLevelImage(level)}</Grid.Column>
        <Grid.Column width={6} />
      </Grid.Row>
    )
  }

  renderRightContent(level, isEven) {
    return (
      <Grid.Row>
        <Grid.Column width={6} />
        <Grid.Column width={4}>{this.renderLevelImage(level)}</Grid.Column>
        <Grid.Column width={6}>
          {this.renderLevelDescription(level, isEven)}
        </Grid.Column>
      </Grid.Row>
    )
  }

  renderMobileContent(level) {
    return (
      <Grid>
        <Grid.Column width={5}>
          {this.renderLevelImage(level, true)}
        </Grid.Column>
        <Grid.Column width={11}>
          {this.renderMobileDescription(level)}
        </Grid.Column>
      </Grid>
    )
  }

  render() {
    const { levels } = this.props
    return (
      <div style={styles.roadmap}>
        <Responsive maxWidth={728}>
          <div
            style={{
              ...styles.timeline,
              ...styles.timelineMobile
            }}
          />
          <Grid>
            {levels.map(level => {
              return this.renderMobileContent(level)
            })}
            <Grid.Row>
              <Grid.Column width={5}>
                <Image
                  src={`${process.env.PUBLIC_URL}/images/CGFace.png`}
                  style={{ ...styles.avatar, marginLeft: '1em', backgroundColor: '#FFFFFF' }}
                  fluid
                  centered
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Responsive>
        <Responsive minWidth={729}>
          <div style={styles.timeline} />
          <Grid>
            {levels.map((level, index) => {
              const isEven = index % 2 === 0 ? true : false
              return isEven
                ? this.renderRightContent(level, isEven)
                : this.renderLeftContent(level, isEven)
            })}
            <Grid.Row>
              <Grid.Column width={16}>
                <Image
                  src={`${process.env.PUBLIC_URL}/images/CGFace.png`}
                  style={{ ...styles.avatar, backgroundColor: '#FFFFFF' }}
                  fluid
                  centered
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Responsive>
      </div>
    )
  }
}

export default withTranslation('corecourses')(RoadMapDetail)
