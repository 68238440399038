import React from 'react'

import { withI18next } from '../../lib/withI18next'
import RoadmapDetail from '../../components/RoadMapDetail'
import CGHeadline from '../../components/common/CGHeadline'
import MainLayout from '../../layouts/MainLayout'

class corecourses extends React.Component {
  renderCourseOverview() {
    const { t } = this.props
    const levels = ['firstLevel', 'secondLevel', 'thirdLevel', 'fourthLevel']
    return (
      <div>
        <div style={{ marginTop: '45px' }}>
          <CGHeadline
            header={t('roadmap.header')}
            subHeader={t('roadmap.subheader')}
          />
          <RoadmapDetail levels={levels} />
        </div>
      </div>
    )
  }

  render() {
    return <MainLayout showLang={true}>{this.renderCourseOverview()}</MainLayout>
  }
}

export default withI18next(['corecourses'])(corecourses)
