import React from "react"
import { PrintPage } from "../../../components/common"

let PrintTemplate;

class CodePrintForm extends React.Component {
    state = {
        codes: []
    }

    componentDidMount() {
        PrintTemplate = require("react-print")
        this.setState({isMounted: true})
    }

    async print(codes) {
        this.setState({ codes }, () => window.print())
    }

    renderReceiptGen() {
        return (
            <PrintTemplate>
                <PrintPage>
                    <ul style={{ paddingTop: "36px"}}>
                        {this.state.codes.map(c => {
                            return <li key={c} style={{ marginTop: "6px", fontSize: "14px" }}>{`passcode: ${c}`}</li>
                        })}
                    </ul>
                </PrintPage>
            </PrintTemplate>
        )
    }

    render() {
        if (!this.state.isMounted) return <div/>
        return (
            <PrintTemplate>
                <PrintPage>
                    {this.renderReceiptGen()}
                </PrintPage>
            </PrintTemplate>
        )
    }
}

export default CodePrintForm
