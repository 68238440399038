export default {
  container: {
    padding: '50px 30px 20px 30px',
    fontSize: '14px'
  },
  dateInput: {
    width: '50%'
  },
  resetBorder: {
    borderRadius: '0'
  },
  rankRow: {
    paddingLeft: '50px'
  },
  studentName: {
    width: '180px'
  },
  badge: {
    width: '60px',
    display: 'inline-flex',
    marginRight: '10px',
    cursor: 'pointer',
  },
  badgeSelected: {
    width: '70px',
    height: '70px',
    borderRadius: '100px',
    zIndex: '100',
    position: 'absolute',
    cursor: 'pointer',
    background: '#00965f',
    opacity: '0.3',
    margin: '-5px'
  }
}