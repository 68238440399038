import React, { Component } from 'react'
import { Button, Grid, Pagination, Table } from 'semantic-ui-react'
import { withRouter } from 'react-router'
import { getCurrentUser } from '../../../common/util'

class StudentList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tableData: props.tableData,
            tableCurrentColumn: props.sorting.column,
            tableDirection: props.sorting.direction,
            activePage: props.activePage || 1,
            totalPages: props.totalPages || 0,
            totalData: props.totalData || 0,
            pageSize: props.pageSize || 0
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.tableData !== state.tableData) {
            return {
                tableData: props.tableData,
                tableCurrentColumn: props.sorting.column,
                tableDirection: props.sorting.direction,
                activePage: props.activePage || 1,
                totalPages: props.totalPages || 0,
                totalData: props.totalData || 0,
                pageSize: props.pageSize || 0,
            }
        }
        return null
    }

    handlePaginationChange = (e, data) => {
        this.props.onPaginationChange(data.activePage)
    }

    handleSort = clickedColumn => {
        let tableDirection = 'asc'
        if (this.state.tableCurrentColumn === clickedColumn) {
            tableDirection = this.state.tableDirection === 'asc' ? 'desc' : 'asc'
        }
        this.props.onSortingChange(clickedColumn, tableDirection)
    }

    onEditStudent = async (studentId) => {
        const userInfo = getCurrentUser()
        await localStorage.setItem('user', JSON.stringify({...userInfo, selectedStudent: {studentId: studentId}}))
        this.props.history.push('/student/manage')
    }

    render() {
        const {tableCurrentColumn, tableDirection, tableData, totalPages, activePage, totalData, pageSize} = this.state

        return (
            <Grid style={{margin: '0'}}>
                <Table celled sortable compact style={{padding: '0'}} size='small'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell textAlign='center' width='2'
                                              sorted={tableCurrentColumn === 'studentId' ? convertSorting(tableDirection) : null}
                                              onClick={() => this.handleSort('studentId')}>Student ID</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='3'
                                              sorted={tableCurrentColumn === 'name' ? convertSorting(tableDirection) : null}
                                              onClick={() => this.handleSort('name')}>Name</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='1'
                                              sorted={tableCurrentColumn === 'nickName' ? convertSorting(tableDirection) : null}
                                              onClick={() => this.handleSort('nickName')}>Nickname</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='3'
                                              sorted={tableCurrentColumn === 'school' ? convertSorting(tableDirection) : null}
                                              onClick={() => this.handleSort('school')}>School</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='2'
                                              sorted={tableCurrentColumn === 'tel' ? convertSorting(tableDirection) : null}
                                              onClick={() => this.handleSort('tel')}>Tel</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='1'
                                              sorted={tableCurrentColumn === 'ads' ? convertSorting(tableDirection) : null}
                                              onClick={() => this.handleSort('ads')}>ADS</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='1'
                                              sorted={tableCurrentColumn === 'picsRights' ? convertSorting(tableDirection) : null}
                                              onClick={() => this.handleSort('picsRights')}>Pics</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width='1'></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {!tableData || tableData.length === 0 ?
                            <Table.Row><Table.Cell verticalAlign='middle' warning textAlign='center' colSpan='8'>
                                No Data Found</Table.Cell></Table.Row> :
                            tableData.map((item, index) => (
                                <Table.Row key={index}>
                                    <Table.Cell verticalAlign='middle' textAlign='center'>{item.studentId}</Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign={window.innerWidth > 760 ? 'left':'center'}>
                                        {item.name}
                                    </Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign='center'>{item.nickName}</Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign={window.innerWidth > 760 ? 'left':'center'}>
                                        {item.school}
                                    </Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign='center'>{item.tel}</Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign='center'>
                                        {item.ads === 1 ? 'Y' : 'N'}</Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign='center'>
                                        {item.picsRights === 1 ? 'Y' : 'N'}</Table.Cell>
                                    <Table.Cell verticalAlign='middle' textAlign='center'>                                        
                                        <Button
											onClick={() => this.onEditStudent(item.studentId)}
											style={{backgroundColor: 'white'}}>
										    <i className='pencil icon' style={{fontSize: '1em'}}/>
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            ))
                        }
                    </Table.Body>
                </Table>
                <Grid.Row columns={2}>
                    <Grid.Column textAlign={window.innerWidth > 760 ? 'left':'center'} verticalAlign='middle' computer='8' tablet='4' mobile='16'>
                        {totalData < 1 || !totalData
                            ? null
                            : `${((activePage - 1) * pageSize) + 1}-${((activePage - 1) * pageSize) + tableData.length} of ${totalData}`
                        }
                    </Grid.Column>
                    <Grid.Column textAlign={window.innerWidth > 760 ? 'right':'center'} verticalAlign='middle' computer='8' tablet='12' mobile='16'>
                        {totalPages < 1 || !totalPages
                            ? null
                            : <Pagination
                                defaultActivePage={activePage}
                                pointing
                                secondary
                                boundaryRange={window.innerWidth > 760 ? 1:0}
                                siblingRange={window.innerWidth > 760 ? 1:0}
                                ellipsisItem={window.innerWidth > 760 ? undefined : null}
                                totalPages={totalPages}
                                onPageChange={this.handlePaginationChange}
                            />
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

export default withRouter(StudentList)

const convertSorting = type => {
    return type === 'asc' ? 'ascending' : 'descending'
}