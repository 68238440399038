export const styles = {
  card: {
    minWidth: '300px',
    width: '45%',
    padding: '10px 20px 20px 20px',
    margin: '30px'
  },
  nextButton: {
    width: '100%'
  },
  backButton: {
    color: '#000000',
    backgroundColor: '#FCD634',
    width: '100%'
  }
}
