import React from 'react'
import { withRouter } from 'react-router'
import { withI18next } from '../../lib/withI18next'
import { Grid, Segment, Form, Input, Icon, Message } from 'semantic-ui-react'

import * as EmailValidator from 'email-validator'
import MainLayout from '../../layouts/MainLayout'
import AddUserInfo from '../../components/AddUserInfo'
import CGButton from '../../components/common/CGButton/index'
import { styles } from './styles'

class signup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      errorMsg: '',
      showPassword: false,
      showCPassword: false,
      validateResult: false
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  handleClickShowPassword = (name, value) => {
    this.setState({
      [name]: !value
    })
  }

  checkboxHandleChange = i => {
    let changeValue = this.state.channel
    changeValue.value[i] = !changeValue.value[i]

    this.setState({
      channel: changeValue
    })
  }

  validateData = () => {
    let errorMsg = ''

    if (this.state.email === null || this.state.email === '') {
      errorMsg = 'Please fill in your email address.'
      this.setState({ errorMsg: errorMsg })
      this.email.focus()
    } else if (!EmailValidator.validate(this.state.email)) {
      errorMsg = 'Your email address is invalid.'
      this.setState({ errorMsg: errorMsg })
      this.email.focus()
    } else if (this.state.password === null || this.state.password === '') {
      errorMsg = 'Please fill in your password.'
      this.setState({ errorMsg: errorMsg })
      this.password.focus()
    } else if (
      this.state.confirmPassword === null ||
      this.state.confirmPassword === ''
    ) {
      errorMsg = 'Please fill in your confirm password.'
      this.setState({ errorMsg: errorMsg })
      this.confirmPassword.focus()
    } else if (this.state.password !== this.state.confirmPassword) {
      errorMsg = 'The both password do not matched.'
      this.setState({ errorMsg: errorMsg })
      this.confirmPassword.focus()
    } else {
      this.setState({
        errorMsg: '',
        validateResult: true
      })
    }
  }

  showError = () => {
    if (this.state.errorMsg !== '') {
      return (
        <Message
          color="red"
          icon="exclamation circle"
          content={this.state.errorMsg}
          size="small"
        />
      )
    }
  }

  hideAddUserInfo = () => {
    this.setState({
      validateResult: false
    })
  }

  renderContent() {
    if (this.state.validateResult) {
      return (
        <AddUserInfo
          email={this.state.email}
          password={this.state.password}
          hideFunction={this.hideAddUserInfo}
        />
      )
    } else {
      return (
        <Form>
          <Form.Field>{this.showError()}</Form.Field>
          <Form.Field>
            <Input
              ref={field => (this.email = field)}
              placeholder="Email Address"
              value={this.state.email}
              onChange={this.handleChange('email')}
            />
          </Form.Field>
          <Form.Field>
            <Input
              ref={field => (this.password = field)}
              placeholder="Password"
              type={this.state.showPassword ? 'text' : 'password'}
              value={this.state.password}
              onChange={this.handleChange('password')}
              icon={
                <Icon
                  name={this.state.showPassword ? 'unhide' : 'hide'}
                  link
                  onClick={() =>
                    this.handleClickShowPassword(
                      'showPassword',
                      this.state.showPassword
                    )
                  }
                />
              }
            />
          </Form.Field>
          <Form.Field>
            <Input
              ref={field => (this.confirmPassword = field)}
              placeholder="Confirm Password"
              type={this.state.showCPassword ? 'text' : 'password'}
              value={this.state.confirmPassword}
              onChange={this.handleChange('confirmPassword')}
              icon={
                <Icon
                  name={this.state.showCPassword ? 'unhide' : 'hide'}
                  link
                  onClick={() =>
                    this.handleClickShowPassword(
                      'showCPassword',
                      this.state.showCPassword
                    )
                  }
                />
              }
            />
          </Form.Field>
          <Form.Field>
            <Grid>
              <Grid.Column width={6}>
                <CGButton
                  style={styles.backButton}
                  onClick={() => this.props.history.push('/signin')}
                >
                  Back
                </CGButton>
              </Grid.Column>
              <Grid.Column width={10} style={{ paddingLeft: 0 }}>
                <CGButton
                  style={styles.nextButton}
                  onClick={() => this.validateData()}
                >
                  Next
                </CGButton>
              </Grid.Column>
            </Grid>
          </Form.Field>
        </Form>
      )
    }
  }

  render() {
    return (
      <MainLayout showLang={false}>
        <div>
          <Grid>
            <Grid.Row centered>
              <Segment style={styles.card}>{this.renderContent()}</Segment>
            </Grid.Row>
          </Grid>
        </div>
      </MainLayout>
    )
  }
}

export default withRouter(withI18next(['signup'])(signup))
