import React from 'react'
import { getCurrentUser, isUndefined, removeToken } from '../../common/util'
import { validateToken } from '../../apis/auth'
import Desktop from './Desktop'
import Mobile from './Mobile'
import { withRouter } from 'react-router'
import { withTranslation } from 'react-i18next'

class NavBar extends React.Component {
    state = {}

    async componentDidMount() {
        const userInfo = getCurrentUser()

        if (!isUndefined(userInfo)) {
            if (new Date() >= new Date(userInfo.exp * 1000)) {
                const result = await validateToken()
                if (!result) {
                    removeToken()
                    this.props.history.push('/signin')
                }
            }
            this.setState({
                currentUser: getCurrentUser()
            })
        }
    }

    changeLanguage = (lang) => {
        // let url = new URL(window.location)
        // url.searchParams.set('lang', lang)
        // window.location = url.toString()
        this.props.i18n.changeLanguage(lang);
    }


    render() {
        const { showLang } = this.props
        const { currentUser } = this.state

        return (
            <React.Fragment>
                <Mobile
                    showLang={showLang}
                    currentUser={currentUser}
                    changeLanguage={this.changeLanguage}
                />
                <Desktop
                    showLang={showLang}
                    currentUser={currentUser}
                    changeLanguage={this.changeLanguage}
                />
            </React.Fragment>
        )
    }
}

export default withRouter(withTranslation()(NavBar))