import React from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

const CGLink = withStyles({ 
    label: { textTransform: 'none' },
    link: { textTransform: 'none', textDecoration: 'none', display: 'block' },
})(({ children, href, color, ...args }) => (
    <Link to={href||'#'} className={args.classes.link} >
        <Button fullWidth color={color||'primary'} {...args}>
            {children}
        </Button>
    </Link>
))

export { CGLink }