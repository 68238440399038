import React, {Component} from 'react'
import { Form, Header, Input, Dropdown, Button, Label, Accordion, Card, Message, Breadcrumb, Grid} from 'semantic-ui-react'
import {withRouter} from 'react-router'
import {withI18next} from '../../../lib/withI18next'
import MainLayout from '../../../layouts/MainLayout'
import styles from './styles'
import apiClient from '../../../apis';
import {getCurrentUser, isUndefined} from '../../../common/util'
import Spinner from '../../../components/common/Spinner'
import { SwatchesPicker } from 'react-color'

class TeacherManage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            teacherData: {
                teacherId: '',
                firstName: '',
                lastName: '',
                nickName: '',
                email: '',
                mobileNumber: '',
                status: '',
                color: '',
                isFullTime: '',
                userId: null,
            },
            displayColorPicker: false,
            isShowTool: true,
            isLoading: true,
            isSaved: false,
            textColor: 'Black',
            errorMsg: '',
            error: {},
            selectedTeacher: null,
        }
    }

    componentDidMount() {
        const userInfo = getCurrentUser()
        if (isUndefined(userInfo)) {
            this.props.history.push('/home')
        }

        if (userInfo.selectedTeacher) {
            this.getTeacherProfile(userInfo.selectedTeacher)
        } else {
            this.getUserListAvailable()
            this.setState({
                isLoading: false,
                editTeacherProfileFlag: false
            })
        }
        
    }

    getUserListAvailable = async () => {
        this.setState({ isLoading: true })
        const { thisTeacherText, teacherData } = this.state
        const result = await apiClient.teacher.getUserListAvailable()
        if (result !== null) {
            let arr = result.map((p, index) => {
                return {
                    key: index,
                    text: p.name,
                    value: p.id,
                }
            })

            if (thisTeacherText) {
                arr.unshift({ key: -1, text: thisTeacherText, value: teacherData.userId})
                arr.unshift({ key: -2, text: 'None', value: null })
            }else{
                arr.unshift({ key: -1, text: 'None', value: null })
            }
            
            this.setState({
                teacherUserId: arr,
                isLoading: false,
            })
        }
    }

    componentDidUpdate(prevState) {
        if (prevState.teacherData !== this.state.teacherData) {
            this.renderFillInForm()
        }
    }

    getTeacherProfile = async(teacherId) => {
        const teacherProfile = await apiClient.teacher.getTeacherProfile( teacherId )
        await this.setState({
            isLoading: false,
            editTeacherProfileFlag: true,
            teacherData: {
                ...this.state.teacherData,
                teacherId: teacherProfile.teacherId,
                firstName: teacherProfile.firstName,
                lastName: teacherProfile.lastName,
                nickName: teacherProfile.nickName,
                email: teacherProfile.email,
                mobileNumber: teacherProfile.mobileNumber,
                status: teacherProfile.status,
                color: '#' + teacherProfile.color,
                isFullTime: teacherProfile.isFullTime,
                userId: teacherProfile.userId,
            }
        })
        if (teacherProfile.userId)  {
            await this.setState({
                thisTeacherText: '[' + this.state.teacherData.userId + '] ' + this.state.teacherData.firstName + ' (' + this.state.teacherData.nickName + ')',
            })
        }

        await this.getUserListAvailable()
        await this.hexToRgbCheckBrightness()
    }

    handleclearItem = () => {
        this.setState({
            teacherData: {
                teacherId: '',
                firstName: '',
                lastName: '',
                nickName: '',
                email: '',
                mobileNumber: '',
                status: '',
                color: '',
                isFullTime: '',
                userId: null,
            },
            selectedTeacher: null,
            editTeacherProfileFlag: false,
            isSaved: false,
            errorMsg: '',
        })
    }

    handleNumberChange = (name) => (event) => {
        if (/^\d*$/.test(event.target.value)) {
            this.handleChange(name)(event)
        }
    }

    handleAddition = (e, { value }) => {
        this.setState((prevState) => ({
            options: [{ text: value, value }, ...prevState.options],
        }))
    }

    handleChange = (name) => (event) => {
        this.setState({
            error: {},
            errorMsg: '',
            teacherData: {
                ...this.state.teacherData,
                [name]: event.target.value,
            },
        })
    }

    handleDropdownChange = (name) => (event, { value }) => {
        this.setState({
            error: {},
            errorMsg: '',
            teacherData: {
                ...this.state.teacherData,
                [name]: value,
            },
        })
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    }

    handleColorChange = async (color) => {
        await this.setState({
            teacherData: {
                ...this.state.teacherData,
                color: color.hex,
            },
            displayColorPicker: false,
        })
        await this.hexToRgbCheckBrightness ()
    }

    hexToRgbCheckBrightness = () => {
        const color = this.state.teacherData.color
        const colorRgb = color
            .replace(
                /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
                (r, g, b) => '#' + r + r + g + g + b + b
            )
            .substring(1)
            .match(/.{2}/g)
            .map((x) => parseInt(x, 16))
        
        const brightness = Math.round(
            (parseInt(colorRgb[0]) * 299 +
                parseInt(colorRgb[1]) * 587 +
                parseInt(colorRgb[2]) * 114) /
                1000
        )

        if (brightness > 125) {
            this.setState ({
                textColor: 'black'
            })
        }else{
            this.setState ({
                textColor: 'white'
            })
        }
    }

    breadcrumbInForm = () => {
        const { teacherData } = this.state
        let contentText
        if (teacherData.teacherId) {
            contentText = `${this.state.teacherData.firstName} ${this.state.teacherData.lastName} (${this.state.teacherData.nickName})`
        } else {
            contentText = `New teacher`
        }

        const sections = [
            { key: 'Home', content: 'Home', href: '/Home' },
            { key: 'ManageTeacher', content: 'Teacher', href: '/Teacher/search' },
            { key: 'info', content: [contentText] },
        ]

        return (
            <Breadcrumb icon='right angle' sections={sections} size='large' />
        )
    }

    renderFillInForm = () => {
        const { teacherData, error, teacherUserId, errorMsg, editTeacherProfileFlag, textColor } = this.state
        return (
            <Card fluid>
                <Card.Content>
                    <Form style={{ margin: '0' }}>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Firstname</label>
                                <Form.Input
                                    placeholder='Firstname'
                                    onChange={this.handleChange('firstName')}
                                    value={teacherData.firstName}
                                    error={error.firstName}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Lastname</label>
                                <Form.Input
                                    placeholder='Lastname'
                                    onChange={this.handleChange('lastName')}
                                    value={teacherData.lastName}
                                    error={error.lastName}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Nickname</label>
                                <Form.Input
                                    placeholder='Nickname'
                                    onChange={this.handleChange('nickName')}
                                    value={teacherData.nickName}
                                    error={error.nickName}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Email</label>
                                <Form.Input
                                    placeholder='Email'
                                    onChange={this.handleChange('email')}
                                    value={teacherData.email}
                                    error={error.email}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Mobile Number</label>
                                <Form.Input
                                    placeholder='Mobilenumber'
                                    onChange={this.handleNumberChange(
                                        'mobileNumber'
                                    )}
                                    value={teacherData.mobileNumber}
                                    maxLength={10}
                                    error={error.mobileNumber}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Type</label>
                                <Dropdown
                                    className='field'
                                    placeholder='Select type'
                                    value={teacherData.isFullTime}
                                    onChange={this.handleDropdownChange(
                                        'isFullTime'
                                    )}
                                    options={[
                                        {
                                            key: 'true',
                                            text: 'Full-time',
                                            value: 1,
                                        },
                                        {
                                            key: 'false',
                                            text: 'Part-time',
                                            value: 0, 
                                        },
                                    ]}
                                    search
                                    selection
                                    error={error.type}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Status</label>
                                <Dropdown
                                    className='field'
                                    placeholder='Select status'
                                    value={editTeacherProfileFlag === false? teacherData.status='ACTIVE':teacherData.status}
                                    onChange={this.handleDropdownChange(
                                        'status'
                                    )}
                                    options={[
                                        {
                                            key: 'true',
                                            text: 'Active',
                                            value: 'ACTIVE',
                                        },
                                        {
                                            key: 'false',
                                            text: 'Cancel',
                                            value: 'CANCEL',
                                            disabled: editTeacherProfileFlag === false? true:false ,
                                        },
                                    ]}
                                    search
                                    selection
                                    error={error.status}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>User</label>
                                <Dropdown
                                    className='field'
                                    placeholder='Select userId'
                                    onAddItem={this.handleAddition}
                                    value={teacherData.userId}
                                    onChange={this.handleDropdownChange(
                                        'userId'
                                    )}
                                    options={teacherUserId}
                                    search
                                    selection
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Color</label>
                                <Input labelPosition='left'>
                                    <Label
                                        className='field'
                                        style={{
                                            backgroundColor: teacherData.color,
                                            color: textColor,
                                        }}
                                        size='large'>
                                        {teacherData.color
                                            ? teacherData.color
                                                  .toUpperCase()
                                                  .toString()
                                            : 'Pick color'}
                                    </Label>
                                    <Button
                                        onClick={this.handleClick}
                                        icon='search'
                                    />
                                </Input>
                                {this.state.displayColorPicker ? (
                                    <div style={styles.popover}>
                                        <SwatchesPicker
                                            onChange={this.handleColorChange}
                                            value={teacherData.color}
                                            error={error.color}
                                        />
                                    </div>
                                ) : null}
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Card.Content>
                <Card.Content extra textAlign='right'>
                    <Grid.Column floated='right' style={{display: 'flex'}}>
                        {errorMsg && (
                                <Message compact
                                    style={styles.errorMessageContainer}
                                    color='red'
                                    floated='left'
                                    size='small'>
                                    {errorMsg}
                                </Message>
                            )}
                        <Button
                                positive
                                labelPosition='right'
                                icon='checkmark'
                                floated='right'
                                content={
                                    editTeacherProfileFlag ? 'Save' : 'Register'
                                }
                                onClick={() => this.validateData()}
                            />
					</Grid.Column>  
                </Card.Content>
            </Card>
        )
    }

    validateData = async () => {
        if (
            this.state.teacherData.firstName === null ||
            this.state.teacherData.firstName === ''
        ) {
            this.setState({
                errorMsg: this.getErrorText('firstName'),
                error: { firstName: true },
            })
        } else if (
            this.state.teacherData.lastName === null ||
            this.state.teacherData.lastName === ''
        ) {
            this.setState({
                errorMsg: this.getErrorText('lastName'),
                error: { lastName: true },
            })
        } else if (
            this.state.teacherData.nickName === null ||
            this.state.teacherData.nickName === ''
        ) {
            this.setState({
                errorMsg: this.getErrorText('nickName'),
                error: { nickName: true },
            })
        } else if (
            this.state.teacherData.email === null ||
            this.state.teacherData.email === ''
        ) {
            this.setState({
                errorMsg: this.getErrorText('email'),
                error: { email: true },
            })
        } else if (this.state.teacherData.mobileNumber.length === 0) {
            this.setState({
                errorMsg: this.getErrorText('mobileNumber'),
                error: { mobileNumber: true },
            })
        } else if (this.state.teacherData.mobileNumber.length !== 10) {
            this.setState({
                errorMsg: this.getErrorText('mobileNumberFormat'),
                error: { mobileNumber: true },
            })
        } else if (
            this.state.teacherData.isFullTime === null ||
            this.state.teacherData.isFullTime === ''
        ) {
            this.setState({
                errorMsg: this.getErrorText('type'),
                error: { isFullTime: true },
            })
        } else if (
            this.state.teacherData.status === null ||
            this.state.teacherData.status === ''
        ) {
            this.setState({
                errorMsg: this.getErrorText('status'),
                error: { status: true },
            })
        } else if (
            this.state.teacherData.color === null ||
            this.state.teacherData.color === ''
        ) {
            this.setState({
                errorMsg: this.getErrorText('color'),
                error: { color: true },
            })
        } else {
            this.doSave()
        }
    }

    getErrorText = (name) => {
        return ERROR_TEXT[name]
    }

    doSave = async () => {
        const { editTeacherProfileFlag, teacherData } = this.state
        this.setState({ 
            isLoading: true,
            errorMsg: '', 
            error: {} })
        ;(await editTeacherProfileFlag) ? this.updateTeacherProfile(teacherData): this.addTeacher(teacherData)
    }

    updateTeacherProfile = async (teacherData) => {
        const result = await apiClient.teacher.updateTeacherProfile(teacherData)

        if (result.status === 'success') {
            this.setState({
                errorMsg: '',
                isSaved: true,
                isLoading: false,
            })
        } else {
            this.setState({
                errorMsg: result.message,
                isLoading: false,
            })
        }

        return result
    }

    addTeacher = async (teacherData) => {
        const result = await apiClient.teacher.addTeacher(teacherData)

        if (result.status === 'success') {
            this.setState({
                teacherData: {
                    ...this.state.teacherData,
                    teacherId: result.teacherId,
                    userId: result.userId,
                },
                errorMsg: '',
                editTeacherProfileFlag: true,
                isSaved: true,
                isLoading: false,
            })
        } else {
            this.setState({
                errorMsg: result.message,
                isLoading: false,
            })
        }

        return result
    }

    render() {
        const { isLoading } = this.state

        if (isLoading) {
            return (
                <MainLayout>
                    <Spinner />
                </MainLayout>
            )
        }

        return (
            <MainLayout id='react-no-print'>
                <div style={styles.container}>
                    <Accordion fluid>
                        <Accordion.Title>
                            <Header
                                textalign='left'
                                style={{ color: '#00B3C5' }}
                                size='large'>
                                {this.breadcrumbInForm()}
                            </Header>
                        </Accordion.Title>
                        <Accordion.Content active={this.state.isShowTool}>
                            {this.renderFillInForm()}
                        </Accordion.Content>
                    </Accordion>
                </div>
            </MainLayout>
        )
    }
}


export default withRouter(withI18next()(TeacherManage))

const ERROR_TEXT = {
    firstName: 'Firstname is required.',
    lastName: 'Lastname is required.',
    nickName: 'Nickname is required.',
    email: 'Email is required.',
    mobileNumber: 'Mobile number is required.',
    mobileNumberFormat: 'Invalid mobile number.',
    status: 'Status is required.',
    type: 'Type is required.',
    color: 'Color is required.',
}
